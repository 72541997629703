import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { vm_transactions } from 'app/base/window/Basewindow.data';
import { VMUserData } from 'app/base/global.data';
import { UserService } from 'app/base/userservice';

@Component({
    selector     : 'navbar-vertical-style-1',
    templateUrl  : './style-1.component.html',
    styleUrls    : ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;
    ls_userid:string
    vmTrans:vm_transactions;
    lVMUserData:VMUserData;
    ls_userName: string
    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private authenticationService: UserService,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        if (this.authenticationService.loggedIn()) {
            this.globalData()
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective)
    set directive(theDirective: FusePerfectScrollbarDirective)
    {
        if ( !theDirective )
        {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                    setTimeout(() => {
                        const activeNavItem: any = document.querySelector('navbar .nav-link.active');

                        if ( activeNavItem )
                        {
                            const activeItemOffsetTop       = activeNavItem.offsetTop,
                                  activeItemOffsetParentTop = activeNavItem.offsetParent.offsetTop,
                                  scrollDistance            = activeItemOffsetTop - activeItemOffsetParentTop - (48 * 3) - 168;

                            this._fusePerfectScrollbar.scrollToTop(scrollDistance);
                        }
                    });
                }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    { 
        this.ls_userid=localStorage.getItem('vg_userid')
        
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if ( this._fuseSidebarService.getSidebar('navbar') )
                    {
                        this._fuseSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void
    {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void
    {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }

    ini_vm_transactions(){
        let ls_dynstructure:  vm_transactions= { 
            Result :"",
            stringjson :"",
            strProcess :"",
            authcode:"",
            report_code:"",
            report_name:"",
            report_sr_no:null,
            report_type:null,
            vmd:""
        }
        return ls_dynstructure
    }
    ini_vm_user(){
        let ls_dynstructure:  VMUserData= { 
            equ_group : "", 
            user_id : "", 
            name : "", 
            counter : null, 
            fy_code : "", 
            password : "", 
            ac_creat_right : "", 
            is_gpmember : "", 
            security_level : "", 
            save_on_exit : "", 
            doc_expiry_notification : "", 
            cost_int : "", 
            payroll_rights : "", 
            dept_code : "", 
            password_change_date : null,  
            active_ind : "", 
            theme : "", 
            change_password : "", 
            login_period_applicable : "", 
            login_period : "", 
            profile_code : "", 
            language_code : "", 
            user_ip_address : "", 
            user_ip_restriction : "", 
            acc_code : "", 
            email_address : "", 
            default_co : "", 
            default_co_name : "", 
            today : null,  
            multi_currency : "", 
            sys_cur_code : "", 
            job_analysis_int : "", 
            job_analysis_type : null, 
            internal_entry_indicator : "", 
            qty_decimal : null, 
            rate_decimal : null, 
            amt_decimal : null, 
            s_dt_fy : null,  
            e_dt_fy : null,  
            verify_must : "", 
            message_delay : null, 
            company_name : "", 
            ddw_acccode : "", 
            ddw_acccodewithfilter : "", 
            company_license : null, 
            blob_attachment : "", 
            arabic_enabled : "", 
            app_confirm : "", 
            save_ack : "", 
            save_confim : "", 
            delete_confim : "", 
            delete_ask : "", 
            ite_group_t : "", 
            ite_family_t : "", 
            ite_class_t : "", 
            ite_commodity_t : "", 
            ite_custom_1_t : "", 
            ite_custom_2_t : "", 
            ite_custom_3_t : "", 
            ite_custom_4_t : "", 
            ite_custom_5_t : "", 
            ite_custom_6_t : "", 
            eas_required : "", 
            wbs_master_name : "", 
            audit_log_link : "", 
            section_levels : null, 
            dashboard_required : "", 
            doc_notification_required : "", 
            todlist_required : "", 
            tasks_required : "", 
            bi_required : "", 
            timer : "", 
            eas_popup_interval : null, 
            dash_board : "", 
            role_id:"",
            Result :"", 
            external_user:"",
            ref_acc_code:"",
            gs_boq:"",
            locale_id:"",
            address:"",
            mobile_no : null ,
            trade_license_no : "" , 
            customer_type:"C", 
            emirates_id:"", 
            firstname:"" ,
            last_name:"",
            comapany_name_t:"",
            sms_pending_count: null ,
            firstnamear:null ,
            approval_edit_rights:null ,
        }
        return ls_dynstructure
    }

    // global data
    async globalData(){

        this.vmTrans= this.ini_vm_transactions() 
        this.vmTrans.strProcess="sp_user_Userinfo"
        this.lVMUserData=this.ini_vm_user()
             
        this.vmTrans.stringjson=  JSON.stringify(this.lVMUserData) ;  
        this.vmTrans =await this.authenticationService.getData(this.vmTrans) 
        if(this.vmTrans.Result=="Success"){
            this.lVMUserData=JSON.parse(this.vmTrans.stringjson);
            localStorage.setItem('gsdata', JSON.stringify(this.lVMUserData));
            this.ls_userName = this.lVMUserData.name;
        }
        else{ 
            alert( this.vmTrans.Result)  
            
        }  

      

    }
}
