

    <mat-card>
            <!-- <mat-card-title class="backgroud accent">Search by :  {{vmSearch.Result}}</mat-card-title> -->

           

                  
    <div>
     
        <div class="buttons">
            <button  (click)="ue_new()" mat-raised-button color="accent">New </button>
            <button  (click)="ue_browse()" mat-raised-button color="accent">Browse </button>
            <button  (click)="ue_attach()" mat-raised-button color="accent">Attach </button>
            <button  (click)="ue_save()" mat-raised-button color="accent">Save </button>
            <!-- <button  (click)="ue_send()" mat-raised-button color="accent">Send </button> -->
            <button  (click)="ue_close()" mat-raised-button color="accent">Close </button>
        </div>    
        <br>
       <div class="row">

            <mat-form-field  appearance="outline" class="ng-form-x-sm">
                <mat-label>Vr #</mat-label> 
                <input matInput [value]="vmData.vr_no" [(ngModel)]="vmData.vr_no" [readonly]="true" >
            </mat-form-field>
 
            <mat-form-field  appearance="outline" class="ng-form-md">
                <mat-label>From User</mat-label> 
                <input matInput [value]="vmData.from_user" [(ngModel)]="vmData.from_user" [readonly]="true"   >
            </mat-form-field>
       </div>    
        <div class="row">
            <mat-form-field  appearance="outline" class="ng-form-lg">
                <mat-label>To User</mat-label> 
                <input matInput [value]="vmData.to_user" [(ngModel)]="vmData.to_user" [readonly]="true" (click)="to_user()" >
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field  appearance="outline" class="ng-form-sm">
                <mat-label>Ref Vr #</mat-label> 
                <input matInput [value]="vmData.ref_m_vr_no" [(ngModel)]="vmData.ref_m_vr_no" [readonly]="true" >
            </mat-form-field>
            <mat-form-field  appearance="outline" class="ng-form-sm">
                <mat-label>Co.</mat-label> 
                <input matInput [value]="vmData.ref_m_fy_code" [(ngModel)]="vmData.ref_m_fy_code" [readonly]="true" >
            </mat-form-field>
            <mat-form-field  appearance="outline" class="ng-form-sm">
                <mat-label>TRC</mat-label> 
                <input matInput [value]="vmData.ref_m_trc_code" [(ngModel)]="vmData.ref_m_trc_code" [readonly]="true" >
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field  appearance="outline"  >
                <mat-label>Message</mat-label> 
                <textarea matInput [(ngModel)]="vmData.message" name="vmData.message"     > </textarea>
            </mat-form-field>
        </div>
        
    </div>

    
    <mat-card>
        <mat-card-title class="backgroud accent">History</mat-card-title>

        <div class=" table-wrapper mat-elevation-z0">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

                
                <ng-container matColumnDef="vr_no">
                    <div class="space-table">    
                        <th class="text-center" mat-header-cell *matHeaderCellDef  class="ng-xx-sm"> Vr # </th>
                        <td mat-cell *matCellDef="let element ; let i = index" class="ng-xx-sm">
                            
                            <div class="space-table">
                                    <p class="text-truncate font-weight-500">{{ element.vr_no  }}</p>
                            </div>
                        </td>
                    </div> 
                </ng-container> 

                <ng-container matColumnDef="vr_date">
                    <div class="space-table">    
                        <th class="text-center" mat-header-cell *matHeaderCellDef  class="ng-sm"> Vr Date </th>
                        <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                            <div class="space-table">
                                <p class="text-truncate font-weight-500">{{ element.vr_date | date:'dd/MM/yyyy'}}</p>
                            </div>
                            
                        </td>
                    </div>
                </ng-container> 
    

                <ng-container matColumnDef="from_user">
                    <div class="space-table">    
                        <th class="text-center" mat-header-cell *matHeaderCellDef  class="ng-sm"> From User </th>
                        <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                            <div class="space-table">
                                <p class="text-truncate font-weight-500">{{ element.from_user  }}</p>
                            </div> 
                        </td>
                    </div>
                </ng-container> 

                <ng-container matColumnDef="to_user">
                    <div class="space-table">    
                        <th class="text-center" mat-header-cell *matHeaderCellDef  class="ng-sm"> To User </th>
                        <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                            <div class="space-table">
                                <p class="text-truncate font-weight-500">{{ element.to_user  }}</p>
                            </div> 
                        </td>
                    </div>
                </ng-container> 

                <ng-container matColumnDef="message">
                    <div class="space-table">    
                        <th class="text-center" mat-header-cell *matHeaderCellDef   > message </th>
                        <td mat-cell *matCellDef="let element ; let i = index"  >
                            <mat-form-field appearance="outline">
                                <textarea matInput [(ngModel)]="element.message" name="element.message"  [readonly]="true"      > </textarea>
                            </mat-form-field>
                            
                        </td>
                    </div>
                </ng-container>

                <ng-container matColumnDef="attach">
                    <div class="space-table">    
                        <th class="text-center" mat-header-cell *matHeaderCellDef   > Attach </th>
                        <td mat-cell *matCellDef="let element ; let i = index"  > 
                            <button (click)="attach_det(element)">Attach</button>
                                <!-- <textarea matInput [(ngModel)]="element.message" name="element.message"  [readonly]="true"      > </textarea> -->
                          
                            
                        </td>
                    </div>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; let i = index; columns: displayedColumns;" (click)="setRow(element)"></tr>
            </table>
        </div>
    </mat-card>
</mat-card>
