import {Component,ViewChild,Inject} from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { UserService } from 'app/base/userservice'; 
import { BaseWindow } from 'app/base/window/BaseWindow.component';
import { ActivatedRoute, Router } from '@angular/router';
import { globalData } from 'app/base/globalData';
import { basefunctionsService } from 'app/base/basefunctionsService';  
import { DatePipe } from '@angular/common';
import { reportFilterService } from './reportFilter.service';
import { VMSearch, VMCommonQuery} from 'app/base/search/search.data'; 
import { ReportObject, s_reports } from 'app/main/Reports/ReportFrame/ReportFrame.data';
import { s_transinformation, VMs_transinfo, vm_transactions } from '../window/Basewindow.data';
import { SearchComponent } from '../search/search.component';
@Component({
    selector:'app-report-filter',
    templateUrl:'./reportFilter.component.html',
    styleUrls:['./reportFilter.component.scss'],
    providers:[DatePipe,reportFilterService]
})

export class reportFilterComponent
{
    ls_VMCommonQuery :VMCommonQuery;
    @ViewChild('reportInput') reportInput;
    vmReportFilters:s_reports 
    ls_selOption:string
    lVMSearch:VMSearch;
    ls_report1:string;
    ls_report2:string;
    ls_report3:string;
    lvrno:string
    lfycode:string
    ltrccode:string
    vmTrans:vm_transactions;
    ld_fromdate:Date
    ls_rangeoption="N" 
    constructor( 
        public config: globalData,
        private dialogRef: MatDialogRef<reportFilterComponent>,
        @Inject(MAT_DIALOG_DATA) public s_transinfo: VMs_transinfo,public dialog: MatDialog, public _user_service:UserService, public router: Router,public _bfs:basefunctionsService,
        public importExcelService:reportFilterService) {
        //this.lVMSearch =this.setIni_lVMSearch();
        //this.lVMSearch=filterParams; 
        this.lfycode=s_transinfo.ls_fy_code
        this.ltrccode=s_transinfo.trc_code
        this.lvrno=s_transinfo.lvr_no.toString()
        this.ls_rangeoption=s_transinfo.rangeoption
        //this.ld_fromdate =new Date(). 
 debugger 

        if (s_transinfo.i_vr_no=="Y"){s_transinfo.i_vr_no="0"}
        
        if(this.ltrccode=="LOC" || this.ltrccode=='AS1') {        
            this.lvrno =s_transinfo.i_vr_no.toString()
        }
       
        if (!this.lvrno){
            this.lvrno =s_transinfo.i_vr_no.toString() 
        }
        
        if (this.lvrno=="0"){
            this.lvrno =s_transinfo.i_vr_no.toString( )
        }
       
 
        this.vmReportFilters=this.ini_sreport();
        let d :Date = new Date();
        d.setMonth(d.getMonth() - 3); 

        this.ld_fromdate= d
        this.vmReportFilters.fromdate=d 
        
        this.hideBoxes();
        //this.ls_selOption="3"

        this.vmReportFilters.fromno =this.lvrno
        this.vmReportFilters.tono =this.lvrno
        this.vmReportFilters.reportobjectcnt=this.s_transinfo.reportobjectcnt
        this.vmReportFilters.menu_name =this.s_transinfo.menu_code

 
        if (this.s_transinfo.printType=="Listing") {
            this.vmReportFilters.level1 =this.s_transinfo.listobjectsrno
            this.vmReportFilters.graph_module =this.s_transinfo.listobjectreportname
        }else
        {
            this.vmReportFilters.level1 =this.s_transinfo.reportobjectsrno
            this.vmReportFilters.graph_module =this.s_transinfo.reportobjectreportname
        }

 

        this.ls_selOption ="3"
        this.option_changed()
    }

    async f_open() {  
        this.vmTrans=this.ini_vm_transactions()
        try{
           
            // let l_VMCommonQuery :VMCommonQuery
            // l_VMCommonQuery =this.setInis_dynasearch(); 
            // l_VMCommonQuery.lstrSQL= "select_bankpay_mandatory" 
            // l_VMCommonQuery.lstrParam1=ls_curcode
            // l_VMCommonQuery.lstrParam2=vl_acccode
            // l_VMCommonQuery.lstrParam3=this.S_TRANSINFO.ls_fy_code
            // l_VMCommonQuery=  await this._user_service.getInLineResultAsyc(l_VMCommonQuery)

            // li_coin_length= +l_VMCommonQuery.lstrData1 
            // ls_vat_region=  l_VMCommonQuery.lstrData2
            // ls_cost_centre=  l_VMCommonQuery.lstrData3
            // ls_cashflow=  l_VMCommonQuery.lstrData4
            // vl_asset_cnt=  +l_VMCommonQuery.lstrData4
            // let   ld_vat_amt:number =0
            // if (this.dw_1dataobject=='dw_jv_detail' || this.dw_1dataobject=='dw_nip_voucher_detail' ) {
            //     ld_vat_amt=this.vat_total
            // } 
 
        }catch(error){
            //this.handleError(error)
        }
 

        // if rb_current.checked=true then
        //     s_report.option2='1'
        //     s_report.vrno[1]=s_transinfo.vr_no
        //     s_report.fromno=s_transinfo.vr_no
        //     s_report.tono=s_transinfo.vr_no	
        // elseif rb_date.checked=true then
        //     s_report.option2='0'
        // elseif rb_voucher.checked=true then
        //     s_report.option2='1'
        // end if

       // this.dialogRef.close(this.vmReportFilters);
    }

    hideBoxes(){
        //this.vmReportFilters=this.ini_sreport();

        // if(this.ls_selOption != "2"){
        //     this.vmReportFilters.fromdate=null;
        //     this.vmReportFilters.todate=null;
        // }

        // if(this.ls_selOption == "2"){
        //     this.vmReportFilters.fromno=null;
        //     this.vmReportFilters.tono=null;
        // }

        // if(this.ls_selOption == "3"){
        //     this.vmReportFilters.tono=null;
        // }
        
        this.ls_report1= 'hidden-box';
        this.ls_report2= 'hidden-box';
        this.ls_report3= 'hidden-box';
        

    }

    option_changed(){
        this.hideBoxes(); 
        return this["ls_report"+this.ls_selOption]= 'null';
    }
    
    async viewRpt() {  
        debugger
        let fromdate1= new Date(this.vmReportFilters.fromdate);
        let todate1= new Date(this.vmReportFilters.todate);
        
 

        if(this.ls_rangeoption =="Y"){
            if(this.ls_selOption =="1" || this.ls_selOption =="3"  ){
                if(this.vmReportFilters.fromno =="0"){
                    this._bfs.f_showmessage("warning", "Please enter a Valid Voucher #");
                    return
                }

            }
        }

        this.vmReportFilters.lsrptpath =this.s_transinfo.printType 
        this.vmReportFilters.menu_name =this.s_transinfo.menu_code

        // let d :Date = new Date();
        // d.setMonth(d.getMonth() - 3); 
        // this.vmReportFilters.fromdate=d 
 
        if (this.ls_selOption =="1") {
            this.vmReportFilters.option2='1'
            //this.vmReportFilters.vrno[1]=s_transinfo.vr_no
           
        }
        else if (this.ls_selOption =="2") {
            this.vmReportFilters.option2='0'
        }
        else if (this.ls_selOption =="3") {
            this.vmReportFilters.option2='1'
            this.vmReportFilters.fromno=this.vmReportFilters.fromno
            this.vmReportFilters.tono=this.vmReportFilters.fromno
        }
         
        // start
        this.vmTrans=this.ini_vm_transactions()
        this.vmTrans.strProcess="sp_report_options"
        this.vmTrans.authcode = localStorage.getItem('access_token'); 
        this.vmTrans.stringjson=  JSON.stringify(this.vmReportFilters) ;  
        this.vmTrans =await this._user_service.getData(this.vmTrans)   
        
        if(this.vmTrans.Result=="Success"){
            let vmReportObject :ReportObject=JSON.parse(this.vmTrans.stringjson); 
              
            this.vmTrans=this.ini_vm_transactions()
            this.vmTrans.strProcess=vmReportObject.ProcessName
            this.vmTrans.authcode = localStorage.getItem('access_token');  
            this.vmTrans.stringjson=   JSON.stringify(this.vmReportFilters)  ; 
            this.vmTrans.report_type="PRT"
            this.vmTrans.report_code=vmReportObject.CODE
            this.vmTrans.report_name=vmReportObject.PrintObject
            // if (this.ls_create=="Y"  ){ 
            //     if(this.ls_blank=="N"){
            //         this.vmTrans.report_code =this.vmReportFilters.graph_module
            //     }else{
            //         this.vmTrans.report_code =""
            //     }
            // }
            this.vmTrans.report_sr_no= this.vmReportFilters.level1
            
            debugger
           // this.vmTrans.rights_code= this.s_transinfo.rights            
            this.vmTrans.report_type ="PRT"
            this.vmTrans.stringjson=  JSON.stringify(this.vmTrans) ; 
            
            this.vmTrans.strProcess="sp_insert_reports"
            this.vmTrans =await this._user_service.postData(this.vmTrans)
            if(this.vmTrans.Result=="Success"){
                //let url:string=localStorage.getItem('apiurl');
                let url:string= this.config.geturl()
                let url1;  
                 
                    url1 = url+'eReports/Index?vmd='+this.vmTrans.authcode 
                
                
                window.open(  url1, '_blank'); 
            }   
        }
        else{ 
            this._bfs.f_showmessage("Info",this.vmTrans.Result)
            
        }  
        

        // For Report From API Working
        // this.vmTrans=this.ini_vm_transactions()
        // this.vmTrans.strProcess="sp_report_options"
        // this.vmTrans.authcode = localStorage.getItem('access_token'); 
        // this.vmTrans.stringjson=  JSON.stringify(this.vmReportFilters) ;

        // this.vmTrans.stringjson=  JSON.stringify(this.vmTrans) ;
        // this.vmTrans.strProcess="sp_insert_reports" 
        // debugger
        // this.vmTrans =await this._user_service.postData(this.vmTrans)
        // if(this.vmTrans.Result=="Success"){
        //     let url:string=localStorage.getItem('apiurl');  
        //     const url1 = url+'Reports/Index?vmd='+this.vmTrans.authcode 
        //     window.open(  url1, '_blank'); 
        // }   
        //For Report From API Working

         

        // localStorage.setItem('gs_report', JSON.stringify(this.vmReportFilters)); 
        // const url  = this.router.createUrlTree(['PrintOuts', "view"]).toString(); 
        // window.open('#' + url, '_blank'); 
        this.dialogRef.close();
        // let fromdate1= new Date(this.vmReportFilters.fromdate);
        // let todate1= new Date(this.vmReportFilters.todate);
        // if(fromdate1 > todate1){
        //     this._bfs.f_showmessage("warning", "Please select the from date less then to date");
        //     return
            
        // }

        // if(this.ls_rangeoption =="Y"){
        //     if(this.ls_selOption =="1" || this.ls_selOption =="3"  ){
        //         if(this.vmReportFilters.fromno =="0"){
        //             this._bfs.f_showmessage("warning", "Please enter a Valid Voucher #");
        //             return
        //         }

        //     }
        // }

        // this.vmReportFilters.lsrptpath =this.s_transinfo.printType 
        // this.vmReportFilters.menu_name =this.s_transinfo.menu_code

        // // let d :Date = new Date();
        // // d.setMonth(d.getMonth() - 3); 
        // // this.vmReportFilters.fromdate=d 
 
        // if (this.ls_selOption =="1") {
        //     this.vmReportFilters.option2='1'
        //     //this.vmReportFilters.vrno[1]=s_transinfo.vr_no
           
        // }
        // else if (this.ls_selOption =="2") {
        //     this.vmReportFilters.option2='0'
        // }
        // else if (this.ls_selOption =="3") {
        //     this.vmReportFilters.option2='1'
        //     this.vmReportFilters.fromno=this.vmReportFilters.fromno
        //     this.vmReportFilters.tono=this.vmReportFilters.fromno
        // }
         
        


        // localStorage.setItem('gs_report', JSON.stringify(this.vmReportFilters)); 
        // const url  = this.router.createUrlTree(['PrintOuts', "view"]).toString(); 
        // window.open('#' + url, '_blank'); 
        // this.dialogRef.close();
    }

    printRpt()
    {
        
    }
    printSel()
    {
        if (this.vmReportFilters.reportobjectcnt>1)
        {
            this.lVMSearch = this.setIni_lVMSearch()
            this.lVMSearch.lstrSQL = "select_report_opt"
            const dialogConfig = new MatDialogConfig();
            this.lVMSearch.lstrColName1 = "Sr #"
            this.lVMSearch.lstrColName2 = "Name" 
            this.lVMSearch.lngColwidth1 = 30
            this.lVMSearch.lngColwidth2 = 40
            dialogConfig.autoFocus = true;
            dialogConfig.height = '77%';
            dialogConfig.width = '77%';
            this.lVMSearch.lstrParam2 =this.s_transinfo.printType 
            dialogConfig.data = this.lVMSearch
            dialogConfig.disableClose = true;
            this.lVMSearch.lstrParam1 =this.vmReportFilters.menu_name
            const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(res => {
                if (res) {
                    this.ls_VMCommonQuery = res;
                    if (this.ls_VMCommonQuery.lstrData1 != "Cancel") {
                        this.vmReportFilters.level1 = +this.ls_VMCommonQuery.lstrData1 
                        this.vmReportFilters.graph_module =  this.ls_VMCommonQuery.lstrData2 
                        this.ls_rangeoption=   this.ls_VMCommonQuery.lstrData3
                    }
                }
            },
            );

        } 
    }

    
    
    close(){
        this.dialogRef.close();
    }

    
    ini_vm_transactions(){
        let ls_dynstructure:  vm_transactions= { 
            Result :"",
            stringjson :"",
            strProcess :"",
            authcode:"",
            report_type:"",
            report_name :"",
            report_code:"",
            report_sr_no:0,
            vmd:""
        }
        return ls_dynstructure
    }
    ini_sreport(){
       
        let ls_s_reports:  s_reports= { 

            user_id	:	"",
            access_rights	:	"",
            reportobject	:	"",
            trc_type_specific	:	"",
            companyname	:	"",
            report_title	:	"",
            prior_fy_code	:	"",
            trc_code	:	 this.ltrccode,
            accountcode	:	[],
            fromdate	: this.ld_fromdate,
            todate	: new Date(),
            fromdatetime	: null,
            todatetime	: null,
            pdcdate	: null,
            pdcaccount	:"",
            pdcexclude	:"",
            cfilter_from	:"",
            cfilter_to	:"",
            openingbal	:"",
            sign_int_from	:null,
            sign_int_to	:null,
            level1	:null,
            level2	:null,
            level3	:null,
            level4	:null,
            level5	:null,
            level6	:null,
            level4group	:null,
            age1	:null,
            age2	:null,
            age3	:null,
            age4	:null,
            age5	:null,
            age1_date	: null,
            age2_date	: null,
            age3_date	: null,
            age4_date	: null,
            age5_date	: null,
            age6_date	: null,
            age7_date	: null,
            age8_date	: null,
            age9_date	: null,
            age10_date	: null,
            age11_date	: null,
            age12_date	: null,
            due1_date	: null,
            due2_date	: null,
            due3_date	: null,
            asondate	: null,
            item_code	:	[],
            ref_trccode	:	[],
            allitem	:"",
            teritory	:	[],
            salesman	:	[],
            allsalesman	:"",
            allaccount	:"",
            vrno	: null,
            allvrno	:	[],
            groupcode	:"",
            groupclicked	:"",
            familycode	:"",
            familyclicked	:"",
            classcode	:"",
            classclicked	:"",
            commoditycode	:"",
            commodityclicked	:"",
            branchcode	:	[],
            option	:"",
            option2	:"",
            option3	:"",
            option4	:"",
            option5	:"",
            option6	:"",
            fromno	: this.lvrno,
            tono	: this.lvrno,
            price_diff	:null,
            from_value	:null,
            to_value	:null,
            a_from	:null,
            a_to	:null,
            b_from	:null,
            b_to	:null,
            c_from	:null,
            c_to	:null,
            job_no	:	[],
            job_number	:null,
            main_head	:	[],
            sub_head	:	[],
            print_pages	:"",
            copies	:null,
            collate	:"",
            print_cancel	:false,
            currency	:"",
            head	:"",
            allbranch	:"",
            audit_no	:null,
            dept	:	[],
            family	:	[],
            classs	:	[],
            group	:	[],
            commodity	:	[],
            datawin_header	:"",
            datawin	:"",
            summary	:false,
            trc_abr_string	:"",
            auth1_name	:"",
            auth2_name	:"",
            auth3_name	:"",
            user_name	:"",
            windowstate	:"",
            dpt	:"",
            pdccode	:	[],
            fy_code	:this.lfycode,
            companycodes	:	[],
            company_title	:"",
            brand	:	[],
            orientation	:null,
            l_margin	:null,
            r_margin	:null,
            t_margin	:null,
            b_margin	:null,
            page_size	:null,
            color	:null,
            doc_location	:	[],
            doc_status	:	[],
            doc_place_issue	:	[],
            id_code	:"",
            make	:	[],
            model	:	[],
            tono_string	:"",
            subject	:"",
            splitbar_pos	:null,
            menu_name	:"",
            window_tag	:"",
            field_name	:	[],
            return_string	:	[],
            classification	:	[],
            country	:	[],
            cost_code	:	[],
            grade	:	[],
            age6	:null,
            age7	:null,
            age8	:null,
            age9	:null,
            age10	:null,
            age11	:null,
            age12	:null,
            amt_dec	:null,
            currency_title	:"",
            custom1	:	[],
            custom2	:	[],
            custom3	:	[],
            custom4	:	[],
            custom5	:	[],
            custom6	:	[],
            gender	:	[],
            national_status	:	[],
            marital_status	:	[],
            blood_group	:	[],
            employer_bank	:	[],
            paytype	:	[],
            oteligibility	:	[],
            leavefrequency	:	[],
            sector	:	[],
            shift	:	[],
            salarymode	:	[],
            bankcode	:	[],
            leave_type	:	[],
            emp_type	:	[],
            page_break	:"",
            graph_syntax	:"",
            graph_datawin	:"",
            graph_arg	:"",
            graph_type	:"",
            graph_module	:"",
            graph_rpt_type	:"",
            pp_no	:	[],
            purchaser	:	[],
            as_code	:	[],
            grade_code	:	[],
            pay_group	:	[],
            category_id	:"",
            status_transfer_type	:	[],
            report_selection_no	:null,
            report_selection_name	:"",
            period_vrno	:	[],
            message	:"",
            attachment_type	:"",
            contact_group	:"",
            supervisor	:	[],
            division	:	[],
            designation_group	:	[],
            transfer_type	:	[],
            age_group	:	[],
            service_period	:	[],
            payment_journal	:	[],
            vr_date	:	[],
            cur_rate	:null,
            block	:	[],
            room	:	[],
            mess	:	[],
            agent_code	:	[],
            batch_no	:	[],
            schedule_no	:	[],
            expense_code	:	[],
         
            posted_ind	:"",	 
            ess_emp_code	:"",	
            pdccodes	:	[],
            consolidation_no	:	[],
            jv_no	:	[],
            payment_no	:[], 
            Result	:	 "",
            lsApiPath	:	 "",
            lsrptpath:"",
            reportobjectcnt:0
             
        }
        return ls_s_reports
    }

    setIni_lVMSearch(){
        let ls_tmp:  VMSearch= {
            TypeCode	:	''	,
            lstrData1	:	''	,
            lstrData2	:	''	,
            lstrData3	:	''	,
            lstrData4	:	''	,
            lstrData5	:	''	,
            lstrData6	:	''	,
            lstrData7	:	''	,
            lstrData8	:	''	,
            lstrData9	:	''	,
            lstrData10	:	''	,
            lstrData11	:	''	,
            lstrData12	:	''	,
            lstrData13	:	''	,
            lstrData14	:	''	,
            lstrData15	:	''	,
            lstrData16	:	''	,
            lstrData17	:	''	,
            lstrData18	:	''	,
            lstrData19	:	''	,
            lstrData20	:	''	,
            lstrData21	:	''	,
            lstrData22	:	''	,
            lstrData23	:	''	,
            lstrData24	:	''	,
            lstrData25	:	''	,
            lstrData26	:	''	,
            lstrData27	:	''	,
            lstrData28	:	''	,
            lstrData29	:	''	,
            lstrData30	:	''	,
            lstrData31	:	''	,
            lstrData32	:	''	,
            lstrData33	:	''	,
            lstrData34	:	''	,
            lstrData35	:	''	,
            lstrData36	:	''	,
            lstrData37	:	''	,
            lstrData38	:	''	,
            lstrData39	:	''	,
            lstrData40	:	''	,
            lstrData41	:	''	,
            lstrData42	:	''	,
            lstrData43	:	''	,
            lstrData44	:	''	,
            lstrData45	:	''	,
            lstrData46	:	''	,
            lstrData47	:	''	,
            lstrData48	:	''	,
            lstrData49	:	''	,
            lstrData50	:	''	,


            lngColwidth1	:	0	,
            lngColwidth2	:	0	,
            lngColwidth3	:	0	,
            lngColwidth4	:	0	,
            lngColwidth5	:	0	,
            lngColwidth6	:	0	,
            lngColwidth7	:	0	,
            lngColwidth8	:	0	,
            lngColwidth9	:	0	,
            lngColwidth10	:	0	,
            lngColwidth11	:	0	,
            lngColwidth12	:	0	,
            lngColwidth13	:	0	,
            lngColwidth14	:	0	,
            lngColwidth15	:	0	,
            lngColwidth16	:	0	,
            lngColwidth17	:	0	,
            lngColwidth18	:	0	,
            lngColwidth19	:	0	,
            lngColwidth20	:	0	,
            lngColwidth21	:	0	,
            lngColwidth22	:	0	,
            lngColwidth23	:	0	,
            lngColwidth24	:	0	,
            lngColwidth25	:	0	,
            lngColwidth26	:	0	,
            lngColwidth27	:	0	,
            lngColwidth28	:	0	,
            lngColwidth29	:	0	,
            lngColwidth30	:	0	,
            lngColwidth31	:	0	,
            lngColwidth32	:	0	,
            lngColwidth33	:	0	,
            lngColwidth34	:	0	,
            lngColwidth35	:	0	,
            lngColwidth36	:	0	,
            lngColwidth37	:	0	,
            lngColwidth38	:	0	,
            lngColwidth39	:	0	,
            lngColwidth40	:	0	,
            lngColwidth41	:	0	,
            lngColwidth42	:	0	,
            lngColwidth43	:	0	,
            lngColwidth44	:	0	,
            lngColwidth45	:	0	,
            lngColwidth46	:	0	,
            lngColwidth47	:	0	,
            lngColwidth48	:	0	,
            lngColwidth49	:	0	,
            lngColwidth50	:	0	,

            lstrColName1	:	''	,
            lstrColName2	:	''	,
            lstrColName3	:	''	,
            lstrColName4	:	''	,
            lstrColName5	:	''	,
            lstrColName6	:	''	,
            lstrColName7	:	''	,
            lstrColName8	:	''	,
            lstrColName9	:	''	,
            lstrColName10	:	''	,
            lstrColName11	:	''	,
            lstrColName12	:	''	,
            lstrColName13	:	''	,
            lstrColName14	:	''	,
            lstrColName15	:	''	,
            lstrColName16	:	''	,
            lstrColName17	:	''	,
            lstrColName18	:	''	,
            lstrColName19	:	''	,
            lstrColName20	:	''	,
            lstrColName21	:	''	,
            lstrColName22	:	''	,
            lstrColName23	:	''	,
            lstrColName24	:	''	,
            lstrColName25	:	''	,
            lstrColName26	:	''	,
            lstrColName27	:	''	,
            lstrColName28	:	''	,
            lstrColName29	:	''	,
            lstrColName30	:	''	,
            lstrColName31	:	''	,
            lstrColName32	:	''	,
            lstrColName33	:	''	,
            lstrColName34	:	''	,
            lstrColName35	:	''	,
            lstrColName36	:	''	,
            lstrColName37	:	''	,
            lstrColName38	:	''	,
            lstrColName39	:	''	,
            lstrColName40	:	''	,
            lstrColName41	:	''	,
            lstrColName42	:	''	,
            lstrColName43	:	''	,
            lstrColName44	:	''	,
            lstrColName45	:	''	,
            lstrColName46	:	''	,
            lstrColName47	:	''	,
            lstrColName48	:	''	,
            lstrColName49	:	''	,
            lstrColName50	:	''	,

            lstrSQL	:	''	,
            lstrError	:	''	,

            lstrUser	:	''	,

            lstrParam1	:	''	,
            lstrParam2	:	''	,
            lstrParam3	:	''	,
            lstrParam4	:	''	,
            lstrParam5	:	''	,
            lstrParam6	:	''	,
            lstrParam7	:	''	,
            lstrParam8	:	''	,
            lstrParam9	:	''	,
            lstrParam10	:	''	,
            lstrParam11	:	''	,
            lstrParam12	:	''	,
            lstrParam13	:	''	,
            lstrParam14	:	''	,
            lstrParam15	:	''	,
            lstrParam16	:	''	,
            lstrParam17	:	''	,
            lstrParam18	:	''	,
            lstrParam19	:	''	,
            lstrParam20	:	''	,
            lstrParam21	:	''	,
            lstrParam22	:	''	,
            lstrParam23	:	''	,
            lstrParam24	:	''	,
            lstrParam25	:	''	,
            lstrParam26	:	''	,
            lstrParam27	:	''	,
            lstrParam28	:	''	,
            lstrParam29	:	''	,
            lstrParam30	:	''	,
            lstrParam31	:	''	,
            lstrParam32	:	''	,
            lstrParam33	:	''	,
            lstrParam34	:	''	,
            lstrParam35	:	''	,
            lstrParam36	:	''	,
            lstrParam37	:	''	,
            lstrParam38	:	''	,
            lstrParam39	:	''	,
            lstrParam40	:	''	,
            lstrParam41	:	''	,
            lstrParam42	:	''	,
            lstrParam43	:	''	,
            lstrParam44	:	''	,
            lstrParam45	:	''	,
            lstrParam46	:	''	,
            lstrParam47	:	''	,
            lstrParam48	:	''	,
            lstrParam49	:	''	,
            lstrParam50	:	''	,
             
            lstrParam51	:	'', 
            lstrParam52	:	'', 
            lstrParam53	:	'', 
            lstrParam54	:	'', 
            lstrParam55	:	'', 
            lstrParam56	:	'', 
            lstrParam57	:	'', 
            lstrParam58	:	'', 
            lstrParam59	:	'', 
            lstrParam60	:	'', 
            lstrParam61	:	'', 
            lstrParam62	:	'', 
            lstrParam63	:	'', 
            lstrParam64	:	'', 
            lstrParam65	:	'', 
            lstrParam66	:	'', 
            lstrParam67	:	'', 
            lstrParam68	:	'', 
            lstrParam69	:	'', 
            lstrParam70	:	'', 
            lstrParam71	:	'', 
            lstrParam72	:	'', 
            lstrParam73	:	'', 
            lstrParam74	:	'', 
            lstrParam75	:	'', 
            lstrParam76	:	'', 
            lstrParam77	:	'', 
            lstrParam78	:	'', 
            lstrParam79	:	'', 
            lstrParam80	:	'', 
            lstrParam81	:	'', 
            lstrParam82	:	'', 
            lstrParam83	:	'', 
            lstrParam84	:	'', 
            lstrParam85	:	'', 
            lstrParam86	:	'', 
            lstrParam87	:	'', 
            lstrParam88	:	'', 
            lstrParam89	:	'', 
            lstrParam90	:	'', 
            lstrParam91	:	'', 
            lstrParam92	:	'', 
            lstrParam93	:	'', 
            lstrParam94	:	'', 
            lstrParam95	:	'', 
            lstrParam96	:	'', 
            lstrParam97	:	'', 
            lstrParam98	:	'', 
            lstrParam99	:	'', 
            lstrParam100	:	'', 
            lstrParam101	:	'', 
            lstrParam102	:	'', 
            lstrParam103	:	'', 
            lstrParam104	:	'', 
            lstrParam105	:	'', 
            lstrParam106	:	'', 
            lstrParam107	:	'', 
            lstrParam108	:	'', 
            lstrParam109	:	'', 
            lstrParam110	:	'', 
            lstrParam111	:	'', 
            lstrParam112	:	'', 
            lstrParam113	:	'', 
            lstrParam114	:	'', 
            lstrParam115	:	'', 
            lstrParam116	:	'', 
            lstrParam117	:	'', 
            lstrParam118	:	'', 
            lstrParam119	:	'', 
            lstrParam120	:	'', 
            lstrParam121	:	'', 
            lstrParam122	:	'', 
            lstrParam123	:	'', 
            lstrParam124	:	'', 
            lstrParam125	:	'', 
            lstrParam126	:	'', 
            lstrParam127	:	'', 
            lstrParam128	:	'', 
            lstrParam129	:	'', 
            lstrParam130	:	'', 
            lstrParam131	:	'', 
            lstrParam132	:	'', 
            lstrParam133	:	'', 
            lstrParam134	:	'', 
            lstrParam135	:	'', 
            lstrParam136	:	'', 
            lstrParam137	:	'', 
            lstrParam138	:	'', 
            lstrParam139	:	'', 
            lstrParam140	:	'', 
            lstrParam141	:	'', 
            lstrParam142	:	'', 
            lstrParam143	:	'', 
            lstrParam144	:	'', 
            lstrParam145	:	'', 
            lstrParam146	:	'', 
            lstrParam147	:	'', 
            lstrParam148	:	'', 
            lstrParam149	:	'', 
            lstrParam150	:	'', 
            lstrParam151	:	'', 
            lstrParam152	:	'', 
            lstrParam153	:	'', 
            lstrParam154	:	'', 
            lstrParam155	:	'', 
            lstrParam156	:	'', 
            lstrParam157	:	'', 
            lstrParam158	:	'', 
            lstrParam159	:	'', 
            lstrParam160	:	'', 
            lstrParam161	:	'', 
            lstrParam162	:	'', 
            lstrParam163	:	'', 
            lstrParam164	:	'', 
            lstrParam165	:	'', 
            lstrParam166	:	'', 
            lstrParam167	:	'', 
            lstrParam168	:	'', 
            lstrParam169	:	'', 
            lstrParam170	:	'', 
            lstrParam171	:	'', 
            lstrParam172	:	'', 
            lstrParam173	:	'', 
            lstrParam174	:	'', 
            lstrParam175	:	'', 
            lstrParam176	:	'', 
            lstrParam177	:	'', 
            lstrParam178	:	'', 
            lstrParam179	:	'', 
            lstrParam180	:	'', 
            lstrParam181	:	'', 
            lstrParam182	:	'', 
            lstrParam183	:	'', 
            lstrParam184	:	'', 
            lstrParam185	:	'', 
            lstrParam186	:	'', 
            lstrParam187	:	'', 
            lstrParam188	:	'', 
            lstrParam189	:	'', 
            lstrParam190	:	'', 
            lstrParam191	:	'', 
            lstrParam192	:	'', 
            lstrParam193	:	'', 
            lstrParam194	:	'', 
            lstrParam195	:	'', 
            lstrParam196	:	'', 
            lstrParam197	:	'', 
            lstrParam198	:	'', 
            lstrParam199	:	'', 
            lstrParam200	:	'', 
                lstrSearch :'',
            lstrSortCol :1,
            lbSortAsc:"Y", 
            lstrShowlist:"N"
        }
        return ls_tmp;
    }

}
