import { Component } from '@angular/core';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent
{
    language_code:string
    ls_addres:string
    ls_company_name:string
    ls_company_name_loc:string
    ls_Contact:string
    ls_map:string
    ls_useful_links:string
    ls_terms_condition:string
    ls_faq:string
    ls_follow_us:string
    ls_contact_num:string

    /**
     * Constructor
     */
    constructor()
    {
      
        this.language_code =localStorage.getItem('language_code')
        if(this.language_code=="AR"){
            this.ls_addres="عنوان"
            this.ls_company_name="شارع الميناء ميناء خالد,"
            this.ls_company_name_loc="الشارقة - الإمارات العربية المتحدة"
            this.ls_Contact="اتصل"
            this.ls_map="موقعك على الخريطة"
            this.ls_useful_links="روابط مفيدة"
            this.ls_terms_condition="الأحكام والشروط"
            this.ls_faq="FAQ"
            this.ls_follow_us="تابعنا"
            this.ls_contact_num="5281666 6 971 +"
        }
        else{
            this.ls_addres="Address"
            this.ls_company_name="Al Meena Street Port Khalid,"
            this.ls_company_name_loc="Sharjah - UAE"
            this.ls_Contact="Contact"
            this.ls_map="Our Location in Map"
            this.ls_useful_links="Useful links"
            this.ls_terms_condition="Terms and Conditions"
            this.ls_faq="FAQ"
            this.ls_follow_us="Follow Us"
            this.ls_contact_num="+971 6 5281666"
        }
    }

}
