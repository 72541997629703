import { Component, Inject } from '@angular/core';



import { UserService } from '../userservice'; 
import { s_approval  } from '../window/Basewindow.data';
import { BaseDialogwindowcomponent } from '../window/BaseDialogwindow.component';
import { basefunctionsService } from '../basefunctionsService';
import { SearchComponent } from '../search/search.component';
import { catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
 
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { Router } from '@angular/router';

 

@Component({
    selector   : 'approval_seend',
    templateUrl: './approval.send.component.html', 
    styleUrls  : ['./approval.send.component.scss'],
    providers:[DatePipe] 
})
export class ApprovalSendComponent   extends BaseDialogwindowcomponent
{  
 

    ls_approval:s_approval;  
    ls_batch:string='N';
    
    // constructor( 
    //     private dialogRef: MatDialogRef<ApprovalSendComponent>,
    //     @Inject(MAT_DIALOG_DATA) public data: s_approval, public _user_service:UserService ) {
    //     this.ls_approval=this.setini_ls_approval()
    //    // this.ls_approval=data; 
       
    // } 

    constructor(
        
        public _user_service:UserService,public dialog: MatDialog   ,public _bfs :basefunctionsService, public adapter: DateAdapter<any>,
        private dialogRef: MatDialogRef<ApprovalSendComponent>, @Inject(MAT_DIALOG_DATA) public data: s_approval ,public datePipe : DatePipe
        , private _fuseSplashScreenService: FuseSplashScreenService
        ,public router: Router){
        super(   _user_service, dialog, _bfs,adapter,datePipe); 
     
        
        this.ls_approval=data  
        this.open();
       

    } 
    async open(){
        this.ls_VMCommonQuery =this.setInis_dynasearch();
        this.ls_VMCommonQuery.lstrSQL="selectdicsetup"
        this.ls_VMCommonQuery.lstrParam1= '59' 
 
        this.ls_VMCommonQuery =await this._user_service.getInLineResultAsyc ( this.ls_VMCommonQuery) 
          
        this.ls_batch= this.ls_VMCommonQuery.lstrData1 
    }
     selectbatch(){
        this.lVMSearch =this.setIni_lVMSearch()
        this.lVMSearch.lstrSQL= "selectbatch"        
        const dialogConfig = new MatDialogConfig();
        this.lVMSearch.lstrColName1="Code"  
        this.lVMSearch.lstrColName2="Name"
        this.lVMSearch.lngColwidth1=30 
        this.lVMSearch.lngColwidth1=40  
        this.lVMSearch.lstrParam1= this.ls_approval.ltrc_code
        dialogConfig.autoFocus = true;
        dialogConfig.height='77%';
        dialogConfig.width='77%';
        dialogConfig.data=  this.lVMSearch 
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(res => {
            if(res){ 
                this.ls_VMCommonQuery  = res; 
                if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                    this.ls_approval.batch_code=this.ls_VMCommonQuery.lstrData1        
                }                
            }
        },(catchError(this.handleError)));  
        
    }
    selectpriority(){
        this.lVMSearch =this.setIni_lVMSearch()
        this.lVMSearch.lstrSQL= "selectpriority"        
        const dialogConfig = new MatDialogConfig();
        this.lVMSearch.lstrColName1="Code"  
        this.lVMSearch.lstrColName2="Name"
        this.lVMSearch.lngColwidth1=30 
        this.lVMSearch.lngColwidth1=40  
        dialogConfig.autoFocus = true;
        dialogConfig.height='77%';
        dialogConfig.width='77%';
        dialogConfig.data=  this.lVMSearch 
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(res => {
            if(res){ 
                this.ls_VMCommonQuery  = res; 
                if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                    this.ls_approval.priority=this.ls_VMCommonQuery.lstrData1       
                    this.ls_approval.priority_text=this.ls_VMCommonQuery.lstrData2
                    
                    //this.vmJV.dw_job_datas[i].job_no=this.ls_VMCommonQuery.lstrData1        
                }                
            }
        },(catchError(this.handleError)));  
        
    }

    selectpriorityDateapp(){
        this.lVMSearch =this.setIni_lVMSearch()
        this.lVMSearch.lstrSQL= "select_yes_no"        
        const dialogConfig = new MatDialogConfig();
        this.lVMSearch.lstrColName1="Code"  
        this.lVMSearch.lstrColName2="Name"
        this.lVMSearch.lngColwidth1=30 
        this.lVMSearch.lngColwidth1=40  
        dialogConfig.autoFocus = true;
        dialogConfig.height='77%';
        dialogConfig.width='77%';
        dialogConfig.data=  this.lVMSearch 
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(res => {
            if(res){ 
                this.ls_VMCommonQuery  = res; 
                if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                    this.ls_approval.prioritydateapplicable=this.ls_VMCommonQuery.lstrData1       
                    this.ls_approval.prioritydateapplicable_text=this.ls_VMCommonQuery.lstrData2
                    //this.vmJV.dw_job_datas[i].job_no=this.ls_VMCommonQuery.lstrData1        
                }                
            }
        },(catchError(this.handleError)));  
        
    }
    async ue_send(  ){  

        if(this.lb_form_enabled==false ){return}        
        this.lb_form_enabled=false

        this._fuseSplashScreenService.show()
        this.ls_VMCommonQuery=await this._user_service.sendapprovalw(this.ls_approval).toPromise()
           
        if(this.ls_VMCommonQuery.lstrError=="Success"){ 
            this.ls_approval.result="Success"; 
            this._fuseSplashScreenService.hide()
            this.dialogRef.close(this.ls_approval);
            this.lb_form_enabled=true
            
            this.router.navigate(['HomeApproval']).catch(err => console.log(err));
        }
        else{
            this._fuseSplashScreenService.hide()
            this._bfs.f_showmessage("Info", this.ls_VMCommonQuery.lstrError)                
            this.lb_form_enabled=true            
        }  
 
    }

    ue_cancel() {
        this.ls_approval.result="Cancel";  
        this.dialogRef.close(this.ls_approval);
    }
      
    setini_ls_approval(){
        let ldata:  s_approval= { 
            lfy_code	:'',
            ltrc_code	:'',
            lvr_no:0,
            lsr_no:0,

            location :'All',
            category :'All',
            company :'All',
            procdepartment :'All',
            exceedingbudget :'All',
            forms :'All',
            intercompany :'All',
            workingas :'All',
            withinbudget :'All',
            amount :0,
            trc_code :'All',
            department :'All',
            cr_code :'All',
            po_type :'All',
            job_division :'All',
            sub_division :'All',
            leave_type :'All',
            trc :'All',
            clearance_type :'All',
            result :'Success',
            source_table :'',
            batch_code:'',
            priority :'3',
            prioritydateapplicable:'N',
            prioritydate :null,
            priority_text:"Low",
            prioritydateapplicable_text:'No',
            action:'',
            remark:'',
            role:'',
            actiontext:'',
            ln_forward_type : 0,
            ls_is_forwarded:'N',
            user_no:0,
            fwd_resp:0,
            fwd_to:'',
            pay_dept:"",
            lcnt:0
          } 
          return ldata
      } 
      
}
