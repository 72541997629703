import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core'; 
import { FuseSharedModule } from '@fuse/shared.module'; 


 
import { baseModule } from 'app/base/base.module';  
import { searchModule } from 'app/base/search/search.module'; 
import { approvalModule } from 'app/base/approvals/approval.module';     
import { searchautoclose } from 'app/base/searchautoclose/searchautoclose.module';
import { Attachmentcomponent } from './Attachment.component';
import { loaderEproModule } from '../loader-epro/loaderEpro.module';
  

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [  
         Attachmentcomponent,   
    ],
    imports     : [

        RouterModule , 
        TranslateModule,
        FuseSharedModule,
        MatButtonModule, 
        MatFormFieldModule, 
        MatInputModule,
        MatDatepickerModule,
        MatDialogModule, 
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        baseModule,
        searchModule,
        searchautoclose,
        approvalModule,
        MatCardModule,
        MatTreeModule,
        MatTabsModule ,MatSelectModule,
        MatSidenavModule,MatCheckboxModule,loaderEproModule
    ],
    exports     : [ 
         Attachmentcomponent,

    ],  
    entryComponents: [  
        Attachmentcomponent ,
      ] 
    //entryComponents: [Job_detailcomponent  ,bill_detailcomponent ,expense_detailcomponent,cashflowcomponent,assetcomponent,prepaidcomponent]
})

export class AttachmentModule
{
}
