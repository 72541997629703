<!-- <mat-toolbar> -->

    <!-- <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex> -->

        <!-- <a href="https://epromis.com/"
           target="_blank" mat-button class="pink" fxFlex="0 0 auto" fxLayout="row"
           fxLayoutAlign="start center">
            <mat-icon class="s-16 mr-sm-4">shopping_cart</mat-icon>
            <span>Purchase ePROMIS 
            </span>
        </a> -->

        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button routerLink="/documentation/getting-started/introduction">Documentation</a>
            <span>&bull;</span>
            <a mat-button routerLink="/documentation/changelog">Changelog</a>
        </div> -->

    <!-- </div> -->

    <div class="row">

        <div class="about">
            <!-- <h2>{{ls_addres}}</h2> -->
          
         <div class="logo"> 
            <img class="logo-icon" src="../../../../../../assets/images/logos/logo.png" > 
        </div>
        <p>
            {{ls_company_name_loc}}<br/> 
        </p>

        <!-- <p>
            {{ls_company_name}} <br/>{{ls_company_name_loc}}
        </p> -->

        </div>

        <div class="contact">
            <h2>{{ls_Contact}}</h2>
           <ul>
               <li><mat-icon>call</mat-icon><span>{{ls_contact_num}}</span> </li>
               <li><mat-icon>mail</mat-icon><span>noc@sharjahport.ae</span></li>
               <!-- <li><mat-icon>location_on</mat-icon><span><a href="#">{{ls_map}}</a></span></li> -->
           </ul>
        </div>

        <div class="bottomlink">
            <h2>{{ls_useful_links}}</h2>
            <ul>
                <li><a href="#">{{ls_terms_condition}}</a> </li>
                <li><a href="https://d3yr6znnc7clr.cloudfront.net/customers/shjprts/User+Guide-Latest.pdf">{{ls_faq}}</a></li>
                <li><a href="#">{{ls_Contact}}</a></li>
            </ul>
        </div>

        <div class="social">
            <h2>{{ls_follow_us}}</h2>
          
            <ul>
                <li><a href="#"><img src="../../../../assets/images/icons/facebook-new.png"></a></li>
                <li><a href="#"><img src="../../../../assets/images/icons/instagram.png"></a></li>
                <li><a href="#"><img src="../../../../assets/images/icons/twitter.png"></a></li>
                <li><a href="#"><img src="../../../../assets/images/icons/linkedin.png"></a></li>
            </ul>

            <div class="appstore mt-12">
                <img src="../../../../assets/images/icons/appstore.png">
                <img src="../../../../assets/images/icons/googleplay.png" class="ml-4">
            </div>
        </div>

    </div>
    
    

<!-- </mat-toolbar> -->
