

/**
 * Shijilesh 15/10/2017 
 * ePROMIS  
 **/
import { Component, Inject } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';

import { UserService } from '../userservice'; 
import { s_approval, vm_transactions  } from '../window/Basewindow.data';
import { BaseDialogwindowcomponent } from '../window/BaseDialogwindow.component';
import { basefunctionsService } from '../basefunctionsService';
import { SearchComponent } from '../search/search.component';
import { catchError } from 'rxjs/operators';

import { DatePipe } from '@angular/common';
 

@Component({
    selector   : 'approval_doc',
    templateUrl: './approval.doc.component.html', 
    styleUrls  : ['./approval.doc.component.scss'],
    providers:[DatePipe] 
})
export class ApprovalDocComponent   extends BaseDialogwindowcomponent
{  
 
    vmTrans:vm_transactions;
    ls_approval:s_approval;  
    // constructor( 
    //     private dialogRef: MatDialogRef<ApprovalSendComponent>,
    //     @Inject(MAT_DIALOG_DATA) public data: s_approval, public _user_service:UserService ) {
    //     this.ls_approval=this.setini_ls_approval()
    //    // this.ls_approval=data; 
       
    // } 

    constructor(  public _user_service:UserService,public dialog: MatDialog   ,public _bfs :basefunctionsService, public adapter: DateAdapter<any>,
        private dialogRef: MatDialogRef<ApprovalDocComponent>, @Inject(MAT_DIALOG_DATA) public data: s_approval,public datePipe : DatePipe ){
        super(   _user_service, dialog, _bfs,adapter,datePipe); 
     
        
        this.ls_approval=data  
    } 
    
    ini_vm_transactions(){
        let ls_dynstructure:  vm_transactions= { 
            Result :"",
            stringjson :"",
            strProcess :"",
            authcode:"",
            report_type:"",
            report_name :"",
            report_code:"",
            report_sr_no:0,
            vmd:""
            
        }
        return ls_dynstructure
    }
    selectpriority(){
        this.lVMSearch =this.setIni_lVMSearch()
        this.lVMSearch.lstrSQL= "selectpriority"        
        const dialogConfig = new MatDialogConfig();
        this.lVMSearch.lstrColName1="Code"  
        this.lVMSearch.lstrColName2="Name"
        this.lVMSearch.lngColwidth1=30 
        this.lVMSearch.lngColwidth1=40  
        dialogConfig.autoFocus = true;
        dialogConfig.height='77%';
        dialogConfig.width='77%';
        dialogConfig.data=  this.lVMSearch 
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(res => {
            if(res){ 
                this.ls_VMCommonQuery  = res; 
                if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                    this.ls_approval.priority=this.ls_VMCommonQuery.lstrData1       
                    this.ls_approval.priority_text=this.ls_VMCommonQuery.lstrData2
                    //this.vmJV.dw_job_datas[i].job_no=this.ls_VMCommonQuery.lstrData1        
                }                
            }
        },(catchError(this.handleError)));  
        
    }
    selectaction(){
        this.lVMSearch =this.setIni_lVMSearch()
        this.lVMSearch.lstrSQL= "selectapprovalaction"        
        const dialogConfig = new MatDialogConfig();
        this.lVMSearch.lstrColName1="Name"   
        this.lVMSearch.lngColwidth1=30 
        this.lVMSearch.lngColwidth1=40  
        this.lVMSearch.lstrParam1 =this.ls_approval.ls_is_forwarded
        dialogConfig.autoFocus = true;
        dialogConfig.height='77%';
        dialogConfig.width='77%';
        dialogConfig.data=  this.lVMSearch 
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(res => {
            if(res){ 
                this.ls_VMCommonQuery  = res; 
                if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                    this.ls_approval.actiontext=this.ls_VMCommonQuery.lstrData1   
                    this.ls_approval.fwd_to=""           
                }                
            }
        },(catchError(this.handleError)));  

    }
    selectpriorityDateapp(){
        this.lVMSearch =this.setIni_lVMSearch()
        this.lVMSearch.lstrSQL= "select_yes_no"        
        const dialogConfig = new MatDialogConfig();
        this.lVMSearch.lstrColName1="Code"  
        this.lVMSearch.lstrColName2="Name"
        this.lVMSearch.lngColwidth1=30 
        this.lVMSearch.lngColwidth1=40  
        dialogConfig.autoFocus = true;
        dialogConfig.height='77%';
        dialogConfig.width='77%';
        dialogConfig.data=  this.lVMSearch 
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(res => {
            if(res){ 
                this.ls_VMCommonQuery  = res; 
                if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                    this.ls_approval.prioritydateapplicable=this.ls_VMCommonQuery.lstrData1       
                    this.ls_approval.prioritydateapplicable_text=this.ls_VMCommonQuery.lstrData2
                    //this.vmJV.dw_job_datas[i].job_no=this.ls_VMCommonQuery.lstrData1        
                }                
            }
        },(catchError(this.handleError)));  
        
    }

    selecttouser(){

        if ( this.ls_approval.actiontext =='Forward for Response' || this.ls_approval.actiontext=='Approve and Forward'  ||  this.ls_approval.actiontext=='Assigned' ){
            this.lVMSearch =this.setIni_lVMSearch()
            this.lVMSearch.lstrSQL= "selectuser"        
            const dialogConfig = new MatDialogConfig();
            this.lVMSearch.lstrColName1="Code"  
            this.lVMSearch.lstrColName2="Name"
            this.lVMSearch.lngColwidth1=30 
            this.lVMSearch.lngColwidth1=40  
            dialogConfig.autoFocus = true;
            dialogConfig.height='77%';
            dialogConfig.width='77%';
            dialogConfig.data=  this.lVMSearch 
            dialogConfig.disableClose = true;
            const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(res => {
                if(res){ 
                    this.ls_VMCommonQuery  = res; 
                    if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                        this.ls_approval.fwd_to=this.ls_VMCommonQuery.lstrData1                
                    }                
                }
            },(catchError(this.handleError)));  
        }
    }
    async ue_approve(  ){  
  
        debugger
        if ( await this.wf_mandatory() ==true){
                    this.ls_VMCommonQuery =await this._user_service.approve(this.ls_approval).toPromise()
               
                 
                    if(this.ls_VMCommonQuery.lstrError=="Success"){ 
                        this.ls_approval.result="Success";
                        this.sendmail()
                        this.dialogRef.close(this.ls_approval);
                        // if(this.ls_approval.ltrc_code=="PAY" || this.ls_approval.ltrc_code=="REC" ){
                        //     this.vmTrans =this.ini_vm_transactions()
                        //     this.vmTrans.strProcess="sp_user_gen" 
                        //     this.vmTrans.stringjson=  JSON.stringify(this.vmTrans) ; 
                        //     this.vmTrans =await this._user_service.postData(this.vmTrans) 
                        //     if(this.vmTrans.Result=="Success"){
                        //         this.lb_form_enabled=true
                        //         this.sendmail()
                        //         this.dialogRef.close(this.ls_approval);
                        //     }
                        //     else{ 
                        //         this._bfs.f_showmessage("Info",this.vmTrans.Result) 
                                
                        //         this.lb_form_enabled=true 
                        //     }
                        // }else{
                        //     this.dialogRef.close(this.ls_approval);
                        // }
                    }
                    else{
                        debugger
                        alert("here")
                        this._bfs.f_showmessage("Info", this.ls_VMCommonQuery.lstrError)
                    
                    } 
                }  
 
    }

    async sendmail(){

        //shijilesh 
        // this.vmTrans.strProcess="sp_email"
                 
        // this.vmTrans.stringjson=  JSON.stringify(this.vmTrans) ; 
        // this.vmTrans =await this._user_service.postDataEmail(this.vmTrans) 
        // if(this.vmTrans.Result=="Success"){
        //     this.lb_form_enabled=true 
            
        // }
        // else{ 
        //     this._bfs.f_showmessage("Info",this.vmTrans.Result) 
        //     //this.S_TRANSINFO.lvr_no=this.vmData.vr_no 
        //     this.lb_form_enabled=true 
        // }  

    }
    async wf_mandatory(): Promise<boolean>{
        
        if ( this.ls_approval.actiontext =='Forward for Response' || this.ls_approval.actiontext=='Approve and Forward'  ||  this.ls_approval.actiontext=='Assigned' ){
            if(this.ls_approval.fwd_to==""){
                this._bfs.f_showmessage("Info","Please Select User")
                return false
            }
        }
        if ( this.ls_approval.actiontext =='Rejected'){
            if(this.ls_approval.remark==""){
                this._bfs.f_showmessage("Info","Please Enter Remarks")
                return false
            }
        }

        if  (this.ls_approval.actiontext == 'Approve' || this.ls_approval.actiontext == 'Acknowledge'  ){}
        else{
            if(!this.ls_approval.remark ){
                this._bfs.f_showmessage("Info","Please Enter Remarks")
                return false
            }
        }
	 

        return true
    }
    ue_cancel() {
        this.ls_approval.result="Cancel";  
        this.dialogRef.close(this.ls_approval);
    }
      
    setini_ls_approval(){
        let ldata:  s_approval= { 
            lfy_code	:"",
            ltrc_code	:"",
            lvr_no:0,
            lsr_no:0,

            location :"All",
            category :"All",
            company :"All",
            procdepartment :"All",
            exceedingbudget :"All",
            forms :"All",
            intercompany :"All",
            workingas :"All",
            withinbudget :"All",
            amount :0,
            trc_code :"All",
            department :"All",
            cr_code :"All",
            po_type :"All",
            job_division :"All",
            sub_division :"All",
            leave_type :"All",
            trc :"All",
            clearance_type :"All",
            result :"Success",
            source_table :"",
            batch_code:"",
            priority :"3",
            prioritydateapplicable:"N",
            prioritydate :null,
            priority_text:"Low",
            prioritydateapplicable_text:"No",
            action:"",
            remark:"",
            role:"",
            actiontext:"",
            ln_forward_type : 0,
            ls_is_forwarded:"N",
            user_no:0,
            fwd_resp:0,
            fwd_to:"",
            pay_dept:"",
            lcnt:0

          } 
          return ldata
      } 
      
}
