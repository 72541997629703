import { Inject, Injectable, Optional } from '@angular/core'; 
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@Injectable()
export class EproUtcDateAdapter extends MomentDateAdapter {

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
    console.log(dateLocale);
  }

  createDate(year: number, month: number, date: number): Moment {
    let result = moment.utc({ year, month, date }).locale(this.locale);
    const _ = moment();
    result = moment(result).add({hours: _.hour(), minutes:_.minute() , seconds:_.second()})
    return result;
  }
}