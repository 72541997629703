
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';

import {  MatOptionModule } from '@angular/material/core';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
 
import { CookieService } from 'ngx-cookie-service';

import { FuseShortcutsComponent } from './shortcuts.component';
import { SendMessageComponent } from './SendMessage/SendMessage.component';
import { FormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '..';
import { searchModule } from 'app/base/search/search.module';
import { AttachmentModule } from 'app/base/Attachment/Attachment.module';
//import { SendMessageModule } from 'app/base/SendMessage/SendMessage.module';

@NgModule({
    declarations: [ 
        FuseShortcutsComponent,
        SendMessageComponent
    ],
    imports     : [

        CommonModule, 
        MatButtonModule, 
        MatInputModule, 
        MatDialogModule,  
        MatIconModule,
        FormsModule ,  
        MatCardModule, 

       // FuseSharedModule,
       // FuseSidebarModule, 

        CommonModule,
        RouterModule,

        FlexLayoutModule,

        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatListModule,
        MatTooltipModule,
        //SendMessageModule,
        MatDialogModule, 
        searchModule,MatTableModule,
        AttachmentModule
      //  SendMessageModule
    ],
    exports     : [
        FuseShortcutsComponent,
        SendMessageComponent
    ],
    providers   : [
        CookieService
    ],

     
    entryComponents: [SendMessageComponent]
})
export class FuseShortcutsModule
{
}
