

/**
 * Shijilesh 07/01/2017 
 * ePROMIS  
 *  For Comm. Services
 **/


//import { Observable } from 'rxjs/Rx';
import { Observable, Subject } from 'rxjs';  
import { globalData } from './globalData'; 
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

  

export abstract class BaseService {  
    authHeader :any;
    //baseUrl: string = '';
    baseURLCatalog: string = '';
    constructor(   public config: globalData  ,public router: Router   ) { 
      
      
      let url=localStorage.getItem('apiurl');  
        
      //alert(url)
      //this.lsBaseURL= url

      //this.getApi() =  url// config.lsBaseURL;
      this.baseURLCatalog = config.lsBaseURLCatalog;
    }

    getApi()  { 
      let url:string= this.baseURLCatalog;//localStorage.getItem('apiurl');  
      //   alert(url)
      return url
    }
    protected handleError(error: any) { 
      if (error.status == 401) {
        localStorage.removeItem('access_token');  
        alert("your session has expired please login again.") 
        this.router.navigate(['account/login']);

      }
      var applicationError = error.headers.get('-Error');

      // either applicationError in header or model error in body
      if (applicationError) {
        return Observable.throw(applicationError);
      }

      var modelStateErrors: string = '';
      var serverError = error.json();

      if (!serverError.type) {
        for (var key in serverError) {
          if (serverError[key])
            modelStateErrors += serverError[key] + '\n';
        }
      }

      modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
      return Observable.throw(modelStateErrors || 'Server error');
  }

    
  getHttpClientHeaderpass(){
    let authToken = localStorage.getItem('access_token_pass'); 

    
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + authToken
      })
    }
    return httpOptions;
  }

  getHttpClientHeader(){
    let authToken = localStorage.getItem('access_token'); 

    
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + authToken
      })
    }
    return httpOptions;
  }

  getHttpClientHeaderForUpload()
  {
    let httpOptions={
      headers:new HttpHeaders({
        'Content-Type':'multipart/form-data',
        'Accept':'application/json'
      })
    }
    return httpOptions;
  }


  getHttpUniurl(){ 
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded' 
      })
    }
    return httpOptions;
  }
  
}

