import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import {DragDropModule} from '@angular/cdk/drag-drop';
import { DragAndDropModule } from 'angular-draggable-droppable';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { loaderEproComponent } from '../loader-epro/loader-epro.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [loaderEproComponent],

  
  exports     : [ 
    loaderEproComponent
    
], 
  imports: [
    CommonModule,
    DragAndDropModule,
    MatCardModule,
    MatTableModule,MatCheckboxModule,
    DragDropModule ,

 
    FuseSharedModule,
    MatButtonModule, 
    MatFormFieldModule, 
    MatInputModule,
    MatDatepickerModule, 
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,   
    MatCardModule, 
    DragDropModule , MatProgressSpinnerModule



  ]
})
export class loaderEproModule { }
