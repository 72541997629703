
/**
 * Shijilesh 10/01/2017 
 * ePROMIS  
 *  Not Completed
 **/

import {Component, Inject} from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { messagebox_struct } from 'app/base/window/Basewindow.data';

@Component({
  selector: 'your-dialog',
  templateUrl:  './messagebox.component.html'
})

 
export class MessageboxComponent {

  messagebox_struct:messagebox_struct
  lb_yes_no:Boolean=false  
  language_code:string
  ls_ok:string
  ls_Yes:string
  ls_no:string
  constructor(@Inject(MAT_DIALOG_DATA) public data: messagebox_struct,private dialogRef: MatDialogRef<MessageboxComponent>) {
    this.messagebox_struct=this.setini_messagebox_struct()
    this.messagebox_struct=data
 
    this.lb_yes_no= this.messagebox_struct.lb_yesno

    this.language_code =localStorage.getItem('language_code')
    if(this.language_code=="AR"){
      this.ls_ok="خروج"
      this.ls_Yes="نعم"
      this.ls_no="لا" 
    } 
    else{
      this.ls_ok="Ok"    
      this.ls_Yes="Yes"
      this.ls_no="No" 
  }


   }

  yes(){
    this.dialogRef.close(true); 
  } 

  no(){
    this.dialogRef.close(false); 
  } 

  setini_messagebox_struct(){
    let msg:messagebox_struct={
      title:"",
      lb_yesno:false,
      text:""
    }
    return msg
  }
}


