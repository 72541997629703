import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
 
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
 
import { SendMessageComponent } from './SendMessage/SendMessage.component';
import { VMUserData } from 'app/base/global.data';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MediaObserver } from '@angular/flex-layout';
@Component({
    selector   : 'fuse-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls  : ['./shortcuts.component.scss']
})
export class FuseShortcutsComponent implements OnInit, OnDestroy
{
    shortcutItems: any[];
    navigationItems: any[];
    filteredNavigationItems: any[];
    searching: boolean;
    mobileShortcutsPanelActive: boolean;

    @Input()
    navigation: any;

    @ViewChild('searchInput')
    searchInputField;

    @ViewChild('shortcuts')
    shortcutsEl: ElementRef;
    lVMUserData:VMUserData;
    // Private
    ls_role_supplier:boolean
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {Renderer2} _renderer
     * @param {CookieService} _cookieService
     * @param {FuseMatchMediaService} _fuseMatchMediaService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {ObservableMedia} _observableMedia
     */
    constructor(
        private _cookieService: CookieService,
        private _fuseMatchMediaService: FuseMatchMediaService,
        private _fuseNavigationService: FuseNavigationService,
        private _mediaObserver: MediaObserver,
        private _renderer: Renderer2,public dialog: MatDialog
    )
    {
        // Set the defaults
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.lVMUserData = JSON.parse(localStorage.getItem('gsdata'));
        if(this.lVMUserData){
            if(this.lVMUserData.role_id!='099002'){
                this.ls_role_supplier= false;
                }else{
                    this.ls_role_supplier= true;
                }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

       

        // Get the navigation items and flatten them
        this.filteredNavigationItems = this.navigationItems = this._fuseNavigationService.getFlatNavigation(this.navigation);

        if ( this._cookieService.check('FUSE2.shortcuts') )
        {
            this.shortcutItems = JSON.parse(this._cookieService.get('FUSE2.shortcuts'));
        }
        else
        { 
 

           if(this.ls_role_supplier){
            this.shortcutItems = [
                 
                {
                    'title':'Send Message',
                    'type' : 'item',
                    'icon': 'message',
                    'url' : ''
                }
                
            ];
           }
           else{
            // User's shortcut items
            this.shortcutItems = [
                // {
                //     'title': 'Calendar',
                //     'type' : 'item',
                //     'icon' : 'today',
                //     'url'  : '/apps/calendar'
                // },
                // {
                //     'title': 'Mail',
                //     'type' : 'item',
                //     'icon' : 'email',
                //     'url'  : '/apps/mail'
                // },
                // {
                //     'title': 'Contacts',
                //     'type' : 'item',
                //     'icon' : 'account_box',
                //     'url'  : '/apps/contacts'
                // },
                // {
                //     'title': 'To-Do',
                //     'type' : 'item',
                //     'icon' : 'check_box',
                //     'url'  : '/apps/todo'
                // },
                // {
                //     'title': 'Charts',
                //     'type' : 'item',
                //     'icon' : 'dashboard',
                //     'url'  : '/maindashboard'
                // },
                // {
                //     'title':'Documents',
                //     'type' : 'item',
                //     'icon': 'description',
                //     'url' : '/documentsexpiry'
                // },
                // {
                //     'title':'Pending Tasks',
                //     'type' : 'item',
                //     'icon': 'book',
                //     'url' : '/documentspending'
                // },
                // {
                //     'title':'Send Message',
                //     'type' : 'item',
                //     'icon': 'message',
                //     'url' : ''
                // }
                // ,
                // {
                //     'title':'Help',
                //     'type' : 'item',
                //     'icon': 'help_center',
                //     'url' : '/academy/all'
                // }
                
            ];
            }
        }

        // Subscribe to media changes
        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if ( this._mediaObserver.isActive('gt-sm') )
                {
                    this.hideMobileShortcutsPanel();
                } 
            });
    }

    open(shortcutItems){

        if(shortcutItems.title=='Send Message'){
            
            try{ 
                
                    const dialogConfig = new MatDialogConfig(); 
                 
                    dialogConfig.autoFocus = true;
                    dialogConfig.height='77%';
                    dialogConfig.width='77%';    
                    dialogConfig.disableClose = true;
                    const dialogRef = this.dialog.open(SendMessageComponent, dialogConfig);  
                    dialogRef.afterClosed().subscribe(async res => {
                        
                    }, ); 
     
            }catch(error){
               // this.handleError(error)
            }
    
        }
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Search
     *
     * @param event
     */
    search(event): void
    {
        const value = event.target.value.toLowerCase();

        if ( value === '' )
        {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;

            return;
        }

        this.searching = true;

        this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
            return navigationItem.title.toLowerCase().includes(value);
        });
    }

    /**
     * Toggle shortcut
     *
     * @param event
     * @param itemToToggle
     */
    toggleShortcut(event, itemToToggle): void
    {
        event.stopPropagation();

        for ( let i = 0; i < this.shortcutItems.length; i++ )
        {
            if ( this.shortcutItems[i].url === itemToToggle.url )
            {
                this.shortcutItems.splice(i, 1);

                // Save to the cookies
                this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));

                return;
            }
        }

        this.shortcutItems.push(itemToToggle);

        // Save to the cookies
        this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));
    }

    /**
     * Is in shortcuts?
     *
     * @param navigationItem
     * @returns {any}
     */
    isInShortcuts(navigationItem): any
    {
        return this.shortcutItems.find(item => {
            return item.url === navigationItem.url;
        });
    }

    /**
     * On menu open
     */
    onMenuOpen(): void
    {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    /**
     * Show mobile shortcuts
     */
    showMobileShortcutsPanel(): void
    {
        this.mobileShortcutsPanelActive = true;
        //shijilesh angular 11 this._renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    /**
     * Hide mobile shortcuts
     */
    hideMobileShortcutsPanel(): void
    {
        this.mobileShortcutsPanelActive = false;
        //shijilesh angular 11  this._renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }
}
