import { Injectable } from '@angular/core'; 
import { VMUserData } from 'app/base/global.data';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor( ) {  
  }

  getLanguage() {
  
    let lVMUserData: VMUserData
    lVMUserData = JSON.parse(localStorage.getItem('gsdata'))
    
    if (lVMUserData){
      console.log( lVMUserData.locale_id)
      return lVMUserData.locale_id
    }else{      
      return "en"
    }
  }
}
