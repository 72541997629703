
<!-- <mat-card> -->
  
  <div mat-dialog-title class="background">{{messagebox_struct.title}}</div>
  <!-- <mat-dialog-content >
    {{messagebox_struct.text}}
  </mat-dialog-content> -->
  <div mat-dialog-content [innerHtml]="'<p>' + messagebox_struct.text + '</p>'"></div>
  <mat-dialog-actions  >
    <button mat-button  class="background brownbtn"  cdkFocusInitial  *ngIf="!messagebox_struct.lb_yesno" (click)="yes()" >{{ls_ok}}</button>  

    <button mat-button cdkFocusInitial  class="background brownbtn" (click)="yes()" *ngIf="messagebox_struct.lb_yesno">{{ls_Yes}}</button>
    <button mat-button   class="background redbtn"  (click)="no()" *ngIf="messagebox_struct.lb_yesno">{{ls_no}}</button>
  </mat-dialog-actions>

  <!-- <div class="buttons ">
    <button mat-button  class="background accent"  cdkFocusInitial  *ngIf="!messagebox_struct.lb_yesno" (click)="yes()" >Ok</button>  

    <button mat-button cdkFocusInitial  class="background accent" (click)="yes()" *ngIf="messagebox_struct.lb_yesno">Yes</button>
    <button mat-button     (click)="no()" *ngIf="messagebox_struct.lb_yesno">No</button>
      
  </div> -->
<!-- </mat-card> -->

<!-- <h1 mat-dialog-title>{{messagebox_struct.title}} </h1>
<div mat-dialog-content>
  <p>{{messagebox_struct.text}}</p> 
</div>
<div mat-dialog-actions>
   <button mat-button (click)="onNoClick()">No Thanks</button> 
   <button mat-button    cdkFocusInitial  *ngIf="!messagebox_struct.lb_yesno" (click)="yes()" >Ok</button>  

  <button mat-button cdkFocusInitial   (click)="yes()" *ngIf="messagebox_struct.lb_yesno">Yes</button> 
   <button mat-button     (click)="no()" *ngIf="messagebox_struct.lb_yesno">No</button> 
 </div> -->