import { NgModule } from '@angular/core';   
import { BaseWindow } from 'app/base/window/BaseWindow.component';
import { CommonModule } from '@angular/common';
import { BaseWindowbuttons } from 'app/base/buttons/BaseWindowbuttons.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

import { BaseDialogwindowcomponent } from 'app/base/window/BaseDialogwindow.component';
import { Basewindowmaster } from './window/Basewindowmaster.component';
import { MessageboxComponent } from './window/messagebox.component';
import { globalData } from './globalData';
import { basefunctionsService } from './basefunctionsService';
import { UserService } from './userservice';
import { reportFilterModule } from './reportFilter/reportFilter.module';
 
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
    imports     : [ 
        CommonModule, 
        MatButtonModule, MatDialogModule,MatIconModule
        ,reportFilterModule ,MatBadgeModule 

      //  MatFormFieldModule, 
 
    ],
    exports     : [ 
        BaseWindowbuttons,
        BaseDialogwindowcomponent,
        BaseWindow, 
        Basewindowmaster,
        
    ], 
    declarations: [  
        BaseWindowbuttons,
        BaseDialogwindowcomponent,
        BaseWindow,
        Basewindowmaster, 
        MessageboxComponent
    ],
    entryComponents: [  
            MessageboxComponent 
          ],
          providers: [ globalData,basefunctionsService ,UserService  ],
}) 

export class baseModule
{
}
