
import { Injectable } from '@angular/core';   
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router'; 
import { globalData } from 'app/base/globalData';
import { BaseService } from 'app/base/BaseService'; 

@Injectable()
export class reportFilterService extends BaseService {
  constructor(public  config: globalData, public httpClient: HttpClient,public router: Router) {super(config  ,router ); }

  UploadExcel(formData: FormData,ApiName:string) {  
    let headers = new HttpHeaders();  
    headers.append('Content-Type', 'multipart/form-data');  
    headers.append('Accept', 'application/json');  
    const httpOptions = { headers: headers };  
    return this.httpClient.post(this.getApi() +   ApiName   , formData, httpOptions)  
  }  
}


