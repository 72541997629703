

<!-- <div class="page-layout blank p-24" >  -->
    <div mat-dialog-title class="background">Confirm</div>
    <mat-dialog-content>
    <div class="" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
         <form  > 
            <div [ngStyle]="formStyle">
                <!-- <div class="row">
                    <mat-form-field *ngIf="ls_batch=='Y'" >  
                        <input  [(ngModel)]= "ls_approval.batch_code" name="ls_approval.batch_code"  type="text"  matInput placeholder="Batch #"  [readonly]="true"  (click)="selectbatch()">    
                    </mat-form-field>
        
                    <mat-form-field >  
                        <input  [(ngModel)]= "ls_approval.priority_text" name="ls_approval.priority_text"  type="text"  matInput placeholder="Priority"  [readonly]="true" (click)="selectpriority()" >    
                    </mat-form-field>
                </div> -->
                <!-- <div class="row">
                    <mat-form-field >  
                        <input  [(ngModel)]= "ls_approval.prioritydateapplicable_text" name="ls_approval.prioritydateapplicable_text"  type="text"  matInput placeholder="Priority Date Applicable?"  [readonly]="true" (click)="selectpriorityDateapp()"   >    
                    </mat-form-field>
             
                    <mat-form-field  *ngIf="ls_approval.prioritydateapplicable=='Y'" appearance="outline"> 
                        <input [(ngModel)]="ls_approval.prioritydate" name="ls_approval.prioritydate" matInput [readonly]="true" [matDatepicker]="myDatepicker" matInput placeholder="Priority Date"  >
                        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                    </mat-form-field>
                </div>  -->
                
            </div>
        </form>
    </div>  
</mat-dialog-content>
<mat-dialog-actions *ngIf="lb_form_enabled==true">
    <button  (click)="ue_send()" mat-raised-button class="brownbtn">
        <mat-icon>done</mat-icon>
        <span class="hide-text"> Send</span>
    </button>
    <button   (click)="ue_cancel()" mat-button   class="background redbtn" >
        <mat-icon>clear</mat-icon>
        <span class="hide-text"> Cancel</span>
    </button> 
</mat-dialog-actions>

     <!-- <div *ngIf="lb_form_enabled==false">
        <p> ... Please Wait ...</p>
        <mat-spinner></mat-spinner>
    </div>  -->
<!-- </div> -->



<!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left" >

    <mat-form-field fxFlex="20">  <input  [(ngModel)]="ls_approval.batch_code" name="batch_code"  matInput placeholder="Batch #" [readonly]="true" > </mat-form-field>
    <mat-form-field fxFlex="20">  <input  [(ngModel)]="priority" name="priority"  matInput placeholder="Priority" [readonly]="true"  (click)="selectpriority()"  > </mat-form-field>
    <mat-form-field fxFlex="20">  <input  [(ngModel)]="prioritydateapplicable" name="prioritydateapplicable"  matInput placeholder="prioritydateapplicable"   > </mat-form-field>
</div>
<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left" >
    <mat-form-field>
        <input [(ngModel)]="prioritydate" name="prioritydate" matInput [readonly]="true" [matDatepicker]="myDatepicker2">
        <mat-datepicker-toggle matSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
        <mat-datepicker #myDatepicker2></mat-datepicker>
    </mat-form-field>
</div>
    
<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3.5%" fxLayoutAlign="left" > 
   
    <br>   
        <button type="button" mat-raised-button (click)="ue_send()">
           
            Send 
        </button> 
        <button type="button" mat-raised-button (click)="ue_cancel()"> 
            Cancel 
        </button> 

</div>

  -->