import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {  TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { AppRoutingModule, routes } from './app.routing';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DateAdapter, MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import {MatTableModule} from '@angular/material/table';

import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatTreeModule} from '@angular/material/tree';
import { ConfigService } from './shared/utils/config.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { AuthGuard } from './shared/utils/auth.guard';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { EproUtcDateAdapter } from './base/epro-utc-adapter';
import { AgmCoreModule } from '@agm/core';
import { TableModule } from 'primeng/table';
import { UserService } from './base/userservice';
import { globalData } from './base/globalData';
import { basefunctionsService } from './base/basefunctionsService'; 
  import { NgxMaskModule } from 'ngx-mask'  
  import { BnNgIdleService } from 'bn-ng-idle'; 

  import { TranslateHttpLoader } from '@ngx-translate/http-loader'; 
import { SettingsService } from './services/SettingsService'; 
export function rootLoaderFactory(http: HttpClient) {  
    return new TranslateHttpLoader(http);
  }

  
@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [ 
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        //RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        RouterModule.forRoot(routes ),
        

        //TranslateModule.forRoot(),

        TranslateModule.forRoot({
            loader: {
            provide: TranslateLoader,
            useFactory: rootLoaderFactory,
            deps: [HttpClient],
            },
            }),
 
            

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        TableModule ,
        //SampleModule
        // new
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter,
               // whitelistedDomains: ['localhost:5000'],
            }
        }),
        AppRoutingModule,

        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,

        // LyThemeModule.setTheme('minima-light'),
        //new 
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule, 
        //AppRoutingModule,
        //HttpModule,
        HttpClientModule,
        MatFormFieldModule, 
        MatButtonModule, 
        MatFormFieldModule, 
        MatInputModule, 
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,  
        MatMenuModule,
        MatTreeModule,
        MatListModule, 
       // headersectionModule,loaderEproModule,
        //
     //   RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        //menuModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        //baseModule,
        LayoutModule, 
      
        //ePROMIS
        //shared 
        //approvalModule, 
        
        //reportModule,
 
        //accountcategoryModule,
        
        AgmCoreModule.forRoot(),
       
        
        NgxMaskModule.forRoot({
            showMaskTyped : true,
            clearIfNotMatch : true
          }) 

    ],
    bootstrap   : [
        AppComponent
    ],

    providers: [
        { provide: LOCALE_ID,
            deps: [SettingsService],      //some service handling global settings
            useFactory: (settingsService) => settingsService.getLanguage()},
         { provide: LocationStrategy, useClass: HashLocationStrategy },
         
         JwtHelperService,
         ConfigService,
         AuthenticationService,
         UserService ,
         globalData ,
         AuthGuard , 
         basefunctionsService,
            { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
            { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
            { provide: DateAdapter, useClass: EproUtcDateAdapter },
            BnNgIdleService
     ],

    
})
export class AppModule
{
}



export function jwtTokenGetter() {
    return localStorage.getItem('id_token');
}
