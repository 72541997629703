import {Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy} from '@angular/core';  
import { ActivatedRoute, Router } from '@angular/router';  
import {  VMs_transinfo, s_approval, vm_transactions, s_transinformation, vm_parm } from './Basewindow.data'; 
import { iButtons } from '../buttons/BaseWindowbuttons.data';
import { globalData } from '../globalData';
import { UserService } from '../userservice';
import { s_dynstructure, VMCommonQuery, VMSearch } from '../search/search.data';


import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';


import { SearchComponent } from '../search/search.component';
import { Observable, throwError } from 'rxjs';
import { ApprovalSendComponent } from '../approvals/approval.send.component';
import { MessageboxComponent } from 'app/base/window/messagebox.component';
import { basefunctionsService } from 'app/base/basefunctionsService';
import { VMUserData } from 'app/base/global.data';
import { ApprovalDocComponent } from '../approvals/approval.doc.component'; 
import { DatePipe } from '@angular/common'; 
import { reportFilterComponent } from '../reportFilter/reportFilter.component';
import { Attachmentcomponent } from '../Attachment/Attachment.component'; 
import { s_drill } from 'app/main/Reports/ReportFrame/ReportFrame.data';
//import { VoidPromptComponent } from '../void-prompt/void-prompt.component';
import { UserTasksComponent } from 'app/base/tasks/UserTasks.component';
import { dw_reports_filter, dw_reports_lables } from '../DynaReports/DynaReports.data';
import { dw_dragdropmaster } from '../dragdropselect/dragdropselect.data';
import { dynSearchComponent } from '../dynSearch/dynSearch.component';


export interface YesNo {
    value: string;
    viewValue: string;
  }

@Component({
        templateUrl: './Basewindowmaster.component.html' , 
        selector: 'basewindowmaster', 
        providers:[DatePipe] 
    })

export class Basewindowmaster implements OnInit,OnDestroy  {
  
    vmTrans:vm_transactions;
    ls_decimals:number=2;
    @ViewChild(MatPaginator) paginator: MatPaginator; 
    @ViewChild(MatSort) sort: MatSort;
    isPhoneAlpha : Boolean = false;// used to set the phone/mobile number field either alphaNumeric or only numbers
    collapseHeight = false; 
    ln_sectrow: number;
    li_Buttons_window:iButtons;
    lstru_approval:s_approval; 
    S_TRANSINFO:VMs_transinfo;
    lqueryparm;
    ls_menuCode:string;
    ls_menuName:string="";
    ls_mode:string;
    lb_submitted:boolean=false
    Idt_open_time:Date
    il_eas_count :number;
    id_vrdate:Date=new Date();
    lb_form_enabled:boolean=true;
    language_code:string
    lb_new:boolean=false;
    lb_first:boolean=false;
    lb_last:boolean=false;
    lb_next:boolean=false;
    lb_prior:boolean=false;
    lb_save:boolean=false;
    lb_delete:boolean=false;
    lb_print:boolean=false;
    lb_listing:boolean=false;
    lb_cheque:boolean=false;
    lb_void:boolean=false;
    lb_approve:boolean=false;
    lb_status:boolean=false;
    lb_attach:boolean=false;
    lb_browse:boolean=false;
    lb_custom_fields:boolean=false;
    lb_import:boolean=false;
    lb_undo:boolean=false;
    lb_send:boolean=false; 
    lb_refresh:boolean=false;  
    lb_close:boolean=false; 
    lb_date_protect:boolean=false
    innerWidth:number
    innerHeight:number
    lVMUserData: VMUserData
    ls_VMCommonQuery :VMCommonQuery;
    gl_attach_cnt:number
    lVMSearch:VMSearch;
    vi_month_code:number;  
    is_doc_approval_cnt // approval Count for this doc 
    ls_duplicate_value:string
    ls_company_name:string
    formStyle = {
        width: '100%',
        display: 'block',
    };

    ngStyleFlex = {      
    };
    ngStyleSmall:any={};
    ngStyleXXSmall:any={};
    ngStyleXsmall:any={};
    
    ngStylemedium:any={};
    ngStylelarge:any={};
    ngStylenumeric:any={};
    ngStyleDate:any={};
    ngStyleAmount:any={}; 
    ngStyleWidth:any={};
    ngCenterHead :any ={}

    ls_header_remarks:string="sample"
    lb_header_remarks:boolean=false
    s_trans:s_transinformation
    YesNos: YesNo[] = [
        {value: 'Y', viewValue: 'Yes'},
        {value: 'N', viewValue: 'No'} 
      ];

    ls_drill:s_drill
    ref_fy_code :string
    ref_trc_code :string
    ref_vr_no:string
    text1	:	string
    text2	:	string
    text3	:	string
    lb_dynmaster:boolean
    ls_doc:s_drill
    ldw_reports_filter:dw_reports_filter
    constructor( public route: ActivatedRoute,public router: Router, public ls_gdata:globalData,public _user_service:UserService,public dialog: MatDialog 
        ,public _bfs :basefunctionsService, public adapter: DateAdapter<any>  ,public datePipe : DatePipe ) {
         
        this.S_TRANSINFO=this.ini_stransinfo()
        this.li_Buttons_window=this.setInilbutton(); 
        this.lVMUserData = JSON.parse(localStorage.getItem('gsdata')); 
        this.adapter.setLocale('en-GB');
        this.vmTrans=this.ini_vm_transactions() 
        this.lb_dynmaster=false
        this.language_code =localStorage.getItem('language_code') 
         
    } 
     
    ngOnInit() { 
          
        this.innerWidth = window.innerWidth; 
//       this.ngOInitRecall()
        if(innerWidth > 450)
        {
            // this.ngStyleSmall = {width: '150px !important','max-width': '150px'}
            this.ngStyleSmall = {width: '150px','max-width': '150px'}
            this.ngStyleXXSmall = {width: '50px','max-width': '50px'}
            this.ngStyleXsmall = {width: '70px','max-width': '70px'}
            this.ngStylemedium = {width: '250px','max-width': '400px'}
            this.ngStylelarge = {width: '350px','max-width': '500px'}
            this.ngStylenumeric = {width: '350px','max-width': '500px','text-align': 'right' }
            this.ngStyleDate = {width: '130px','max-width': '130px'}
            this.ngStyleAmount = {width: '150px','max-width': '150px','text-align': 'right'}
            this.ngStyleWidth = {width: '100%'}
            this.ngCenterHead = {textAlign:'center'}
        }
        else
        {
            this.ngStylenumeric = {'text-align': 'right' }
            this.ngStyleAmount = {'text-align': 'right' }

        };
 
        this.route.params.subscribe(async value => {  
         
            this.ls_menuCode= value.id   
            debugger
 
            this.S_TRANSINFO.trc_code=this.ls_menuCode
            this.vmTrans.strProcess="sp_s_transinfo_master_browse"
            this.vmTrans.stringjson=  JSON.stringify(this.S_TRANSINFO) ; 
            this.vmTrans =await this._user_service.getData(this.vmTrans)  
            if(this.vmTrans.Result=="Success"){
                this.S_TRANSINFO=JSON.parse(this.vmTrans.stringjson); 
                this.lb_form_enabled=true     
                this.ls_menuName=this.S_TRANSINFO.menu_tree_name   
                this.ls_decimals=this.S_TRANSINFO.amt_dec

                if(!this.S_TRANSINFO.rights){
                    this._bfs.f_showmessage("Info","No Rights !!!")
                    this.goHome();
                }
                debugger
                this.ls_drill= JSON.parse( localStorage.getItem('s_drill' ) ) 
                if(!this.ls_drill){
                    this.ls_drill=this.set_drill()
                }
                if(this.ls_drill.ref_type){
                    this.S_TRANSINFO.ls_fy_code=this.ls_drill.fy_code
                    this.S_TRANSINFO.trc_code=this.ls_drill.trc_code
                    this.S_TRANSINFO.lvr_no=this.ls_drill.vr_no   
                    this.S_TRANSINFO.windowstate=this.ls_drill.ref_type
                    
                        
                    await this.f_open()
                    //await this.ue_new(null) 

                    if (this.ls_drill.ref_type=='Approval Screen'){
                        this.lb_form_enabled=true
                        this.S_TRANSINFO.ls_fy_code=this.ls_drill.fy_code
                        this.S_TRANSINFO.trc_code=this.ls_drill.trc_code
                        this.S_TRANSINFO.lvr_no=this.ls_drill.vr_no   
                        
                        await this.ue_retrieve() 
                    }
                    else if (this.ls_drill.ref_type=='To Do List'){
                        this.lb_form_enabled=true
                        await this.ue_to_do_list() 
                    }
                    localStorage.removeItem('s_drill' ); 
                    
                } 
                else{
                    await this.f_open()
                   // await this.ue_new(null) 
                }

                  this.setAccess(); 
                //this.f_open()
            //}
            //},(error => this.handleError(error))); 
            }
            else{
                await this.f_open()
            }

        }); 
        this.wf_insert_log('IN'); 
        this.wf_parentngOnInit()
    }

    
    async wf_MReporting(code:string,jsondata:string){
        // For Report From API

        let vmLabelData:dw_reports_lables=this.inidw_reports_lables()
        this.vmTrans=this.ini_vm_transactions()
        vmLabelData.code=code
        this.vmTrans.strProcess="sp_dynreport_labels"
        this.vmTrans.stringjson=  JSON.stringify(vmLabelData) ; 

        this.vmTrans =await this._user_service.getData(this.vmTrans)   
        
        if(this.vmTrans.Result=="Success"){
            vmLabelData=JSON.parse(this.vmTrans.stringjson);   
            this.vmTrans=this.ini_vm_transactions()
            this.vmTrans.strProcess=vmLabelData.sp_name
            this.vmTrans.authcode = localStorage.getItem('access_token');  
            this.vmTrans.stringjson=   jsondata  ;  
            this.vmTrans.report_type="RPT"
            this.vmTrans.report_code=code
            this.vmTrans.stringjson=  JSON.stringify(this.vmTrans) ; 
        
             
            this.vmTrans.strProcess="sp_insert_reports"
            this.vmTrans =await this._user_service.postData(this.vmTrans)
            if(this.vmTrans.Result=="Success"){
                let url:string= this._user_service.getApi() //localStorage.getItem('apiurl');  
                const url1 = url+'Downloads/Index?vmds='+this.vmTrans.authcode 
                window.open(  url1, '_blank'); 
            }   
        }
        else{ 
            this._bfs.f_showmessage("Info",this.vmTrans.Result)
            
        }  
        
        
        //For Report From API
    }

    async wf_MReportingemail(code:string,jsondata:string){
        // For Report From API

        let vmLabelData:dw_reports_lables=this.inidw_reports_lables()
        this.vmTrans=this.ini_vm_transactions()
        vmLabelData.code=code
        this.vmTrans.strProcess="sp_dynreport_labels"
        this.vmTrans.stringjson=  JSON.stringify(vmLabelData) ; 

        this.vmTrans =await this._user_service.getData(this.vmTrans)   
        
        if(this.vmTrans.Result=="Success"){
            vmLabelData=JSON.parse(this.vmTrans.stringjson);   
            this.vmTrans=this.ini_vm_transactions()
            this.vmTrans.strProcess=vmLabelData.sp_name
            this.vmTrans.authcode = localStorage.getItem('access_token');  
            this.vmTrans.stringjson=   jsondata  ;  
            this.vmTrans.report_type="RPT"
            this.vmTrans.report_code=code
            this.vmTrans.stringjson=  JSON.stringify(this.vmTrans) ; 
        
             
            this.vmTrans.strProcess="sp_insert_reports"
            this.vmTrans =await this._user_service.postData(this.vmTrans)
            if(this.vmTrans.Result=="Success"){
                let url:string= this._user_service.getApi() //localStorage.getItem('apiurl');  
                const url1 = url+'Downloads/email?vmds='+this.vmTrans.authcode 
                window.open(  url1, '_blank'); 
                this._bfs.f_showmessage("Info","Email send")
                //  var popup = window.open(url1, "Popup", "left=10000,top=10000,width=0,height=0");

                 
                // let resutdt= await this._bfs.f_showmessageok("Info","Email send")
                // if (resutdt==false ){
                //     popup.close()   
                // }

                  
                

                
                
            }   
        }
        else{ 
            this._bfs.f_showmessage("Info",this.vmTrans.Result)
            
        }  
        
        
        //For Report From API
    }

    inidw_reports_lables()
    {
        let ldatDet2: dw_dragdropmaster[] = [];
        let ldata: dw_reports_lables = { 
            refcode:null,
            code : null,  
            name : null,  
            fromdate1 : null, 
            fromdate1_data : null, 
            todate1 : null, 
            todate1_data : null, 
            fromdate2 : null, 
            fromdate2_data : null, 
            todate2 : null, 
            todate2_data : null, 
            fromdate3 : null, 
            fromdate3_data : null, 
            todate3 : null, 
            todate3_data : null, 
            fromdate4 : null, 
            fromdate4_data : null, 
            todate4 : null, 
            todate4_data : null, 
            fromdate5 : null, 
            fromdate5_data : null, 
            todate5 : null, 
            todate5_data : null, 
            fromdate6 : null, 
            fromdate6_data : null, 
            todate6 : null, 
            todate6_data : null, 
            fromdate7 : null, 
            fromdate7_data : null, 
            todate7 : null, 
            todate7_data : null, 
            
            optionboxgrp1_heading : null, 
            optionboxgrp1_1 : null, 
            optionboxgrp1_1_data : null, 
            optionboxgrp1_2 : null, 
            optionboxgrp1_2_data : null, 
            optionboxgrp1_3 : null, 
            optionboxgrp1_3_data : null, 
            optionboxgrp1_4 : null, 
            optionboxgrp1_4_data : null, 
            optionboxgrp1_5 : null, 
            optionboxgrp1_5_data : null, 
            optionboxgrp1_6 : null, 
            optionboxgrp1_6_data : null, 
            optionboxgrp1_7 : null, 
            optionboxgrp1_7_data : null, 
            optionboxgrp1_8 : null, 
            optionboxgrp1_8_data : null, 
            optionboxgrp1_9 : null, 
            optionboxgrp1_9_data : null, 
            optionboxgrp1_10 : null, 
            optionboxgrp1_10_data : null, 
            optionboxgrp1_11 : null, 
            optionboxgrp1_11_data : null, 
            optionboxgrp1_12 : null, 
            optionboxgrp1_12_data : null, 
            optionboxgrp1_13 : null, 
            optionboxgrp1_13_data : null, 
            optionboxgrp1_14 : null, 
            optionboxgrp1_14_data : null, 
            optionboxgrp1_15 : null, 
            optionboxgrp1_15_data : null, 
            optionboxgrp2_heading : null, 
            optionboxgrp2_1 : null, 
            optionboxgrp2_1_data : null, 
            optionboxgrp2_2 : null, 
            optionboxgrp2_2_data : null, 
            optionboxgrp2_3 : null, 
            optionboxgrp2_3_data : null, 
            optionboxgrp2_4 : null, 
            optionboxgrp2_4_data : null, 
            optionboxgrp2_5 : null, 
            optionboxgrp2_5_data : null, 
            optionboxgrp2_6 : null, 
            optionboxgrp2_6_data : null, 
            optionboxgrp2_7 : null, 
            optionboxgrp2_7_data : null, 
            optionboxgrp2_8 : null, 
            optionboxgrp2_8_data : null, 
            optionboxgrp2_9 : null, 
            optionboxgrp2_9_data : null, 
            optionboxgrp2_10 : null, 
            optionboxgrp2_10_data : null, 
            optionboxgrp2_11 : null, 
            optionboxgrp2_11_data : null, 
            optionboxgrp2_12 : null, 
            optionboxgrp2_12_data : null, 
            optionboxgrp2_13 : null, 
            optionboxgrp2_13_data : null, 
            optionboxgrp2_14 : null, 
            optionboxgrp2_14_data : null, 
            optionboxgrp2_15 : null, 
            optionboxgrp2_15_data : null, 
            optionboxgrp3_heading : null, 
            optionboxgrp3_1 : null, 
            optionboxgrp3_1_data : null, 
            optionboxgrp3_2 : null, 
            optionboxgrp3_2_data : null, 
            optionboxgrp3_3 : null, 
            optionboxgrp3_3_data : null, 
            optionboxgrp3_4 : null, 
            optionboxgrp3_4_data : null, 
            optionboxgrp3_5 : null, 
            optionboxgrp3_5_data : null, 
            optionboxgrp3_6 : null, 
            optionboxgrp3_6_data : null, 
            optionboxgrp3_7 : null, 
            optionboxgrp3_7_data : null, 
            optionboxgrp3_8 : null, 
            optionboxgrp3_8_data : null, 
            optionboxgrp3_9 : null, 
            optionboxgrp3_9_data : null, 
            optionboxgrp3_10 : null, 
            optionboxgrp3_10_data : null, 
            optionboxgrp3_11 : null, 
            optionboxgrp3_11_data : null, 
            optionboxgrp3_12 : null, 
            optionboxgrp3_12_data : null, 
            optionboxgrp3_13 : null, 
            optionboxgrp3_13_data : null, 
            optionboxgrp3_14 : null, 
            optionboxgrp3_14_data : null, 
            optionboxgrp3_15 : null, 
            optionboxgrp3_15_data : null, 
            optionboxgrp4_heading : null, 
            optionboxgrp4_1 : null, 
            optionboxgrp4_1_data : null, 
            optionboxgrp4_2 : null, 
            optionboxgrp4_2_data : null, 
            optionboxgrp4_3 : null, 
            optionboxgrp4_3_data : null, 
            optionboxgrp4_4 : null, 
            optionboxgrp4_4_data : null, 
            optionboxgrp4_5 : null, 
            optionboxgrp4_5_data : null, 
            optionboxgrp4_6 : null, 
            optionboxgrp4_6_data : null, 
            optionboxgrp4_7 : null, 
            optionboxgrp4_7_data : null, 
            optionboxgrp4_8 : null, 
            optionboxgrp4_8_data : null, 
            optionboxgrp4_9 : null, 
            optionboxgrp4_9_data : null, 
            optionboxgrp4_10 : null, 
            optionboxgrp4_10_data : null, 
            optionboxgrp4_11 : null, 
            optionboxgrp4_11_data : null, 
            optionboxgrp4_12 : null, 
            optionboxgrp4_12_data : null, 
            optionboxgrp4_13 : null, 
            optionboxgrp4_13_data : null, 
            optionboxgrp4_14 : null, 
            optionboxgrp4_14_data : null, 
            optionboxgrp4_15 : null, 
            optionboxgrp4_15_data : null, 
            optionboxgrp5_heading : null, 
            optionboxgrp5_1 : null, 
            optionboxgrp5_1_data : null, 
            optionboxgrp5_2 : null, 
            optionboxgrp5_2_data : null, 
            optionboxgrp5_3 : null, 
            optionboxgrp5_3_data : null, 
            optionboxgrp5_4 : null, 
            optionboxgrp5_4_data : null, 
            optionboxgrp5_5 : null, 
            optionboxgrp5_5_data : null, 
            optionboxgrp5_6 : null, 
            optionboxgrp5_6_data : null, 
            optionboxgrp5_7 : null, 
            optionboxgrp5_7_data : null, 
            optionboxgrp5_8 : null, 
            optionboxgrp5_8_data : null, 
            optionboxgrp5_9 : null, 
            optionboxgrp5_9_data : null, 
            optionboxgrp5_10 : null, 
            optionboxgrp5_10_data : null, 
            optionboxgrp5_11 : null, 
            optionboxgrp5_11_data : null, 
            optionboxgrp5_12 : null, 
            optionboxgrp5_12_data : null, 
            optionboxgrp5_13 : null, 
            optionboxgrp5_13_data : null, 
            optionboxgrp5_14 : null, 
            optionboxgrp5_14_data : null, 
            optionboxgrp5_15 : null, 
            optionboxgrp5_15_data : null, 
            checkbox_heading : null, 
            checkbox_1 : null, 
            checkbox_1_data : null, 
            checkbox_2 : null, 
            checkbox_2_data : null, 
            checkbox_3 : null, 
            checkbox_3_data : null, 
            checkbox_4 : null, 
            checkbox_4_data : null, 
            checkbox_5 : null, 
            checkbox_5_data : null, 
            checkbox_6 : null, 
            checkbox_6_data : null, 
            checkbox_7 : null, 
            checkbox_7_data : null, 
            checkbox_8 : null, 
            checkbox_8_data : null, 
            checkbox_9 : null, 
            checkbox_9_data : null, 
            checkbox_10 : null, 
            checkbox_10_data : null, 
            checkbox_11 : null, 
            checkbox_11_data : null, 
            checkbox_12 : null, 
            checkbox_12_data : null, 
            checkbox_13 : null, 
            checkbox_13_data : null, 
            checkbox_14 : null, 
            checkbox_14_data : null, 
            checkbox_15 : null, 
            checkbox_15_data : null, 
            year_drop1 : null, 
            year_drop1_data : null, 
            year_drop2 : null, 
            year_drop2_data : null, 
            year_drop3 : null, 
            year_drop3_data : null, 
            year_drop4 : null, 
            year_drop4_data : null, 
            year_drop5 : null, 
            year_drop5_data : null, 
            year_drop6 : null, 
            year_drop6_data : null, 
            year_drop7 : null, 
            year_drop7_data : null, 
            text_select1 : null, 
            text_select1_data : null, 
            text_select2 : null, 
            text_select2_data : null, 
            text_select3 : null, 
            text_select3_data : null, 
            text_select4 : null, 
            text_select4_data : null, 
            text_select5 : null, 
            text_select5_data : null, 
            text_select6 : null, 
            text_select6_data : null, 
            text_select7 : null, 
            text_select7_data : null, 
            text_select8 : null, 
            text_select8_data : null, 
            text_select9 : null, 
            text_select9_data : null, 
            text_select10 : null, 
            text_select10_data : null, 
            text_select11 : null, 
            text_select11_data : null, 
            text_select12 : null, 
            text_select12_data : null, 
            text_select13 : null, 
            text_select13_data : null, 
            text_select14 : null, 
            text_select14_data : null, 
            text_select15 : null, 
            text_select15_data : null, 
            text_sql_select1 : null, 
            text_sql_select1_data : null, 
            text_sql_select2 : null, 
            text_sql_select2_data : null, 
            text_sql_select3 : null, 
            text_sql_select3_data : null, 
            text_sql_select4 : null, 
            text_sql_select4_data : null, 
            text_sql_select5 : null, 
            text_sql_select5_data : null, 
            text_sql_select6 : null, 
            text_sql_select6_data : null, 
            text_sql_select7 : null, 
            text_sql_select7_data : null, 
            text_sql_select8 : null, 
            text_sql_select8_data : null, 
            text_sql_select9 : null, 
            text_sql_select9_data : null, 
            text_sql_select10 : null, 
            text_sql_select10_data : null, 
            text_sql_select11 : null, 
            text_sql_select11_data : null, 
            text_sql_select12 : null, 
            text_sql_select12_data : null, 
            text_sql_select13 : null, 
            text_sql_select13_data : null, 
            text_sql_select14 : null, 
            text_sql_select14_data : null, 
            text_sql_select15 : null, 
            text_sql_select15_data : null, 
            Result:"",
            sp_name:"",
            dw_dragdropsSele: ldatDet2,

            text_param1 : "", 
            text_param2 : "", 
            text_param3 : "", 
            text_param4 : "", 
            text_param5 : "", 
            text_param6 : "", 
            text_param7 : "", 
            child_report:"" ,
 
            checkboxGrp2_1_heading:null,
            checkboxGrp2_1 : null, 
            checkboxGrp2_1_data : null, 
            checkboxGrp2_2 : null, 
            checkboxGrp2_2_data : null, 
            checkboxGrp2_3 : null, 
            checkboxGrp2_3_data : null, 
            checkboxGrp2_4 : null, 
            checkboxGrp2_4_data : null, 
            checkboxGrp2_5 : null, 
            checkboxGrp2_5_data : null, 
            checkboxGrp2_6 : null, 
            checkboxGrp2_6_data : null, 
            checkboxGrp2_7 : null, 
            checkboxGrp2_7_data : null, 
            checkboxGrp2_8 : null, 
            checkboxGrp2_8_data : null, 
            checkboxGrp2_9 : null, 
            checkboxGrp2_9_data : null, 
            checkboxGrp2_10 : null, 
            checkboxGrp2_10_data : null, 
            checkboxGrp2_11 : null, 
            checkboxGrp2_11_data : null, 
            checkboxGrp2_12 : null, 
            checkboxGrp2_12_data : null, 
            checkboxGrp2_13 : null, 
            checkboxGrp2_13_data : null, 
            checkboxGrp2_14 : null, 
            checkboxGrp2_14_data : null, 
            checkboxGrp2_15 : null, 
            checkboxGrp2_15_data : null, 

            checkboxGrp3_1_heading:null,
            checkboxGrp3_1 : null, 
            checkboxGrp3_1_data : null, 
            checkboxGrp3_2 : null, 
            checkboxGrp3_2_data : null, 
            checkboxGrp3_3 : null, 
            checkboxGrp3_3_data : null, 
            checkboxGrp3_4 : null, 
            checkboxGrp3_4_data : null, 
            checkboxGrp3_5 : null, 
            checkboxGrp3_5_data : null, 
            checkboxGrp3_6 : null, 
            checkboxGrp3_6_data : null, 
            checkboxGrp3_7 : null, 
            checkboxGrp3_7_data : null, 
            checkboxGrp3_8 : null, 
            checkboxGrp3_8_data : null, 
            checkboxGrp3_9 : null, 
            checkboxGrp3_9_data : null, 
            checkboxGrp3_10 : null, 
            checkboxGrp3_10_data : null, 
            checkboxGrp3_11 : null, 
            checkboxGrp3_11_data : null, 
            checkboxGrp3_12 : null, 
            checkboxGrp3_12_data : null, 
            checkboxGrp3_13 : null, 
            checkboxGrp3_13_data : null, 
            checkboxGrp3_14 : null, 
            checkboxGrp3_14_data : null, 
            checkboxGrp3_15 : null, 
            checkboxGrp3_15_data : null, 
            lreportwrcnt:0,
            text_selectparam1:  "",
            text_selectparam2:  "",
            text_selectparam3:  "",
            text_selectparam4:  "",
            text_selectparam5:  "",
            text_selectparam6:  "",
            text_selectparam7:  "",
            text_selectparam8:  "",
            text_selectparam9:  "",
            text_selectparam10:  "",
            text_selectparam11:  "",
            text_selectparam12:  "",
            text_selectparam13:  "",
            text_selectparam14:  "",
            text_selectparam15:  "",
            report_desc:"",
            report_name:"",
            srno:null,
        }    
         return ldata
    } 
    ngOInitRecall(){
        this.route.params.subscribe(async value => { 
            this.ls_menuCode= value.id 
 
            //let data=  await this._user_service.getInLineResultAsyc(l_VMCommonQuery)

             this.S_TRANSINFO  =await this._user_service.setS_transinfo(this.ls_menuCode   ) 
         
                this.ls_menuName=this.S_TRANSINFO.menu_tree_name  
                if( this.S_TRANSINFO.vr_dt_edit=="N"){
                    this.lb_date_protect=true
                } 
                // ??
                this.s_trans = JSON.parse(localStorage.getItem('s_trans')); 
                if(!this.s_trans) {
                    this.s_trans=this.setInis_s_transinformation()
                }
                localStorage.removeItem('s_trans'); 
                //
                

                
                 
                  

        }); 
        this.wf_insert_log('IN'); 
    }

    ini_interface(){
        
    } 

    set_drill(){
        let arr:string[]=[]
        let data :s_drill ={
              
            fy_code	:	"",
            trc_code	:	"",
            vr_no	:	 0,
            ref_fy_code	:	"",
            ref_trc_code	:	"",
            ref_vr_no	:	 0,
            ref_sr_no	:	 0,
            ref_dsr_no	:	 0,
            text1	:	"",
            text2	:	"",
            text3	:	"",
            text4	:	"",
            text5	:	"",
            text6   :	"",
            text7	:	"",
            ref_type	:	"",
            ref_vr_nos:arr
        }
        return data
    }
    wf_parentngOnInit(){ 
    }

    async wf_getmonthcode():   Promise<string>{

        this.lVMSearch =this.setIni_lVMSearch() 
        this.lVMSearch.lstrSQL= "selectmonthcode" 
        this.lVMSearch.lstrColName1="Month Code"; 
        this.lVMSearch.lngColwidth1=10 
        this.lVMSearch.lstrParam1=this.S_TRANSINFO.ls_fy_code 
        this.lVMSearch.lstrParam2=this.S_TRANSINFO.trc_code 
        this.lVMSearch.lstrSortCol=1
        this.lVMSearch.lbSortAsc="N" 

        this.ls_VMCommonQuery =await this._user_service.getSearchResults(this.lVMSearch)
        if (this.ls_VMCommonQuery.lstrData1=="Cancel"){
            
            return;
        }else{
            return this.ls_VMCommonQuery.lstrData1
            
        }  
        // this.lVMSearch =this.setIni_lVMSearch() 
        // this.lVMSearch.lstrSQL= "selectmonthcode"
        // const dialogConfig = new MatDialogConfig();
        // this.lVMSearch.lstrColName1="Month Code"; 
        // this.lVMSearch.lngColwidth1=10 
        // this.lVMSearch.lstrParam1=this.S_TRANSINFO.ls_fy_code 
        // this.lVMSearch.lstrParam2=this.S_TRANSINFO.trc_code 
        // this.lVMSearch.lstrSortCol=1
        // this.lVMSearch.lbSortAsc="N"
        // dialogConfig.autoFocus = true;
        // dialogConfig.height='77%';
        // dialogConfig.width='77%';
        // dialogConfig.data=  this.lVMSearch 
        // dialogConfig.disableClose = true; 
        // const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
    
        // this.ls_VMCommonQuery =await dialogRef.afterClosed().toPromise()
        // return this.ls_VMCommonQuery.lstrData1;   
    }
    

    async getMonthCode(){
        console.log("getMonthCode")
           
        this.lVMSearch =this.setIni_lVMSearch() 
        this.lVMSearch.lstrSQL= "selectmonthcode"
        // const dialogConfig = new MatDialogConfig();
        this.lVMSearch.lstrColName1="Month Code"; 
        this.lVMSearch.lngColwidth1=10 
        this.lVMSearch.lstrParam1=this.S_TRANSINFO.ls_fy_code 
        this.lVMSearch.lstrParam2=this.S_TRANSINFO.trc_code 
        this.lVMSearch.lstrSortCol=1
        this.lVMSearch.lbSortAsc="N"
        // dialogConfig.autoFocus = true;
        // dialogConfig.height='77%';
        // dialogConfig.width='77%';
        // dialogConfig.data=  this.lVMSearch 
        // dialogConfig.disableClose = true; 

        this.ls_VMCommonQuery =await this._user_service.getSearchResults(this.lVMSearch)
        if (this.ls_VMCommonQuery.lstrData1=="Cancel"){
            this.goHome();
            return;
        }else{
            this.vi_month_code= +  this.ls_VMCommonQuery.lstrData1; 
            //this.f_open();
        } 
        console.log("getMonthCode E")
        // const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
       
        // dialogRef.afterClosed().subscribe(res => {
        //     if(res){ 
        //         this.ls_VMCommonQuery  = res; 
        //         if (this.ls_VMCommonQuery.lstrData1=="Cancel"){
        //             this.goHome();
        //             return;
        //         }
        //         this.vi_month_code= +  this.ls_VMCommonQuery.lstrData1; 
        //         this.f_open();
        //     }
        // },(error => this.handleError(error))); 
    }
    f_showmessage(ls_string:string){
        this._bfs.f_showmessage("info",ls_string) 

    }
    getApprovalcnt(){
  
        this.ls_VMCommonQuery =this.setInis_dynasearch();
        if( this.S_TRANSINFO.trc_code !="" && this.S_TRANSINFO.ls_fy_code !="" ){ 
            this.ls_VMCommonQuery.lstrSQL= "Approvalcnt"
            this.ls_VMCommonQuery.lstrParam1=this.S_TRANSINFO.ls_fy_code
            this.ls_VMCommonQuery.lstrParam2=this.S_TRANSINFO.trc_code
            //"  select (select dbo.fn_approval_count( '"+ this.S_TRANSINFO.ls_fy_code + "','" + this.S_TRANSINFO.trc_code +"' )) vi_approval_count from  dual ";
            this._user_service.getInLineResult( this.ls_VMCommonQuery).subscribe(async (getdata) => { 
                this.ls_VMCommonQuery =  getdata ; 
                this.S_TRANSINFO.approvalcnt =+ this.ls_VMCommonQuery.lstrData1 
                //this.vi_month_code=+this.getMonthCode();  
                 this.setAccess();  
            } );  
        }
    }
    getFyCode(){
          
        this.lVMSearch =this.setIni_lVMSearch()
 
        if( this.lVMUserData.default_co !="All" ){
            this.S_TRANSINFO.ls_fy_code =this.lVMUserData.default_co
            this.ls_company_name =  this.lVMUserData.company_name
            this.getApprovalcnt();  
            return 
        }
        
        this.lVMSearch.lstrSQL= "selectcomapnywithuser"
        const dialogConfig = new MatDialogConfig();
        this.lVMSearch.lstrColName1="Code";
        this.lVMSearch.lstrColName2="Company Name";
        this.lVMSearch.lngColwidth1=4
        this.lVMSearch.lngColwidth2=30 
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.height='77%';
        dialogConfig.width='77%';
        this.lVMSearch.lstrSortCol=1
        this.lVMSearch.lbSortAsc="Y"
        dialogConfig.data=  this.lVMSearch 
          
        const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
       
        dialogRef.afterClosed().subscribe(res => {
            if(res){ 
                this.ls_VMCommonQuery  = res; 
                if (this.ls_VMCommonQuery.lstrData1=="Cancel"){
                    this.goHome();
                    return;
                } 
                this.S_TRANSINFO.ls_fy_code =this.ls_VMCommonQuery.lstrData1
                this.ls_company_name =this.ls_VMCommonQuery.lstrData2
                this.getApprovalcnt();  
                
                
            }
        },(error => this.handleError(error))); 
    }

    ngOnDestroy(){
        this.wf_insert_log('OUT');
    }

    ue_to_do_list(){

    }

    ///////////////////////// User Events /////////////////////////////////////////////
    async ue_close(event){ 

        let resutdt 
        
        if ( this.ls_menuCode!="07010" && this.ls_menuCode!="07015" && this.ls_menuCode!="07017" && this.ls_menuCode!="09001"  && this.ls_menuCode!="09002"   && this.ls_menuCode!="09003") {
            if (this.language_code == "AR") {

                resutdt=await this._bfs.f_showmessage_yes_no("معلومات", "هل أنت متأكد من الإغلاق؟")
            }
            else {
                resutdt=await this._bfs.f_showmessage_yes_no("Info", "Are you sure to Close?")
            }
             
            if (resutdt == false) {
                return
            }
          
            else{
                this.router.navigate(['HomeApproval']).catch(err => console.log(err)); 
            }
        }

       if (this.ls_menuCode=="07010" || this.ls_menuCode=="07015" ||  this.ls_menuCode=="07017" || this.ls_menuCode=="09001"  || this.ls_menuCode=="09002"   || this.ls_menuCode=="09003"){
            if (this.ls_mode == "Edit") {
                if (this.language_code == "AR") {

                    resutdt = await this._bfs.f_showmessage_yes_no("معلومات", "هل أنت متأكد من الإغلاق؟")
                }
                else {
                    resutdt = await this._bfs.f_showmessage_yes_no("Info", "Are you sure to Close?")
                }

                if (resutdt == false) {
                    return
                }

                else {
                    this.router.navigate(['HomeApproval']).catch(err => console.log(err));
                }
            }

            else {

                if (this.language_code == "AR") {

                    resutdt = await this._bfs.f_showmessage_yes_no("معلومات", "لم يتم حفظ المعاملة. سيؤدي ترك هذه المعاملة إلى تجاهل جميع المعلومات التي تم إدخالها.")
                }
                else {
                    resutdt = await this._bfs.f_showmessage_yes_no("Info", "Transaction not saved.Leaving this transaction will discard all information entered.")
                }

                if (resutdt == false) {
                    return
                }
                else {
                    this.router.navigate(['HomeApproval']).catch(err => console.log(err));
                }

            }
        }
    }
     ue_new(event){   
        this.vmTrans=this.ini_vm_transactions()
            this.ls_mode="New";
            this.S_TRANSINFO.ls_approvalind ="N" //"X" ??
            this.S_TRANSINFO.lvr_no=0
            this.S_TRANSINFO.ln_doccanapplcnt =0 
            this.ln_sectrow=-1
            this.gl_attach_cnt=0
            this.lb_submitted=false
            this.setAccess() 
            localStorage.removeItem('s_doc')
    }
    ue_first(event){alert('ue_first');}
    ue_last(event){alert('ue_last');}
    ue_next(event){alert('ue_next');}
    ue_prior(event){alert('ue_prior');}
    ue_save(event){alert('ue_save');}

    ue_related(event){
        
        if(this.lb_form_enabled==false ){return}
        this.lb_form_enabled=false 
        this.lVMSearch =this.setIni_lVMSearch() 
        this.lVMSearch.lstrSQL=  "select_related_menu" 
        const dialogConfig = new MatDialogConfig();
        this.lVMSearch.lbSortAsc="N"
        dialogConfig.autoFocus = true;
        dialogConfig.height='77%';
        dialogConfig.width='77%';
        this.lVMSearch.lstrParam1 =this.ls_menuCode
        dialogConfig.data= this.lVMSearch 
        dialogConfig.disableClose = true; 
        this.lVMSearch.lstrColName1='Code'
        this.lVMSearch.lstrColName2='Name' 
       
        const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
        
        dialogRef.afterClosed().subscribe(res => {
            if(res){ 
                this.ls_VMCommonQuery  = res; 
                if(  this.ls_VMCommonQuery.lstrData1!="Cancel" ){ 
                    
                    
                    localStorage.removeItem('s_drill' );  
                
                    const url  = this.router.createUrlTree([this.ls_VMCommonQuery.lstrData3+ '/' +  this.ls_VMCommonQuery.lstrData1 ]).toString(); 
                    window.open('#' + url, '_blank');   
                
                } 
            }
        },(error => this.handleError(error)));  
        this.lb_form_enabled=true

    }
    ue_help(event){alert('ue_help');}
    ue_delete(event){alert('ue_delete');}
 
        ue_reporting(event){
        
 

            if(this.lb_form_enabled==false ){return}
            this.lb_form_enabled=false 
            this.lVMSearch =this.setIni_lVMSearch() 
            this.lVMSearch.lstrSQL=  "select_report_writer_se" 
            const dialogConfig = new MatDialogConfig();
            this.lVMSearch.lbSortAsc="N"
            dialogConfig.autoFocus = true;
            dialogConfig.height='77%';
            dialogConfig.width='77%';
            this.lVMSearch.lstrParam1 =this.ls_menuCode
            dialogConfig.data= this.lVMSearch 
            dialogConfig.disableClose = true; 
            this.lVMSearch.lstrColName1='Report ID'
            this.lVMSearch.lstrColName2='Code'
            this.lVMSearch.lstrColName3='Report Name'
            this.lVMSearch.lstrColName4='Type'
            this.lVMSearch.lstrColName5='Report Description'
           
            const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
            
            dialogRef.afterClosed().subscribe(res => {
                if(res){ 
                    this.ls_VMCommonQuery  = res; 
                    if(  this.ls_VMCommonQuery.lstrData1!="Cancel" ){ 
                        let s_drill=this.set_drill()
                        s_drill.text1= this.ls_VMCommonQuery.lstrData1
                        s_drill.ref_type='Approval Screen'
                        s_drill.fy_code="10"
                        s_drill.trc_code="DYN"
                        
                        localStorage.setItem('s_drill', JSON.stringify(s_drill));  
                    
                        const url  = this.router.createUrlTree(['/ReportWriterSetting/09069001' ]).toString(); 
                        window.open('#' + url, '_blank');   
                    
                    } 
                }
            },(error => this.handleError(error)));  
            this.lb_form_enabled=true


            
     
             
       
        } 
    
    ue_print(event){
        
        try{
            const dialogConfig = new MatDialogConfig(); 
            dialogConfig.autoFocus = true;
            // dialogConfig.height='35%';
            // dialogConfig.width='30%';        
            dialogConfig.disableClose = true;  
            this.lVMSearch =this.setIni_lVMSearch(); 
            this.S_TRANSINFO.menu_code=this.ls_menuCode
            this.S_TRANSINFO.printType="Print"
            dialogConfig.data=this.S_TRANSINFO;
            const dialogRef = this.dialog.open(reportFilterComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(async result => {
                if(result)
                {
                    console.log(result);
                }
            },
            (error => this.handleError(error)));         
        }catch(error){
            this.handleError(error)
        }
    }
    ue_listing(event){
        try{
            const dialogConfig = new MatDialogConfig(); 
            dialogConfig.autoFocus = true;
            // dialogConfig.height='35%';
            // dialogConfig.width='30%';        
            dialogConfig.disableClose = true;  
            this.lVMSearch =this.setIni_lVMSearch(); 
            this.S_TRANSINFO.printType="Listing"
            this.S_TRANSINFO.menu_code=this.ls_menuCode
            dialogConfig.data=this.S_TRANSINFO;
            const dialogRef = this.dialog.open(reportFilterComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(async result => {
                if(result)
                {
                    console.log(result);
                }
            },
            (error => this.handleError(error)));         
        }catch(error){
            this.handleError(error)
        }
    }
    ue_cheque(event){alert('ue_cheque');}
    ue_void(event){  
       
}

    async ue_approve(event){

        if(this.lb_form_enabled==false ){return}
        this.lb_form_enabled=false

        if( this.S_TRANSINFO.lvr_no ==0){
            this._bfs.f_showmessage("Info","Please select Voucher")
            this.lb_form_enabled=true
            return
        }
        this.lstru_approval=this.setini_ls_approval();
        this.lstru_approval.lfy_code= this.S_TRANSINFO.ls_fy_code
        this.lstru_approval.ltrc_code= this.S_TRANSINFO.trc_code
        this.lstru_approval.lvr_no= this.S_TRANSINFO.lvr_no

        this.ls_VMCommonQuery =this.setInis_dynasearch();
        this.ls_VMCommonQuery.lstrSQL="getapprovalpending"
        this.ls_VMCommonQuery.lstrParam1=this.S_TRANSINFO.ls_fy_code
        this.ls_VMCommonQuery.lstrParam2=this.S_TRANSINFO.trc_code
        this.ls_VMCommonQuery.lstrParam3=this.S_TRANSINFO.lvr_no.toString()
 
        this.ls_VMCommonQuery =await this._user_service.getInLineResultAsyc ( this.ls_VMCommonQuery) 
          
        this.lstru_approval.lsr_no=+ this.ls_VMCommonQuery.lstrData1 
        this.lstru_approval.role= this.ls_VMCommonQuery.lstrData9 
        this.lstru_approval.ln_forward_type =+this.ls_VMCommonQuery.lstrData11 
        this.lstru_approval.ls_is_forwarded=this.ls_VMCommonQuery.lstrData13
        this.lstru_approval.user_no= +this.ls_VMCommonQuery.lstrData7
        this.lstru_approval.fwd_resp= +this.ls_VMCommonQuery.lstrData14
        this.lstru_approval.priority_text= this.ls_VMCommonQuery.lstrData15
        this.lstru_approval.priority= this.ls_VMCommonQuery.lstrData8 
        if (this.lstru_approval.user_no ==99 || this.lstru_approval.user_no ==100|| this.lstru_approval.user_no==101 ||this.lstru_approval.user_no==105 ||this.lstru_approval.user_no==107 ||this.lstru_approval.user_no==108 ){ 
            this.lstru_approval.actiontext="Acknowledge"
        }
        else{
            this.lstru_approval.actiontext="Approve"
        }
        await this.wf_Approve(this.lstru_approval)
        this.lb_form_enabled=true
    } 

    // ue_status(event){
    //     alert("Status")
    //     this.ls_gdata.gs_transinfo=this.S_TRANSINFO
    //     console.log(this.ls_gdata.gs_transinfo)
    //     this.router.navigate([ '/approvalStatus']).catch(err => console.log(err));
    //     //this.router.navigate([ this.ls_VMCommonQuery.lstrData2 +'/'+this.ls_VMCommonQuery.lstrData1]).catch(err => console.log(err));
    // }
    ue_status(event){ 
          
        debugger
        localStorage.setItem('gs_transinfo', JSON.stringify( this.S_TRANSINFO))
        //this.router.navigate([ '/approvalStatus']).catch(err => console.log(err));
        const url  = this.router.createUrlTree(['approvalStatus', "view"]).toString(); 
        window.open('#' + url, '_blank'); 
    }
    ue_attach(event){
        if(this.S_TRANSINFO.attachment_rights){
            try{
                if(this.S_TRANSINFO.lvr_no){
                    const dialogConfig = new MatDialogConfig(); 
                    dialogConfig.autoFocus = true;
                    dialogConfig.height='77%';
                    dialogConfig.width='95%';          
                    dialogConfig.maxWidth='100%';        
                    dialogConfig.disableClose = true;  
                
                    this.lVMSearch =this.setIni_lVMSearch(); 
                    // this.S_TRANSINFO.printType="Listing"
                    this.S_TRANSINFO.menu_code=this.ls_menuCode
                    dialogConfig.data=this.S_TRANSINFO;
                    const dialogRef = this.dialog.open(Attachmentcomponent, dialogConfig);
                    dialogRef.afterClosed().subscribe(async result => {
                        if(result)
                        {
                            console.log(result);
                        }
                    },
                    (error => this.handleError(error))); 
                }
                else{
                    this._bfs.f_showmessage("Info","Please save the document and try")
                }      
            }catch(error){
                this.handleError(error)
            }
        }
    }
    ue_task(event){
        try{
        
 
                 const dialogConfig = new MatDialogConfig(); 
                dialogConfig.autoFocus = true;
                dialogConfig.height='77%';
                dialogConfig.width='95%';        
                dialogConfig.maxWidth='100%';
                dialogConfig.disableClose = true;  
                debugger
                this.lVMSearch =this.setIni_lVMSearch(); 
                this.S_TRANSINFO.menu_code=this.ls_menuCode
                dialogConfig.data=this.S_TRANSINFO;
                const dialogRef = this.dialog.open(UserTasksComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(async result => {
                    if(result)
                    {
                        console.log(result);
                    }
                },
                (error => this.handleError(error)));         
            
        }catch(error){
            this.handleError(error)
        }
    
    }
    ue_browse(event){alert('ue_browse');}
    ue_custom_fields(event){
       
    }
    ue_import(event){alert('ue_import');}
    ue_undo(event){alert('ue_undo');}
    ue_send(event){alert('ue_send');} 
    ue_refresh(event){alert('ue_refresh');}
    ue_exit(event){}
    
    //Other events

    
    async ue_retrieve(){  
        this.ls_mode="Edit" 
        await this.getApprovalcntAll()
        this.lb_submitted=false

        localStorage.removeItem('s_doc');  
        this.ls_doc= this.set_drill()
        this.ls_doc.fy_code=this.S_TRANSINFO.ls_fy_code
        this.ls_doc.trc_code=this.S_TRANSINFO.trc_code
        this.ls_doc.vr_no=this.S_TRANSINFO.lvr_no

        localStorage.setItem('s_doc', JSON.stringify(this.ls_doc));
     }
     async ue_filldown(){ 
        this.ls_mode="New"  
        
     } 
    // Base Functions
    
    getdatetostring(vr_date:Date):string{ 
        let ls_data:string  =   this.datePipe.transform( vr_date, "MM/dd/yyyy");
         return  ls_data ;//vr_date.getMonth() + 1 +"/" +vr_date.getDate() +"/" +vr_date.getFullYear()
     }

     getdatetostringf(vr_date:Date,lsformat:string):string{ 
        let ls_data:string  =   this.datePipe.transform( vr_date, lsformat);
         return  ls_data ;//vr_date.getMonth() + 1 +"/" +vr_date.getDate() +"/" +vr_date.getFullYear()
     }


    async wf_sendApproval(ps_approval:s_approval){         
        if(this.S_TRANSINFO.posting_method=="POST"){

            
            this.vmTrans.strProcess="sp_post_voucher"
            
            this.lb_form_enabled=false
            this.vmTrans.stringjson=  JSON.stringify(ps_approval) ;  
            this.vmTrans =await this._user_service.postData(this.vmTrans) 
            if(this.vmTrans.Result=="Success"){
                this.lb_form_enabled=true 
                this._bfs.f_showmessage("Info","Posted")
               
                this.ue_retrieve()
            }
            else{ 
                this._bfs.f_showmessage("Info",this.vmTrans.Result)  
                this.lb_form_enabled=true 
            }  
        }
        else{
            const dialogConfig = new MatDialogConfig(); 
            dialogConfig.autoFocus = true;
            // dialogConfig.height='40%';
            // dialogConfig.width='80%';
            dialogConfig.minWidth = '300px'
            dialogConfig.data=  ps_approval
            dialogConfig.disableClose = true; 
            const dialogRef = this.dialog.open(ApprovalSendComponent, dialogConfig);
        
            dialogRef.afterClosed().subscribe(res => {
                if(res){ 
                    ps_approval  = res; 
                    if (ps_approval.result=="Cancel"){
                        this.f_showmessage("Cancelled")
                    }
                    if (ps_approval.result=="Success"){
                        //this.f_showmessage("Send for Approval")
                        

                        if(this.S_TRANSINFO.trc_code=='REN' || this.S_TRANSINFO.trc_code=='RER')
                        {
                            if(this.language_code=="AR"){ 
           
                                this._bfs.f_showmessage ("معلومات","تم إرسال معاملتك. سوف تتلقى تأكيداً على عنوان البريد الإلكتروني المسجل خلال 48 ساعة عمل. في حال عدم تلقي أي بريد إلكتروني، يرجى الاتصال بنا على5285868 - 06. و شكراً ")
                             }
                           else{
                                this._bfs.f_showmessage("Info","Your request has been sent for processing. You will receive a confirmation on the registered email address within 48 working hours. In case if you have not received any email, please contact us at 06-5285868 or companyregistration@sharjahport.ae. ")
                           } 
                        }
                        else{
                            if(this.language_code=="AR"){ 
           
                                this._bfs.f_showmessage ("معلومات","تم إرسال معاملتك. سوف تتلقى تأكيداً على عنوان البريد الإلكتروني المسجل خلال 48 ساعة عمل. في حال عدم تلقي أي بريد إلكتروني، يرجى الاتصال بنا على5285868 - 06. و شكراً ")
                             }
                           else{
                                this._bfs.f_showmessage("Info","Your request has been sent for processing. You will receive a confirmation on the registered email address within 48 working hours. In case if you have not received any email, please contact us at 06-5285868 or noc@sharjahport.ae. ")
                           } 
                        }
                       

                        this.S_TRANSINFO.lvr_no= ps_approval.lvr_no 
                        this.ue_new("")
                    }  
                }
            },(error => this.handleError(error)));
        } 
         
    }

    ini_vm_parm(){
           
        let ls_dynstructure:  vm_parm= { 
            Result :"",
            stringjson :"",
            strProcess :"",
            authcode:"",
            object:null
        }
        return ls_dynstructure
    }
    async openSearchData(selectedColumn,selectedTitle,jsonData):Promise<any>{
        try{
 
            let lsvm_parm:vm_parm
            lsvm_parm = this.ini_vm_parm()
            const dialogConfig = new MatDialogConfig(); 
            lsvm_parm.strProcess= selectedColumn;
            lsvm_parm.Result=  selectedTitle; 
            lsvm_parm.object =jsonData  
            dialogConfig.autoFocus = true;
            dialogConfig.height='30%';
            dialogConfig.width='30%';   
            dialogConfig.data=lsvm_parm;
            dialogConfig.disableClose = true;
            debugger
            const dialogRef = this.dialog.open(dynSearchComponent, dialogConfig);  
            dialogRef.afterClosed().toPromise()
                        
                
        
                // const dialogConfig = new MatDialogConfig(); 
                // this.vmTrans.strProcess=selectedColumn;
                // this.vmTrans.Result= selectedTitle;
                // this.vmTrans.stringjson=  jsonData;
                // dialogConfig.autoFocus = true;
                // dialogConfig.height='30%';
                // dialogConfig.width='30%';   
                // dialogConfig.data=this.vmTrans;
                // dialogConfig.disableClose = true;
                // const dialogRef = this.dialog.open(dynSearchComponent, dialogConfig)
                // let res =await dialogRef.afterClosed().toPromise()
                
                // return (res) 
 
        }catch(error){
            this.handleError(error)
        }

    }

    wf_Approve(ps_approval:s_approval){          
        const dialogConfig = new MatDialogConfig(); 
        dialogConfig.autoFocus = true;
        // dialogConfig.height='55%';
        dialogConfig.minWidth= '300px';
        // dialogConfig.width='55%';
        dialogConfig.data=  ps_approval
        dialogConfig.disableClose = true;  
        
        const dialogRef = this.dialog.open(ApprovalDocComponent, dialogConfig);
       
        dialogRef.afterClosed().subscribe(async res => {
            if(res){ 
                ps_approval  = res; 
                if (ps_approval.result=="Cancel"){
                    alert("cancelled");
                     
                }
                debugger
                if (ps_approval.result=="Success"){ 
                    alert("Updated")
                    this.S_TRANSINFO.lvr_no= ps_approval.lvr_no  

                         
                    this.ue_retrieve( ) 
                    this.sendmail(ps_approval)
                }  
            }
        },(error => this.handleError(error))); 
    }


    async sendmail(ps_approval){

        //shijilesh 
        // this.vmTrans.strProcess="sp_email"
                 
        // this.vmTrans.stringjson=  JSON.stringify(ps_approval) ; 
        // this.vmTrans =await this._user_service.postDataEmail(this.vmTrans) 
        // if(this.vmTrans.Result=="Success"){
        //     this.lb_form_enabled=true 
             
            
        // }
        // else{  
        //     this.lb_form_enabled=true 
        // }  

    }
    wf_insert_log ( status:string){

        // if status='IN' THEN 
        //     SELECT GETDATE() into :Idt_open_time  FROM FYMAS where fy_code=:vg_fycode;
        // ELSE	

        // insert into user_activity_log
        //     (status,	menu_code,menu_name,open_time)
        // VALUES (:STATUS ,:S_TRANSINFO.window_tag ,:S_TRANSINFO.window_title,:Idt_open_time) ;
        // COMMIT;
        // END IF

    }
    
    wf_set_attachement_status ( vi_fycode:string,  vi_trccode:string,  vi_vrno:string){
        //string Ls_attachment_blob

        // long IL_attacment_count
        //     select blob_attachment into :Ls_attachment_blob from GENERAL_SETUP;
        //     if isnull(Ls_attachment_blob) then Ls_attachment_blob='N'
        //     if Ls_attachment_blob='Y' then
        //         select count(*)  into :IL_attacment_count 	from attachment_detail where fy_code=:vi_fycode and trc_code=:vi_trccode and vr_no=:vi_vrno using t_attachment;
        //     else
        //         select count(*)  into :IL_attacment_count 	from attachment_detail where fy_code=:vi_fycode and trc_code=:vi_trccode and vr_no=:vi_vrno ;
        //     end if 
            
        // if IL_attacment_count>0 then
        //     luo_buttons.p_attach.FocusRectangle=true
        // else
        //     luo_buttons.p_attach.FocusRectangle=false
        // end if	
        // IL_attacment_count=0
        // long ll_pending_notes
        // select count(*),sum(case when is_doc_rcvd='N' then 1 else 0 end)   into 	:IL_attacment_count,:ll_pending_notes from  custom_defined_detail where fy_code=:vi_fycode and trc_code=:vi_trccode and vr_no=:vi_vrno ;
 

        // if IL_attacment_count=0 then
        //     luo_buttons.p_customfield.tag='0'
        // else
        //     if ll_pending_notes=0 then 
        //         luo_buttons.p_customfield.tag='1'
        //     else
        //         luo_buttons.p_customfield.tag='2'
        //     end if			
        // end if	

    }


   

 
    setAccess(){         
 
        debugger
        this.li_Buttons_window.lb_attachedCount=this.gl_attach_cnt 
        this.S_TRANSINFO.ls_appstatus="NONE";  
        this.li_Buttons_window.lb_save=true
        this.li_Buttons_window.lb_delete=true 
        this.li_Buttons_window.lb_browse=true  
        this.li_Buttons_window.lb_new=true
        this.li_Buttons_window.lb_approve=false
        this.li_Buttons_window.lb_send=false
 
        if (this.S_TRANSINFO.attachment_applicable=="Y"){
            if(this.S_TRANSINFO.attachment_rights){
                this.li_Buttons_window.lb_attach=true;
            }  
            else{
                this.li_Buttons_window.lb_attach=false;
            } 
        }else{
            this.li_Buttons_window.lb_attach=false;
        }
        if (this.S_TRANSINFO.listobjectsrno>0){
            this.li_Buttons_window.lb_listing=true;
        }else{
            if(this.lb_dynmaster==true){
                this.li_Buttons_window.lb_listing=true;
            }
            else{
                this.li_Buttons_window.lb_listing=false;
            }
        }
        if (this.S_TRANSINFO.reportobjectsrno>0){
            this.li_Buttons_window.lb_print=true;
        }else{
            if(this.S_TRANSINFO.trc_code !='DYN'){
                this.li_Buttons_window.lb_print=false;
            }
        }

        if (this.S_TRANSINFO.rights.indexOf('C') == -1){
            if(this.ls_mode=='New'){ 
                this.li_Buttons_window.lb_new=false
                this.li_Buttons_window.lb_save=false
            }
        }
        if (this.S_TRANSINFO.rights.indexOf('M') == -1){
            if(this.ls_mode=='Edit'){  
                this.li_Buttons_window.lb_save=false
            }
        }
        if (this.S_TRANSINFO.rights.indexOf('D') == -1){
            this.li_Buttons_window.lb_delete=false
        }
        if ((this.S_TRANSINFO.rights.indexOf('E') == -1) && (this.S_TRANSINFO.rights.indexOf('M') == -1)
            && (this.S_TRANSINFO.rights.indexOf('D') == -1)  ){
                this.li_Buttons_window.lb_browse=false
        }
        if (this.S_TRANSINFO.rights.indexOf('L') == -1){
            this.li_Buttons_window.lb_listing=false
        }  
   
        if(this.ls_mode=='Edit' && (this.S_TRANSINFO.ls_approvalind =="N" || this.S_TRANSINFO.ls_approvalind=="") ){ 
           //this.li_Buttons_window.lb_send=true
            if(this.S_TRANSINFO.approvalcnt > 0){ 
                this.li_Buttons_window.lb_send=true 
            } 
        }
        if(this.is_doc_approval_cnt >0){
            this.li_Buttons_window.lb_status=true
            if(this.S_TRANSINFO.ls_approvalind =="R" || this.S_TRANSINFO.ls_approvalind =="" || this.S_TRANSINFO.ls_approvalind =="N"  ){ //Ticket (05001 /1) || this.S_TRANSINFO.ls_approvalind =="R"  ){
               
            }
            else{
                this.li_Buttons_window.lb_delete=false
            }
            // if(this.S_TRANSINFO.ls_approvalind =="Y" || this.S_TRANSINFO.ls_approvalind =="S" ){//|| this.S_TRANSINFO.ls_approvalind =="R"  ){
            //     this.li_Buttons_window.lb_delete=false
            // }
        } 
        
        if (this.S_TRANSINFO.ls_approvalind != "N"){
            if(this.S_TRANSINFO.ln_doccanapplcnt > 0){ 
                this.li_Buttons_window.lb_approve=true
            }else{
                if(this.is_doc_approval_cnt >0){
                    if(this.S_TRANSINFO.trc_code =="JBM"){
                         
                    }else{
                        this.li_Buttons_window.lb_approve=false
                        if (this.S_TRANSINFO.ls_approvalind != "R"){
                            this.li_Buttons_window.lb_save=false                
                        } 
                    }
                }
            }
        } 

        if (this.S_TRANSINFO.ls_approvalind == "R" && this.is_doc_approval_cnt >0){
            this.li_Buttons_window.lb_send=true
        }
        if (this.S_TRANSINFO.ls_approvalind =='Y' && this.is_doc_approval_cnt >0){
            if(this.S_TRANSINFO.trc_code =="JBM"){
            }
            else{    this.li_Buttons_window.lb_save=false}
             
        } 
        if(this.S_TRANSINFO.cust_feild_cnt>0){
            this.li_Buttons_window.lb_custom_fields=true
        }else{
            this.li_Buttons_window.lb_custom_fields=false
        }
        

        if (this.S_TRANSINFO.lreportwrcnt>0){
            this.li_Buttons_window.lb_reportwriter=true;
        }else{
            this.li_Buttons_window.lb_reportwriter=false;
        } 


        if( this.S_TRANSINFO.posting_method=='POST'){
            this.li_Buttons_window.lb_status=false
        }
            
        if( this.S_TRANSINFO.lrelated>0){
            this.li_Buttons_window.lb_related=true
        }else{
            this.li_Buttons_window.lb_related=false
        }

        this.set_button_local()   
        
    }
     
    set_button_local(){}
    

    f_open(){ 
    } 

    async getApprovalcntAll(){ 
        debugger
        if( this.S_TRANSINFO.trc_code !="" && this.S_TRANSINFO.ls_fy_code !="" && this.S_TRANSINFO.lvr_no !=0  ){
            this.ls_VMCommonQuery =this.setInis_dynasearch();
            this.ls_VMCommonQuery.lstrSQL="ApprovalcntAll"
            this.ls_VMCommonQuery.lstrParam1=this.S_TRANSINFO.ls_fy_code
            this.ls_VMCommonQuery.lstrParam2=this.S_TRANSINFO.trc_code
            this.ls_VMCommonQuery.lstrParam3=this.S_TRANSINFO.lvr_no.toString()

            //" select (select dbo.fn_approval_count_doc( '"+ this.S_TRANSINFO.ls_fy_code + "','" + this.S_TRANSINFO.trc_code +"',"  + this.S_TRANSINFO.lvr_no +" )) vi_approval_count1 ,  (select dbo.fn_can_approval_doc( '"+ this.S_TRANSINFO.ls_fy_code + "','" + this.S_TRANSINFO.trc_code +"',"  + this.S_TRANSINFO.lvr_no +",'"  + this.vg_userid +"' )) vi_approval_count2 from  dual              ";
            this.ls_VMCommonQuery = await this._user_service.getInLineResultAsyc( this.ls_VMCommonQuery)   
            this.is_doc_approval_cnt=+ this.ls_VMCommonQuery.lstrData1 
            this.S_TRANSINFO.ln_doccanapplcnt =+ this.ls_VMCommonQuery.lstrData2  
            this.gl_attach_cnt=+ this.ls_VMCommonQuery.lstrData4;
            
             
        } 
          this.setAccess();  
    }

    goHome(){ 
        this.router.navigate(['HomeApproval']).catch(err => console.log(err));

    }
     

    AmtDecInput (element) {
        const dotPos = element.target.value.indexOf('.');

        if (dotPos === -1) {
            element.target.value = parseFloat(element.target.value).toFixed(this.S_TRANSINFO.amt_dec);
        } 
           
        if (dotPos !== -1 && (element.target.value.split('.')[1].length < this.S_TRANSINFO.amt_dec || element.target.value.split('.')[1].length > this.S_TRANSINFO.amt_dec)) {
            element.target.value = parseFloat(element.target.value).toFixed(this.S_TRANSINFO.amt_dec);
        }
    }

    QtyDecInput (element) {
        const dotPos = element.target.value.indexOf('.');

        if (dotPos === -1) {
            element.target.value = parseFloat(element.target.value).toFixed(this.S_TRANSINFO.qty_dec);
        } 
           
        if (dotPos !== -1 && (element.target.value.split('.')[1].length < this.S_TRANSINFO.qty_dec || element.target.value.split('.')[1].length > this.S_TRANSINFO.qty_dec)) {
            element.target.value = parseFloat(element.target.value).toFixed(this.S_TRANSINFO.qty_dec);
        }
    }

    setPhone(event){
        let  val;
           if(this.isPhoneAlpha == false){
                val = event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key)) 
                // console.log(1,val );
                return val;
            }
            else{
                // console.log(2, val);
            return val; 
      }
    }
       
    
    setInilbutton(){              
        let lbuttons: iButtons = {  lb_new:true,lb_first:false,lb_last:false,lb_next:false,lb_prior:false,lb_save:true,lb_delete:true,lb_print:true,lb_listing:false,lb_cheque:false,lb_void:false,lb_approve:false,lb_status:false,lb_attach:true,lb_browse:true,lb_custom_fields:false,lb_import:false,lb_undo:false,lb_send:false, lb_refresh:false, lb_close:true,lb_tasks:false,lb_attachedCount:0,lb_reportwriter:false
            ,lb_related:false,lb_help:false} 
        return lbuttons;
    }
    checkDuplicateInObject(propertyName, inputArray) {
        var seenDuplicate = false,
            testObject = {};
      

        inputArray.map(function(item) {
            // if(item.propertyName instanceof Date && !isNaN(item.propertyName.valueOf())){
            //     item.propertyName=new Date(item.propertyName);
            //     var itemPropertyName = item[propertyName];  
            // }else{
            //     var itemPropertyName = item[propertyName];
            // }
          var itemPropertyName = item[propertyName];    
          if (itemPropertyName in testObject) {
            testObject[itemPropertyName].duplicate = true;
            this.ls_duplicate_value=testObject[itemPropertyName].valueOf().toString()
            item.duplicate = true;
            seenDuplicate = true;
          }
          else {
            testObject[itemPropertyName] = item;
            delete item.duplicate;
          }
        });
      
        return seenDuplicate;
      }

    setIni_lVMSearch(){
        let ls_tmp:  VMSearch= {
            TypeCode	:	''	,
            lstrData1	:	''	,
            lstrData2	:	''	,
            lstrData3	:	''	,
            lstrData4	:	''	,
            lstrData5	:	''	,
            lstrData6	:	''	,
            lstrData7	:	''	,
            lstrData8	:	''	,
            lstrData9	:	''	,
            lstrData10	:	''	,
            lstrData11	:	''	,
            lstrData12	:	''	,
            lstrData13	:	''	,
            lstrData14	:	''	,
            lstrData15	:	''	,
            lstrData16	:	''	,
            lstrData17	:	''	,
            lstrData18	:	''	,
            lstrData19	:	''	,
            lstrData20	:	''	,
            lstrData21	:	''	,
            lstrData22	:	''	,
            lstrData23	:	''	,
            lstrData24	:	''	,
            lstrData25	:	''	,
            lstrData26	:	''	,
            lstrData27	:	''	,
            lstrData28	:	''	,
            lstrData29	:	''	,
            lstrData30	:	''	,
            lstrData31	:	''	,
            lstrData32	:	''	,
            lstrData33	:	''	,
            lstrData34	:	''	,
            lstrData35	:	''	,
            lstrData36	:	''	,
            lstrData37	:	''	,
            lstrData38	:	''	,
            lstrData39	:	''	,
            lstrData40	:	''	,
            lstrData41	:	''	,
            lstrData42	:	''	,
            lstrData43	:	''	,
            lstrData44	:	''	,
            lstrData45	:	''	,
            lstrData46	:	''	,
            lstrData47	:	''	,
            lstrData48	:	''	,
            lstrData49	:	''	,
            lstrData50	:	''	,


            lngColwidth1	:	0	,
            lngColwidth2	:	0	,
            lngColwidth3	:	0	,
            lngColwidth4	:	0	,
            lngColwidth5	:	0	,
            lngColwidth6	:	0	,
            lngColwidth7	:	0	,
            lngColwidth8	:	0	,
            lngColwidth9	:	0	,
            lngColwidth10	:	0	,
            lngColwidth11	:	0	,
            lngColwidth12	:	0	,
            lngColwidth13	:	0	,
            lngColwidth14	:	0	,
            lngColwidth15	:	0	,
            lngColwidth16	:	0	,
            lngColwidth17	:	0	,
            lngColwidth18	:	0	,
            lngColwidth19	:	0	,
            lngColwidth20	:	0	,
            lngColwidth21	:	0	,
            lngColwidth22	:	0	,
            lngColwidth23	:	0	,
            lngColwidth24	:	0	,
            lngColwidth25	:	0	,
            lngColwidth26	:	0	,
            lngColwidth27	:	0	,
            lngColwidth28	:	0	,
            lngColwidth29	:	0	,
            lngColwidth30	:	0	,
            lngColwidth31	:	0	,
            lngColwidth32	:	0	,
            lngColwidth33	:	0	,
            lngColwidth34	:	0	,
            lngColwidth35	:	0	,
            lngColwidth36	:	0	,
            lngColwidth37	:	0	,
            lngColwidth38	:	0	,
            lngColwidth39	:	0	,
            lngColwidth40	:	0	,
            lngColwidth41	:	0	,
            lngColwidth42	:	0	,
            lngColwidth43	:	0	,
            lngColwidth44	:	0	,
            lngColwidth45	:	0	,
            lngColwidth46	:	0	,
            lngColwidth47	:	0	,
            lngColwidth48	:	0	,
            lngColwidth49	:	0	,
            lngColwidth50	:	0	,

            lstrColName1	:	''	,
            lstrColName2	:	''	,
            lstrColName3	:	''	,
            lstrColName4	:	''	,
            lstrColName5	:	''	,
            lstrColName6	:	''	,
            lstrColName7	:	''	,
            lstrColName8	:	''	,
            lstrColName9	:	''	,
            lstrColName10	:	''	,
            lstrColName11	:	''	,
            lstrColName12	:	''	,
            lstrColName13	:	''	,
            lstrColName14	:	''	,
            lstrColName15	:	''	,
            lstrColName16	:	''	,
            lstrColName17	:	''	,
            lstrColName18	:	''	,
            lstrColName19	:	''	,
            lstrColName20	:	''	,
            lstrColName21	:	''	,
            lstrColName22	:	''	,
            lstrColName23	:	''	,
            lstrColName24	:	''	,
            lstrColName25	:	''	,
            lstrColName26	:	''	,
            lstrColName27	:	''	,
            lstrColName28	:	''	,
            lstrColName29	:	''	,
            lstrColName30	:	''	,
            lstrColName31	:	''	,
            lstrColName32	:	''	,
            lstrColName33	:	''	,
            lstrColName34	:	''	,
            lstrColName35	:	''	,
            lstrColName36	:	''	,
            lstrColName37	:	''	,
            lstrColName38	:	''	,
            lstrColName39	:	''	,
            lstrColName40	:	''	,
            lstrColName41	:	''	,
            lstrColName42	:	''	,
            lstrColName43	:	''	,
            lstrColName44	:	''	,
            lstrColName45	:	''	,
            lstrColName46	:	''	,
            lstrColName47	:	''	,
            lstrColName48	:	''	,
            lstrColName49	:	''	,
            lstrColName50	:	''	,

            lstrSQL	:	''	,
            lstrError	:	''	,

            lstrUser	:	''	,

            lstrParam1	:	''	,
            lstrParam2	:	''	,
            lstrParam3	:	''	,
            lstrParam4	:	''	,
            lstrParam5	:	''	,
            lstrParam6	:	''	,
            lstrParam7	:	''	,
            lstrParam8	:	''	,
            lstrParam9	:	''	,
            lstrParam10	:	''	,
            lstrParam11	:	''	,
            lstrParam12	:	''	,
            lstrParam13	:	''	,
            lstrParam14	:	''	,
            lstrParam15	:	''	,
            lstrParam16	:	''	,
            lstrParam17	:	''	,
            lstrParam18	:	''	,
            lstrParam19	:	''	,
            lstrParam20	:	''	,
            lstrParam21	:	''	,
            lstrParam22	:	''	,
            lstrParam23	:	''	,
            lstrParam24	:	''	,
            lstrParam25	:	''	,
            lstrParam26	:	''	,
            lstrParam27	:	''	,
            lstrParam28	:	''	,
            lstrParam29	:	''	,
            lstrParam30	:	''	,
            lstrParam31	:	''	,
            lstrParam32	:	''	,
            lstrParam33	:	''	,
            lstrParam34	:	''	,
            lstrParam35	:	''	,
            lstrParam36	:	''	,
            lstrParam37	:	''	,
            lstrParam38	:	''	,
            lstrParam39	:	''	,
            lstrParam40	:	''	,
            lstrParam41	:	''	,
            lstrParam42	:	''	,
            lstrParam43	:	''	,
            lstrParam44	:	''	,
            lstrParam45	:	''	,
            lstrParam46	:	''	,
            lstrParam47	:	''	,
            lstrParam48	:	''	,
            lstrParam49	:	''	,
            lstrParam50	:	''	,
             
            lstrParam51	:	'', 
            lstrParam52	:	'', 
            lstrParam53	:	'', 
            lstrParam54	:	'', 
            lstrParam55	:	'', 
            lstrParam56	:	'', 
            lstrParam57	:	'', 
            lstrParam58	:	'', 
            lstrParam59	:	'', 
            lstrParam60	:	'', 
            lstrParam61	:	'', 
            lstrParam62	:	'', 
            lstrParam63	:	'', 
            lstrParam64	:	'', 
            lstrParam65	:	'', 
            lstrParam66	:	'', 
            lstrParam67	:	'', 
            lstrParam68	:	'', 
            lstrParam69	:	'', 
            lstrParam70	:	'', 
            lstrParam71	:	'', 
            lstrParam72	:	'', 
            lstrParam73	:	'', 
            lstrParam74	:	'', 
            lstrParam75	:	'', 
            lstrParam76	:	'', 
            lstrParam77	:	'', 
            lstrParam78	:	'', 
            lstrParam79	:	'', 
            lstrParam80	:	'', 
            lstrParam81	:	'', 
            lstrParam82	:	'', 
            lstrParam83	:	'', 
            lstrParam84	:	'', 
            lstrParam85	:	'', 
            lstrParam86	:	'', 
            lstrParam87	:	'', 
            lstrParam88	:	'', 
            lstrParam89	:	'', 
            lstrParam90	:	'', 
            lstrParam91	:	'', 
            lstrParam92	:	'', 
            lstrParam93	:	'', 
            lstrParam94	:	'', 
            lstrParam95	:	'', 
            lstrParam96	:	'', 
            lstrParam97	:	'', 
            lstrParam98	:	'', 
            lstrParam99	:	'', 
            lstrParam100	:	'', 
            lstrParam101	:	'', 
            lstrParam102	:	'', 
            lstrParam103	:	'', 
            lstrParam104	:	'', 
            lstrParam105	:	'', 
            lstrParam106	:	'', 
            lstrParam107	:	'', 
            lstrParam108	:	'', 
            lstrParam109	:	'', 
            lstrParam110	:	'', 
            lstrParam111	:	'', 
            lstrParam112	:	'', 
            lstrParam113	:	'', 
            lstrParam114	:	'', 
            lstrParam115	:	'', 
            lstrParam116	:	'', 
            lstrParam117	:	'', 
            lstrParam118	:	'', 
            lstrParam119	:	'', 
            lstrParam120	:	'', 
            lstrParam121	:	'', 
            lstrParam122	:	'', 
            lstrParam123	:	'', 
            lstrParam124	:	'', 
            lstrParam125	:	'', 
            lstrParam126	:	'', 
            lstrParam127	:	'', 
            lstrParam128	:	'', 
            lstrParam129	:	'', 
            lstrParam130	:	'', 
            lstrParam131	:	'', 
            lstrParam132	:	'', 
            lstrParam133	:	'', 
            lstrParam134	:	'', 
            lstrParam135	:	'', 
            lstrParam136	:	'', 
            lstrParam137	:	'', 
            lstrParam138	:	'', 
            lstrParam139	:	'', 
            lstrParam140	:	'', 
            lstrParam141	:	'', 
            lstrParam142	:	'', 
            lstrParam143	:	'', 
            lstrParam144	:	'', 
            lstrParam145	:	'', 
            lstrParam146	:	'', 
            lstrParam147	:	'', 
            lstrParam148	:	'', 
            lstrParam149	:	'', 
            lstrParam150	:	'', 
            lstrParam151	:	'', 
            lstrParam152	:	'', 
            lstrParam153	:	'', 
            lstrParam154	:	'', 
            lstrParam155	:	'', 
            lstrParam156	:	'', 
            lstrParam157	:	'', 
            lstrParam158	:	'', 
            lstrParam159	:	'', 
            lstrParam160	:	'', 
            lstrParam161	:	'', 
            lstrParam162	:	'', 
            lstrParam163	:	'', 
            lstrParam164	:	'', 
            lstrParam165	:	'', 
            lstrParam166	:	'', 
            lstrParam167	:	'', 
            lstrParam168	:	'', 
            lstrParam169	:	'', 
            lstrParam170	:	'', 
            lstrParam171	:	'', 
            lstrParam172	:	'', 
            lstrParam173	:	'', 
            lstrParam174	:	'', 
            lstrParam175	:	'', 
            lstrParam176	:	'', 
            lstrParam177	:	'', 
            lstrParam178	:	'', 
            lstrParam179	:	'', 
            lstrParam180	:	'', 
            lstrParam181	:	'', 
            lstrParam182	:	'', 
            lstrParam183	:	'', 
            lstrParam184	:	'', 
            lstrParam185	:	'', 
            lstrParam186	:	'', 
            lstrParam187	:	'', 
            lstrParam188	:	'', 
            lstrParam189	:	'', 
            lstrParam190	:	'', 
            lstrParam191	:	'', 
            lstrParam192	:	'', 
            lstrParam193	:	'', 
            lstrParam194	:	'', 
            lstrParam195	:	'', 
            lstrParam196	:	'', 
            lstrParam197	:	'', 
            lstrParam198	:	'', 
            lstrParam199	:	'', 
            lstrParam200	:	'', 
                lstrSearch :'',
            lstrSortCol :1,
            lbSortAsc:"N", 
            lstrShowlist:"N"
        }
        return ls_tmp;
    }

    setini_ls_approval(){
      let ldata:  s_approval= { 
            lfy_code	:'',
            ltrc_code	:'',
            lvr_no:0,
            lsr_no:0,
           
            location :'All',
            category :'All',
            company :'All',
            procdepartment :'All',
            exceedingbudget :'All',
            forms :'All',
            intercompany :'All',
            workingas :'All',
            withinbudget :'All',
            amount :0,
            trc_code :'All',
            department :'All',
            cr_code :'All',
            po_type :'All',
            job_division :'All',
            sub_division :'All',
            leave_type :'All',
            trc :'All',
            clearance_type :'All',
            result :'Success',
            source_table :'',
            batch_code:'',
            priority :'3',
            prioritydateapplicable:'N',
            prioritydate :this.id_vrdate,
            priority_text:"Low",
            prioritydateapplicable_text:'No',
            action:'',
            remark:'',
            role:'',
            actiontext:'',
            ln_forward_type : 0,
            ls_is_forwarded:'N',
            user_no:0,
            fwd_resp:0,
            fwd_to:'',
            pay_dept:"",
            lcnt:0
        } 
        return ldata
    }
    setInis_dynasearch(){ 
        
            let ls_VMCommonQuery:  VMCommonQuery= {
                TypeCode	:	''	,
                lstrData1	:	''	,
                lstrData2	:	''	,
                lstrData3	:	''	,
                lstrData4	:	''	,
                lstrData5	:	''	,
                lstrData6	:	''	,
                lstrData7	:	''	,
                lstrData8	:	''	,
                lstrData9	:	''	,
                lstrData10	:	''	,
                lstrData11	:	''	,
                lstrData12	:	''	,
                lstrData13	:	''	,
                lstrData14	:	''	,
                lstrData15	:	''	,
                lstrData16	:	''	,
                lstrData17	:	''	,
                lstrData18	:	''	,
                lstrData19	:	''	,
                lstrData20	:	''	,
                lstrData21	:	''	,
                lstrData22	:	''	,
                lstrData23	:	''	,
                lstrData24	:	''	,
                lstrData25	:	''	,
                lstrData26	:	''	,
                lstrData27	:	''	,
                lstrData28	:	''	,
                lstrData29	:	''	,
                lstrData30	:	''	,
                lstrData31	:	''	,
                lstrData32	:	''	,
                lstrData33	:	''	,
                lstrData34	:	''	,
                lstrData35	:	''	,
                lstrData36	:	''	,
                lstrData37	:	''	,
                lstrData38	:	''	,
                lstrData39	:	''	,
                lstrData40	:	''	,
                lstrData41	:	''	,
                lstrData42	:	''	,
                lstrData43	:	''	,
                lstrData44	:	''	,
                lstrData45	:	''	,
                lstrData46	:	''	,
                lstrData47	:	''	,
                lstrData48	:	''	,
                lstrData49	:	''	,
                lstrData50	:	''	,

                 

                lstrSQL	:	''	,
                lstrError	:	''	,
                
                lstrUser :'',
                lstrParam1	:	''	,
                lstrParam2	:	''	,
                lstrParam3	:	''	,
                lstrParam4	:	''	,
                lstrParam5	:	''	,
                lstrParam6	:	''	,
                lstrParam7	:	''	,
                lstrParam8	:	''	,
                lstrParam9	:	''	,
                lstrParam10	:	''	,
                lstrParam11	:	''	,
                lstrParam12	:	''	,
                lstrParam13	:	''	,
                lstrParam14	:	''	,
                lstrParam15	:	''	,
                lstrParam16	:	''	,
                lstrParam17	:	''	,
                lstrParam18	:	''	,
                lstrParam19	:	''	,
                lstrParam20	:	''	,
                lstrParam21	:	''	,
                lstrParam22	:	''	,
                lstrParam23	:	''	,
                lstrParam24	:	''	,
                lstrParam25	:	''	,
                lstrParam26	:	''	,
                lstrParam27	:	''	,
                lstrParam28	:	''	,
                lstrParam29	:	''	,
                lstrParam30	:	''	,
                lstrParam31	:	''	,
                lstrParam32	:	''	,
                lstrParam33	:	''	,
                lstrParam34	:	''	,
                lstrParam35	:	''	,
                lstrParam36	:	''	,
                lstrParam37	:	''	,
                lstrParam38	:	''	,
                lstrParam39	:	''	,
                lstrParam40	:	''	,
                lstrParam41	:	''	,
                lstrParam42	:	''	,
                lstrParam43	:	''	,
                lstrParam44	:	''	,
                lstrParam45	:	''	,
                lstrParam46	:	''	,
                lstrParam47	:	''	,
                lstrParam48	:	''	,
                lstrParam49	:	''	,
                lstrParam50	:	''	, 

                lstrParam51	:	'', 
                lstrParam52	:	'', 
                lstrParam53	:	'', 
                lstrParam54	:	'', 
                lstrParam55	:	'', 
                lstrParam56	:	'', 
                lstrParam57	:	'', 
                lstrParam58	:	'', 
                lstrParam59	:	'', 
                lstrParam60	:	'', 
                lstrParam61	:	'', 
                lstrParam62	:	'', 
                lstrParam63	:	'', 
                lstrParam64	:	'', 
                lstrParam65	:	'', 
                lstrParam66	:	'', 
                lstrParam67	:	'', 
                lstrParam68	:	'', 
                lstrParam69	:	'', 
                lstrParam70	:	'', 
                lstrParam71	:	'', 
                lstrParam72	:	'', 
                lstrParam73	:	'', 
                lstrParam74	:	'', 
                lstrParam75	:	'', 
                lstrParam76	:	'', 
                lstrParam77	:	'', 
                lstrParam78	:	'', 
                lstrParam79	:	'', 
                lstrParam80	:	'', 
                lstrParam81	:	'', 
                lstrParam82	:	'', 
                lstrParam83	:	'', 
                lstrParam84	:	'', 
                lstrParam85	:	'', 
                lstrParam86	:	'', 
                lstrParam87	:	'', 
                lstrParam88	:	'', 
                lstrParam89	:	'', 
                lstrParam90	:	'', 
                lstrParam91	:	'', 
                lstrParam92	:	'', 
                lstrParam93	:	'', 
                lstrParam94	:	'', 
                lstrParam95	:	'', 
                lstrParam96	:	'', 
                lstrParam97	:	'', 
                lstrParam98	:	'', 
                lstrParam99	:	'', 
                lstrParam100	:	'', 
                lstrParam101	:	'', 
                lstrParam102	:	'', 
                lstrParam103	:	'', 
                lstrParam104	:	'', 
                lstrParam105	:	'', 
                lstrParam106	:	'', 
                lstrParam107	:	'', 
                lstrParam108	:	'', 
                lstrParam109	:	'', 
                lstrParam110	:	'', 
                lstrParam111	:	'', 
                lstrParam112	:	'', 
                lstrParam113	:	'', 
                lstrParam114	:	'', 
                lstrParam115	:	'', 
                lstrParam116	:	'', 
                lstrParam117	:	'', 
                lstrParam118	:	'', 
                lstrParam119	:	'', 
                lstrParam120	:	'', 
                lstrParam121	:	'', 
                lstrParam122	:	'', 
                lstrParam123	:	'', 
                lstrParam124	:	'', 
                lstrParam125	:	'', 
                lstrParam126	:	'', 
                lstrParam127	:	'', 
                lstrParam128	:	'', 
                lstrParam129	:	'', 
                lstrParam130	:	'', 
                lstrParam131	:	'', 
                lstrParam132	:	'', 
                lstrParam133	:	'', 
                lstrParam134	:	'', 
                lstrParam135	:	'', 
                lstrParam136	:	'', 
                lstrParam137	:	'', 
                lstrParam138	:	'', 
                lstrParam139	:	'', 
                lstrParam140	:	'', 
                lstrParam141	:	'', 
                lstrParam142	:	'', 
                lstrParam143	:	'', 
                lstrParam144	:	'', 
                lstrParam145	:	'', 
                lstrParam146	:	'', 
                lstrParam147	:	'', 
                lstrParam148	:	'', 
                lstrParam149	:	'', 
                lstrParam150	:	'', 
                lstrParam151	:	'', 
                lstrParam152	:	'', 
                lstrParam153	:	'', 
                lstrParam154	:	'', 
                lstrParam155	:	'', 
                lstrParam156	:	'', 
                lstrParam157	:	'', 
                lstrParam158	:	'', 
                lstrParam159	:	'', 
                lstrParam160	:	'', 
                lstrParam161	:	'', 
                lstrParam162	:	'', 
                lstrParam163	:	'', 
                lstrParam164	:	'', 
                lstrParam165	:	'', 
                lstrParam166	:	'', 
                lstrParam167	:	'', 
                lstrParam168	:	'', 
                lstrParam169	:	'', 
                lstrParam170	:	'', 
                lstrParam171	:	'', 
                lstrParam172	:	'', 
                lstrParam173	:	'', 
                lstrParam174	:	'', 
                lstrParam175	:	'', 
                lstrParam176	:	'', 
                lstrParam177	:	'', 
                lstrParam178	:	'', 
                lstrParam179	:	'', 
                lstrParam180	:	'', 
                lstrParam181	:	'', 
                lstrParam182	:	'', 
                lstrParam183	:	'', 
                lstrParam184	:	'', 
                lstrParam185	:	'', 
                lstrParam186	:	'', 
                lstrParam187	:	'', 
                lstrParam188	:	'', 
                lstrParam189	:	'', 
                lstrParam190	:	'', 
                lstrParam191	:	'', 
                lstrParam192	:	'', 
                lstrParam193	:	'', 
                lstrParam194	:	'', 
                lstrParam195	:	'', 
                lstrParam196	:	'', 
                lstrParam197	:	'', 
                lstrParam198	:	'', 
                lstrParam199	:	'', 
                lstrParam200	:	'', 
                    lstrSearch :'',

                lstrSortCol :1,
                lbSortAsc:"N",
        }
        return ls_VMCommonQuery;
    }
   
    setInis_s_transinformation(){
          
        
        let ls_VMCommonQuery:  s_transinformation= { 
                trc_name	:	""	,
                trc_object1	:	""	,
                trc_object2	:	""	,
                fy_code	:	""	,
                trc_code	:	""	,
                trc_abr	:	""	,
                ref_trc_abr	:	""	,
                vr_no	:	null,
                vr_date	:	null	,
                top_a_code	:	""	,
                bot_a_code	:	""	,
                bot_a_dbcr	:	null	,
                internal_entry_indicator	:	""	,
                internal_entry_code1	:	""	,
                internal_entry_code2	:	""	,
                eff_cost	:	""	,
                inv_int	:	""	,
                auto_verify	:	""	,
                datawin_header	:	""	,
                datawin_detail	:	""	,
                datawin_detail2	:	""	,
                datawin_inventory	:	""	,
                datawin	:	""	,
                datawin_custom_fields	:	""	,
                text1	:	""	,
                text2	:	""	,
                text3	:	""	,
                text4	:	""	,
                text5	:	""	,
                text6	:	""	,
                text7	:	""	,
                text8	:	""	,
                text9	:	""	,
                text10	:	""	,
                text11	:	""	,
                text12	:	""	,
                text13	:	""	,
                text14	:	""	,
                windowvisible	:	false	,
                qty_dec	:	null	,
                rate_dec	:	null	,
                amt_dec	:	null	,
                br_code	:	""	,
                credit_card	:	""	,
                chq_no_ind	:	null	,
                chq_date_ind	:	null	,
                chq_bank_ind	:	null	,
                invoice_rate_ind	:	null	,
                chq_details_ind	:	null	,
                doc_analysis_code	:	""	,
                sto_nega	:	""	,
                i_vr_no	:	""	,
                doc_refer_pr_code	:	""	,
                doc_refer_sr_code	:	""	,
                doc_refer_pi_code	:	""	,
                doc_refer_si_code	:	""	,
                doc_refer_po_code	:	""	,
                doc_refer_so_code	:	""	,
                doc_refer_grn_code	:	""	,
                doc_refer_dn_code	:	""	,
                pp_jv_code	:	""	,
                depre_jv_code	:	""	,
                deferred_jv_code	:	""	,
                srno	:	null	,
                dsr_no	:	null	,
                print_on_save	:	""	,
                salesman	:	""	,
                acc_code	:	""	,
                acc_filter	:	""	,
                cor_c_int	:	""	,
                cor_days	:	null	,
                ent_c_int	:	""	,
                ent_days	:	null	,
                c_l_ctrl	:	""	,
                vr_dt_edit	:	""	,
                cost_int	:	""	,
                sm_cntrl	:	""	,
                fixed_pric	:	""	,
                item_dup	:	""	,
                s_dt_fy	:	null	,
                e_dt_fy	:	null	,
                s_dt_entry	:	null	,
                e_dt_entry	:	null	,
                trc_close_date	:	null	,
                mode	:	""	,
                tag	:	""	,
                narration_dup	:	""	,
                narration	:	""	,
                lastdate_dup	:	""	,
                book_object	:	""	,
                list_object	:	""	,
                register_object	:	""	,
                dataobject	:	""	,
                acc_ind	:	""	,
                access_rights	:	""	,
                ds_bill	:	""	,
                ds_job	:	""	,
                can_reedit	:	""	,
                job_no	:	null	,
                dept_job_code	:	""	,
                window1	:	""	,
                grn_type	:	""	,
                security_level	:	""	,
                assets_depr_posting_ind	:	""	,
                prepaid_calc_posting_ind	:	""	,
                deferred_calc_posting_ind	:	""	,
                close_ind	:	""	,
                option	:	""	,
                bill_sr_no	:	null	,
                bill_acc_code	:	""	,
                bill_allocation_fc_amt	:	null,
                bill_allocation_amount	:	null,
                bill_sign	:	null	,
                bill_cur_code	:	""	,
                bill_cur_rate	:	null,
                bill_chq_date	:	null	,
                inventory_right	:	""	,
                windowstate	:	""	,
                total_amt	:	null,
                ddw_acccode	:	""	,
                ddw_acccodewithfilter	:	""	,
                auto_bill_save	:	""	,
                advance	:	""	,
                auth1_name	:	""	,
                auth2_name	:	""	,
                auth3_name	:	""	,
                user_name	:	""	,
                zero_save	:	""	,
                change_detail_dbcr	:	""	,
                ok	:	""	,
                company_code	:	""	,
                trc_type	:	""	,
                drilldown_from	:	""	,
                future_ent_days	:	null	,
                ite_qty	:	null,
                window_title	:	""	,
                window_tag	:	""	,
                ite_code	:	""	,
                priority	:	""	,
                base_cur_code	:	""	,
                report_cur_rate	:	null,
                report_amt	:	null,
                trans_cur_dec	:	null	,
                role_id	:	""	,
                role_no	:	null	,
                reference_trc_code	:	""	,
                reference_vr_no	:	null	,
                trc_codes	:	null	,
                graph_syntax : ""  ,
                gr_string	:	""	,
                closing_type	:	""	,
                inout	:	""	,
                ref_fy_code	:	""	,
                ref_trc_code	:	""	,
                ref_vr_no	:	null,
                ref_sr_no	:	null,
                attachment_syntax	:	""	,
                client_name	:	""	,
                ess_emp_code	:	""	,
                approval_ind	:	""	,
             
             
        }
        return ls_VMCommonQuery
    }
    ini_stransinfo(){
        let ls_dynstructure:  VMs_transinfo= { 
            trc_code : "", 
            trc_name : "", 
            trc_object1 : "", 
            trc_object2 : "", 
            trc_abr : "", 
            top_a_code : "", 
            bot_a_code : "", 
            bot_a_dbcr : 0, 
            auto_verify : "", 
            text1 : "", 
            text2 : "", 
            text3 : "", 
            text4 : "", 
            text5 : "", 
            text6 : "", 
            text7 : "", 
            text8 : "", 
            text9 : "", 
            text10 : "", 
            text11 : "", 
            text12 : "", 
            text13 : "", 
            text14 : "", 
            inv_int : "", 
            acc_ind : "", 
            eff_cost : "", 
            chq_no_ind : "", 
            chq_date_ind : "", 
            chq_bank_ind : "", 
            invoice_rate_ind : 0, 
            chq_details_ind : "", 
            doc_analysis_code : "", 
            doc_refer_pr_code : "", 
            doc_refer_sr_code : "", 
            doc_refer_pi_code : "", 
            doc_refer_si_code : "", 
            doc_refer_po_code : "", 
            doc_refer_so_code : "", 
            doc_refer_grn_code : "", 
            doc_refer_dn_code : "", 
            print_on_save : "", 
            salesman : "", 
            trc_close_date : null,  
            narration_dup : "", 
            narration : "", 
            lastdate_dup : "", 
            book_object : "", 
            list_object : "", 
            register_object : "", 
            internal_entry_code1 : "", 
            internal_entry_code2 : "", 
            close_ind : "", 
            auto_bill_save : "", 
            ref_trc_abr : "", 
            auth1_name : "", 
            auth2_name : "", 
            auth3_name : "", 
            zero_save : "", 
            change_detail_dbcr : "", 
            trc_type : "", 
            graph_syntax : "", 
            qty_dec : 0, 
            rate_dec : 0, 
            amt_dec : 0, 
            credit_card : "", 
            s_dt_fy : null,  
            e_dt_fy : null,  
            s_dt_entry : null,  
            e_dt_entry : null,  
            internal_entry_indicator : "", 
            grn_type : "", 
            ddw_acccode : "", 
            ddw_acccodewithfilter : "", 
            advance : "", 
            user_name : "", 
            br_code : "", 
            br_name:"",
            i_vr_no : "", 
            sto_nega : "", 
            acc_filter : "", 
            vg_zoom : "", 
            cor_c_int : "", 
            cor_days : 0, 
            ent_c_int : "", 
            ent_days : 0, 
            c_l_ctrl : "", 
            vr_dt_edit : "", 
            cost_int : "", 
            sm_cntrl : "", 
            fixed_pric : "", 
            item_dup : "", 
            can_reedit : "", 
            security_level : "", 
            inventory_right : "", 
            windowstate : "", 
            rights : "", 
            object_name : "", 
            arg_1 : "", 
            reference_trc_code : "", 
            menu_code : "", 
            user_id : "", 
            ls_approvalind : "", 
            ls_appstatus : "", 
            ln_doccanapplcnt : 0,  
            approvalcnt : 0,  
            ls_fy_code : "", 
            lvr_no : 0, 
            menu_tree_name : "", 
            top_a_name:"",
            printType:"",
            sr_no:0,
            Result:"",
            cust_feild_cnt:0,
            reportobjectcnt:0,
            posting_method:"",
            attachment_applicable:"N",
            listing_applicable:"",
            reportobjectsrno:0,
            reportobjectreportname:"",
            listobjectsrno:0,
            listobjectreportname:"",
            lreportwrcnt:0,
            rangeoption:"",
            attachment_rights:"",
            lrelated:0
        }
        return ls_dynstructure
        
    }

    ini_vm_transactions(){
        let ls_dynstructure:  vm_transactions= { 
            Result :"",
            stringjson :"",
            strProcess :"",
            authcode:"",
            report_code:"",
            report_name:"",
            report_sr_no:null,
            report_type:null,
            vmd:""
        }
        return ls_dynstructure
    }
    ini_dw_reports_filter(){
        let ls_dynstructure:  dw_reports_filter= { 
            lstrfilter1 : "",  
            lstrfilter2 : "",
            lstrfilter3 : "",
            lstrfilter4 : "",
            lstrfilter5 : "",
            lstrfilter6 : "",
            lstrfilter7 : "",
        }
        return ls_dynstructure
    }
    
    handleError(error) {

        if (error.status == 401) { 
            alert("your session has expired please login again,,,") 
            this.router.navigate(['account/login']);
        }

        this.lb_form_enabled=true
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
       // window.alert(errorMessage);
        console.log(error)
        return throwError(errorMessage);
      } 

      async wf_month_validate(ldate:Date):Promise<boolean>{
         
        let vl_month_code:number

        let lstrtemp:string =   this.datePipe.transform(ldate, "yy"); 
        vl_month_code=  +(this.S_TRANSINFO.ls_fy_code + lstrtemp) 

        if( vl_month_code!= this.vi_month_code ) {
            let monthcode:string  =await this.wf_getmonthcode()

            if (monthcode=="Cancel" || monthcode ==""){
                return false 
            }else{
                this.vi_month_code= + monthcode
            }  
        }

        if ( vl_month_code!= this.vi_month_code ){ 
            this._bfs.f_showmessage("Messaging", "Wrong month code selected")
            return false
        } 
        return true
    }

    // ngInvalid
    showInvalid(element:any){
        try{
            if(this.lb_submitted){
                if(!element){
                    return 'ng-error'
                }else{
                    return ''
                }
            }
                    
            
        }
        catch(error){
            this.handleError(error)
    
        }

        }
   
}
