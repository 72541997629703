<app-loader-epro class="loader-container" *ngIf="!lb_form_enabled" ></app-loader-epro>
<div class="" >      
    <h2 class="maintitle">{{vmData.menu_name}}</h2>
    <!-- <h2 class="mat-h2"  *ngIf="lb_form_enabled" >{{ls_menuName}}  </h2>   -->
    
        <div class="mb-24" fxLayout="column" fxLayoutAlign="start" > 
            <div class="ng-width-100" > 
                <div class="buttons">
                    <button (click)="add()" mat-raised-button class="brownbtn">
                        <mat-icon>add</mat-icon>
                        <span class="hide-text"> Add</span>
                    </button>
                    <button (click)="delete()" mat-raised-button class="redbtn">
                        <mat-icon>delete</mat-icon>
                        <span class="hide-text">Delete</span>
                    </button>
                    
                    <button (click)="ue_save($event)" mat-raised-button class="brownbtn">
                      <mat-icon>save</mat-icon>
                      <span class="hide-text">Save</span>
                  </button>

                    <button (click)="close()" mat-raised-button class="redbtn">
                        <mat-icon>close</mat-icon>
                        <span class="hide-text"> Close</span>
                    </button>
                    <button (click)="showall()" mat-raised-button class="brownbtn">
                      <mat-icon>add</mat-icon>
                      <span class="hide-text">Show List</span>
                    </button>

                    <button (click)="showallList()" mat-raised-button class="brownbtn">
                     <mat-icon>add</mat-icon>
                     <span class="hide-text">Show All</span>
                   </button>
                </div>
                   <div class="table-wrapper bor-1-table mt-16">
                    <table mat-table [dataSource]="ds_dw_task_history" class="table-striped">

                        <ng-container matColumnDef="sl_no">
                           <div class="space-table">
                              <th class="text-center ng-xx-sm" mat-header-cell *matHeaderCellDef >Sr. # </th>
                              <td mat-cell *matCellDef="let element ; let i = index" class="ng-xx-sm text">
                                 <div class="space-table">
                                    <mat-form-field appearance="outline" >
                                    <input [(ngModel)]="element.sl_no" name="element.sl_no" matInput
                                         [readonly]="true" >
                                </mat-form-field>  
                                 </div>
                              </td>
                           </div>
                        </ng-container>
                        <ng-container matColumnDef="user_code">
                           <div class="space-table">
                              <th class="text-center ng-sm" mat-header-cell *matHeaderCellDef > 
                                User Code
                                </th>
                              <td mat-cell *matCellDef="let element ; let i = index" class="text-center ng-sm">
                                 <div class="space-table">
                                  <mat-form-field appearance="outline"  >
                                    <input [(ngModel)]="element.user_code" name="element.user_code" matInput
                                         [readonly]="true" (click)="user_code_clicked(element)">
                                </mat-form-field>    
                                </div>
                              </td>
                           </div>
                        </ng-container>
                        <ng-container matColumnDef="assigned_user">
                           <div class="space-table">
                              <th class="text-center ng-sm" mat-header-cell *matHeaderCellDef > 
                                Assigned User
                                </th>
                              <td mat-cell *matCellDef="let element ; let i = index" class="text-center ng-sm">
                                 <div class="space-table">
                                  <mat-form-field appearance="outline"  >
                                    <input [(ngModel)]="element.assigned_user" name="element.assigned_user" matInput
                                         [readonly]="true" (click)="assigned_user_clicked(element)">
                                </mat-form-field>    
                                </div>
                              </td>
                           </div>
                        </ng-container>
                        <ng-container matColumnDef="vr_date">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm text-center">Vr. Date </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline" >
                                            <input [(ngModel)]="element.vr_date" name="element.vr_date" matInput
                                                [matDatepicker]="vr_date" [readonly]="true" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="vr_date"></mat-datepicker-toggle>
                                            <mat-datepicker #vr_date></mat-datepicker>
                                        </mat-form-field>

                                        
                                    </div>
                                </td>
                            </ng-container>
                          <ng-container matColumnDef="priority_status">
                              <div class="space-table">
                                 <th class="text-center " mat-header-cell *matHeaderCellDef >Priority </th>
                                 <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm text-center">
                                    <div class="space-table">
                                     <mat-form-field appearance="outline" >
                                         <mat-select class="select" [(ngModel)]="element.priority_status">
                                           <mat-option value="Low">Low</mat-option>
                                           <mat-option value="Medium">Medium</mat-option>
                                           <mat-option value="High">High</mat-option>
                                         </mat-select>
                                       </mat-form-field>
                                   </div>
                                 </td>
                              </div>
                           </ng-container>

                        <ng-container matColumnDef="type_screen">
                           <div class="space-table">
                              <th class="text-center " mat-header-cell *matHeaderCellDef >Type Screen </th>
                              <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm text-center">
                                 <div class="space-table">
                                  <mat-form-field appearance="outline" >
                                      <mat-select class="select" [(ngModel)]="element.type_screen">
                                        <mat-option value="Report">Report</mat-option>
                                        <mat-option value="Screen">Screen</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                </div>
                              </td>
                           </div>
                        </ng-container>

                        <ng-container matColumnDef="remarks">
                            <div class="space-table">
                               <th class="text-center" mat-header-cell *matHeaderCellDef >Remarks </th>
                               <td mat-cell *matCellDef="let element ; let i = index" class="ng-lg">
                                  <div class="space-table">
                                     <mat-form-field appearance="outline">
                                        <!-- <input matInput [value]="element.remarks" [(ngModel)]="element.remarks"    [readonly]="element.row_status!='New'" > -->
                                        <!-- <textarea matInput [(ngModel)]="element.remarks" name="element.remarks" [readonly]="element.row_status!='New'" 
                                           cdkTextareaAutosize  #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"   cdkAutosizeMaxRows="5"  class="text-area-field"> 
                                      </textarea> -->
                                      <textarea matInput [(ngModel)]="element.remarks" name="element.remarks"     [readonly]="element.row_status!='New'" > </textarea>
                                     </mat-form-field>
                                     
                                  </div>
                               </td>
                            </div>
                         </ng-container>

                        <ng-container matColumnDef="comments">
                            <div class="space-table">
                               <th class="text-center" mat-header-cell *matHeaderCellDef >Comments </th>
                               <td mat-cell *matCellDef="let element ; let i = index" class="ng-lg">
                                  <div class="space-table">
                                     <mat-form-field appearance="outline">
                                        <textarea matInput [(ngModel)]="element.comments" name="element.comments"    > </textarea>
                                     </mat-form-field>
                                     
                                  </div>
                               </td>
                            </div>
                         </ng-container>
                         <ng-container matColumnDef="status">
                           <div class="space-table">
                              <th class="text-center " mat-header-cell *matHeaderCellDef >Status </th>
                              <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm text-center">
                                 <div class="space-table">
                                  <mat-form-field appearance="outline" >
                                      <mat-select class="select" [(ngModel)]="element.status"  (selectionChange)="status_chnage(element)">
                                        <mat-option value="Pending">Pending</mat-option>
                                        <mat-option value="WIP">WIP</mat-option>
                                        <mat-option value="Completed">Completed</mat-option>
                                        <mat-option value="To Be Closed">To Be Closed</mat-option>
                                        <mat-option value="Closed">Closed</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                </div>
                              </td>
                           </div>
                        </ng-container>

                        <ng-container matColumnDef="closed_user">
                            <div class="space-table">
                               <th class="text-center ng-sm" mat-header-cell *matHeaderCellDef > 
                                 Completed User
                                 </th>
                               <td mat-cell *matCellDef="let element ; let i = index" class="text-center ng-sm">
                                  <div class="space-table">
                                   <mat-form-field appearance="outline"  >
                                     <input [(ngModel)]="element.closed_user" name="element.closed_user" matInput
                                          [readonly]="true" >
                                 </mat-form-field>    
                                 </div>
                               </td>
                            </div>
                         </ng-container>
                         <ng-container matColumnDef="closed_date">
                                 <th mat-header-cell *matHeaderCellDef class="ng-sm text-center">Completed Date </th>
                                 <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                     <div class="space-table">
                                         <mat-form-field appearance="outline" >
                                             <input [(ngModel)]="element.closed_date" name="element.closed_date" matInput
                                                 [matDatepicker]="closed_date" [readonly]="true" disabled>
                                             <mat-datepicker-toggle matSuffix [for]="closed_date"></mat-datepicker-toggle>
                                             <mat-datepicker #closed_date></mat-datepicker>
                                         </mat-form-field>
 
                                         
                                     </div>
                                 </td>
                             </ng-container>

                             <ng-container matColumnDef="f_closed_user">
                              <div class="space-table">
                                 <th class="text-center ng-sm" mat-header-cell *matHeaderCellDef > 
                                   Closed User
                                   </th>
                                 <td mat-cell *matCellDef="let element ; let i = index" class="text-center ng-sm">
                                    <div class="space-table">
                                     <mat-form-field appearance="outline"  >
                                       <input [(ngModel)]="element.f_closed_user" name="element.f_closed_user" matInput
                                            [readonly]="true" >
                                   </mat-form-field>    
                                   </div>
                                 </td>
                              </div>
                           </ng-container>
                           <ng-container matColumnDef="f_closed_date">
                                   <th mat-header-cell *matHeaderCellDef class="ng-sm text-center">Closed Date </th>
                                   <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                       <div class="space-table">
                                           <mat-form-field appearance="outline" >
                                               <input [(ngModel)]="element.f_closed_date" name="element.f_closed_date" matInput
                                                   [matDatepicker]="f_closed_date" [readonly]="true" disabled>
                                               <mat-datepicker-toggle matSuffix [for]="f_closed_date"></mat-datepicker-toggle>
                                               <mat-datepicker #f_closed_date></mat-datepicker>
                                           </mat-form-field>
   
                                           
                                       </div>
                                   </td>
                               </ng-container>
                               <ng-container matColumnDef="attach">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm text-center">Attach </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm text-center">
                                    <div class="space-table">
                                      <button (click)="ue_attach(element)" mat-raised-button class="brownbtn">
                                        <mat-icon>attach_file</mat-icon>
                                        <span class="hide-text">attach</span>
                                    </button>

                                        
                                    </div>
                                </td>

                                 
                              </ng-container>

                         <tr mat-header-row *matHeaderRowDef="dc_dw_task_history" class="theadcol"></tr>
                            <tr mat-row *matRowDef="let element; let i = index; columns: dc_dw_task_history;"
                                (click)="setRow(element)"> </tr>
                    </table>
 
                </div> 
               </div>
        </div> 
</div>