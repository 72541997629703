import {Component,ViewChild,Inject} from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';

import {  MatOptionModule } from '@angular/material/core';
import { UserService } from 'app/base/userservice'; 
import { BaseWindow } from 'app/base/window/BaseWindow.component';
import { ActivatedRoute, Router } from '@angular/router';
import { globalData } from 'app/base/globalData';
import { basefunctionsService } from 'app/base/basefunctionsService';  
import { DatePipe } from '@angular/common';
import { componentFactoryName } from '@angular/compiler';
import { VMCommonQuery , VMSearch} from 'app/base/search/search.data'; 
import { BaseDialogwindowcomponent } from 'app/base/window/BaseDialogwindow.component';  
  
import { dw_message_list, dw_message_send } from './SendMessage.data'; 
import { VMs_transinfo, vm_transactions } from 'app/base/window/Basewindow.data';
import { SearchComponent } from 'app/base/search/search.component';
import { s_drill } from 'app/main/Reports/ReportFrame/ReportFrame.data';
import { Attachmentcomponent } from 'app/base/Attachment/Attachment.component';

@Component({    selector   : 'SendMessageComponent',    templateUrl: './SendMessage.component.html',    styleUrls  : ['./SendMessage.component.scss'] ,
     providers:[DatePipe]  
})

export class SendMessageComponent //extends BaseDialogwindowcomponent 
{
    S_TRANSINFO:VMs_transinfo;
    vmTrans:vm_transactions;
    vmData:dw_message_send
    vmDataTmp:dw_message_send

    vmSearch:vm_transactions
    vmSearchTmp: vm_transactions

    ls_anySearch:string= 'N'
    lVMSearch:VMSearch;
    
    ls_filter:string
    ls_selectedRow : string
    ls_mode:string
    ls_VMCommonQuery :VMCommonQuery;
    ls_doc:s_drill 
    dataSource: MatTableDataSource<dw_message_list>;
    displayedColumns=['vr_no','vr_date','from_user','to_user','message' ];
    constructor(public _user_service: UserService, public dialog: MatDialog, public _bfs: basefunctionsService, public adapter: DateAdapter<any>,
        private dialogRef: MatDialogRef<SendMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: vm_transactions, public lcredireq: UserService
        , public datePipe: DatePipe) {
           // super(_user_service, dialog, _bfs, adapter, datePipe); 
           // this.ls_VMCommonQuery=this.setInis_dynasearch();  
           this.vmTrans= this.ini_vm_transactions()  
           this.vmData =this.inidw_message_send()
 
           this.ls_doc= JSON.parse( localStorage.getItem('s_doc' ) )  
            
            this.ue_new( )

            this.S_TRANSINFO=this.ini_stransinfo()
            
         
    }

    ini_vm_transactions(){
        let ls_dynstructure:  vm_transactions= { 
            Result :"",
            stringjson :"",
            strProcess :"",
            authcode:"",
            report_type:"",
            report_name :"",
            report_code:"",
            report_sr_no:0,
            vmd:""

            
        }
        return ls_dynstructure
    }
    async ue_save( ){
   
        if(this.ls_mode=="New"){
            this.vmTrans.strProcess="sp_send_message_save"
        }else{
            this.vmTrans.strProcess="sp_send_message_update"
        }
            
        this.vmTrans.stringjson=  JSON.stringify(this.vmData) ; 
        this.vmTrans =await this._user_service.postData(this.vmTrans) 
        if(this.vmTrans.Result=="Success"){ 
            this._bfs.f_showmessage("Info","Saved")
            this.vmData=JSON.parse(this.vmTrans.stringjson);  

                
        }
        else{ 
            this._bfs.f_showmessage("Info",this.vmTrans.Result)  
        }    


 
         
    }
    async ue_delete( ){ 

            let resutdt= await this._bfs.f_showmessage_yes_no("Info","Are you sure to delete?")
            if (resutdt==false ){
                return   
            }
 
            this.vmData.Result=  "Success"  
             
            
            this.vmTrans.strProcess="sp_send_message_delete"
            this.vmTrans.stringjson=  JSON.stringify(this.vmData) ; 
            this.vmTrans =await this._user_service.postData(this.vmTrans) 
            if(this.vmTrans.Result=="Success"){ 
                this._bfs.f_showmessage("Info","Deleted")
                this.vmData=JSON.parse(this.vmTrans.stringjson);
              
                this.ue_new( )
            }
            else{ 
                this._bfs.f_showmessage("Info",this.vmTrans.Result) 
            }  
            
    }
    async ue_new( ){
        this.ls_mode ="New"
        this.vmData =this.inidw_message_send()

        if(this.ls_doc){
            this.vmData.ref_m_fy_code =this.ls_doc.fy_code 
            this.vmData.ref_m_trc_code =this.ls_doc.trc_code 
            this.vmData.ref_m_vr_no =this.ls_doc.vr_no.toString()   

            try{
                this.vmTrans.strProcess="sp_send_message_new"
                this.vmTrans.stringjson=  JSON.stringify(this.vmData) ; 
                this.vmTrans =await this._user_service.getData(this.vmTrans)  
                 if(this.vmTrans.Result=="Success"){
                    this.vmDataTmp=JSON.parse(this.vmTrans.stringjson);  
                    this.ls_mode ="New"
                    this.vmData.dw_message_lists =this.vmDataTmp.dw_message_lists 
                    this.dataSource = new MatTableDataSource(this.vmData.dw_message_lists)
                }
                else{ 
                    this._bfs.f_showmessage("Info",this.vmTrans.Result) 
                }  
     
            }catch(error){ 
            }
        }
    }
    ue_void( ){this._bfs.f_showmessage("Info",'ue_void');} 
    attach_det(element:dw_message_list){
        try{

            if ( this.vmData.vr_no){
                const dialogConfig = new MatDialogConfig(); 
                dialogConfig.autoFocus = true;
                dialogConfig.height='77%';
                dialogConfig.width='95%';        
                dialogConfig.maxWidth='100%';
                dialogConfig.disableClose = true;  
                debugger
                this.lVMSearch =this.setIni_lVMSearch(); 
                // this.S_TRANSINFO.printType="Listing"
                this.S_TRANSINFO.menu_code="this.ls_menuCode"
                this.S_TRANSINFO.attachment_rights="CMDE@P"
                this.S_TRANSINFO.trc_code= 'MSG'//'ASS'
                this.S_TRANSINFO.ls_fy_code=element.fy_code
                this.S_TRANSINFO.lvr_no=element.vr_no
                dialogConfig.data=this.S_TRANSINFO;
                const dialogRef = this.dialog.open(Attachmentcomponent, dialogConfig);
                dialogRef.afterClosed().subscribe(async result => {
                    if(result)
                    {
                        console.log(result);
                    }
                },
                );         
            }
            else 
            {
                this._bfs.f_showmessage("Info","Please Save the document and try")
                return
            } 
        }catch(error){ 
        } 
    }
    ue_attach( ){
 
        try{

            if ( this.vmData.vr_no){
                const dialogConfig = new MatDialogConfig(); 
                dialogConfig.autoFocus = true;
                dialogConfig.height='77%';
                dialogConfig.width='95%';        
                dialogConfig.maxWidth='100%';
                dialogConfig.disableClose = true;  
                debugger
                this.lVMSearch =this.setIni_lVMSearch(); 
                // this.S_TRANSINFO.printType="Listing"
                this.S_TRANSINFO.menu_code="this.ls_menuCode"
                this.S_TRANSINFO.attachment_rights="CMDE@P"
                this.S_TRANSINFO.trc_code= 'MSG'//'ASS'
                this.S_TRANSINFO.ls_fy_code=this.vmData.fy_code
                this.S_TRANSINFO.lvr_no=this.vmData.vr_no
                dialogConfig.data=this.S_TRANSINFO;
                const dialogRef = this.dialog.open(Attachmentcomponent, dialogConfig);
                dialogRef.afterClosed().subscribe(async result => {
                    if(result)
                    {
                        console.log(result);
                    }
                },
                );         
            }
            else 
            {
                this._bfs.f_showmessage("Info","Please Save the document and try")
                return
            } 
        }catch(error){ 
        } 
        
    } 
    
    
    
    ue_browse( ){  
 
        this.lVMSearch =this.setIni_lVMSearch() 
        this.lVMSearch.lstrSQL=  "sel_sendmessage" 
        const dialogConfig = new MatDialogConfig();
        this.lVMSearch.lbSortAsc="N"
        dialogConfig.autoFocus = true;
        dialogConfig.height='77%';
        dialogConfig.width='77%';
        dialogConfig.data= this.lVMSearch 
        dialogConfig.disableClose = true; 
        this.lVMSearch.lstrColName1="Vr #"
        this.lVMSearch.lstrColName2="Vr Date"   
        this.lVMSearch.lstrColName3="From User"   
        this.lVMSearch.lstrColName4="To User"   
        this.lVMSearch.lstrColName5="Message" 
        //vr_no,vr_date,from_user,to_user,message
        const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
        
        dialogRef.afterClosed().subscribe(res => {
                if(res){ 
                    this.ls_VMCommonQuery  = res; 
                    if(  this.ls_VMCommonQuery.lstrData1!="Cancel" ){
                        this.vmData.vr_no =  +this.ls_VMCommonQuery.lstrData1;  
                        this.ue_retrieve() 
                    } 
                }
        }, ); 
         

    } 

    async ue_retrieve(){ 

        try{
            this.vmTrans.strProcess="sp_send_message_browse"
            this.vmTrans.stringjson=  JSON.stringify(this.vmData) ; 
            this.vmTrans =await this._user_service.getData(this.vmTrans)  
             if(this.vmTrans.Result=="Success"){
                this.vmData=JSON.parse(this.vmTrans.stringjson);  
                this.ls_mode ="New"
                this.dataSource = new MatTableDataSource(this.vmData.dw_message_lists)
                //this.S_TRANSINFO.lvr_no= this.vmData.vr_no
                //this.S_TRANSINFO.ls_approvalind=this.vmData.approved
                //super.ue_retrieve()       
            }
            else{ 
                this._bfs.f_showmessage("Info",this.vmTrans.Result) 
            }  
 
        }catch(error){ 
        }
    }
    ue_custom_fields( ){this._bfs.f_showmessage("Info",'ue_custom_fields');}
    ue_import( ){this._bfs.f_showmessage("Info",'ue_import');}
    ue_undo( ){this._bfs.f_showmessage("Info",'ue_undo');}
    async ue_send( ){  
       
    }

    to_user(){

        let ls_to_user:string=""
        this.lVMSearch =this.setIni_lVMSearch()
                 
        this.lVMSearch.lstrSQL= "select_to_user"
        
        
        this.lVMSearch.lstrColName1="Resource Code"  
        this.lVMSearch.lstrColName2="Resource Name"
            
        const dialogConfig = new MatDialogConfig();
        this.lVMSearch.TypeCode='m' 
        this.lVMSearch.lngColwidth1=30 
        this.lVMSearch.lngColwidth2=40  
        dialogConfig.autoFocus = true;
        dialogConfig.height='77%';
        dialogConfig.width='77%';
        dialogConfig.data=  this.lVMSearch 
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(async res => {
            if(res){ 
                if(res.lstrData1 != "Cancel"){
                    
                    
                    let i=res.length
                    while (i--) { 
                        if(ls_to_user){
                            ls_to_user= ls_to_user +","  +res[i].lstrData1
                        }else{
                            ls_to_user=res[i].lstrData1
                        }
                    } 

                    this.vmData.to_user=ls_to_user
                }                
            }
        }, ); 
     
    }
     
    inidw_message_send()
    {


        let ldatDet2: dw_message_list[] = [];		
        let ldata: dw_message_send = { 
            fy_code : "10" ,
            trc_code : "MSG" ,
            vr_no : null ,
            vr_date : null  ,
            from_user : localStorage.getItem('vg_userid') ,
            to_user : null ,
            message : null ,
            ref_m_fy_code : null ,
            ref_m_trc_code : null ,
            ref_m_vr_no : null ,
            Result:"", 
            dw_message_lists:ldatDet2,
        }    
            return ldata  
    } 
    inidw_message_list()
    {
        let ldata: dw_message_list = { 
            fy_code : null ,
            trc_code : null ,
            vr_no : null ,
            vr_date : null  ,
            from_user : null ,
            to_user : null ,
            message : null ,
            ref_m_fy_code : null ,
            ref_m_trc_code : null ,
            ref_m_vr_no : null ,
            remarks : null ,
            userinfo : null ,
        }    
         return ldata  
    } 
    ue_close(){
        this.dialogRef.close();
    }

    setIni_lVMSearch(){
        let ls_tmp:  VMSearch= {
            TypeCode	:	''	,
            lstrData1	:	''	,
            lstrData2	:	''	,
            lstrData3	:	''	,
            lstrData4	:	''	,
            lstrData5	:	''	,
            lstrData6	:	''	,
            lstrData7	:	''	,
            lstrData8	:	''	,
            lstrData9	:	''	,
            lstrData10	:	''	,
            lstrData11	:	''	,
            lstrData12	:	''	,
            lstrData13	:	''	,
            lstrData14	:	''	,
            lstrData15	:	''	,
            lstrData16	:	''	,
            lstrData17	:	''	,
            lstrData18	:	''	,
            lstrData19	:	''	,
            lstrData20	:	''	,
            lstrData21	:	''	,
            lstrData22	:	''	,
            lstrData23	:	''	,
            lstrData24	:	''	,
            lstrData25	:	''	,
            lstrData26	:	''	,
            lstrData27	:	''	,
            lstrData28	:	''	,
            lstrData29	:	''	,
            lstrData30	:	''	,
            lstrData31	:	''	,
            lstrData32	:	''	,
            lstrData33	:	''	,
            lstrData34	:	''	,
            lstrData35	:	''	,
            lstrData36	:	''	,
            lstrData37	:	''	,
            lstrData38	:	''	,
            lstrData39	:	''	,
            lstrData40	:	''	,
            lstrData41	:	''	,
            lstrData42	:	''	,
            lstrData43	:	''	,
            lstrData44	:	''	,
            lstrData45	:	''	,
            lstrData46	:	''	,
            lstrData47	:	''	,
            lstrData48	:	''	,
            lstrData49	:	''	,
            lstrData50	:	''	,


            lngColwidth1	:	0	,
            lngColwidth2	:	0	,
            lngColwidth3	:	0	,
            lngColwidth4	:	0	,
            lngColwidth5	:	0	,
            lngColwidth6	:	0	,
            lngColwidth7	:	0	,
            lngColwidth8	:	0	,
            lngColwidth9	:	0	,
            lngColwidth10	:	0	,
            lngColwidth11	:	0	,
            lngColwidth12	:	0	,
            lngColwidth13	:	0	,
            lngColwidth14	:	0	,
            lngColwidth15	:	0	,
            lngColwidth16	:	0	,
            lngColwidth17	:	0	,
            lngColwidth18	:	0	,
            lngColwidth19	:	0	,
            lngColwidth20	:	0	,
            lngColwidth21	:	0	,
            lngColwidth22	:	0	,
            lngColwidth23	:	0	,
            lngColwidth24	:	0	,
            lngColwidth25	:	0	,
            lngColwidth26	:	0	,
            lngColwidth27	:	0	,
            lngColwidth28	:	0	,
            lngColwidth29	:	0	,
            lngColwidth30	:	0	,
            lngColwidth31	:	0	,
            lngColwidth32	:	0	,
            lngColwidth33	:	0	,
            lngColwidth34	:	0	,
            lngColwidth35	:	0	,
            lngColwidth36	:	0	,
            lngColwidth37	:	0	,
            lngColwidth38	:	0	,
            lngColwidth39	:	0	,
            lngColwidth40	:	0	,
            lngColwidth41	:	0	,
            lngColwidth42	:	0	,
            lngColwidth43	:	0	,
            lngColwidth44	:	0	,
            lngColwidth45	:	0	,
            lngColwidth46	:	0	,
            lngColwidth47	:	0	,
            lngColwidth48	:	0	,
            lngColwidth49	:	0	,
            lngColwidth50	:	0	,

            lstrColName1	:	''	,
            lstrColName2	:	''	,
            lstrColName3	:	''	,
            lstrColName4	:	''	,
            lstrColName5	:	''	,
            lstrColName6	:	''	,
            lstrColName7	:	''	,
            lstrColName8	:	''	,
            lstrColName9	:	''	,
            lstrColName10	:	''	,
            lstrColName11	:	''	,
            lstrColName12	:	''	,
            lstrColName13	:	''	,
            lstrColName14	:	''	,
            lstrColName15	:	''	,
            lstrColName16	:	''	,
            lstrColName17	:	''	,
            lstrColName18	:	''	,
            lstrColName19	:	''	,
            lstrColName20	:	''	,
            lstrColName21	:	''	,
            lstrColName22	:	''	,
            lstrColName23	:	''	,
            lstrColName24	:	''	,
            lstrColName25	:	''	,
            lstrColName26	:	''	,
            lstrColName27	:	''	,
            lstrColName28	:	''	,
            lstrColName29	:	''	,
            lstrColName30	:	''	,
            lstrColName31	:	''	,
            lstrColName32	:	''	,
            lstrColName33	:	''	,
            lstrColName34	:	''	,
            lstrColName35	:	''	,
            lstrColName36	:	''	,
            lstrColName37	:	''	,
            lstrColName38	:	''	,
            lstrColName39	:	''	,
            lstrColName40	:	''	,
            lstrColName41	:	''	,
            lstrColName42	:	''	,
            lstrColName43	:	''	,
            lstrColName44	:	''	,
            lstrColName45	:	''	,
            lstrColName46	:	''	,
            lstrColName47	:	''	,
            lstrColName48	:	''	,
            lstrColName49	:	''	,
            lstrColName50	:	''	,

            lstrSQL	:	''	,
            lstrError	:	''	,

            lstrUser	:	''	,

            lstrParam1	:	''	,
            lstrParam2	:	''	,
            lstrParam3	:	''	,
            lstrParam4	:	''	,
            lstrParam5	:	''	,
            lstrParam6	:	''	,
            lstrParam7	:	''	,
            lstrParam8	:	''	,
            lstrParam9	:	''	,
            lstrParam10	:	''	,
            lstrParam11	:	''	,
            lstrParam12	:	''	,
            lstrParam13	:	''	,
            lstrParam14	:	''	,
            lstrParam15	:	''	,
            lstrParam16	:	''	,
            lstrParam17	:	''	,
            lstrParam18	:	''	,
            lstrParam19	:	''	,
            lstrParam20	:	''	,
            lstrParam21	:	''	,
            lstrParam22	:	''	,
            lstrParam23	:	''	,
            lstrParam24	:	''	,
            lstrParam25	:	''	,
            lstrParam26	:	''	,
            lstrParam27	:	''	,
            lstrParam28	:	''	,
            lstrParam29	:	''	,
            lstrParam30	:	''	,
            lstrParam31	:	''	,
            lstrParam32	:	''	,
            lstrParam33	:	''	,
            lstrParam34	:	''	,
            lstrParam35	:	''	,
            lstrParam36	:	''	,
            lstrParam37	:	''	,
            lstrParam38	:	''	,
            lstrParam39	:	''	,
            lstrParam40	:	''	,
            lstrParam41	:	''	,
            lstrParam42	:	''	,
            lstrParam43	:	''	,
            lstrParam44	:	''	,
            lstrParam45	:	''	,
            lstrParam46	:	''	,
            lstrParam47	:	''	,
            lstrParam48	:	''	,
            lstrParam49	:	''	,
            lstrParam50	:	''	,
             
            lstrParam51	:	'', 
            lstrParam52	:	'', 
            lstrParam53	:	'', 
            lstrParam54	:	'', 
            lstrParam55	:	'', 
            lstrParam56	:	'', 
            lstrParam57	:	'', 
            lstrParam58	:	'', 
            lstrParam59	:	'', 
            lstrParam60	:	'', 
            lstrParam61	:	'', 
            lstrParam62	:	'', 
            lstrParam63	:	'', 
            lstrParam64	:	'', 
            lstrParam65	:	'', 
            lstrParam66	:	'', 
            lstrParam67	:	'', 
            lstrParam68	:	'', 
            lstrParam69	:	'', 
            lstrParam70	:	'', 
            lstrParam71	:	'', 
            lstrParam72	:	'', 
            lstrParam73	:	'', 
            lstrParam74	:	'', 
            lstrParam75	:	'', 
            lstrParam76	:	'', 
            lstrParam77	:	'', 
            lstrParam78	:	'', 
            lstrParam79	:	'', 
            lstrParam80	:	'', 
            lstrParam81	:	'', 
            lstrParam82	:	'', 
            lstrParam83	:	'', 
            lstrParam84	:	'', 
            lstrParam85	:	'', 
            lstrParam86	:	'', 
            lstrParam87	:	'', 
            lstrParam88	:	'', 
            lstrParam89	:	'', 
            lstrParam90	:	'', 
            lstrParam91	:	'', 
            lstrParam92	:	'', 
            lstrParam93	:	'', 
            lstrParam94	:	'', 
            lstrParam95	:	'', 
            lstrParam96	:	'', 
            lstrParam97	:	'', 
            lstrParam98	:	'', 
            lstrParam99	:	'', 
            lstrParam100	:	'', 
            lstrParam101	:	'', 
            lstrParam102	:	'', 
            lstrParam103	:	'', 
            lstrParam104	:	'', 
            lstrParam105	:	'', 
            lstrParam106	:	'', 
            lstrParam107	:	'', 
            lstrParam108	:	'', 
            lstrParam109	:	'', 
            lstrParam110	:	'', 
            lstrParam111	:	'', 
            lstrParam112	:	'', 
            lstrParam113	:	'', 
            lstrParam114	:	'', 
            lstrParam115	:	'', 
            lstrParam116	:	'', 
            lstrParam117	:	'', 
            lstrParam118	:	'', 
            lstrParam119	:	'', 
            lstrParam120	:	'', 
            lstrParam121	:	'', 
            lstrParam122	:	'', 
            lstrParam123	:	'', 
            lstrParam124	:	'', 
            lstrParam125	:	'', 
            lstrParam126	:	'', 
            lstrParam127	:	'', 
            lstrParam128	:	'', 
            lstrParam129	:	'', 
            lstrParam130	:	'', 
            lstrParam131	:	'', 
            lstrParam132	:	'', 
            lstrParam133	:	'', 
            lstrParam134	:	'', 
            lstrParam135	:	'', 
            lstrParam136	:	'', 
            lstrParam137	:	'', 
            lstrParam138	:	'', 
            lstrParam139	:	'', 
            lstrParam140	:	'', 
            lstrParam141	:	'', 
            lstrParam142	:	'', 
            lstrParam143	:	'', 
            lstrParam144	:	'', 
            lstrParam145	:	'', 
            lstrParam146	:	'', 
            lstrParam147	:	'', 
            lstrParam148	:	'', 
            lstrParam149	:	'', 
            lstrParam150	:	'', 
            lstrParam151	:	'', 
            lstrParam152	:	'', 
            lstrParam153	:	'', 
            lstrParam154	:	'', 
            lstrParam155	:	'', 
            lstrParam156	:	'', 
            lstrParam157	:	'', 
            lstrParam158	:	'', 
            lstrParam159	:	'', 
            lstrParam160	:	'', 
            lstrParam161	:	'', 
            lstrParam162	:	'', 
            lstrParam163	:	'', 
            lstrParam164	:	'', 
            lstrParam165	:	'', 
            lstrParam166	:	'', 
            lstrParam167	:	'', 
            lstrParam168	:	'', 
            lstrParam169	:	'', 
            lstrParam170	:	'', 
            lstrParam171	:	'', 
            lstrParam172	:	'', 
            lstrParam173	:	'', 
            lstrParam174	:	'', 
            lstrParam175	:	'', 
            lstrParam176	:	'', 
            lstrParam177	:	'', 
            lstrParam178	:	'', 
            lstrParam179	:	'', 
            lstrParam180	:	'', 
            lstrParam181	:	'', 
            lstrParam182	:	'', 
            lstrParam183	:	'', 
            lstrParam184	:	'', 
            lstrParam185	:	'', 
            lstrParam186	:	'', 
            lstrParam187	:	'', 
            lstrParam188	:	'', 
            lstrParam189	:	'', 
            lstrParam190	:	'', 
            lstrParam191	:	'', 
            lstrParam192	:	'', 
            lstrParam193	:	'', 
            lstrParam194	:	'', 
            lstrParam195	:	'', 
            lstrParam196	:	'', 
            lstrParam197	:	'', 
            lstrParam198	:	'', 
            lstrParam199	:	'', 
            lstrParam200	:	'', 
                lstrSearch :'',
            lstrSortCol :1,
            lbSortAsc:"Y", 
            lstrShowlist:"N"
        }
        return ls_tmp;
    }

    ini_stransinfo(){
        let ls_dynstructure:  VMs_transinfo= { 
            trc_code : "", 
            trc_name : "", 
            trc_object1 : "", 
            trc_object2 : "", 
            trc_abr : "", 
            top_a_code : "", 
            bot_a_code : "", 
            bot_a_dbcr : 0, 
            auto_verify : "", 
            text1 : "", 
            text2 : "", 
            text3 : "", 
            text4 : "", 
            text5 : "", 
            text6 : "", 
            text7 : "", 
            text8 : "", 
            text9 : "", 
            text10 : "", 
            text11 : "", 
            text12 : "", 
            text13 : "", 
            text14 : "", 
            inv_int : "", 
            acc_ind : "", 
            eff_cost : "", 
            chq_no_ind : "", 
            chq_date_ind : "", 
            chq_bank_ind : "", 
            invoice_rate_ind : 0, 
            chq_details_ind : "", 
            doc_analysis_code : "", 
            doc_refer_pr_code : "", 
            doc_refer_sr_code : "", 
            doc_refer_pi_code : "", 
            doc_refer_si_code : "", 
            doc_refer_po_code : "", 
            doc_refer_so_code : "", 
            doc_refer_grn_code : "", 
            doc_refer_dn_code : "", 
            print_on_save : "", 
            salesman : "", 
            trc_close_date : null,  
            narration_dup : "", 
            narration : "", 
            lastdate_dup : "", 
            book_object : "", 
            list_object : "", 
            register_object : "", 
            internal_entry_code1 : "", 
            internal_entry_code2 : "", 
            close_ind : "", 
            auto_bill_save : "", 
            ref_trc_abr : "", 
            auth1_name : "", 
            auth2_name : "", 
            auth3_name : "", 
            zero_save : "", 
            change_detail_dbcr : "", 
            trc_type : "", 
            graph_syntax : "", 
            qty_dec : 0, 
            rate_dec : 0, 
            amt_dec : 0, 
            credit_card : "", 
            s_dt_fy : null,  
            e_dt_fy : null,  
            s_dt_entry : null,  
            e_dt_entry : null,  
            internal_entry_indicator : "", 
            grn_type : "", 
            ddw_acccode : "", 
            ddw_acccodewithfilter : "", 
            advance : "", 
            user_name : "", 
            br_code : "", 
            br_name:"",
            i_vr_no : "", 
            sto_nega : "", 
            acc_filter : "", 
            vg_zoom : "", 
            cor_c_int : "", 
            cor_days : 0, 
            ent_c_int : "", 
            ent_days : 0, 
            c_l_ctrl : "", 
            vr_dt_edit : "", 
            cost_int : "", 
            sm_cntrl : "", 
            fixed_pric : "", 
            item_dup : "", 
            can_reedit : "", 
            security_level : "", 
            inventory_right : "", 
            windowstate : "", 
            rights : "", 
            object_name : "", 
            arg_1 : "", 
            reference_trc_code : "", 
            menu_code : "", 
            user_id : "", 
            ls_approvalind : "", 
            ls_appstatus : "", 
            ln_doccanapplcnt : 0,  
            approvalcnt : 0,  
            ls_fy_code : "", 
            lvr_no : 0, 
            menu_tree_name : "", 
            top_a_name:"",
            printType:"",
            sr_no:0,
            Result:"",
            cust_feild_cnt:0,
            reportobjectcnt:0,
            posting_method:"",
            attachment_applicable:"N",
            listing_applicable:"",
            reportobjectsrno:0,
            reportobjectreportname:"",
            listobjectsrno:0,
            listobjectreportname:"",
            lreportwrcnt:0,
            rangeoption:"",
            attachment_rights:"",
            lrelated:0

        }
        return ls_dynstructure
        
    }

}

