/**
 * Shijilesh 07/01/2017 
 * ePROMIS  
 *  For Comm. functions
 **/

import { Injectable, Component } from '@angular/core';  

import { BaseService } from './BaseService'; 
import { Observable } from 'rxjs'; 
import { globalData } from './globalData';  
import { VMs_transinfo, messagebox_struct } from './window/Basewindow.data';
import { VMCommonQuery, VMSearch } from './search/search.data';  
import { HttpClient } from '@angular/common/http'; 
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

import { MessageboxComponent } from 'app/base/window/messagebox.component'; 
import { SearchComponent } from './search/search.component';
import { promise } from 'protractor'; 
import { Router } from '@angular/router'; 
//import { DatePipe } from '@angular/common';
import * as moment from 'moment'; 
import { searchautocloseComponent } from './searchautoclose/searchautoclose.component';
import { VMSearchData } from './searchautoclose/searchautoclose.data';
 
@Injectable()
export class basefunctionsService  extends BaseService   {
  
  private access_token  ; 
  constructor( public config: globalData   , public httpClient: HttpClient ,public dialog: MatDialog ,public router: Router
    //, public datePipe : DatePipe
    ) {    super(config ,  router  ); } 
 
  async validate_soft_closing( vl_fy_code :string ,vl_type :string ,vl_vr_date:Date,vl_trc_code :string){

  }
  
  async daysdiff(firstDate ,secondDate ){
    firstDate = moment(firstDate,'DD/MM/YYYY');
    secondDate = moment(secondDate,'DD/MM/YYYY');
    let diffInDays:number = Math.abs(firstDate.diff(secondDate, 'days')); 
    return diffInDays
 }
  async getdatefromstring(dateString:string): Promise<Date>  {
    
        var dateParts = dateString.split('/'); 
        dateString = dateParts[1] +"/"+ dateParts[0]  +"/"+ dateParts[2] 
        let ldate:Date = new Date(dateString) 
        return ldate
  }

  async  fn_get_sale_rate(customer:string,item:string,ratecode:string,unit_code:string,vr_date:Date,loc:string):Promise<number>{

    //fn_get_sale_rate(this.vmInvData.acc_code , s_iteminfo.item_code,vl_rate_type ,unit_code,vl_date, this.vmInvData.source_br_code  )
    let ls_date:string =this.getdatetostring(vr_date)
     
    let l_VMCommonQuery :VMCommonQuery
    l_VMCommonQuery =this.setInis_dynasearch(); 
    l_VMCommonQuery.lstrSQL= "fn_get_sale_rate"
    l_VMCommonQuery.lstrParam1=customer 
    l_VMCommonQuery.lstrParam2=item
    l_VMCommonQuery.lstrParam3=ratecode
    l_VMCommonQuery.lstrParam4=unit_code
    l_VMCommonQuery.lstrParam5=ls_date
    l_VMCommonQuery.lstrParam6=loc

    l_VMCommonQuery=  await this.getInLineResultAsyc(l_VMCommonQuery)
    if ( !(l_VMCommonQuery == null || typeof l_VMCommonQuery === 'undefined')){
        return +l_VMCommonQuery.lstrData1
    }else{
        return 0
    }
    
}

async  fn_get_alpha( code:string):Promise<string>{

  //fn_get_sale_rate(this.vmInvData.acc_code , s_iteminfo.item_code,vl_rate_type ,unit_code,vl_date, this.vmInvData.source_br_code  )
  
  let l_VMCommonQuery :VMCommonQuery
  l_VMCommonQuery =this.setInis_dynasearch(); 
  l_VMCommonQuery.lstrSQL= "select_alpha_auto"
  l_VMCommonQuery.lstrParam1=code  

  l_VMCommonQuery=  await this.getInLineResultAsyc(l_VMCommonQuery)
  if ( !(l_VMCommonQuery == null || typeof l_VMCommonQuery === 'undefined')){
      return  l_VMCommonQuery.lstrData1
  }else{
      return '0'
  }
  
}

  // 
  async fn_check_stock_avilable(vl_item:string,vl_branch:string,fycode:string,trcode:string,vrno:number,vl_qty:number,company:string,ref_fycode:string,ref_trc:string,ref_vrno:number)
    :Promise<boolean>{
    let lVMCommonQuery: VMCommonQuery
    lVMCommonQuery=this.setInis_dynasearch();
    lVMCommonQuery.lstrSQL= "fn_check_stock_avilable"
    lVMCommonQuery.lstrParam1=vl_item
    lVMCommonQuery.lstrParam2=vl_branch
    lVMCommonQuery.lstrParam3=fycode
    lVMCommonQuery.lstrParam4=trcode
    lVMCommonQuery.lstrParam5=vrno.toString()
    lVMCommonQuery.lstrParam6=vl_qty.toString()
    lVMCommonQuery.lstrParam7=company
    lVMCommonQuery.lstrParam8=ref_fycode
    lVMCommonQuery.lstrParam9=ref_trc
    lVMCommonQuery.lstrParam10=ref_vrno.toString()
    let options = this.getHttpClientHeader();
    let data=  await this.httpClient.post<VMCommonQuery>( this.getApi() +'api/commonQuerys/GetInlineResult',lVMCommonQuery,   options).toPromise() ;
      if (data.lstrData1=="false"){
        alert(data.lstrData2)
        return false
      }else{
        return true;
      }
 }

  

  async fn_check_stock(vl_item:string,vl_branch:string,fycode:string,trcode:string,vrno:number,vl_qty:number,company:string )
      :Promise<boolean>{
      let lVMCommonQuery: VMCommonQuery
      lVMCommonQuery=this.setInis_dynasearch();
      lVMCommonQuery.lstrSQL= "fn_check_stock_avilable"
      lVMCommonQuery.lstrParam1=vl_item
      lVMCommonQuery.lstrParam2=vl_branch
      lVMCommonQuery.lstrParam3=fycode
      lVMCommonQuery.lstrParam4=trcode
      lVMCommonQuery.lstrParam5=vrno.toString()
      lVMCommonQuery.lstrParam6=vl_qty.toString()
      lVMCommonQuery.lstrParam7=company 
      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<VMCommonQuery>( this.getApi() +'api/commonQuerys/GetInlineResult',lVMCommonQuery,   options).toPromise() ;
        if (data.lstrData1=="false"){
          alert(data.lstrData2)
          return false
        }else{
          return true;
        }
  }
  async fn_check_overstock(vl_item:string,vl_branch:string,fycode:string,trcode:string,vrno:number,vl_qty:number  )
      :Promise<boolean>{
      let lVMCommonQuery: VMCommonQuery
      lVMCommonQuery=this.setInis_dynasearch();
      lVMCommonQuery.lstrSQL= "fn_check_stock_avilable"
      lVMCommonQuery.lstrParam1=vl_item
      lVMCommonQuery.lstrParam2=vl_branch
      lVMCommonQuery.lstrParam3=fycode
      lVMCommonQuery.lstrParam4=trcode
      lVMCommonQuery.lstrParam5=(vrno || 0).toString()
      lVMCommonQuery.lstrParam6=(vl_qty ||0).toString() 
      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<VMCommonQuery>( this.getApi() +'api/commonQuerys/GetInlineResult',lVMCommonQuery,   options).toPromise() ;
        if (data.lstrData1=="false"){
          alert(data.lstrData2)
          return false
        }else{
          return true;
        }
  }

  async get_cur_rate(  lfy_code:string ,vr_date:string,cur_code:string ) : Promise<number>    {  

    let lVMCommonQuery: VMCommonQuery
    lVMCommonQuery=this.setInis_dynasearch();
    lVMCommonQuery.lstrSQL= "get_cur_rate"
    lVMCommonQuery.lstrParam1=lfy_code 
    lVMCommonQuery.lstrParam2=cur_code 
 
    lVMCommonQuery.lstrParam3=  vr_date  
    let options = this.getHttpClientHeader();
    let data=  await this.httpClient.post<VMCommonQuery>( this.getApi() +'api/commonQuerys/GetInlineResult',lVMCommonQuery,   options).toPromise() ;
     
    if ( !(data == null || typeof data === 'undefined')){
      return +data.lstrData1;
    }
    else{
      return 0
    }
    
  } 
  
  getdatetostring(vr_date:Date):string{ 
    return vr_date.getMonth() + 1 +"/" +vr_date.getDate() +"/" +vr_date.getFullYear()
  }

  async get_job_ind(  ls_accode:string ,ls_cost_centre :string ) : Promise<string>    {  
    try{
      let lVMCommonQuery: VMCommonQuery
      lVMCommonQuery=this.setInis_dynasearch();
      lVMCommonQuery.lstrSQL= "get_job_ind"
      lVMCommonQuery.lstrParam1=ls_accode  
      lVMCommonQuery.lstrParam2=ls_cost_centre  
      let options = this.getHttpClientHeader();
      lVMCommonQuery  =  await this.httpClient.post<VMCommonQuery>( this.getApi() +'api/commonQuerys/GetInlineResult',lVMCommonQuery,   options).toPromise() ;
      if ( !(lVMCommonQuery== null || typeof lVMCommonQuery=== 'undefined')){
        return lVMCommonQuery.lstrData1 ;
      }
      else{
        this.f_showmessage("info","Coud not retrieve informations")
        return ""
      }
    }catch(error){
      this.handleError(error)
    }
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  calcDuration(fromTime,toTime)
  {
      if((fromTime) && (toTime))
      {
          if((fromTime.toString().indexOf("_") < 0) && (toTime.toString().indexOf("_") < 0))
          {
              let ms = moment(toTime,"YYYY-MM-DD HH:mm:ss").diff(moment(fromTime,"YYYY-MM-DD HH:mm:ss"));
              let d = moment.duration(ms);
              let diffTime = Math.floor(d.asHours()) + moment.utc(ms).format(":mm");
              return diffTime;
          }
      }
  }
  addDurations(duration1,duration2,duration3)
  {
      let totDuration;
      if(duration3 != 0){ 
        totDuration = (moment.duration(duration1).add(duration2)).subtract(duration3);
      }else{
        totDuration = (moment.duration(duration1).add(duration2));
      }
      return `${moment.utc(totDuration.asMilliseconds()).format("HH.mm")}`;     
  }

  async getAccountCode(lsdatatag:string ,S_TRANSINFO:VMs_transinfo) :Promise<VMCommonQuery>{
    try{
      let lVMSearch:VMSearch;
      lVMSearch =this.setIni_lVMSearch()  
      let lVMCommonQuery: VMCommonQuery
      lVMCommonQuery=this.setInis_dynasearch();
      S_TRANSINFO.ddw_acccode=S_TRANSINFO.ddw_acccode|| ""
     
        if(S_TRANSINFO.trc_code != "037" && S_TRANSINFO.trc_code !="038"){
            if (S_TRANSINFO.trc_code=="0" ||  (!S_TRANSINFO.acc_filter || S_TRANSINFO.acc_filter=="N")) {
                lVMSearch.lstrSQL= "ddw_acccode_company"
            }else{
                lVMSearch.lstrSQL= "ddw_acccodewithfilter"
            }        
        }else if(S_TRANSINFO.trc_code == "037"){
            lVMSearch.lstrSQL= "ddw_acccode_company_pool"
        }else if(S_TRANSINFO.trc_code == "038"){
            lVMSearch.lstrSQL= "ddw_acccode_company_pool"
            lVMSearch.lstrSQL="ddw_acccode_company_institution"
        }

        if(S_TRANSINFO.ddw_acccode !=""){
          lVMSearch.lstrSQL=S_TRANSINFO.ddw_acccode
        } 
      
      // Detail 
      if (lsdatatag=="D"){
          
          if (S_TRANSINFO.trc_code =="0" ||  (S_TRANSINFO.acc_filter=="" || S_TRANSINFO.acc_filter=="N")) { 
              if (S_TRANSINFO.ddw_acccode==""){
                  lVMSearch.lstrSQL= "ddw_acccode_company"
              } 
              else{
                  lVMSearch.lstrSQL= S_TRANSINFO.ddw_acccode
              } 
          }else{
              if( S_TRANSINFO.ddw_acccodewithfilter==""){
                  lVMSearch.lstrSQL= "ddw_acccodewithfilter"
              }   
              else{
                  lVMSearch.lstrSQL= S_TRANSINFO.ddw_acccodewithfilter
              } 
          }
              
      }

      if(S_TRANSINFO.trc_code=="BID" ) {
        lVMSearch.lstrSQL="ddw_acccodewithfilter"
      }
      
      const dialogConfig = new MatDialogConfig();
      lVMSearch.lstrColName1="A/C Code"  
      lVMSearch.lstrColName2="Account Name"
      lVMSearch.lstrColName3="Cur."  
      lVMSearch.lstrColName4="Group Name"
      lVMSearch.lstrColName5="Phone"
      lVMSearch.lstrColName7="Territory"
      lVMSearch.lstrColName6="Postal"

      lVMSearch.lngColwidth1=30 
      lVMSearch.lngColwidth2=100 
      lVMSearch.lngColwidth3=4 
      lVMSearch.lngColwidth5=100 
      lVMSearch.lngColwidth6=30
      lVMSearch.lngColwidth7=40

      lVMSearch.lstrParam1=S_TRANSINFO.ls_fy_code
      lVMSearch.lstrParam2=S_TRANSINFO.trc_code
      lVMSearch.lstrParam3=lsdatatag

      dialogConfig.autoFocus = true;
      dialogConfig.height='77%';
      dialogConfig.width='77%';
      lVMSearch.lstrParam199="1"
      lVMSearch.lstrParam200="Accounts"

      dialogConfig.data=  lVMSearch 
      dialogConfig.disableClose = true;
      lVMSearch.lstrSortCol=1
      lVMSearch.lbSortAsc="Y"
      const dialogRef = this.dialog.open(SearchComponent, dialogConfig); 
      lVMCommonQuery =await dialogRef.afterClosed().toPromise()

      if(  lVMCommonQuery.lstrData1!="Cancel"){ 

          if(lVMCommonQuery.lstrData22=="I"){
              this.f_showmessage("info","Cannot Select ;This code is reserved for system entry")
              lVMCommonQuery.lstrData1="Cancel"
              return lVMCommonQuery
          }
          //if(lVMCommonQuery.lstrData28==""){
              if(lVMCommonQuery.lstrData22=="Y"){
                  this.f_showmessage("info","Cannot Select ; This code is Closed ")
                  lVMCommonQuery.lstrData1="Cancel"
                  return lVMCommonQuery 
              }
          //} 
          
          if(lVMCommonQuery.lstrData14 !=""){
              this.f_showmessage("info",lVMCommonQuery.lstrData14)
          }
          
          let l_VMCommonQuery :VMCommonQuery
          l_VMCommonQuery =this.setInis_dynasearch(); 
          l_VMCommonQuery.lstrSQL= "w_bankpay_account_select"
          l_VMCommonQuery.lstrParam1=lVMCommonQuery.lstrData1
          l_VMCommonQuery.lstrParam2=S_TRANSINFO.ls_fy_code

          let data=  await this.getInLineResultAsyc(l_VMCommonQuery)

          // if (S_TRANSINFO.trc_code=="001"){
          //     let ld_od_expiry = new Date(l_VMCommonQuery.lstrData1);
          //     if(ld_od_expiry < this.id_vrdate){
          //         this.f_showmessage( 'Over draft facility is expired')
          //         lVMCommonQuery.lstrData1="Cancel"
          //         return lVMCommonQuery
          //     }
          // } 
          if (+data.lstrData2 >0 ){
              let l_lVMSearch2 :VMSearch

              l_lVMSearch2 =this.setIni_lVMSearch()
              l_lVMSearch2.lstrSQL= "account_message_pop" 
                      
              const dialogConfig = new MatDialogConfig();
              l_lVMSearch2.lstrColName1="code"  
              l_lVMSearch2.lstrColName1="Name"
              l_lVMSearch2.lngColwidth1=30 
              l_lVMSearch2.lngColwidth1=100 
              dialogConfig.autoFocus = true;
              dialogConfig.height='77%';
              dialogConfig.width='77%';
              dialogConfig.data=  l_lVMSearch2 
              dialogConfig.disableClose = true;
              l_lVMSearch2.lstrParam1=lVMCommonQuery.lstrData1
              const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
              let l_VMCommonQuery2 :VMCommonQuery

              l_VMCommonQuery2 =await dialogRef.afterClosed().toPromise()
              if(l_VMCommonQuery2.lstrData3=="Y"){
                  lVMCommonQuery.lstrData1="Cancel"
                  return lVMCommonQuery
              } 
              
          } 
      }
        
      return lVMCommonQuery
    }catch(error){
      this.handleError(error)
    }

}

  async get_cost_centre(  ls_accode:string ,vl_company :string ) : Promise<string>    {  
    try{
      let lVMCommonQuery: VMCommonQuery
      lVMCommonQuery=this.setInis_dynasearch();
      lVMCommonQuery.lstrSQL= "get_costcentre_inline"
      lVMCommonQuery.lstrParam1=ls_accode  
      lVMCommonQuery.lstrParam2=vl_company  
      let options = this.getHttpClientHeader();
      lVMCommonQuery  =  await this.httpClient.post<VMCommonQuery>( this.getApi() +'api/commonQuerys/GetInlineResult',lVMCommonQuery,   options).toPromise() ;
      if (  (lVMCommonQuery== null || typeof lVMCommonQuery=== 'undefined')){
        this.f_showmessage("info","No Details found")
        return ""
      }
      // Is_cost_centre_applicable  Is_cost_centre_company_filter job_int cost_centre_ac_filter_cnt cost_centre_ac_filter_cnt_com

      if(+lVMCommonQuery.lstrData1 ==0 ){
        this.f_showmessage("info","Cost centre not applicable for this account")
        return  ""
      }

      let lsql:string;
      if ( !(lVMCommonQuery== null || typeof lVMCommonQuery=== 'undefined')){
        if(lVMCommonQuery.lstrData3 =="Y"){//job_int
          lsql="get_costcentre_job_ind"
        }else{
          lsql= "get_costcentre_job_div"
          if(lVMCommonQuery.lstrData2 =="Y"){ //Is_cost_centre_company_filter
            if(+lVMCommonQuery.lstrData5 >0 ){//cost_centre_ac_filter_cnt_com
              lsql= "get_costcentre_comp_filter"    
            }else{
              lsql= "get_costcentre_non_comp_filter"  
            }
          } 
          else{
            if(+lVMCommonQuery.lstrData4 >0 ){//cost_centre_ac_filter_cnt
              lsql= "get_costcentre_filter" 
            }else{
              lsql = "get_costcentre_non_filter"   
            }
          }        
        } 
      }

      let lVMSearch :VMSearch 
      lVMSearch =this.setIni_lVMSearch()  
      lVMSearch.lstrSQL=  lsql
      const dialogConfig = new MatDialogConfig();
      lVMSearch.lstrColName1="Code"  
      lVMSearch.lstrColName2="Name" 

      lVMSearch.lngColwidth1=30 
      lVMSearch.lngColwidth2=100  

      lVMSearch.lstrParam1=ls_accode
      lVMSearch.lstrParam2= vl_company

      dialogConfig.autoFocus = true;
      dialogConfig.height='77%';
      dialogConfig.width='77%';
      dialogConfig.data=  lVMSearch
      dialogConfig.disableClose = true;
      const dialogRef = this.dialog.open(SearchComponent, dialogConfig); 
      lVMCommonQuery =await dialogRef.afterClosed().toPromise()
      
      if ( !(lVMCommonQuery== null || typeof lVMCommonQuery=== 'undefined')){
        return lVMCommonQuery.lstrData1 ;
      }
      else{
        return ""
      }
    }catch(error){
      this.handleError(error)
    }
    
  } 

  async get_currency_list(company:string, vr_date:string):Promise<VMCommonQuery>{
    try{
      let lsql:string ;

      let lVMCommonQuery: VMCommonQuery
      lVMCommonQuery=this.setInis_dynasearch();
      
      let lVMSearch :VMSearch 
      lVMSearch =this.setIni_lVMSearch()  
      lVMSearch.lstrSQL=  "selectcurrency"
      const dialogConfig = new MatDialogConfig();
      lVMSearch.lstrColName1="Code"  
      lVMSearch.lstrColName2="Rate" 

      lVMSearch.lngColwidth1=30 
      lVMSearch.lngColwidth2=100  

      lVMSearch.lstrParam1=company 
      lVMSearch.lstrParam2= vr_date// this.getdatetostring(vr_date )  

      dialogConfig.autoFocus = true;
      dialogConfig.height='77%';
      dialogConfig.width='77%';
      dialogConfig.data=  lVMSearch
      dialogConfig.disableClose = true;
      const dialogRef = this.dialog.open(SearchComponent, dialogConfig); 
      lVMCommonQuery =await dialogRef.afterClosed().toPromise()
      
      if ( !(lVMCommonQuery== null || typeof lVMCommonQuery=== 'undefined')){
        return lVMCommonQuery  ;
      }
      else{
        lVMCommonQuery.lstrData1="Cancel"
        return lVMCommonQuery
      }
    }catch(error){
      this.handleError(error)
    }
  }

  async f_showmessage(lstr_title:string,ls_string:string){
    try{
      let messagebox_struct:messagebox_struct
      messagebox_struct=this.setini_messagebox_struct()
      messagebox_struct.text=ls_string
      messagebox_struct.title=lstr_title
      const dialogRef =  this.dialog.open(MessageboxComponent, {
          minWidth: '300px',
          data: messagebox_struct 
        });
        return await dialogRef.afterClosed().toPromise()   
      }catch(error){
        this.handleError(error)
      }
  }
  async f_showmessageok(lstr_title:string,ls_string:string){
    try{
      let messagebox_struct:messagebox_struct
      messagebox_struct=this.setini_messagebox_struct()
      messagebox_struct.text=ls_string
      messagebox_struct.title=lstr_title
      messagebox_struct.lb_yesno =false
      const dialogRef =  this.dialog.open(MessageboxComponent, {
          minWidth: '300px',
          data: messagebox_struct 
        });
        return await dialogRef.afterClosed().toPromise()   
      }catch(error){
        this.handleError(error)
      }
  }
  async f_get_tax_code(vat_option:string,region:string) :Promise<string>{
    try{
      let ls_tax_code
      if (vat_option=='Y' && region=='LOCAL'  ) 
      {  
        ls_tax_code='SR'      
      }
      if (vat_option=='Y' && region=='GCC' ) 
      {  
        ls_tax_code='IG'      
      }
      if (vat_option=='Y' && region=='NONGCC' ) 
      {  
        ls_tax_code='RC'      
      }
      if (vat_option=='E'  ) 
      {  
        ls_tax_code='EX'      
      }
      if (vat_option=='N' ) 
      {  
        ls_tax_code='NA'      
      }
      return ls_tax_code
 
      }catch(error){
        this.handleError(error)
      }
  }
 
  async f_showmessage_yes_no(lstr_title:string,ls_string:string){
    try{
      let messagebox_struct:messagebox_struct
      messagebox_struct=this.setini_messagebox_struct()
      messagebox_struct.lb_yesno=true
      messagebox_struct.text=ls_string
      messagebox_struct.title=lstr_title
      const dialogRef =  
      this.dialog.open(MessageboxComponent, {
          minWidth: '300px',
          data:  messagebox_struct 
        });  
    
        return await dialogRef.afterClosed().toPromise() 
      }catch(error){
        this.handleError(error)
      }
  }

async  getInLineResultAsyc( lVMCommonQuery:VMCommonQuery ) : Promise<VMCommonQuery>    {  
  try{
    let options = this.getHttpClientHeader();
    let data=  await this.httpClient.post<VMCommonQuery>( this.getApi() +'api/commonQuerys/GetInlineResult',lVMCommonQuery,   options).toPromise() ;
    
    return data;
  }catch(error){
    this.handleError(error)
  }
} 
 
async  capsLetterfound( strings:string,type:string ) : Promise<boolean>    {   
  var i=0;
    var character='';
    while (i <= strings.length){
        character = strings.charAt(i);
        if(type=="NUMBER"){
          if(isNaN(+character)){
            return true
          }
        }
        if(type=="CAPS"){
            if (character == character.toUpperCase()) {
                return true
                //alert ('upper case true');
            }
          }
        if(type=="SMALL"){
            if (character == character.toLowerCase()){
              return true 
            } 
          }

          if(type=="SPCL"){
            if (character != character.toLowerCase() || character != character.toUpperCase() ||   !isNaN(+character)  ) {
              return true 
            } 
          }
        
        i++;
  }
   return false
}

 
setini_messagebox_struct(){
  let msg:messagebox_struct={
    title:"",
    lb_yesno:false,
    text:""
  }
  return msg
}
  setInis_dynasearch(){ 
        
      let ls_VMCommonQuery:  VMCommonQuery= {
          TypeCode  : ''  ,
          lstrData1 : ''  ,
          lstrData2 : ''  ,
          lstrData3 : ''  ,
          lstrData4 : ''  ,
          lstrData5 : ''  ,
          lstrData6 : ''  ,
          lstrData7 : ''  ,
          lstrData8 : ''  ,
          lstrData9 : ''  ,
          lstrData10  : ''  ,
          lstrData11  : ''  ,
          lstrData12  : ''  ,
          lstrData13  : ''  ,
          lstrData14  : ''  ,
          lstrData15  : ''  ,
          lstrData16  : ''  ,
          lstrData17  : ''  ,
          lstrData18  : ''  ,
          lstrData19  : ''  ,
          lstrData20  : ''  ,
          lstrData21  : ''  ,
          lstrData22  : ''  ,
          lstrData23  : ''  ,
          lstrData24  : ''  ,
          lstrData25  : ''  ,
          lstrData26  : ''  ,
          lstrData27  : ''  ,
          lstrData28  : ''  ,
          lstrData29  : ''  ,
          lstrData30  : ''  ,
          lstrData31  : ''  ,
          lstrData32  : ''  ,
          lstrData33  : ''  ,
          lstrData34  : ''  ,
          lstrData35  : ''  ,
          lstrData36  : ''  ,
          lstrData37  : ''  ,
          lstrData38  : ''  ,
          lstrData39  : ''  ,
          lstrData40  : ''  ,
          lstrData41  : ''  ,
          lstrData42  : ''  ,
          lstrData43  : ''  ,
          lstrData44  : ''  ,
          lstrData45  : ''  ,
          lstrData46  : ''  ,
          lstrData47  : ''  ,
          lstrData48  : ''  ,
          lstrData49  : ''  ,
          lstrData50  : ''  ,

           

          lstrSQL : ''  ,
          lstrError : ''  ,
          
          lstrUser :'',
          lstrParam1  : ''  ,
          lstrParam2  : ''  ,
          lstrParam3  : ''  ,
          lstrParam4  : ''  ,
          lstrParam5  : ''  ,
          lstrParam6  : ''  ,
          lstrParam7  : ''  ,
          lstrParam8  : ''  ,
          lstrParam9  : ''  ,
          lstrParam10 : ''  ,
          lstrParam11 : ''  ,
          lstrParam12 : ''  ,
          lstrParam13 : ''  ,
          lstrParam14 : ''  ,
          lstrParam15 : ''  ,
          lstrParam16 : ''  ,
          lstrParam17 : ''  ,
          lstrParam18 : ''  ,
          lstrParam19 : ''  ,
          lstrParam20 : ''  ,
          lstrParam21 : ''  ,
          lstrParam22 : ''  ,
          lstrParam23 : ''  ,
          lstrParam24 : ''  ,
          lstrParam25 : ''  ,
          lstrParam26 : ''  ,
          lstrParam27 : ''  ,
          lstrParam28 : ''  ,
          lstrParam29 : ''  ,
          lstrParam30 : ''  ,
          lstrParam31 : ''  ,
          lstrParam32 : ''  ,
          lstrParam33 : ''  ,
          lstrParam34 : ''  ,
          lstrParam35 : ''  ,
          lstrParam36 : ''  ,
          lstrParam37 : ''  ,
          lstrParam38 : ''  ,
          lstrParam39 : ''  ,
          lstrParam40 : ''  ,
          lstrParam41 : ''  ,
          lstrParam42 : ''  ,
          lstrParam43 : ''  ,
          lstrParam44 : ''  ,
          lstrParam45 : ''  ,
          lstrParam46 : ''  ,
          lstrParam47 : ''  ,
          lstrParam48 : ''  ,
          lstrParam49 : ''  ,
          lstrParam50 : ''  , 

          lstrParam51 : '', 
          lstrParam52 : '', 
          lstrParam53 : '', 
          lstrParam54 : '', 
          lstrParam55 : '', 
          lstrParam56 : '', 
          lstrParam57 : '', 
          lstrParam58 : '', 
          lstrParam59 : '', 
          lstrParam60 : '', 
          lstrParam61 : '', 
          lstrParam62 : '', 
          lstrParam63 : '', 
          lstrParam64 : '', 
          lstrParam65 : '', 
          lstrParam66 : '', 
          lstrParam67 : '', 
          lstrParam68 : '', 
          lstrParam69 : '', 
          lstrParam70 : '', 
          lstrParam71 : '', 
          lstrParam72 : '', 
          lstrParam73 : '', 
          lstrParam74 : '', 
          lstrParam75 : '', 
          lstrParam76 : '', 
          lstrParam77 : '', 
          lstrParam78 : '', 
          lstrParam79 : '', 
          lstrParam80 : '', 
          lstrParam81 : '', 
          lstrParam82 : '', 
          lstrParam83 : '', 
          lstrParam84 : '', 
          lstrParam85 : '', 
          lstrParam86 : '', 
          lstrParam87 : '', 
          lstrParam88 : '', 
          lstrParam89 : '', 
          lstrParam90 : '', 
          lstrParam91 : '', 
          lstrParam92 : '', 
          lstrParam93 : '', 
          lstrParam94 : '', 
          lstrParam95 : '', 
          lstrParam96 : '', 
          lstrParam97 : '', 
          lstrParam98 : '', 
          lstrParam99 : '', 
          lstrParam100  : '', 
          lstrParam101  : '', 
          lstrParam102  : '', 
          lstrParam103  : '', 
          lstrParam104  : '', 
          lstrParam105  : '', 
          lstrParam106  : '', 
          lstrParam107  : '', 
          lstrParam108  : '', 
          lstrParam109  : '', 
          lstrParam110  : '', 
          lstrParam111  : '', 
          lstrParam112  : '', 
          lstrParam113  : '', 
          lstrParam114  : '', 
          lstrParam115  : '', 
          lstrParam116  : '', 
          lstrParam117  : '', 
          lstrParam118  : '', 
          lstrParam119  : '', 
          lstrParam120  : '', 
          lstrParam121  : '', 
          lstrParam122  : '', 
          lstrParam123  : '', 
          lstrParam124  : '', 
          lstrParam125  : '', 
          lstrParam126  : '', 
          lstrParam127  : '', 
          lstrParam128  : '', 
          lstrParam129  : '', 
          lstrParam130  : '', 
          lstrParam131  : '', 
          lstrParam132  : '', 
          lstrParam133  : '', 
          lstrParam134  : '', 
          lstrParam135  : '', 
          lstrParam136  : '', 
          lstrParam137  : '', 
          lstrParam138  : '', 
          lstrParam139  : '', 
          lstrParam140  : '', 
          lstrParam141  : '', 
          lstrParam142  : '', 
          lstrParam143  : '', 
          lstrParam144  : '', 
          lstrParam145  : '', 
          lstrParam146  : '', 
          lstrParam147  : '', 
          lstrParam148  : '', 
          lstrParam149  : '', 
          lstrParam150  : '', 
          lstrParam151  : '', 
          lstrParam152  : '', 
          lstrParam153  : '', 
          lstrParam154  : '', 
          lstrParam155  : '', 
          lstrParam156  : '', 
          lstrParam157  : '', 
          lstrParam158  : '', 
          lstrParam159  : '', 
          lstrParam160  : '', 
          lstrParam161  : '', 
          lstrParam162  : '', 
          lstrParam163  : '', 
          lstrParam164  : '', 
          lstrParam165  : '', 
          lstrParam166  : '', 
          lstrParam167  : '', 
          lstrParam168  : '', 
          lstrParam169  : '', 
          lstrParam170  : '', 
          lstrParam171  : '', 
          lstrParam172  : '', 
          lstrParam173  : '', 
          lstrParam174  : '', 
          lstrParam175  : '', 
          lstrParam176  : '', 
          lstrParam177  : '', 
          lstrParam178  : '', 
          lstrParam179  : '', 
          lstrParam180  : '', 
          lstrParam181  : '', 
          lstrParam182  : '', 
          lstrParam183  : '', 
          lstrParam184  : '', 
          lstrParam185  : '', 
          lstrParam186  : '', 
          lstrParam187  : '', 
          lstrParam188  : '', 
          lstrParam189  : '', 
          lstrParam190  : '', 
          lstrParam191  : '', 
          lstrParam192  : '', 
          lstrParam193  : '', 
          lstrParam194  : '', 
          lstrParam195  : '', 
          lstrParam196  : '', 
          lstrParam197  : '', 
          lstrParam198  : '', 
          lstrParam199  : '', 
          lstrParam200  : '', 
              lstrSearch :'',

          lstrSortCol :1,
          lbSortAsc:"Y",
  }
  return ls_VMCommonQuery;
}

setIni_lVMSearch(){
  let ls_tmp:  VMSearch= {
      TypeCode  : ''  ,
      lstrData1 : ''  ,
      lstrData2 : ''  ,
      lstrData3 : ''  ,
      lstrData4 : ''  ,
      lstrData5 : ''  ,
      lstrData6 : ''  ,
      lstrData7 : ''  ,
      lstrData8 : ''  ,
      lstrData9 : ''  ,
      lstrData10  : ''  ,
      lstrData11  : ''  ,
      lstrData12  : ''  ,
      lstrData13  : ''  ,
      lstrData14  : ''  ,
      lstrData15  : ''  ,
      lstrData16  : ''  ,
      lstrData17  : ''  ,
      lstrData18  : ''  ,
      lstrData19  : ''  ,
      lstrData20  : ''  ,
      lstrData21  : ''  ,
      lstrData22  : ''  ,
      lstrData23  : ''  ,
      lstrData24  : ''  ,
      lstrData25  : ''  ,
      lstrData26  : ''  ,
      lstrData27  : ''  ,
      lstrData28  : ''  ,
      lstrData29  : ''  ,
      lstrData30  : ''  ,
      lstrData31  : ''  ,
      lstrData32  : ''  ,
      lstrData33  : ''  ,
      lstrData34  : ''  ,
      lstrData35  : ''  ,
      lstrData36  : ''  ,
      lstrData37  : ''  ,
      lstrData38  : ''  ,
      lstrData39  : ''  ,
      lstrData40  : ''  ,
      lstrData41  : ''  ,
      lstrData42  : ''  ,
      lstrData43  : ''  ,
      lstrData44  : ''  ,
      lstrData45  : ''  ,
      lstrData46  : ''  ,
      lstrData47  : ''  ,
      lstrData48  : ''  ,
      lstrData49  : ''  ,
      lstrData50  : ''  ,

      lngColwidth1  : 0 ,
      lngColwidth2  : 0 ,
      lngColwidth3  : 0 ,
      lngColwidth4  : 0 ,
      lngColwidth5  : 0 ,
      lngColwidth6  : 0 ,
      lngColwidth7  : 0 ,
      lngColwidth8  : 0 ,
      lngColwidth9  : 0 ,
      lngColwidth10 : 0 ,
      lngColwidth11 : 0 ,
      lngColwidth12 : 0 ,
      lngColwidth13 : 0 ,
      lngColwidth14 : 0 ,
      lngColwidth15 : 0 ,
      lngColwidth16 : 0 ,
      lngColwidth17 : 0 ,
      lngColwidth18 : 0 ,
      lngColwidth19 : 0 ,
      lngColwidth20 : 0 ,
      lngColwidth21 : 0 ,
      lngColwidth22 : 0 ,
      lngColwidth23 : 0 ,
      lngColwidth24 : 0 ,
      lngColwidth25 : 0 ,
      lngColwidth26 : 0 ,
      lngColwidth27 : 0 ,
      lngColwidth28 : 0 ,
      lngColwidth29 : 0 ,
      lngColwidth30 : 0 ,
      lngColwidth31 : 0 ,
      lngColwidth32 : 0 ,
      lngColwidth33 : 0 ,
      lngColwidth34 : 0 ,
      lngColwidth35 : 0 ,
      lngColwidth36 : 0 ,
      lngColwidth37 : 0 ,
      lngColwidth38 : 0 ,
      lngColwidth39 : 0 ,
      lngColwidth40 : 0 ,
      lngColwidth41 : 0 ,
      lngColwidth42 : 0 ,
      lngColwidth43 : 0 ,
      lngColwidth44 : 0 ,
      lngColwidth45 : 0 ,
      lngColwidth46 : 0 ,
      lngColwidth47 : 0 ,
      lngColwidth48 : 0 ,
      lngColwidth49 : 0 ,
      lngColwidth50 : 0 ,

      lstrColName1  : ''  ,
      lstrColName2  : ''  ,
      lstrColName3  : ''  ,
      lstrColName4  : ''  ,
      lstrColName5  : ''  ,
      lstrColName6  : ''  ,
      lstrColName7  : ''  ,
      lstrColName8  : ''  ,
      lstrColName9  : ''  ,
      lstrColName10 : ''  ,
      lstrColName11 : ''  ,
      lstrColName12 : ''  ,
      lstrColName13 : ''  ,
      lstrColName14 : ''  ,
      lstrColName15 : ''  ,
      lstrColName16 : ''  ,
      lstrColName17 : ''  ,
      lstrColName18 : ''  ,
      lstrColName19 : ''  ,
      lstrColName20 : ''  ,
      lstrColName21 : ''  ,
      lstrColName22 : ''  ,
      lstrColName23 : ''  ,
      lstrColName24 : ''  ,
      lstrColName25 : ''  ,
      lstrColName26 : ''  ,
      lstrColName27 : ''  ,
      lstrColName28 : ''  ,
      lstrColName29 : ''  ,
      lstrColName30 : ''  ,
      lstrColName31 : ''  ,
      lstrColName32 : ''  ,
      lstrColName33 : ''  ,
      lstrColName34 : ''  ,
      lstrColName35 : ''  ,
      lstrColName36 : ''  ,
      lstrColName37 : ''  ,
      lstrColName38 : ''  ,
      lstrColName39 : ''  ,
      lstrColName40 : ''  ,
      lstrColName41 : ''  ,
      lstrColName42 : ''  ,
      lstrColName43 : ''  ,
      lstrColName44 : ''  ,
      lstrColName45 : ''  ,
      lstrColName46 : ''  ,
      lstrColName47 : ''  ,
      lstrColName48 : ''  ,
      lstrColName49 : ''  ,
      lstrColName50 : ''  ,

      lstrSQL : ''  ,
      lstrError : ''  ,

      lstrUser  : ''  ,

      lstrParam1  : ''  ,
      lstrParam2  : ''  ,
      lstrParam3  : ''  ,
      lstrParam4  : ''  ,
      lstrParam5  : ''  ,
      lstrParam6  : ''  ,
      lstrParam7  : ''  ,
      lstrParam8  : ''  ,
      lstrParam9  : ''  ,
      lstrParam10 : ''  ,
      lstrParam11 : ''  ,
      lstrParam12 : ''  ,
      lstrParam13 : ''  ,
      lstrParam14 : ''  ,
      lstrParam15 : ''  ,
      lstrParam16 : ''  ,
      lstrParam17 : ''  ,
      lstrParam18 : ''  ,
      lstrParam19 : ''  ,
      lstrParam20 : ''  ,
      lstrParam21 : ''  ,
      lstrParam22 : ''  ,
      lstrParam23 : ''  ,
      lstrParam24 : ''  ,
      lstrParam25 : ''  ,
      lstrParam26 : ''  ,
      lstrParam27 : ''  ,
      lstrParam28 : ''  ,
      lstrParam29 : ''  ,
      lstrParam30 : ''  ,
      lstrParam31 : ''  ,
      lstrParam32 : ''  ,
      lstrParam33 : ''  ,
      lstrParam34 : ''  ,
      lstrParam35 : ''  ,
      lstrParam36 : ''  ,
      lstrParam37 : ''  ,
      lstrParam38 : ''  ,
      lstrParam39 : ''  ,
      lstrParam40 : ''  ,
      lstrParam41 : ''  ,
      lstrParam42 : ''  ,
      lstrParam43 : ''  ,
      lstrParam44 : ''  ,
      lstrParam45 : ''  ,
      lstrParam46 : ''  ,
      lstrParam47 : ''  ,
      lstrParam48 : ''  ,
      lstrParam49 : ''  ,
      lstrParam50 : ''  ,
       
      lstrParam51 : '', 
      lstrParam52 : '', 
      lstrParam53 : '', 
      lstrParam54 : '', 
      lstrParam55 : '', 
      lstrParam56 : '', 
      lstrParam57 : '', 
      lstrParam58 : '', 
      lstrParam59 : '', 
      lstrParam60 : '', 
      lstrParam61 : '', 
      lstrParam62 : '', 
      lstrParam63 : '', 
      lstrParam64 : '', 
      lstrParam65 : '', 
      lstrParam66 : '', 
      lstrParam67 : '', 
      lstrParam68 : '', 
      lstrParam69 : '', 
      lstrParam70 : '', 
      lstrParam71 : '', 
      lstrParam72 : '', 
      lstrParam73 : '', 
      lstrParam74 : '', 
      lstrParam75 : '', 
      lstrParam76 : '', 
      lstrParam77 : '', 
      lstrParam78 : '', 
      lstrParam79 : '', 
      lstrParam80 : '', 
      lstrParam81 : '', 
      lstrParam82 : '', 
      lstrParam83 : '', 
      lstrParam84 : '', 
      lstrParam85 : '', 
      lstrParam86 : '', 
      lstrParam87 : '', 
      lstrParam88 : '', 
      lstrParam89 : '', 
      lstrParam90 : '', 
      lstrParam91 : '', 
      lstrParam92 : '', 
      lstrParam93 : '', 
      lstrParam94 : '', 
      lstrParam95 : '', 
      lstrParam96 : '', 
      lstrParam97 : '', 
      lstrParam98 : '', 
      lstrParam99 : '', 
      lstrParam100  : '', 
      lstrParam101  : '', 
      lstrParam102  : '', 
      lstrParam103  : '', 
      lstrParam104  : '', 
      lstrParam105  : '', 
      lstrParam106  : '', 
      lstrParam107  : '', 
      lstrParam108  : '', 
      lstrParam109  : '', 
      lstrParam110  : '', 
      lstrParam111  : '', 
      lstrParam112  : '', 
      lstrParam113  : '', 
      lstrParam114  : '', 
      lstrParam115  : '', 
      lstrParam116  : '', 
      lstrParam117  : '', 
      lstrParam118  : '', 
      lstrParam119  : '', 
      lstrParam120  : '', 
      lstrParam121  : '', 
      lstrParam122  : '', 
      lstrParam123  : '', 
      lstrParam124  : '', 
      lstrParam125  : '', 
      lstrParam126  : '', 
      lstrParam127  : '', 
      lstrParam128  : '', 
      lstrParam129  : '', 
      lstrParam130  : '', 
      lstrParam131  : '', 
      lstrParam132  : '', 
      lstrParam133  : '', 
      lstrParam134  : '', 
      lstrParam135  : '', 
      lstrParam136  : '', 
      lstrParam137  : '', 
      lstrParam138  : '', 
      lstrParam139  : '', 
      lstrParam140  : '', 
      lstrParam141  : '', 
      lstrParam142  : '', 
      lstrParam143  : '', 
      lstrParam144  : '', 
      lstrParam145  : '', 
      lstrParam146  : '', 
      lstrParam147  : '', 
      lstrParam148  : '', 
      lstrParam149  : '', 
      lstrParam150  : '', 
      lstrParam151  : '', 
      lstrParam152  : '', 
      lstrParam153  : '', 
      lstrParam154  : '', 
      lstrParam155  : '', 
      lstrParam156  : '', 
      lstrParam157  : '', 
      lstrParam158  : '', 
      lstrParam159  : '', 
      lstrParam160  : '', 
      lstrParam161  : '', 
      lstrParam162  : '', 
      lstrParam163  : '', 
      lstrParam164  : '', 
      lstrParam165  : '', 
      lstrParam166  : '', 
      lstrParam167  : '', 
      lstrParam168  : '', 
      lstrParam169  : '', 
      lstrParam170  : '', 
      lstrParam171  : '', 
      lstrParam172  : '', 
      lstrParam173  : '', 
      lstrParam174  : '', 
      lstrParam175  : '', 
      lstrParam176  : '', 
      lstrParam177  : '', 
      lstrParam178  : '', 
      lstrParam179  : '', 
      lstrParam180  : '', 
      lstrParam181  : '', 
      lstrParam182  : '', 
      lstrParam183  : '', 
      lstrParam184  : '', 
      lstrParam185  : '', 
      lstrParam186  : '', 
      lstrParam187  : '', 
      lstrParam188  : '', 
      lstrParam189  : '', 
      lstrParam190  : '', 
      lstrParam191  : '', 
      lstrParam192  : '', 
      lstrParam193  : '', 
      lstrParam194  : '', 
      lstrParam195  : '', 
      lstrParam196  : '', 
      lstrParam197  : '', 
      lstrParam198  : '', 
      lstrParam199  : '', 
      lstrParam200  : '', 
          lstrSearch :'',
      lstrSortCol :1,
      lbSortAsc:"Y", 
      lstrShowlist:"N"
  }
  return ls_tmp;
}

calcWorkHours(a_from,a_to){
  a_from = "01/01/1990 " + a_from;
  a_to = "01/01/1990 " + a_to;

  if(a_from && a_to)
  {
      var ms = moment(a_to,"DD/MM/YYYY HH:mm:ss").diff(moment(a_from,"DD/MM/YYYY HH:mm:ss"));
      var d = moment.duration(ms);
      var sess1=+(d.hours().toString().padStart(2, '0') +'.' + d.minutes().toString().padStart(2, '0'));     
      return sess1;
  }
}

calcWorkHoursWithBreak(a_from,a_to,a_from_break,a_to_break,b_from,b_to){
  a_from = "01/01/1990 " + a_from;
  a_to = "01/01/1990 " + a_to;

  a_from_break = "01/01/1990 " + a_from_break;
  a_to_break = "01/01/1990 " + a_to_break;

  b_from = "01/01/1990 " + b_from;
  b_to = "01/01/1990 " + b_to;

  if(a_from && a_to)
  {
      var ms = moment(a_to,"DD/MM/YYYY HH:mm:ss").diff(moment(a_from,"DD/MM/YYYY HH:mm:ss"));
      var d = moment.duration(ms);
      var sess1=+(d.hours().toString().padStart(2, '0') +'.' + d.minutes().toString().padStart(2, '0'));     

      if(a_from_break && a_to_break)
      {
          var ms = moment(a_to_break,"DD/MM/YYYY HH:mm:ss").diff(moment(a_from_break,"DD/MM/YYYY HH:mm:ss"));
          var d = moment.duration(ms);
          var brk=+(d.hours().toString().padStart(2, '0')+'.' + d.minutes().toString().padStart(2, '0'));
      }

      if(b_from && b_to)
      {
          var ms = moment(b_to,"DD/MM/YYYY HH:mm:ss").diff(moment(b_from,"DD/MM/YYYY HH:mm:ss"));
          var d = moment.duration(ms);
          var sess2=+(d.hours().toString().padStart(2, '0')+'.' + d.minutes().toString().padStart(2, '0'));
      }
      return sess1 - brk + sess2;
  }
}

validateTime(newTime)
{
    if(newTime){
        newTime=newTime.substring(11,16)
        if(newTime.substr(0,2) > 24) {return false;}
        else if((newTime.substr(0,2) == 24) && (newTime.substr(3,2) > 0)){return false;}
        else if((newTime.substr(0,2) < 24) && (newTime.substr(3,2) > 59)){return false;}
        else {return true;}
    }else{
      return false;
    }
}
getLastDateOfMonth(year:number,month:number)
{
    switch(month)
    {
        case 1: case 3: case 5: case 7: case 8: case 10: case 12:{
          return 31;
          break;
        }
        case 2:{
          if(year % 4 == 0){
              return 29;
          }else{
              return 28;
          }
          break;
        }
        case 4: case 6: case 9: case 11:{
          return 30;
          break;
        }
    }
}

isNumeric(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

isNull(n){
  return (!isNaN(parseFloat(n))) && isFinite(n) ? n : 0;
}

setProperIndex(index,pageIndex,pageSize){
  index=(index) + (pageIndex * pageSize)
  return index;
}

validate12digits(srcText,leftDigits,RightDigits)
{
    if(srcText){
      let totDigits=leftDigits+RightDigits+1;
      let errFlag:boolean=false;
      if(srcText.toString().indexOf(".") >0){
        if((srcText.split(".").length - 1) > 1){
            errFlag=true;
        }
        if(((srcText.split(".").length - 1) < 0)){
            if(srcText.length > totDigits){
            errFlag=true;
            }
        }
        else
        {
            let arrText=srcText.split(".")
            let leftSide=arrText[0];
            let rightSide=arrText[1];
            
            if(leftSide){
                if(leftSide.length > leftDigits){ errFlag=true;}
                if(!this.isNumeric(leftSide)){ errFlag=true;}
            }
            if(rightSide){
                if(rightSide.length > RightDigits){ errFlag=true;}
                if(!this.isNumeric(rightSide)){ errFlag=true;}
            }
        }
      }
      else
      {
          if(srcText.length > leftDigits){ errFlag=true;}
          if(!this.isNumeric(srcText)){ errFlag=true;}
      }

      if(errFlag==true)
      {
          console.log(this.f_showmessage("Info",'invalid input'));
          return false;
      }
  }
}

trimming_fn(x) {    
  return x ? x.replace(/^\s+|\s+$/gm, '') : '';    
};    

validateYear(year){
  if(!this.isNumeric(year) || year < 2002 || year > 2100) { 
      this.f_showmessage("Info","Invalid Year")
      return false;
  }
}

checkEmail(str):boolean
{
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!re.test(str))
    {
        return false            
    }
}

}

