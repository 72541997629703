const mockMenuIcons = [
  
    {
      id: '02',
      style: { backgroundColor: '#0082D7' },
      icon: 'assets/images/menu-icons/02.png'
    },
    {
      id: '03',
      style: { backgroundColor: '#0b4c0b' },
      icon: 'assets/images/menu-icons/03.png'
    },
    {
      id: '04',
      style: { backgroundColor: '#000033' },
      icon: 'assets/images/menu-icons/04.png'
    },
    {
      id: '05',
      style: { backgroundColor: '#DB0031' },
      icon: 'assets/images/menu-icons/05.png'
    },
    {
      id: '06',
      style: { backgroundColor: '#39043d' },
      icon: 'assets/images/menu-icons/06.png'
    },
    {
      id: '07',
      style: { backgroundColor: '#134945' },
      icon: 'assets/images/menu-icons/07.png'
    },
    {
      id: '08',
      style: { backgroundColor: '#e0aa00' },
      icon: 'assets/images/menu-icons/08.png'
    },
    {
      id: '09',
      style: { backgroundColor: '#4f3307' },
      icon: 'assets/images/menu-icons/09.png'
    },
    {
      id: '10',
      style: { backgroundColor: '#5C198C' },
      icon: 'assets/images/menu-icons/10.png'
    },
    {
      id: '11',
      style: { backgroundColor: '#464747' },
      icon: 'assets/images/menu-icons/11.png'
    },
    {
      id: '12',
      style: { backgroundColor: '#FF940D' },
      icon: 'assets/images/menu-icons/12.png'
    },
    {
      id: '13',
      style: { backgroundColor: '#262626'},
      icon: 'assets/images/menu-icons/13.png'
    },
    {
      id: '14',
      style: { backgroundColor: '#3f0411' },
      icon: 'assets/images/menu-icons/14.png'
    },
    {
      id: '16',
      style: { backgroundColor: '#300f2d' },
      icon: 'assets/images/menu-icons/16.png'
    },
    {
      id: '17',
      style: { backgroundColor: '#000033' },
      icon: 'assets/images/menu-icons/17.png'
    },
    {
      id: '18',
      style: { backgroundColor: '#871d12' },
      icon: 'assets/images/menu-icons/18.png'
    },
    {
      id: '19',
     style: { backgroundColor: '#026c91'},
      icon: 'assets/images/menu-icons/19.png'
    },
  ];
  
  export default mockMenuIcons;
  