

/**
 * Shijilesh 17/07/2017 
 * ePROMIS  
 **/

import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';  
import { iButtons } from './BaseWindowbuttons.data'; 

@Component({ selector: 'basewindowbuttons',  templateUrl: './BaseWindowbuttons.component.html',styleUrls: ['BaseWindowbuttons.component.css'], })

export class BaseWindowbuttons implements OnInit {
  
    @Input() libutton:iButtons;
    @Output() ld_ue_exit = new EventEmitter<string>();
    @Output() ld_ue_new = new EventEmitter<string>();
    @Output() ld_ue_first = new EventEmitter<string>();
    @Output() ld_ue_last = new EventEmitter<string>();
    @Output() ld_ue_next = new EventEmitter<string>();
    @Output() ld_ue_prior = new EventEmitter<string>();
    @Output() ld_ue_save = new EventEmitter<string>();
    @Output() ld_ue_delete = new EventEmitter<string>();
    @Output() ld_ue_print = new EventEmitter<string>();
    @Output() ld_ue_listing = new EventEmitter<string>();
    @Output() ld_ue_cheque = new EventEmitter<string>();
    @Output() ld_ue_void = new EventEmitter<string>();
    @Output() ld_ue_approve = new EventEmitter<string>();
    @Output() ld_ue_status = new EventEmitter<string>();
    @Output() ld_ue_attach = new EventEmitter<string>();
    @Output() ld_ue_browse = new EventEmitter<string>();
    @Output() ld_ue_custom_fields = new EventEmitter<string>();
    @Output() ld_ue_import = new EventEmitter<string>();
    @Output() ld_ue_undo = new EventEmitter<string>();
    @Output() ld_ue_send = new EventEmitter<string>(); 
    @Output() ld_ue_refresh = new EventEmitter<string>();
    @Output() ld_ue_close = new EventEmitter<string>();
    @Output() ld_ue_task = new EventEmitter<string>();
    @Output() ld_ue_reportwriter = new EventEmitter<string>();
    @Output() lb_related = new EventEmitter<string>();
    @Output() lb_help = new EventEmitter<string>();
    ls_first:string
    ls_Previous:string
    ls_Next:string
    ls_Last:string
    ls_New:string
    ls_Save:string
    ls_Browse:string
    ls_Delete:string
    ls_Cancel:string
    ls_Pay:string
    ls_Print:string
    ls_d_report:string
    ls_Listing:string
    ls_Approve:string
    ls_Status:string
    ls_Edit:string
    ls_Submit:string
    ls_Attach:string
    ls_Import:string
    ls_Cash:string
    ls_Refresh:string
    ls_Task:string
    ls_Close:string

    language_code:string

    ngOnInit() {
        this.language_code =localStorage.getItem('language_code')
        if(this.language_code=="AR"){
            this.ls_first="أولا"
            this.ls_Previous="سابق"
            this.ls_Next="التالي"
            this.ls_Last="الاخير"
            this.ls_New="جديد"
            this.ls_Save="يحفظ"
            this.ls_Browse="تصفح"
            this.ls_Delete="حذف"
            this.ls_Cancel="يلغي"
            this.ls_Pay="يدفع"
            this.ls_Print="مطبعة"
            this.ls_d_report="D.نقل"
            this.ls_Listing="قائمة"
            this.ls_Approve="يوافق" 
            this.ls_Status="حالة"
            this.ls_Edit="يحرر"
            this.ls_Submit="يقدم"
            this.ls_Attach="يربط"
            this.ls_Import="يستورد"
            this.ls_Cash="نقدي"
            this.ls_Refresh="ينعش" 
            this.ls_Task="مهمة"
            this.ls_Close="يغلق" 
        }
        else{
            this.ls_first="First"
            this.ls_Previous="Previous"
            this.ls_Next="Next"
            this.ls_Last="Last"
            this.ls_New="New"
            this.ls_Save="Save"
            this.ls_Browse="Browse"
            this.ls_Delete="Delete"
            this.ls_Cancel="Cancel"
            this.ls_Pay="Pay"
            this.ls_Print="Print"

            this.ls_d_report="D.Report"
            this.ls_Listing="Listing"
            this.ls_Approve="Approve" 
            this.ls_Status="Status"
            this.ls_Edit="Edit"
            this.ls_Submit="Submit"
            this.ls_Attach="Attach"
            this.ls_Import="Import"
            this.ls_Cash="Cash"
            this.ls_Refresh="Refresh" 
            this.ls_Task="Task"
            this.ls_Close="Close"
             

        }
    }
   
    ue_new(){this.ld_ue_new.emit('ue_new');}
    ue_first(){this.ld_ue_first.emit('ue_first');}
    ue_last(){this.ld_ue_last.emit('ue_last');}
    ue_next(){this.ld_ue_next.emit('ue_next');}
    ue_prior(){this.ld_ue_prior.emit('ue_prior');}
    ue_save(){this.ld_ue_save.emit('ue_save');}
    ue_delete(){this.ld_ue_delete.emit('ue_delete');}
    ue_print(){this.ld_ue_print.emit('ue_print');}
    ue_listing(){this.ld_ue_listing.emit('ue_listing');}
    ue_cheque(){this.ld_ue_cheque.emit('ue_cheque');}
    ue_void(){this.ld_ue_void.emit('ue_void');}
    ue_approve(){this.ld_ue_approve.emit('ue_approve');}
    ue_status(){this.ld_ue_status.emit('ue_status');}
    ue_attach(){this.ld_ue_attach.emit('ue_attach');}
    ue_browse(){this.ld_ue_browse.emit('ue_browse');}
    ue_custom_fields(){this.ld_ue_custom_fields.emit('ue_custom_fields');}
    ue_import(){this.ld_ue_import.emit('ue_import');}
    ue_undo(){this.ld_ue_undo.emit('ue_undo');}
    ue_send(){this.ld_ue_send.emit('ue_send');} 
    ue_refresh(){this.ld_ue_refresh.emit('ue_refresh');}
    ue_close(){this.ld_ue_close.emit('ue_close');}
    ue_task(){this.ld_ue_task.emit('ue_task');}
    ue_reporting(){this.ld_ue_reportwriter.emit('ue_reporting');}
    ue_related(){this.lb_related.emit('ue_related');}
    ue_help(){this.lb_help.emit('ue_help');}
}


