

/**
 * Shijilesh 07/02/2017 
 * ePROMIS  
 *  For Comm. Services
 **/


import { Injectable } from '@angular/core'; 
import { map, catchError } from 'rxjs/operators';

import { BaseService } from './BaseService'; 
import { Observable } from 'rxjs'; 
import { globalData } from './globalData'; 
import { VMUserData, menu_head, UserMaster } from './global.data';
import { VMs_transinfo, s_approval, vm_transactions } from './window/Basewindow.data';
import { VMCommonQuery, VMSearch, VMCommonQueryLarge } from './search/search.data';  
import { HttpClient } from '@angular/common/http';  
import { Router } from '@angular/router';
import { dw_dragdrop } from './dragdropselect/dragdropselect.data';
import { dw_dragdropmastersave, dw_dragdropsave } from './dragdropsave/dragdropsave.data';
import { VMSearchData } from './searchautoclose/searchautoclose.data';
import { searchautocloseComponent } from './searchautoclose/searchautoclose.component'; 
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

import { dw_dyndw_report_column_detail } from './dynreport/dynreport.data';
import { dw_dyndw_report_column_drill } from './dynreportdrill/dynreportdrill.data';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Injectable()
export class UserService extends BaseService {
  
  private access_token  ; 
  constructor(  public config: globalData    , public httpClient: HttpClient,public router: Router,public dialog: MatDialog
    , public _fuseSplashScreenService: FuseSplashScreenService) {  
      super(config  ,router  ); 
     } 

 
 
  login(user): Observable<any> {
     
    let options =  this.getHttpUniurl();
 
    return this.httpClient.post<any>(this.getApi() + 'api/Dynatransactions/token',   "username="+user.email +"&password="+user.password +"&grant_type=password" ,   options)
    .pipe(
      catchError(this.handleError)
    );

  }
  async downloadURLs3(req_no:string,  sr_no:number,file:string):  Promise<string>  {
    return this.getApi()   +'api/vm_transactions/fileshj?req_no=' +  req_no+"&sr_no=" + sr_no +"&file=" +encodeURIComponent(file)
  }

  async downloadURLs3_pop(fy_code:string,trc_code :string, vr_no:string, dsr_no:number):  Promise<string>  {
    return this.getApi()   +'api/vm_transactions/file?fy_code=' +  fy_code+"&trc_code=" + trc_code+"&vr_no=" +vr_no +"&dsr_no="+dsr_no
  }

  // login(user): Observable<boolean> {
  //   try{
  //     alert("getting info")
  //     alert( this.getApi() + 'token' +   "username="+user.email +"&password="+user.password +"&grant_type=password"  )
  //     let headers = new Headers();
  //     headers.append('Content-Type', 'application/x-www-form-urlencoded');
  //     return this.http.post(this.getApi() + 'token',   "username="+user.email +"&password="+user.password +"&grant_type=password" , { headers }     )
  //       .pipe(map(res => res.json()))
  //       .pipe(map(res => {localStorage.setItem('access_token', res.access_token);        
  //         return true;
  //       })); 
  //     }catch(error){
  //       //this.handleError(error)
  //   }
  // }
   
  // loginparm(user: string ,password:string): Observable<boolean> {
  //   try{
  //     let headers = new Headers();
  //     headers.append('Content-Type', 'application/x-www-form-urlencoded');
      
  //     return this.http.post(this.getApi() + 'token',   "username="+user +"&password="+ password +"&grant_type=password" , { headers }     )
  //     .pipe(map(res => res.json()))
  //       .pipe(map(res => { 
  //         localStorage.setItem('access_token', res.access_token);
            
  //         return true;
  //       })); 
  //     }catch(error){
  //       this.handleError(error)
  //   }
  // }
  // async  getMenu2(   ) : Promise<any[]>    {  
  //   try{
  //       let options = this.getHttpClientHeader();
  //       let data=  await this.httpClient.get<any[]>( this.getApi() + 'api/UserData/Menu',   options).pipe(
  //         catchError(this.handleError)
  //       ).toPromise() ;
        
  //       return data; 
  //     }catch(error){
  //       this.handleError(error)
  //     }
  //   } 
 

  getMenu2(   ): Observable<any[] >{ 
    try{
     
      let options = this.getHttpClientHeader();   
      return this.httpClient.get<any[]>( this.getApi() + 'api/UserData/Menu2',  options).pipe(
        
        catchError(this.handleError)
      );
    }catch(error){
      this.handleError(error)
    }
  } 

  getMenu3(   ): Observable<any[] >{ 
    try{
      let options = this.getHttpClientHeader();   
      return this.httpClient.get<any[]>( this.getApi() + 'api/UserData/Menu2',  options).pipe(
        catchError(this.handleError)
      );
    }catch(error){
      this.handleError(error)
    }
  } 

  
  logout(): void { 
      localStorage.removeItem('access_token');    
  }

  loggedIn() { 

    let id_logindate2:Date=new Date();
    this.access_token=localStorage.getItem('access_token')
    let id_logindate:string =localStorage.getItem('logindate')
    let id_logindate1:Date=new Date(id_logindate);
      

    var diff =(id_logindate2.getTime() - id_logindate1.getTime()) / 1000;
    diff /= 60;
  

    if (!localStorage.getItem('access_token')){ 
      return false; 
    }
    else  {return true}
    // else{  
    //   if (diff>600){
    //     alert("your session has expired please login again..")
    //     return false 
    //   }
    //   else  {return true}
      
    // }
      
  }
   
  isLoggedIn() {
    return this.loggedIn;
  }
 

  setGlobalVariables( ): Observable<VMUserData>{  
    try{
      let options = this.getHttpClientHeader();   
      return this.httpClient.get<VMUserData>( this.getApi() +'api/UserData/Userinfo',   options).pipe(
        catchError(this.handleError)
      );
    }catch(error){
      this.handleError(error)
    }

  }
  
  setS_transinfo( ls_menu:string): Promise<VMs_transinfo >  {  
    try{
      let options = this.getHttpClientHeader();   
      return this.httpClient.get<VMs_transinfo>( this.getApi() +'api/FormData/s_transinfo?ls_menu='+ ls_menu,   options).pipe(
        catchError(this.handleError)
      ).toPromise() ;
    }catch(error){
      this.handleError(error)
    }
  }
  
 setS_transinfo_master( ls_menu:string): Observable<VMs_transinfo>{  
    try{
       
      let options = this.getHttpClientHeader();   
      return this.httpClient.get<VMs_transinfo>( this.getApi() +'api/FormData/s_transinfo_master?ls_menu='+ ls_menu,   options).pipe(
        catchError(this.handleError)
      );
    }catch(error){
      this.handleError(error)
    }
  }
  getInLineResult( lVMCommonQuery:VMCommonQuery ): Observable<VMCommonQuery >{  
    try{
      this._fuseSplashScreenService.show()
      let options = this.getHttpClientHeader();   
      
      let data =this.httpClient.post<VMCommonQuery>( this.getApi() +'api/commonQuerys/GetInlineResult',lVMCommonQuery,   options).pipe(
        catchError(this.handleError)
      );
      this._fuseSplashScreenService.hide()
      return data
    }catch(error){
      this._fuseSplashScreenService.hide()
      this.handleError(error)
    }
  } 

  getSearchData( lVMCommonQuery:VMCommonQuery ): Observable<VMCommonQuery[] >{ 
    try{
      this._fuseSplashScreenService.show()
      let options = this.getHttpClientHeader();   
      let data= this.httpClient.post<Array<VMCommonQuery>>( this.getApi() +'api/commonQuerys/GetList', lVMCommonQuery, options).pipe(
        catchError(this.handleError)
      );
      this._fuseSplashScreenService.hide()
      return data
    }catch(error){
      this._fuseSplashScreenService.hide()
      this.handleError(error)
    }
  } 
  getSearchDataDyn( lVMCommonQuery:VMCommonQuery ): Observable<VMCommonQueryLarge[] >{ 
    try{
      this._fuseSplashScreenService.show()
      let options = this.getHttpClientHeader();   
      let data = this.httpClient.post<Array<VMCommonQueryLarge>>( this.getApi() +'api/commonQuerys/GetListDyn', lVMCommonQuery, options).pipe(
        catchError(this.handleError)
      );
      this._fuseSplashScreenService.hide()
      return data 
    }catch(error){
      this._fuseSplashScreenService.hide()
      this.handleError(error)
    }
  } 
  getSearchDataDynu( lVMCommonQuery:VMCommonQuery ): Observable<VMCommonQueryLarge[] >{ 
    try{
      this._fuseSplashScreenService.show()
      let options = this.getHttpClientHeader();   
      let data= this.httpClient.post<Array<VMCommonQueryLarge>>( this.getApi() +'api/commonQuerys/GetListDynu', lVMCommonQuery, options).pipe(
        catchError(this.handleError)
      );
      this._fuseSplashScreenService.hide()
      return data
    }catch(error){
      this._fuseSplashScreenService.hide()
      this.handleError(error)
    }
  } 

  async  getInLineResultAsycDynu( lVMCommonQuery:VMCommonQuery ) : Promise<VMCommonQueryLarge[]>    {  
    try{
      this._fuseSplashScreenService.show()
      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<Array<VMCommonQueryLarge>>( this.getApi() +'api/commonQuerys/GetListDynu',lVMCommonQuery,   options).pipe(
        catchError(this.handleError)
      ).toPromise() ;
      this._fuseSplashScreenService.hide()
      return data;
    }catch(error){
      this._fuseSplashScreenService.hide()
      this.handleError(error)
    }
  } 

  getTableDrill( lsdat:dw_dyndw_report_column_drill): Observable<VMCommonQuery[]>{ 
    try{ 
      let options = this.getHttpClientHeader();   
      return this.httpClient.post<Array<VMCommonQuery>>( this.getApi() +'api/dw_dyndw_report_master/Drilldown',lsdat, options).pipe(
        catchError(this.handleError)
      );
    }catch(error){
      this.handleError(error)
    }
  } 
  getTablePro( lReportNo:string ): Observable<dw_dyndw_report_column_detail>{ 
    try{
      let options = this.getHttpClientHeader();   
      return this.httpClient.get<dw_dyndw_report_column_detail>( this.getApi() +'api/commonQuerys/TableProp?lReportNo=' +lReportNo, options).pipe(
        catchError(this.handleError)
      );
    }catch(error){
      this.handleError(error)
    }
  } 

   
   
  async  getSearchDatainline( lVMCommonQuery:VMCommonQuery ) : Promise<VMCommonQuery[]>    {  
    try{ 
      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<Array<VMCommonQuery>>( this.getApi() +'api/commonQuerys/GetList',lVMCommonQuery,   options).pipe(
        catchError(this.handleError)
      ).toPromise() ;
      
      return data;
    }catch(error){
      this.handleError(error)
    }
  } 


  async  getInLineResultAsyclist( lVMCommonQuery:VMCommonQuery ) : Promise<dw_dragdrop[]>    {  
    try{ 
      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<Array<dw_dragdrop>>( this.getApi() +'api/commonQuerys/GetRptlist',lVMCommonQuery,   options).pipe(
        catchError(this.handleError)
      ).toPromise() ;
      
      return data;
    }catch(error){
      this.handleError(error)
    }
  } 

  async  getInLineResultAsyclistDragDrop( lVMCommonQuery:VMCommonQuery ) : Promise<dw_dragdropsave[]>    {  
    try{ 
      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<Array<dw_dragdropsave>>( this.getApi() +'api/commonQuerys/GetDragDSelect',lVMCommonQuery,   options).pipe(
        catchError(this.handleError)
      ).toPromise() ;
      
      return data;
    }catch(error){
      this.handleError(error)
    }
  } 
   
  async  postDragDrop( ldata:  dw_dragdropmastersave ) : Promise<dw_dragdropmastersave>    {  
    try{  
      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<dw_dragdropmastersave>( this.getApi() +'api/commonQuerys/SetDragD',ldata,   options).pipe(
        catchError(this.handleError)
      ).toPromise() ;
      
      return data;
    }catch(error){
      this.handleError(error)
    }
  } 

        
  async  getInLineResultAsyc( lVMCommonQuery:VMCommonQuery ) : Promise<VMCommonQuery>    {  
    try{
      this._fuseSplashScreenService.show()
      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<VMCommonQuery>( this.getApi() +'api/commonQuerys/GetInlineResult',lVMCommonQuery,   options).pipe(
        catchError(this.handleError)
      ).toPromise() ;
      this._fuseSplashScreenService.hide()
      return data;
    }catch(error){
      this._fuseSplashScreenService.hide()
      this.handleError(error)
    }
  } 
  async  getInLineResultAsyclst( lVMCommonQuery:VMCommonQuery ) : Promise<VMCommonQuery[]>    {  
    try{
      this._fuseSplashScreenService.show()
      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<VMCommonQuery[]>( this.getApi() +'api/commonQuerys/GetInlineResult',lVMCommonQuery,   options).pipe(
        catchError(this.handleError)
      ).toPromise() ;
      this._fuseSplashScreenService.hide()
      return data;
    }catch(error){
      this._fuseSplashScreenService.hide()
      this.handleError(error)
    }
  } 

  async  getInLineResultAsycw( lVMCommonQuery:VMCommonQuery ) : Promise<VMCommonQuery>    {  
    try{ 
      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<VMCommonQuery>( this.getApi() +'api/commonQuerys/GetInlineResult',lVMCommonQuery,   options).pipe(
        catchError(this.handleError)
      ).toPromise() ; 
      return data;
    }catch(error){
      this._fuseSplashScreenService.hide()
      this.handleError(error)
    }
  } 
    sendapprovalw( ls_approval:s_approval ): Observable<VMCommonQuery>{ 
    try{ 
      let options = this.getHttpClientHeader();   
      let data= this.httpClient.post<VMCommonQuery>( this.getApi() +'api/Approval/Send', ls_approval, options).pipe(
        catchError(this.handleError)
      ) 
      return data
    }catch(error){ 
      this._fuseSplashScreenService.hide()
      this.handleError(error)
    }
  }  

  sendapproval( ls_approval:s_approval ): Observable<VMCommonQuery>{ 
    try{
      this._fuseSplashScreenService.show()
      let options = this.getHttpClientHeader();   
      let data= this.httpClient.post<VMCommonQuery>( this.getApi() +'api/Approval/Send', ls_approval, options).pipe(
        catchError(this.handleError)
      )
      this._fuseSplashScreenService.hide()
      return data
    }catch(error){ 
      this._fuseSplashScreenService.hide()
      this.handleError(error)
    }
  } 
  approve( ls_approval:s_approval ): Observable<VMCommonQuery>{ 
    try{
      this._fuseSplashScreenService.show()
      let options = this.getHttpClientHeader();   
      let data= this.httpClient.post<VMCommonQuery>( this.getApi() +'api/Approval/approvedoc', ls_approval, options).pipe(
        catchError(this.handleError)
      )
      this._fuseSplashScreenService.hide()
      return data
    }catch(error){
      this._fuseSplashScreenService.hide()
      this.handleError(error)
    }
  }
  
  
  async getSearchResults(lVMSearch:VMSearch): Promise<VMCommonQuery>  {
       
    let ls_VMCommonQuery :VMCommonQuery =this.setInis_dynasearch();

    let lsSearchData:VMSearchData = this.iniVMSearchData(); 
    //lsSearchData.VMSearchs.push(lVMSearch)
    lsSearchData.VMSearchs.push(this.setIni_lVMSearch());
    lsSearchData.VMSearchs[0]=lVMSearch
    ls_VMCommonQuery.lstrSQL=lVMSearch.lstrSQL 
    ls_VMCommonQuery.lstrUser=lVMSearch.lstrUser
    ls_VMCommonQuery.lstrParam1=lVMSearch.lstrParam1
    ls_VMCommonQuery.lstrParam2=lVMSearch.lstrParam2
    ls_VMCommonQuery.lstrParam3=lVMSearch.lstrParam3
    ls_VMCommonQuery.lstrParam4=lVMSearch.lstrParam4
    ls_VMCommonQuery.lstrParam5=lVMSearch.lstrParam5
    ls_VMCommonQuery.lstrParam6=lVMSearch.lstrParam6
    ls_VMCommonQuery.lstrParam7=lVMSearch.lstrParam7
    ls_VMCommonQuery.lstrParam8=lVMSearch.lstrParam8
    ls_VMCommonQuery.lstrParam9=lVMSearch.lstrParam9
    ls_VMCommonQuery.lstrParam10=lVMSearch.lstrParam10
    ls_VMCommonQuery.lstrParam11=lVMSearch.lstrParam11
    ls_VMCommonQuery.lstrParam12=lVMSearch.lstrParam12
    ls_VMCommonQuery.lstrParam13=lVMSearch.lstrParam13
    ls_VMCommonQuery.lstrParam14=lVMSearch.lstrParam14
    ls_VMCommonQuery.lstrParam15=lVMSearch.lstrParam15
    ls_VMCommonQuery.lstrParam16=lVMSearch.lstrParam16
    ls_VMCommonQuery.lstrParam17=lVMSearch.lstrParam17
    ls_VMCommonQuery.lstrParam18=lVMSearch.lstrParam18
    ls_VMCommonQuery.lstrParam19=lVMSearch.lstrParam19
    ls_VMCommonQuery.lstrParam20=lVMSearch.lstrParam20
    ls_VMCommonQuery.lstrParam21=lVMSearch.lstrParam21
    ls_VMCommonQuery.lstrParam22=lVMSearch.lstrParam22
    ls_VMCommonQuery.lstrParam23=lVMSearch.lstrParam23
    ls_VMCommonQuery.lstrParam24=lVMSearch.lstrParam24
    ls_VMCommonQuery.lstrParam25=lVMSearch.lstrParam25
    ls_VMCommonQuery.lstrParam26=lVMSearch.lstrParam26
    ls_VMCommonQuery.lstrParam27=lVMSearch.lstrParam27
    ls_VMCommonQuery.lstrParam28=lVMSearch.lstrParam28
    ls_VMCommonQuery.lstrParam29=lVMSearch.lstrParam29
    ls_VMCommonQuery.lstrParam30=lVMSearch.lstrParam30
    ls_VMCommonQuery.lstrParam31=lVMSearch.lstrParam31
    ls_VMCommonQuery.lstrParam32=lVMSearch.lstrParam32
    ls_VMCommonQuery.lstrParam33=lVMSearch.lstrParam33
    ls_VMCommonQuery.lstrParam34=lVMSearch.lstrParam34
    ls_VMCommonQuery.lstrParam35=lVMSearch.lstrParam35
    ls_VMCommonQuery.lstrParam36=lVMSearch.lstrParam36
    ls_VMCommonQuery.lstrParam37=lVMSearch.lstrParam37
    ls_VMCommonQuery.lstrParam38=lVMSearch.lstrParam38
    ls_VMCommonQuery.lstrParam39=lVMSearch.lstrParam39
    ls_VMCommonQuery.lstrParam40=lVMSearch.lstrParam40
    ls_VMCommonQuery.lstrParam41=lVMSearch.lstrParam41
    ls_VMCommonQuery.lstrParam42=lVMSearch.lstrParam42
    ls_VMCommonQuery.lstrParam43=lVMSearch.lstrParam43
    ls_VMCommonQuery.lstrParam44=lVMSearch.lstrParam44
    ls_VMCommonQuery.lstrParam45=lVMSearch.lstrParam45
    ls_VMCommonQuery.lstrParam46=lVMSearch.lstrParam46
    ls_VMCommonQuery.lstrParam47=lVMSearch.lstrParam47
    ls_VMCommonQuery.lstrParam48=lVMSearch.lstrParam48
    ls_VMCommonQuery.lstrParam49=lVMSearch.lstrParam49
    ls_VMCommonQuery.lstrParam50=lVMSearch.lstrParam50   
    ls_VMCommonQuery.lstrParam51=lVMSearch.lstrParam51
    ls_VMCommonQuery.lstrParam52	=lVMSearch.lstrParam52
    ls_VMCommonQuery.lstrParam53	=lVMSearch.lstrParam53
    ls_VMCommonQuery.lstrParam54	=lVMSearch.lstrParam54 
    ls_VMCommonQuery.lstrParam55	=lVMSearch.lstrParam55
    ls_VMCommonQuery.lstrParam56	=lVMSearch.lstrParam56
    ls_VMCommonQuery.lstrParam57	=lVMSearch.lstrParam57
    ls_VMCommonQuery.lstrParam58	=lVMSearch.lstrParam58
    ls_VMCommonQuery.lstrParam59	=lVMSearch.lstrParam59
    ls_VMCommonQuery.lstrParam60	=lVMSearch.lstrParam60
    ls_VMCommonQuery.lstrParam61	=lVMSearch.lstrParam61
    ls_VMCommonQuery.lstrParam62	=lVMSearch.lstrParam62
    ls_VMCommonQuery.lstrParam63	=lVMSearch.lstrParam63
    ls_VMCommonQuery.lstrParam64	=lVMSearch.lstrParam64
    ls_VMCommonQuery.lstrParam65	=lVMSearch.lstrParam65
    ls_VMCommonQuery.lstrParam66	=lVMSearch.lstrParam66
    ls_VMCommonQuery.lstrParam67	=lVMSearch.lstrParam67
    ls_VMCommonQuery.lstrParam68	=lVMSearch.lstrParam68
    ls_VMCommonQuery.lstrParam69	=lVMSearch.lstrParam69
    ls_VMCommonQuery.lstrParam70	=lVMSearch.lstrParam70
    ls_VMCommonQuery.lstrParam71	=lVMSearch.lstrParam71
    ls_VMCommonQuery.lstrParam72	=lVMSearch.lstrParam72
    ls_VMCommonQuery.lstrParam73	=lVMSearch.lstrParam73
    ls_VMCommonQuery.lstrParam74	=lVMSearch.lstrParam74
    ls_VMCommonQuery.lstrParam75	=lVMSearch.lstrParam75
    ls_VMCommonQuery.lstrParam76	=lVMSearch.lstrParam76
    ls_VMCommonQuery.lstrParam77	=lVMSearch.lstrParam77
    ls_VMCommonQuery.lstrParam78	=lVMSearch.lstrParam78
    ls_VMCommonQuery.lstrParam79	=lVMSearch.lstrParam79
    ls_VMCommonQuery.lstrParam80	=lVMSearch.lstrParam80
    ls_VMCommonQuery.lstrParam81	=lVMSearch.lstrParam81
    ls_VMCommonQuery.lstrParam82	=lVMSearch.lstrParam82
    ls_VMCommonQuery.lstrParam83	=lVMSearch.lstrParam83
    ls_VMCommonQuery.lstrParam84	=lVMSearch.lstrParam84
    ls_VMCommonQuery.lstrParam85	=lVMSearch.lstrParam85
    ls_VMCommonQuery.lstrParam86	=lVMSearch.lstrParam86
    ls_VMCommonQuery.lstrParam87	=lVMSearch.lstrParam87
    ls_VMCommonQuery.lstrParam88	=lVMSearch.lstrParam88
    ls_VMCommonQuery.lstrParam89	=lVMSearch.lstrParam89
    ls_VMCommonQuery.lstrParam90	=lVMSearch.lstrParam90
    ls_VMCommonQuery.lstrParam91	=lVMSearch.lstrParam91
    ls_VMCommonQuery.lstrParam92	=lVMSearch.lstrParam92
    ls_VMCommonQuery.lstrParam93	=lVMSearch.lstrParam93
    ls_VMCommonQuery.lstrParam94	=lVMSearch.lstrParam94
    ls_VMCommonQuery.lstrParam95	=lVMSearch.lstrParam95
    ls_VMCommonQuery.lstrParam96	=lVMSearch.lstrParam96
    ls_VMCommonQuery.lstrParam97	=lVMSearch.lstrParam97
    ls_VMCommonQuery.lstrParam98	=lVMSearch.lstrParam98
    ls_VMCommonQuery.lstrParam99	=lVMSearch.lstrParam99
    ls_VMCommonQuery.lstrParam100	=lVMSearch.lstrParam100
    ls_VMCommonQuery.lstrParam101	=lVMSearch.lstrParam101
    ls_VMCommonQuery.lstrParam102	=lVMSearch.lstrParam102
    ls_VMCommonQuery.lstrParam103	=lVMSearch.lstrParam103
    ls_VMCommonQuery.lstrParam104	=lVMSearch.lstrParam104
    ls_VMCommonQuery.lstrParam105	=lVMSearch.lstrParam105
    ls_VMCommonQuery.lstrParam106	=lVMSearch.lstrParam106
    ls_VMCommonQuery.lstrParam107	=lVMSearch.lstrParam107
    ls_VMCommonQuery.lstrParam108	=lVMSearch.lstrParam108
    ls_VMCommonQuery.lstrParam109	=lVMSearch.lstrParam109
    ls_VMCommonQuery.lstrParam110	=lVMSearch.lstrParam110
    ls_VMCommonQuery.lstrParam111	=lVMSearch.lstrParam111
    ls_VMCommonQuery.lstrParam112	=lVMSearch.lstrParam112
    ls_VMCommonQuery.lstrParam113	=lVMSearch.lstrParam113
    ls_VMCommonQuery.lstrParam114	=lVMSearch.lstrParam114
    ls_VMCommonQuery.lstrParam115	=lVMSearch.lstrParam115
    ls_VMCommonQuery.lstrParam116	=lVMSearch.lstrParam116
    ls_VMCommonQuery.lstrParam117	=lVMSearch.lstrParam117
    ls_VMCommonQuery.lstrParam118	=lVMSearch.lstrParam118
    ls_VMCommonQuery.lstrParam119	=lVMSearch.lstrParam119
    ls_VMCommonQuery.lstrParam120	=lVMSearch.lstrParam120
    ls_VMCommonQuery.lstrParam121	=lVMSearch.lstrParam121
    ls_VMCommonQuery.lstrParam122	=lVMSearch.lstrParam122
    ls_VMCommonQuery.lstrParam123	=lVMSearch.lstrParam123
    ls_VMCommonQuery.lstrParam124	=lVMSearch.lstrParam124
    ls_VMCommonQuery.lstrParam125	=lVMSearch.lstrParam125
    ls_VMCommonQuery.lstrParam126	=lVMSearch.lstrParam126
    ls_VMCommonQuery.lstrParam127	=lVMSearch.lstrParam127
    ls_VMCommonQuery.lstrParam128	=lVMSearch.lstrParam128
    ls_VMCommonQuery.lstrParam129	=lVMSearch.lstrParam129
    ls_VMCommonQuery.lstrParam130	=lVMSearch.lstrParam130
    ls_VMCommonQuery.lstrParam131	=lVMSearch.lstrParam131
    ls_VMCommonQuery.lstrParam132	=lVMSearch.lstrParam132
    ls_VMCommonQuery.lstrParam133	=lVMSearch.lstrParam133
    ls_VMCommonQuery.lstrParam134	=lVMSearch.lstrParam134
    ls_VMCommonQuery.lstrParam135	=lVMSearch.lstrParam135
    ls_VMCommonQuery.lstrParam136	=lVMSearch.lstrParam136
    ls_VMCommonQuery.lstrParam137	=lVMSearch.lstrParam137
    ls_VMCommonQuery.lstrParam138	=lVMSearch.lstrParam138
    ls_VMCommonQuery.lstrParam139	=lVMSearch.lstrParam139
    ls_VMCommonQuery.lstrParam140	=lVMSearch.lstrParam140
    ls_VMCommonQuery.lstrParam141	=lVMSearch.lstrParam141
    ls_VMCommonQuery.lstrParam142	=lVMSearch.lstrParam142
    ls_VMCommonQuery.lstrParam143	=lVMSearch.lstrParam143
    ls_VMCommonQuery.lstrParam144	=lVMSearch.lstrParam144
    ls_VMCommonQuery.lstrParam145	=lVMSearch.lstrParam145
    ls_VMCommonQuery.lstrParam146	=lVMSearch.lstrParam146
    ls_VMCommonQuery.lstrParam147	=lVMSearch.lstrParam147
    ls_VMCommonQuery.lstrParam148	=lVMSearch.lstrParam148
    ls_VMCommonQuery.lstrParam149	=lVMSearch.lstrParam149
    ls_VMCommonQuery.lstrParam150	=lVMSearch.lstrParam150
    ls_VMCommonQuery.lstrParam151	=lVMSearch.lstrParam151
    ls_VMCommonQuery.lstrParam152	=lVMSearch.lstrParam152
    ls_VMCommonQuery.lstrParam153	=lVMSearch.lstrParam153
    ls_VMCommonQuery.lstrParam154	=lVMSearch.lstrParam154
    ls_VMCommonQuery.lstrParam155	=lVMSearch.lstrParam155
    ls_VMCommonQuery.lstrParam156	=lVMSearch.lstrParam156
    ls_VMCommonQuery.lstrParam157	=lVMSearch.lstrParam157
    ls_VMCommonQuery.lstrParam158	=lVMSearch.lstrParam158
    ls_VMCommonQuery.lstrParam159	=lVMSearch.lstrParam159
    ls_VMCommonQuery.lstrParam160	=lVMSearch.lstrParam160
    ls_VMCommonQuery.lstrParam161	=lVMSearch.lstrParam161
    ls_VMCommonQuery.lstrParam162	=lVMSearch.lstrParam162
    ls_VMCommonQuery.lstrParam163	=lVMSearch.lstrParam163
    ls_VMCommonQuery.lstrParam164	=lVMSearch.lstrParam164
    ls_VMCommonQuery.lstrParam165	=lVMSearch.lstrParam165
    ls_VMCommonQuery.lstrParam166	=lVMSearch.lstrParam166
    ls_VMCommonQuery.lstrParam167	=lVMSearch.lstrParam167
    ls_VMCommonQuery.lstrParam168	=lVMSearch.lstrParam168
    ls_VMCommonQuery.lstrParam169	=lVMSearch.lstrParam169
    ls_VMCommonQuery.lstrParam170	=lVMSearch.lstrParam170
    ls_VMCommonQuery.lstrParam171	=lVMSearch.lstrParam171
    ls_VMCommonQuery.lstrParam172	=lVMSearch.lstrParam172
    ls_VMCommonQuery.lstrParam173	=lVMSearch.lstrParam173
    ls_VMCommonQuery.lstrParam174	=lVMSearch.lstrParam174
    ls_VMCommonQuery.lstrParam175	=lVMSearch.lstrParam175
    ls_VMCommonQuery.lstrParam176	=lVMSearch.lstrParam176
    ls_VMCommonQuery.lstrParam177	=lVMSearch.lstrParam177
    ls_VMCommonQuery.lstrParam178	=lVMSearch.lstrParam178
    ls_VMCommonQuery.lstrParam179	=lVMSearch.lstrParam179
    ls_VMCommonQuery.lstrParam180	=lVMSearch.lstrParam180
    ls_VMCommonQuery.lstrParam181	=lVMSearch.lstrParam181
    ls_VMCommonQuery.lstrParam182	=lVMSearch.lstrParam182
    ls_VMCommonQuery.lstrParam183	=lVMSearch.lstrParam183
    ls_VMCommonQuery.lstrParam184	=lVMSearch.lstrParam184
    ls_VMCommonQuery.lstrParam185	=lVMSearch.lstrParam185
    ls_VMCommonQuery.lstrParam186	=lVMSearch.lstrParam186
    ls_VMCommonQuery.lstrParam187	=lVMSearch.lstrParam187
    ls_VMCommonQuery.lstrParam188	=lVMSearch.lstrParam188
    ls_VMCommonQuery.lstrParam189	=lVMSearch.lstrParam189
    ls_VMCommonQuery.lstrParam190	=lVMSearch.lstrParam190
    ls_VMCommonQuery.lstrParam191	=lVMSearch.lstrParam191
    ls_VMCommonQuery.lstrParam192	=lVMSearch.lstrParam192
    ls_VMCommonQuery.lstrParam193	=lVMSearch.lstrParam193
    ls_VMCommonQuery.lstrParam194	=lVMSearch.lstrParam194
    ls_VMCommonQuery.lstrParam195	=lVMSearch.lstrParam195
    ls_VMCommonQuery.lstrParam196	=lVMSearch.lstrParam196
    ls_VMCommonQuery.lstrParam197	=lVMSearch.lstrParam197
    ls_VMCommonQuery.lstrParam198	=lVMSearch.lstrParam198
    ls_VMCommonQuery.lstrParam199	=lVMSearch.lstrParam199
    ls_VMCommonQuery.lstrParam200	=lVMSearch.lstrParam200
    ls_VMCommonQuery.lstrSearch =""
    ls_VMCommonQuery.lstrSortCol=lVMSearch.lstrSortCol
    ls_VMCommonQuery.lbSortAsc=lVMSearch.lbSortAsc 
    lsSearchData.VMCommonQuery =await this.getSearchDatainline( ls_VMCommonQuery)

    if (lsSearchData.VMCommonQuery.length==1){
      return  lsSearchData.VMCommonQuery[0]
    }
    else{
      const dialogConfig = new MatDialogConfig(); 
      dialogConfig.autoFocus = true;
      dialogConfig.height='77%';
      dialogConfig.width='77%';
      dialogConfig.data= lsSearchData
      dialogConfig.disableClose = true;
  
      const dialogRef = this.dialog.open(searchautocloseComponent, dialogConfig);
  
      return  await  dialogRef.afterClosed().toPromise()
   
    }
     

    
  }


  iniVMSearchData(){ 
    
    let ldatDet:  VMSearch[] = [];
    let ldatJob: VMCommonQuery[] = []; 


    

    let ldata: VMSearchData = {
      Result :"", 
      trc_abr:"", 
      selectedrow : 0, 
      VMSearchs:ldatDet,  
      VMCommonQuery:ldatJob
    }
    return ldata
  }

  setIni_lVMSearch(){
    let ls_tmp:  VMSearch= {
        TypeCode	:	''	,
        lstrData1	:	''	,
        lstrData2	:	''	,
        lstrData3	:	''	,
        lstrData4	:	''	,
        lstrData5	:	''	,
        lstrData6	:	''	,
        lstrData7	:	''	,
        lstrData8	:	''	,
        lstrData9	:	''	,
        lstrData10	:	''	,
        lstrData11	:	''	,
        lstrData12	:	''	,
        lstrData13	:	''	,
        lstrData14	:	''	,
        lstrData15	:	''	,
        lstrData16	:	''	,
        lstrData17	:	''	,
        lstrData18	:	''	,
        lstrData19	:	''	,
        lstrData20	:	''	,
        lstrData21	:	''	,
        lstrData22	:	''	,
        lstrData23	:	''	,
        lstrData24	:	''	,
        lstrData25	:	''	,
        lstrData26	:	''	,
        lstrData27	:	''	,
        lstrData28	:	''	,
        lstrData29	:	''	,
        lstrData30	:	''	,
        lstrData31	:	''	,
        lstrData32	:	''	,
        lstrData33	:	''	,
        lstrData34	:	''	,
        lstrData35	:	''	,
        lstrData36	:	''	,
        lstrData37	:	''	,
        lstrData38	:	''	,
        lstrData39	:	''	,
        lstrData40	:	''	,
        lstrData41	:	''	,
        lstrData42	:	''	,
        lstrData43	:	''	,
        lstrData44	:	''	,
        lstrData45	:	''	,
        lstrData46	:	''	,
        lstrData47	:	''	,
        lstrData48	:	''	,
        lstrData49	:	''	,
        lstrData50	:	''	,


        lngColwidth1	:	0	,
        lngColwidth2	:	0	,
        lngColwidth3	:	0	,
        lngColwidth4	:	0	,
        lngColwidth5	:	0	,
        lngColwidth6	:	0	,
        lngColwidth7	:	0	,
        lngColwidth8	:	0	,
        lngColwidth9	:	0	,
        lngColwidth10	:	0	,
        lngColwidth11	:	0	,
        lngColwidth12	:	0	,
        lngColwidth13	:	0	,
        lngColwidth14	:	0	,
        lngColwidth15	:	0	,
        lngColwidth16	:	0	,
        lngColwidth17	:	0	,
        lngColwidth18	:	0	,
        lngColwidth19	:	0	,
        lngColwidth20	:	0	,
        lngColwidth21	:	0	,
        lngColwidth22	:	0	,
        lngColwidth23	:	0	,
        lngColwidth24	:	0	,
        lngColwidth25	:	0	,
        lngColwidth26	:	0	,
        lngColwidth27	:	0	,
        lngColwidth28	:	0	,
        lngColwidth29	:	0	,
        lngColwidth30	:	0	,
        lngColwidth31	:	0	,
        lngColwidth32	:	0	,
        lngColwidth33	:	0	,
        lngColwidth34	:	0	,
        lngColwidth35	:	0	,
        lngColwidth36	:	0	,
        lngColwidth37	:	0	,
        lngColwidth38	:	0	,
        lngColwidth39	:	0	,
        lngColwidth40	:	0	,
        lngColwidth41	:	0	,
        lngColwidth42	:	0	,
        lngColwidth43	:	0	,
        lngColwidth44	:	0	,
        lngColwidth45	:	0	,
        lngColwidth46	:	0	,
        lngColwidth47	:	0	,
        lngColwidth48	:	0	,
        lngColwidth49	:	0	,
        lngColwidth50	:	0	,

        lstrColName1	:	''	,
        lstrColName2	:	''	,
        lstrColName3	:	''	,
        lstrColName4	:	''	,
        lstrColName5	:	''	,
        lstrColName6	:	''	,
        lstrColName7	:	''	,
        lstrColName8	:	''	,
        lstrColName9	:	''	,
        lstrColName10	:	''	,
        lstrColName11	:	''	,
        lstrColName12	:	''	,
        lstrColName13	:	''	,
        lstrColName14	:	''	,
        lstrColName15	:	''	,
        lstrColName16	:	''	,
        lstrColName17	:	''	,
        lstrColName18	:	''	,
        lstrColName19	:	''	,
        lstrColName20	:	''	,
        lstrColName21	:	''	,
        lstrColName22	:	''	,
        lstrColName23	:	''	,
        lstrColName24	:	''	,
        lstrColName25	:	''	,
        lstrColName26	:	''	,
        lstrColName27	:	''	,
        lstrColName28	:	''	,
        lstrColName29	:	''	,
        lstrColName30	:	''	,
        lstrColName31	:	''	,
        lstrColName32	:	''	,
        lstrColName33	:	''	,
        lstrColName34	:	''	,
        lstrColName35	:	''	,
        lstrColName36	:	''	,
        lstrColName37	:	''	,
        lstrColName38	:	''	,
        lstrColName39	:	''	,
        lstrColName40	:	''	,
        lstrColName41	:	''	,
        lstrColName42	:	''	,
        lstrColName43	:	''	,
        lstrColName44	:	''	,
        lstrColName45	:	''	,
        lstrColName46	:	''	,
        lstrColName47	:	''	,
        lstrColName48	:	''	,
        lstrColName49	:	''	,
        lstrColName50	:	''	,

        lstrSQL	:	''	,
        lstrError	:	''	,

        lstrUser	:	''	,

        lstrParam1	:	''	,
        lstrParam2	:	''	,
        lstrParam3	:	''	,
        lstrParam4	:	''	,
        lstrParam5	:	''	,
        lstrParam6	:	''	,
        lstrParam7	:	''	,
        lstrParam8	:	''	,
        lstrParam9	:	''	,
        lstrParam10	:	''	,
        lstrParam11	:	''	,
        lstrParam12	:	''	,
        lstrParam13	:	''	,
        lstrParam14	:	''	,
        lstrParam15	:	''	,
        lstrParam16	:	''	,
        lstrParam17	:	''	,
        lstrParam18	:	''	,
        lstrParam19	:	''	,
        lstrParam20	:	''	,
        lstrParam21	:	''	,
        lstrParam22	:	''	,
        lstrParam23	:	''	,
        lstrParam24	:	''	,
        lstrParam25	:	''	,
        lstrParam26	:	''	,
        lstrParam27	:	''	,
        lstrParam28	:	''	,
        lstrParam29	:	''	,
        lstrParam30	:	''	,
        lstrParam31	:	''	,
        lstrParam32	:	''	,
        lstrParam33	:	''	,
        lstrParam34	:	''	,
        lstrParam35	:	''	,
        lstrParam36	:	''	,
        lstrParam37	:	''	,
        lstrParam38	:	''	,
        lstrParam39	:	''	,
        lstrParam40	:	''	,
        lstrParam41	:	''	,
        lstrParam42	:	''	,
        lstrParam43	:	''	,
        lstrParam44	:	''	,
        lstrParam45	:	''	,
        lstrParam46	:	''	,
        lstrParam47	:	''	,
        lstrParam48	:	''	,
        lstrParam49	:	''	,
        lstrParam50	:	''	,
         
        lstrParam51	:	'', 
        lstrParam52	:	'', 
        lstrParam53	:	'', 
        lstrParam54	:	'', 
        lstrParam55	:	'', 
        lstrParam56	:	'', 
        lstrParam57	:	'', 
        lstrParam58	:	'', 
        lstrParam59	:	'', 
        lstrParam60	:	'', 
        lstrParam61	:	'', 
        lstrParam62	:	'', 
        lstrParam63	:	'', 
        lstrParam64	:	'', 
        lstrParam65	:	'', 
        lstrParam66	:	'', 
        lstrParam67	:	'', 
        lstrParam68	:	'', 
        lstrParam69	:	'', 
        lstrParam70	:	'', 
        lstrParam71	:	'', 
        lstrParam72	:	'', 
        lstrParam73	:	'', 
        lstrParam74	:	'', 
        lstrParam75	:	'', 
        lstrParam76	:	'', 
        lstrParam77	:	'', 
        lstrParam78	:	'', 
        lstrParam79	:	'', 
        lstrParam80	:	'', 
        lstrParam81	:	'', 
        lstrParam82	:	'', 
        lstrParam83	:	'', 
        lstrParam84	:	'', 
        lstrParam85	:	'', 
        lstrParam86	:	'', 
        lstrParam87	:	'', 
        lstrParam88	:	'', 
        lstrParam89	:	'', 
        lstrParam90	:	'', 
        lstrParam91	:	'', 
        lstrParam92	:	'', 
        lstrParam93	:	'', 
        lstrParam94	:	'', 
        lstrParam95	:	'', 
        lstrParam96	:	'', 
        lstrParam97	:	'', 
        lstrParam98	:	'', 
        lstrParam99	:	'', 
        lstrParam100	:	'', 
        lstrParam101	:	'', 
        lstrParam102	:	'', 
        lstrParam103	:	'', 
        lstrParam104	:	'', 
        lstrParam105	:	'', 
        lstrParam106	:	'', 
        lstrParam107	:	'', 
        lstrParam108	:	'', 
        lstrParam109	:	'', 
        lstrParam110	:	'', 
        lstrParam111	:	'', 
        lstrParam112	:	'', 
        lstrParam113	:	'', 
        lstrParam114	:	'', 
        lstrParam115	:	'', 
        lstrParam116	:	'', 
        lstrParam117	:	'', 
        lstrParam118	:	'', 
        lstrParam119	:	'', 
        lstrParam120	:	'', 
        lstrParam121	:	'', 
        lstrParam122	:	'', 
        lstrParam123	:	'', 
        lstrParam124	:	'', 
        lstrParam125	:	'', 
        lstrParam126	:	'', 
        lstrParam127	:	'', 
        lstrParam128	:	'', 
        lstrParam129	:	'', 
        lstrParam130	:	'', 
        lstrParam131	:	'', 
        lstrParam132	:	'', 
        lstrParam133	:	'', 
        lstrParam134	:	'', 
        lstrParam135	:	'', 
        lstrParam136	:	'', 
        lstrParam137	:	'', 
        lstrParam138	:	'', 
        lstrParam139	:	'', 
        lstrParam140	:	'', 
        lstrParam141	:	'', 
        lstrParam142	:	'', 
        lstrParam143	:	'', 
        lstrParam144	:	'', 
        lstrParam145	:	'', 
        lstrParam146	:	'', 
        lstrParam147	:	'', 
        lstrParam148	:	'', 
        lstrParam149	:	'', 
        lstrParam150	:	'', 
        lstrParam151	:	'', 
        lstrParam152	:	'', 
        lstrParam153	:	'', 
        lstrParam154	:	'', 
        lstrParam155	:	'', 
        lstrParam156	:	'', 
        lstrParam157	:	'', 
        lstrParam158	:	'', 
        lstrParam159	:	'', 
        lstrParam160	:	'', 
        lstrParam161	:	'', 
        lstrParam162	:	'', 
        lstrParam163	:	'', 
        lstrParam164	:	'', 
        lstrParam165	:	'', 
        lstrParam166	:	'', 
        lstrParam167	:	'', 
        lstrParam168	:	'', 
        lstrParam169	:	'', 
        lstrParam170	:	'', 
        lstrParam171	:	'', 
        lstrParam172	:	'', 
        lstrParam173	:	'', 
        lstrParam174	:	'', 
        lstrParam175	:	'', 
        lstrParam176	:	'', 
        lstrParam177	:	'', 
        lstrParam178	:	'', 
        lstrParam179	:	'', 
        lstrParam180	:	'', 
        lstrParam181	:	'', 
        lstrParam182	:	'', 
        lstrParam183	:	'', 
        lstrParam184	:	'', 
        lstrParam185	:	'', 
        lstrParam186	:	'', 
        lstrParam187	:	'', 
        lstrParam188	:	'', 
        lstrParam189	:	'', 
        lstrParam190	:	'', 
        lstrParam191	:	'', 
        lstrParam192	:	'', 
        lstrParam193	:	'', 
        lstrParam194	:	'', 
        lstrParam195	:	'', 
        lstrParam196	:	'', 
        lstrParam197	:	'', 
        lstrParam198	:	'', 
        lstrParam199	:	'', 
        lstrParam200	:	'', 
            lstrSearch :'',
        lstrSortCol :1,
        lbSortAsc:"Y", 
        lstrShowlist:"N"
    }
    return ls_tmp;
}

setInis_dynasearch(){
          
        
  let ls_VMCommonQuery:  VMCommonQuery= {
      TypeCode	:	''	,
      lstrData1	:	''	,
      lstrData2	:	''	,
      lstrData3	:	''	,
      lstrData4	:	''	,
      lstrData5	:	''	,
      lstrData6	:	''	,
      lstrData7	:	''	,
      lstrData8	:	''	,
      lstrData9	:	''	,
      lstrData10	:	''	,
      lstrData11	:	''	,
      lstrData12	:	''	,
      lstrData13	:	''	,
      lstrData14	:	''	,
      lstrData15	:	''	,
      lstrData16	:	''	,
      lstrData17	:	''	,
      lstrData18	:	''	,
      lstrData19	:	''	,
      lstrData20	:	''	,
      lstrData21	:	''	,
      lstrData22	:	''	,
      lstrData23	:	''	,
      lstrData24	:	''	,
      lstrData25	:	''	,
      lstrData26	:	''	,
      lstrData27	:	''	,
      lstrData28	:	''	,
      lstrData29	:	''	,
      lstrData30	:	''	,
      lstrData31	:	''	,
      lstrData32	:	''	,
      lstrData33	:	''	,
      lstrData34	:	''	,
      lstrData35	:	''	,
      lstrData36	:	''	,
      lstrData37	:	''	,
      lstrData38	:	''	,
      lstrData39	:	''	,
      lstrData40	:	''	,
      lstrData41	:	''	,
      lstrData42	:	''	,
      lstrData43	:	''	,
      lstrData44	:	''	,
      lstrData45	:	''	,
      lstrData46	:	''	,
      lstrData47	:	''	,
      lstrData48	:	''	,
      lstrData49	:	''	,
      lstrData50	:	''	,

       

      lstrSQL	:	''	,
      lstrError	:	''	,
      
      lstrUser :'',
      lstrParam1	:	''	,
      lstrParam2	:	''	,
      lstrParam3	:	''	,
      lstrParam4	:	''	,
      lstrParam5	:	''	,
      lstrParam6	:	''	,
      lstrParam7	:	''	,
      lstrParam8	:	''	,
      lstrParam9	:	''	,
      lstrParam10	:	''	,
      lstrParam11	:	''	,
      lstrParam12	:	''	,
      lstrParam13	:	''	,
      lstrParam14	:	''	,
      lstrParam15	:	''	,
      lstrParam16	:	''	,
      lstrParam17	:	''	,
      lstrParam18	:	''	,
      lstrParam19	:	''	,
      lstrParam20	:	''	,
      lstrParam21	:	''	,
      lstrParam22	:	''	,
      lstrParam23	:	''	,
      lstrParam24	:	''	,
      lstrParam25	:	''	,
      lstrParam26	:	''	,
      lstrParam27	:	''	,
      lstrParam28	:	''	,
      lstrParam29	:	''	,
      lstrParam30	:	''	,
      lstrParam31	:	''	,
      lstrParam32	:	''	,
      lstrParam33	:	''	,
      lstrParam34	:	''	,
      lstrParam35	:	''	,
      lstrParam36	:	''	,
      lstrParam37	:	''	,
      lstrParam38	:	''	,
      lstrParam39	:	''	,
      lstrParam40	:	''	,
      lstrParam41	:	''	,
      lstrParam42	:	''	,
      lstrParam43	:	''	,
      lstrParam44	:	''	,
      lstrParam45	:	''	,
      lstrParam46	:	''	,
      lstrParam47	:	''	,
      lstrParam48	:	''	,
      lstrParam49	:	''	,
      lstrParam50	:	''	, 

      lstrParam51	:	'', 
      lstrParam52	:	'', 
      lstrParam53	:	'', 
      lstrParam54	:	'', 
      lstrParam55	:	'', 
      lstrParam56	:	'', 
      lstrParam57	:	'', 
      lstrParam58	:	'', 
      lstrParam59	:	'', 
      lstrParam60	:	'', 
      lstrParam61	:	'', 
      lstrParam62	:	'', 
      lstrParam63	:	'', 
      lstrParam64	:	'', 
      lstrParam65	:	'', 
      lstrParam66	:	'', 
      lstrParam67	:	'', 
      lstrParam68	:	'', 
      lstrParam69	:	'', 
      lstrParam70	:	'', 
      lstrParam71	:	'', 
      lstrParam72	:	'', 
      lstrParam73	:	'', 
      lstrParam74	:	'', 
      lstrParam75	:	'', 
      lstrParam76	:	'', 
      lstrParam77	:	'', 
      lstrParam78	:	'', 
      lstrParam79	:	'', 
      lstrParam80	:	'', 
      lstrParam81	:	'', 
      lstrParam82	:	'', 
      lstrParam83	:	'', 
      lstrParam84	:	'', 
      lstrParam85	:	'', 
      lstrParam86	:	'', 
      lstrParam87	:	'', 
      lstrParam88	:	'', 
      lstrParam89	:	'', 
      lstrParam90	:	'', 
      lstrParam91	:	'', 
      lstrParam92	:	'', 
      lstrParam93	:	'', 
      lstrParam94	:	'', 
      lstrParam95	:	'', 
      lstrParam96	:	'', 
      lstrParam97	:	'', 
      lstrParam98	:	'', 
      lstrParam99	:	'', 
      lstrParam100	:	'', 
      lstrParam101	:	'', 
      lstrParam102	:	'', 
      lstrParam103	:	'', 
      lstrParam104	:	'', 
      lstrParam105	:	'', 
      lstrParam106	:	'', 
      lstrParam107	:	'', 
      lstrParam108	:	'', 
      lstrParam109	:	'', 
      lstrParam110	:	'', 
      lstrParam111	:	'', 
      lstrParam112	:	'', 
      lstrParam113	:	'', 
      lstrParam114	:	'', 
      lstrParam115	:	'', 
      lstrParam116	:	'', 
      lstrParam117	:	'', 
      lstrParam118	:	'', 
      lstrParam119	:	'', 
      lstrParam120	:	'', 
      lstrParam121	:	'', 
      lstrParam122	:	'', 
      lstrParam123	:	'', 
      lstrParam124	:	'', 
      lstrParam125	:	'', 
      lstrParam126	:	'', 
      lstrParam127	:	'', 
      lstrParam128	:	'', 
      lstrParam129	:	'', 
      lstrParam130	:	'', 
      lstrParam131	:	'', 
      lstrParam132	:	'', 
      lstrParam133	:	'', 
      lstrParam134	:	'', 
      lstrParam135	:	'', 
      lstrParam136	:	'', 
      lstrParam137	:	'', 
      lstrParam138	:	'', 
      lstrParam139	:	'', 
      lstrParam140	:	'', 
      lstrParam141	:	'', 
      lstrParam142	:	'', 
      lstrParam143	:	'', 
      lstrParam144	:	'', 
      lstrParam145	:	'', 
      lstrParam146	:	'', 
      lstrParam147	:	'', 
      lstrParam148	:	'', 
      lstrParam149	:	'', 
      lstrParam150	:	'', 
      lstrParam151	:	'', 
      lstrParam152	:	'', 
      lstrParam153	:	'', 
      lstrParam154	:	'', 
      lstrParam155	:	'', 
      lstrParam156	:	'', 
      lstrParam157	:	'', 
      lstrParam158	:	'', 
      lstrParam159	:	'', 
      lstrParam160	:	'', 
      lstrParam161	:	'', 
      lstrParam162	:	'', 
      lstrParam163	:	'', 
      lstrParam164	:	'', 
      lstrParam165	:	'', 
      lstrParam166	:	'', 
      lstrParam167	:	'', 
      lstrParam168	:	'', 
      lstrParam169	:	'', 
      lstrParam170	:	'', 
      lstrParam171	:	'', 
      lstrParam172	:	'', 
      lstrParam173	:	'', 
      lstrParam174	:	'', 
      lstrParam175	:	'', 
      lstrParam176	:	'', 
      lstrParam177	:	'', 
      lstrParam178	:	'', 
      lstrParam179	:	'', 
      lstrParam180	:	'', 
      lstrParam181	:	'', 
      lstrParam182	:	'', 
      lstrParam183	:	'', 
      lstrParam184	:	'', 
      lstrParam185	:	'', 
      lstrParam186	:	'', 
      lstrParam187	:	'', 
      lstrParam188	:	'', 
      lstrParam189	:	'', 
      lstrParam190	:	'', 
      lstrParam191	:	'', 
      lstrParam192	:	'', 
      lstrParam193	:	'', 
      lstrParam194	:	'', 
      lstrParam195	:	'', 
      lstrParam196	:	'', 
      lstrParam197	:	'', 
      lstrParam198	:	'', 
      lstrParam199	:	'', 
      lstrParam200	:	'', 
          lstrSearch :'',

      lstrSortCol :1,
      lbSortAsc:"Y",
  }
  return ls_VMCommonQuery;
 }
 
  async getData(paramProduct: vm_transactions): Promise<vm_transactions>{
    try{   
      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<vm_transactions>( this.getApi() +'api/vm_transactions/Get', paramProduct,  options).pipe(
        catchError(this.handleError)
      ).toPromise() ; 
      return data; 
    }catch(error){
      this.handleError(error)
    } 
  }

  // async getDatauaepass(ls_code:string ): Promise<any>{
  //   try{   
  //     let options = this.getHttpClientHeader();
  //     let data=  await this.httpClient.get(  this.getApi() +'api/Dynatransactions/uaepass?code='+ls_code  ,  options).pipe(
  //       catchError(this.handleError)
  //     ).toPromise() ; 
  //     return data; 
  //   }catch(error){
  //     this.handleError(error)
  //   } 
  // }

  getDatauaepass(ls_code:string): Observable<any> {
     
    let options =  this.getHttpUniurl();
 
 
    return this.httpClient.get<any>(this.getApi() +'api/Dynatransactions/uaepass?code='+ls_code  ,  options )
    .pipe(
      catchError(this.handleError)
    );

  }

  getemail(url:string): Observable<any> {
     
    let options =  this.getHttpUniurl();
 
 
    return this.httpClient.get<any>(url ,  options )
    .pipe(
      catchError(this.handleError)
    );

  }
  async getDatau(paramProduct: vm_transactions): Promise<vm_transactions>{
    try{  
      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<vm_transactions>( this.getApi() +'api/vm_transactions/Getu', paramProduct,  options).pipe(
        catchError(this.handleError)
      ).toPromise() ; 
      return data; 
    }catch(error){
      this.handleError(error)
    } 
    
  }

  async postDatau(paramProduct: vm_transactions): Promise<vm_transactions>{ 
    try{  
      let options = this.getHttpClientHeader(); 
      let data=  await this.httpClient.post<vm_transactions>( this.getApi() +'api/vm_transactions/Updateu', paramProduct,  options).pipe(
        catchError(this.handleError)
      ).toPromise() ; 
      return data; 
    }catch(error){
      this.handleError(error)
    } 
  } 
 async tokenuaepass(paramProduct: vm_transactions): Promise<vm_transactions>{ 
    try{  
      let options = this.getHttpClientHeader(); 
      let data=  await this.httpClient.post<vm_transactions>( this.getApi() +'api/Dynatransactions/tokenuaepass', paramProduct,  options).pipe(
        catchError(this.handleError)
      ).toPromise() ; 
      return data; 
    }catch(error){
      this.handleError(error)
    } 
  } 
  async getDataCatalog(paramProduct: vm_transactions): Promise<vm_transactions>{
    try{   

      let options = this.getHttpClientHeader();
      let data=  await this.httpClient.post<vm_transactions>( this.baseURLCatalog +'api/vm_transactions/Getu', paramProduct,  options).pipe(
        catchError(this.handleError)
      ).toPromise() ; 
      return data; 
    }catch(error){
      this.handleError(error)
    } 
  }

  async postData(paramProduct: vm_transactions): Promise<vm_transactions>{ 
    try{  
      let options = this.getHttpClientHeader(); 
      let data=  await this.httpClient.post<vm_transactions>( this.getApi() +'api/vm_transactions/Update', paramProduct,  options).pipe(
        catchError(this.handleError)
      ).toPromise() ; 
      return data; 
    }catch(error){
      this.handleError(error)
    } 
  } 
  async postDatapay(paramProduct: vm_transactions): Promise<vm_transactions>{ 
    try{  
      let options = this.getHttpClientHeader(); 
      let data=  await this.httpClient.post<vm_transactions>( this.getApi() +'api/vm_transactions/Updatepay', paramProduct,  options).pipe(
        catchError(this.handleError)
      ).toPromise() ; 
      return data; 
    }catch(error){
      this.handleError(error)
    } 
  } 
  async postDataEmail(paramProduct: vm_transactions): Promise<vm_transactions>{ 
    try{  
      let options = this.getHttpClientHeader(); 
      let data=  await this.httpClient.post<vm_transactions>( this.getApi() +'api/vm_transactions/email', paramProduct,  options).pipe(
        catchError(this.handleError)
      ).toPromise() ; 
      return data; 
    }catch(error){
      this.handleError(error)
    } 
  } 
 async postDataAtt(paramProduct: vm_transactions): Promise<vm_transactions>{ 
    try{  
      let options = this.getHttpClientHeader(); 
      let data=  await this.httpClient.post<vm_transactions>( this.getApi() +'api/vm_transactions/AttUpdate', paramProduct,  options).pipe(
        catchError(this.handleError)
      ).toPromise() ; 
      return data; 
    }catch(error){
      this.handleError(error)
    } 
  } 
  async postDataAttports(paramProduct: vm_transactions): Promise<vm_transactions>{ 
    try{  
      let options = this.getHttpClientHeader(); 
      let data=  await this.httpClient.post<vm_transactions>( this.getApi() +'api/vm_transactions/AttUpdatePorts', paramProduct,  options).pipe(
        catchError(this.handleError)
      ).toPromise() ; 
      return data; 
    }catch(error){
      this.handleError(error)
    } 
  } 
}


