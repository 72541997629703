
<div class="ui-fluid">
    <div class="ui-g p-grid">
        <div class="ui-g-12">
            <!-- <basewindowbuttons [libutton]="li_Buttons_window" (ld_ue_exit)="ue_exit($event)" (ld_ue_new)="ue_new($event)"
      (ld_ue_first)="ue_first($event)" (ld_ue_last)="ue_last($event)" (ld_ue_next)="ue_next($event)"
      (ld_ue_prior)="ue_prior($event)" (ld_ue_save)="ue_save($event)" (ld_ue_delete)="ue_delete($event)"
      (ld_ue_print)="ue_print($event)" (ld_ue_listing)="ue_listing($event)" (ld_ue_cheque)="ue_cheque($event)"
      (ld_ue_void)="ue_void($event)" (ld_ue_approve)="ue_approve($event)" (ld_ue_status)="ue_status($event)"
      (ld_ue_attach)="ue_attach($event)" (ld_ue_browse)="ue_browse($event)"
      (ld_ue_custom_fields)="ue_custom_fields($event)" (ld_ue_import)="ue_import($event)" (ld_ue_undo)="ue_undo($event)"
      (ld_ue_send)="ue_send($event)" (ld_ue_refresh)="ue_refresh($event)" (ld_ue_close)="ue_close($event) " (ld_ue_task)="ue_task($event)"
      (ld_ue_reportwriter)="ue_reporting($event)"    (lb_related)="ue_related($event)"  (lb_help)="ue_help($event)" >
</basewindowbuttons>  -->

            <!-- <basewindowbuttons [libutton]="li_Buttons_window" 
(ld_ue_exit)="ue_exit($event)"
(ld_ue_new)="ue_new($event)"
(ld_ue_first)="ue_first($event)"
(ld_ue_last)="ue_last($event)"
(ld_ue_next)="ue_next($event)"
(ld_ue_prior)="ue_prior($event)"
(ld_ue_save)="ue_save($event)"
(ld_ue_delete)="ue_delete($event)"
(ld_ue_print)="ue_print($event)"
(ld_ue_listing)="ue_listing($event)"
(ld_ue_cheque)="ue_cheque($event)"
(ld_ue_void)="ue_void($event)"
(ld_ue_approve)="ue_approve($event)"
(ld_ue_status)="ue_status($event)"
(ld_ue_attach)="ue_attach($event)"
(ld_ue_browse)="ue_browse($event)"
(ld_ue_custom_fields)="ue_custom_fields($event)"
(ld_ue_import)="ue_import($event)"
(ld_ue_undo)="ue_undo($event)"
(ld_ue_send)="ue_send($event)" 
(ld_ue_refresh)="ue_refresh($event)"
(ld_ue_close)="ue_close($event)"
></basewindowbuttons>
        </div> -->
    </div>
</div>
 
    
