<mat-toolbar class="p-0 mat-elevation-z1 toptoolbar">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <!-- <img class="logo-icon" src="assets/images/logos/fuse.svg"> -->
                    
                </div>
            </div>
            
            <div class="govtname">
                <!-- <img src="assets/images/logos/govtname.png"> -->
                <h2>{{ls_govt}}</h2>
                <h3>{{ls_auth}}</h3>
            </div>

            <!-- <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation" ></fuse-shortcuts>
            </div> -->

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutAlign="center center"> 
              
                <a href="#/HomeApproval" ><button (click)="homeclick()" mat-button matTooltip="Home" class="massagebtn linkcolor">
                    <mat-icon>home</mat-icon>
                </button></a>

                <button mat-button matTooltip="Home" class="massagebtn linkcolor" *ngIf="ls_sms_pending_count>0 && ls_equ_group=='002039'">
                    <mat-icon class="blinking">sms_failed</mat-icon>
                </button>
               
                <div class="toolbar-separator"></div>
 
            <!--added Language button and popup -->
                 <button mat-button fxHide fxShow.gt-xs
                        class="language-button"
                        [matMenuTriggerFor]="languageMenu">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                        <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                    </div>
                </button> 
    
                <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
    
                    <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                        <span fxLayout="row" fxLayoutAlign="start center">
                            <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                            <span class="iso">{{lang.title}}</span>
                        </span>
                    </button>
    
                </mat-menu>  
                <div class="toolbar-separator"></div>

                        <!--added notification button and popup -->
                        <!-- <button mat-button class="massagebtn" (click)="chatMenuShow()" matTooltip="Notifications">
                            <mat-icon>notifications</mat-icon>
                            <span class="badge-up">10</span>
                        </button> -->

                        <div *ngIf="ls_chatShow" class="chatMenu mat-elevation-z4">
                            <div class="usercontent">
                                <div class="row">
                                    <div class="notification-container">
                                        <h3>Notifications
                                            <mat-icon>drafts</mat-icon>
                                        </h3> 
                                        <div class="notificationscroll"  >
                                            
                                            <mat-accordion>
                                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" *ngFor="let notification of lVMUserData.notificationstitles" >
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            {{notification.subjects}}  
                                                            <!-- - {{notification.lcnt}} -->
                                                            <span class="badge-up2">{{notification.lcnt}}</span>
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <div  class="notifitext" *ngFor="let dataz of  getItems(notification)" >
                                                        <h5 matTooltip="{{dataz.mle_msg}} &#13; {{dataz.fy_code}} - {{dataz.trc_code}} - {{dataz.vr_no}}  &#13; {{dataz.created_date}} " matTooltipPosition="above" matTooltipClass="tooltip"
                                                        (dblclick)="drill(dataz)" >{{dataz.mle_msg}}</h5>
                                                        <span> {{dataz.created_date}}</span>
                                                        <mat-icon class="close" (click)="close(dataz)">close</mat-icon>
                                                    </div> 
                                                   
                                                </mat-expansion-panel>
                                              </mat-accordion>
                        
                                        </div>
                        
                                    </div>
                        
                        
                                </div>
                        
                            </div>
                        
                        </div>

                        <div class="toolbar-separator"></div>

                        <!-- <mat-icon (click)="goprofile()"  class="s-20"  >account_circle</mat-icon>  -->
                        <div class="profileedit" >
                            <img src="assets/images/profile/profile_edit.png" (click)="goprofile()" matTooltip="Edit Profile">
                        </div>

                        <div class="toolbar-separator"></div>

                <div class="user-button" > 
                        <span class="username mr-12 ml-12" fxShow fxShow.gt-sm>{{ls_welcome}} {{ls_userName | slice:0:10 }}</span> 
                </div>

                <button mat-button class="logout"  matTooltip="Logout" (click)="logout()"  >
                    {{ls_logout}} <mat-icon class="s-20"  >exit_to_app</mat-icon> 
                </button>
                
            </div>

            <!-- <div *ngIf="ls_userShow" class="userMenu mat-elevation-z4">
                <div class="row p-12">
                    
                    <div >
                        <button class="mat-menu-item"   >
                            <mat-icon class="hide-text">business</mat-icon>
                            <span > {{lVMUserData.company_name }}</span>
                        </button>
    
                        <button class="mat-menu-item" (click)="email_address_clicked()">
                            <mat-icon class="hide-text">mail</mat-icon>
                            <span> {{lVMUserData.email_address }}</span>
                        </button>
                    </div>
                    <div class="row ">
                        <div class="logout-item">
                           
                            <button class="logout-btn ng-form-sm" (click)="logout()" color="warn" mat-raised-button>
                                <mat-icon>exit_to_app</mat-icon> 
                            </button>
                        </div>
                    
                    </div>
                       
    
                </div>
                
            </div> -->

            <div class="toolbar-separator"></div>

            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

           

            <!-- <div class="toolbar-separator" fxHide fxShow.gt-xs></div> -->

            <!-- <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button> -->

            <!-- <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div> -->

            <!-- <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->

        </div>

    </div>

</mat-toolbar>
