import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators'
import { ConfigService } from '../utils/config.service';
import { JwtHelperService } from '@auth0/angular-jwt'; 

@Injectable()
export class AuthenticationService {
    _baseUrl: string = '';
    authHeader: any;
    constructor(//private http: Http,
         private jwtHelperService: JwtHelperService, private configService: ConfigService) {
        // set token if saved in local storage
        this._baseUrl = configService.getApiURI();
        this.authHeader = configService.getAuthHeaders();
       // alert("AuthenticationService")
    }

    // private options() {

    //     const headers = new Headers({
    //       'Content-Type': 'application/json; charset=utf-8;'
    //       , 'Accept': '*/*'
    //     });
    //     const options = new RequestOptions({ headers: headers });
    //     return options;
    //   }


    // login(user): Observable<boolean> {
    //     return this.http.post(this._baseUrl + 'Users/Authenticate', { UserID:user.email, Password: user.password })
    //     .pipe(map(response => {
    //         let user = response.json();
    //         if (user && user.token) {
    //             localStorage.setItem('id_token', user['token']);
    //         }
    //         return user;
    //     }));      
    // }

  
    logout(): void {
        localStorage.removeItem('vg_userid');
        localStorage.removeItem('access_token');    
        localStorage.removeItem('menu' );
        localStorage.removeItem('gsdata' );
        localStorage.removeItem('language_code' );
        window.location.reload()

        localStorage.removeItem("mem_selectedMenuItem")
        localStorage.removeItem("mem_index" )
    //     localStorage.removeItem('equ_group');
    //     localStorage.removeItem('user_id');
    //     localStorage.removeItem('name');
    //    // localStorage.removeItem('counter', this.lVMUserData.counter.toString());
    //     localStorage.removeItem('fy_code');
    //     localStorage.removeItem('password');
    //     localStorage.removeItem('ac_creat_right');
    //     localStorage.removeItem('is_gpmember');
    //     localStorage.removeItem('security_level');
    //     localStorage.removeItem('save_on_exit');
    //     localStorage.removeItem('doc_expiry_notification');
    //     localStorage.removeItem('cost_int');
    //     localStorage.removeItem('payroll_rights');
    //     localStorage.removeItem('dept_code');
    //    // localStorage.removeItem('password_change_date', this.lVMUserData.password_change_date.toString());
    //     localStorage.removeItem('active_ind');
    //     localStorage.removeItem('theme');
    //     localStorage.removeItem('change_password');
    //     localStorage.removeItem('login_period_applicable');
    //     localStorage.removeItem('login_period');
    //     localStorage.removeItem('profile_code');
    //     localStorage.removeItem('language_code');
    //     localStorage.removeItem('user_ip_address');
    //     localStorage.removeItem('user_ip_restriction');
    //     localStorage.removeItem('acc_code');
    //     localStorage.removeItem('email_address');
    //     localStorage.removeItem('vg_default_company');
    //     localStorage.removeItem('default_co_name');

    //     localStorage.removeItem('vl_serverdate');
    //     localStorage.removeItem('vg_multycurrency');
    //     localStorage.removeItem('vg_cur_code');
    //     localStorage.removeItem('vg_job_analysis_int'); 
    //     localStorage.removeItem('vg_job_analysis_type' );
    //     localStorage.removeItem('vg_internal_entry_indicator');
    //     localStorage.removeItem('vg_qty_dec' );
    //     localStorage.removeItem('vg_rate_dec' );
    //     localStorage.removeItem('vg_amount_dec' );
    //     localStorage.removeItem('vg_start_fydate' );
    //     localStorage.removeItem('vg_end_fydate' );
    //     localStorage.removeItem('vg_verify_must' );
    //     localStorage.removeItem('vg_message_delay' );
    //     localStorage.removeItem('vg_company_name' );
    //     localStorage.removeItem('vg_ddw_acccode' );
    //     localStorage.removeItem('vg_ddw_acccodewithfilter' );
    //     localStorage.removeItem('vg_company_license' );

    }

    loggedIn() {
        // const token: string = this.jwtHelperService.tokenGetter();
        // if (!token) { return false; }
        // const tokenExpired: boolean = this.jwtHelperService.isTokenExpired(token);
        // return !tokenExpired;
        return false;
    }

    // getMenu2(): Observable<any>{ 
    //     //   alert("here");
    //     // let authToken = localStorage.getItem('access_token');
    //     // if (!localStorage.getItem('access_token'))
    //     // {  
             
    //         // if(  this.loginparm("shijiles","1"))
    //         // { 
    //         return this.http.get(  "http://epromis.selfip.net:446/epromisapi/api/UserData/Menu",  this.options())
    //         .pipe(map((response: Response) => <any[]>response.json()));
    //         // }
    //      } 
     
}