<div class="" > 
    <!-- <button  (click)="ue_refresh()" mat-raised-button color="accent"  > 
        <mat-icon>refresh</mat-icon>
        <span class="hide-text"> Refresh  </span>
    </button> -->
    <button  (click)="ue_cancel()" mat-raised-button class="redbtn mb-8">
        <mat-icon>cancel</mat-icon>
        <span class="hide-text"> Cancel  </span>
    </button>  
 
    <p-table #dt [columns]="cols" [value]="lVMCommonQueryresult" [paginator]="true" [rows]="50" [resizableColumns]="true"  >

        <ng-template pTemplate="caption" > 
            <div class="ng-flex flex-rv-row">        
                <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" class="form-control ng-md">
                <i class="material-icons search"> search</i>
             </div>
        </ng-template>
        
        <ng-template pTemplate="header" let-columns>  
            <tr>
                <th   *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn pReorderableColumn class="">
                    {{col.header}}
                    <!-- <p-sortIcon [field]="col.field"></p-sortIcon> -->
                </th>
            </tr>
            <tr>
                <th   *ngFor="let col of columns" [ngSwitch]="col.field" class="ui-fluid ng-md">
                    <input  pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)" class="form-control">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr    [pSelectableRow]="rowData">
                <td *ngFor="let col of columns" (click)="select(rowData)" class="ng-md">
                    {{rowData[col.field]}}
                </td>
            </tr>
        </ng-template>
        <!-- <ng-template pTemplate="body" let-lVMCommonQueryresult  let-rowData let-rowIndex="rowIndex" >
            <tr>
                <td  (click)="select(rowData)">{{lVMCommonQueryresult.lstrData1}} </td>
                <td  (click)="select(rowData) ">{{lVMCommonQueryresult.lstrData2}}</td>
                <td  (click)="select(rowData) ">{{lVMCommonQueryresult.lstrData3}}</td>
                <td  (click)="select(rowData) ">{{lVMCommonQueryresult.lstrData4}}</td>
            </tr>
        </ng-template> -->
    </p-table>
</div>
<!-- 
<div class="page-layout blank p-24" > 

    <mat-form-field    >
        <input [(ngModel)]= "filtertext" name="filtertext"   matInput (keyup)="applyFilter($event.target.value) "  (keydown.enter)="keyenter($event.target.value)" placeholder="Filter">
        
    </mat-form-field> 
    
    <button  (click)="ue_refresh()" mat-raised-button color="accent"  > 
        <mat-icon>refresh</mat-icon>
        <span class="hide-text"> Refresh  </span>
    </button>
        
    <button  (click)="ue_cancel()" mat-raised-button color="accent" >
        <mat-icon>cancel</mat-icon>
        <span class="hide-text"> Cancel  </span>
    </button>


     
   
    <div class="table-wrapper mat-elevation-z8"> 
        <table mat-table [dataSource]="dataSource"    matSort class="mat-elevation-z8"> 
   
            
            <ng-container matColumnDef="lstrData1">
                <div class="space-table">
                    <th [ngStyle]="ngStylemedium" mat-header-cell *matHeaderCellDef> {{lVMSearch.lstrColName1}} </th>
                    <td [ngStyle]="ngStylemedium" mat-cell *matCellDef="let row" (click)="select(row) ">{{row.lstrData1}}     </td>
                </div>
            </ng-container>

            <ng-container matColumnDef="lstrData2">
                <div class="space-table">
                    <th [ngStyle]="ngStylemedium" mat-header-cell *matHeaderCellDef> {{lVMSearch.lstrColName2}} </th>
                    <td [ngStyle]="ngStylemedium" mat-cell *matCellDef="let row" (click)="select(row) ">{{row.lstrData2}}     </td>
                </div>
            </ng-container>
            <ng-container matColumnDef="lstrData3">
                <div class="space-table">
                    <th [ngStyle]="ngStylemedium" mat-header-cell *matHeaderCellDef> {{lVMSearch.lstrColName3}} </th>
                    <td [ngStyle]="ngStylemedium" mat-cell *matCellDef="let row" (click)="select(row) ">{{row.lstrData3}}     </td>
                </div>
            </ng-container>
            <ng-container matColumnDef="lstrData4">
                <div class="space-table">
                    <th [ngStyle]="ngStylemedium" mat-header-cell *matHeaderCellDef> {{lVMSearch.lstrColName4}} </th>
                    <td [ngStyle]="ngStylemedium" mat-cell *matCellDef="let row" (click)="select(row) ">{{row.lstrData4}}     </td>
                </div> 
            </ng-container>
            <ng-container matColumnDef="lstrData5">
                <div class="space-table">
                    <th [ngStyle]="ngStylemedium" mat-header-cell *matHeaderCellDef> {{lVMSearch.lstrColName5}} </th>
                    <td [ngStyle]="ngStylemedium" mat-cell *matCellDef="let row" (click)="select(row) ">{{row.lstrData5}}     </td>
                </div>
            </ng-container>
           

            
            

            <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
            <tr mat-row *matRowDef="let i = index; columns: displayedColumns"  ></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>

   </div> -->


<!-- 
<button   (click)="ue_cancel()" mat-raised-button color="accent" > Cancel </button> 
 <div class="page-layout blank p-24" >  
    
    
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value) "  (keydown.enter)="keyenter($event.target.value)" placeholder="Filter">
    </mat-form-field> 
    
    <mat-table [dataSource]="dataSource" matSort > 
        <ng-container matColumnDef="lstrData1">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{lVMSearch.lstrColName1}} </mat-header-cell>
            <mat-cell *matCellDef="let row" (click)="select(row) "> {{row.lstrData1}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lstrData2">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{lVMSearch.lstrColName2}} </mat-header-cell>
            <mat-cell *matCellDef="let row" (click)="select(row)" > {{row.lstrData2}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lstrData3">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{lVMSearch.lstrColName3}} </mat-header-cell>
            <mat-cell *matCellDef="let row"  (click)="select(row)"  > {{row.lstrData3}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lstrData4">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{lVMSearch.lstrColName4}} </mat-header-cell>
            <mat-cell *matCellDef="let row"  (click)="select(row)"  > {{row.lstrData4}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lstrData5">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{lVMSearch.lstrColName5}} </mat-header-cell>
            <mat-cell *matCellDef="let row"  (click)="select(row)" > {{row.lstrData5}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row> 

    </mat-table>
    <mat-paginator [pageSizeOptions]="[7, 14, 28]" showFirstLastButtons></mat-paginator> 

 </div>   -->