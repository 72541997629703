
/**
 * Shijilesh 27/02/2017 
 * ePROMIS  
 **/

import { Component, Inject, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Injectable, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


import { UserService } from '../userservice';
//import { throwError } from 'rxjs/internal/observable/throwError';
import { Router } from '@angular/router';
import { VMCommonQuery, VMSearch } from '../search/search.data';
import { VMSearchData } from './searchautoclose.data';
 

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';

@Component({
    selector   : 'searchautoclose',
    templateUrl: './searchautoclose.component.html', 
    styleUrls  : ['./searchautoclose.component.scss'], 
}) 
@Injectable()
export class searchautocloseComponent implements OnInit 
{ 

    cols: any[]=[];
    map: {[key:string]:string} = {};  

    ngStyleXXSmall = {
        width: '30px !important',
        'max-width': '30px',
    };
    ngStyleXsmall = {
        width: '70px !important',
        'max-width': '70px',
    }; 
    ngStyleSmall = {
        width: '150px !important',
        'max-width': '150px',
    };
   
    ngStylemedium = {
        width: '250px !important',
        'max-width': '400px',
    };
    ngStylelarge = {
        width: '350px !important',
        'max-width': '500px',
    };

    ngStylenumeric = {
        width: '350px !important',
        'max-width': '500px',
        'text-align': 'right' 
    };

    lVMCommonQuery:VMCommonQuery; 
    lVMCommonQueryarr:VMCommonQuery[]; 
    lVMCommonQueryresult:VMCommonQuery[]; 
    filtertext:string
    lVMSearch:VMSearch;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns = [ 'lstrData1'  ,'lstrData2','lstrData3','lstrData4','lstrData5'];
    
    changeDetection: ChangeDetectionStrategy.OnPush
    dataSource: MatTableDataSource<VMCommonQuery>;
    @ViewChild(MatSort) sort: MatSort;
    //[object Object], [object Object], ?, [object Object], [object Object])
    constructor( 
        private dialogRef: MatDialogRef<searchautocloseComponent>,
        @Inject(MAT_DIALOG_DATA) public data: VMSearchData 
        ) {

       // this.ls_dynstructure = data.description;
      

    //    this.cols = [
    //     { field: 'lstrData1', header: 'lstrData1' },
    //     { field: 'lstrData2', header: 'lstrData2' },
    //     { field: 'lstrData3', header: 'lstrData3' },
    //     { field: 'lstrData4', header: 'lstrData4' }
    //     ];
    //      this.cols=this.cols2 
  
      
         this.lVMSearch=data.VMSearchs[0] ; 
         this.lVMCommonQuery=this.setInis_dynasearch(); 
      
        
    }

    ngOnInit() {
        
        this.f_set_map()
        this.cols = [
                { field: 'lstrData1', header: 'lstrData1' }, 
                ];

      //  console.log(this.lVMSearch) 
        let i: number,i2:number,lrow:number
        lrow= 0
        for (i = 0; i < 20; i++) {  
            i2=i+ 1 
            if(this.map['lstrColName' + i2] =="" || this.map['lstrColName' + i2]==null || typeof this.map['lstrColName' + i2] === 'undefined'){ 
          
            }else{
                lrow =lrow + 1
            }       
        } 
      
        this.cols[lrow] = new Array();
 

        i =this.cols.length
        while (i--) {
            
            this.cols.splice(i, 1)  
        }

        // this.cols.pop() 
        // this.cols.pop()
        // this.cols.pop()
 
      //  alert(lrow)
        for (i = 0; i <  lrow; i++) {
            i2=i+ 1 
            this.cols.push({field:'lstrData'+i2 ,header: this.map['lstrColName' + i2]})  
        }  
   //     console.log(this.cols)
         this.getData( ""); 

    }

    async f_set_map(  ){
       
        this.map['lstrColName1'] = this.lVMSearch.lstrColName1
        this.map['lstrColName2'] = this.lVMSearch.lstrColName2
        this.map['lstrColName3'] = this.lVMSearch.lstrColName3
        this.map['lstrColName4'] = this.lVMSearch.lstrColName4
        this.map['lstrColName5'] = this.lVMSearch.lstrColName5
        this.map['lstrColName6'] = this.lVMSearch.lstrColName6
        this.map['lstrColName7'] = this.lVMSearch.lstrColName7
        this.map['lstrColName8'] = this.lVMSearch.lstrColName8
        this.map['lstrColName9'] = this.lVMSearch.lstrColName9
        this.map['lstrColName10'] = this.lVMSearch.lstrColName10
        this.map['lstrColName11'] = this.lVMSearch.lstrColName11
        this.map['lstrColName12'] = this.lVMSearch.lstrColName12
        this.map['lstrColName13'] = this.lVMSearch.lstrColName13
        this.map['lstrColName14'] = this.lVMSearch.lstrColName14
        this.map['lstrColName15'] = this.lVMSearch.lstrColName15
        this.map['lstrColName16'] = this.lVMSearch.lstrColName16
        this.map['lstrColName17'] = this.lVMSearch.lstrColName17
        this.map['lstrColName18'] = this.lVMSearch.lstrColName18
        this.map['lstrColName19'] = this.lVMSearch.lstrColName19
        this.map['lstrColName20'] = this.lVMSearch.lstrColName20

       
    }
    ue_refresh(){ 
        try{
            this.getData(this.filtertext.trim().toLowerCase());
        }catch(error){
            this.handleError(error)
        }
    }
    getData( lstrSearch:string  ){
        try{ 
            this.lVMCommonQueryresult=this.data.VMCommonQuery 
               // this.cd.markForCheck()
                this.dataSource = new MatTableDataSource(this.data.VMCommonQuery );
                this.dataSource.sort = this.sort;  
                this.dataSource.paginator = this.paginator;  
                setTimeout(() => this.dataSource.paginator = this.paginator);
                
           
                    
                


            // this.lVMCommonQuery.lstrSQL=this.data.lstrSQL 
            // this.lVMCommonQuery.lstrUser=this.data.lstrUser
            // this.lVMCommonQuery.lstrParam1=this.data.lstrParam1
            // this.lVMCommonQuery.lstrParam2=this.data.lstrParam2
            // this.lVMCommonQuery.lstrParam3=this.data.lstrParam3
            // this.lVMCommonQuery.lstrParam4=this.data.lstrParam4
            // this.lVMCommonQuery.lstrParam5=this.data.lstrParam5
            // this.lVMCommonQuery.lstrParam6=this.data.lstrParam6
            // this.lVMCommonQuery.lstrParam7=this.data.lstrParam7
            // this.lVMCommonQuery.lstrParam8=this.data.lstrParam8
            // this.lVMCommonQuery.lstrParam9=this.data.lstrParam9
            // this.lVMCommonQuery.lstrParam10=this.data.lstrParam10
            // this.lVMCommonQuery.lstrParam11=this.data.lstrParam11
            // this.lVMCommonQuery.lstrParam12=this.data.lstrParam12
            // this.lVMCommonQuery.lstrParam13=this.data.lstrParam13
            // this.lVMCommonQuery.lstrParam14=this.data.lstrParam14
            // this.lVMCommonQuery.lstrParam15=this.data.lstrParam15
            // this.lVMCommonQuery.lstrParam16=this.data.lstrParam16
            // this.lVMCommonQuery.lstrParam17=this.data.lstrParam17
            // this.lVMCommonQuery.lstrParam18=this.data.lstrParam18
            // this.lVMCommonQuery.lstrParam19=this.data.lstrParam19
            // this.lVMCommonQuery.lstrParam20=this.data.lstrParam20
            // this.lVMCommonQuery.lstrParam21=this.data.lstrParam21
            // this.lVMCommonQuery.lstrParam22=this.data.lstrParam22
            // this.lVMCommonQuery.lstrParam23=this.data.lstrParam23
            // this.lVMCommonQuery.lstrParam24=this.data.lstrParam24
            // this.lVMCommonQuery.lstrParam25=this.data.lstrParam25
            // this.lVMCommonQuery.lstrParam26=this.data.lstrParam26
            // this.lVMCommonQuery.lstrParam27=this.data.lstrParam27
            // this.lVMCommonQuery.lstrParam28=this.data.lstrParam28
            // this.lVMCommonQuery.lstrParam29=this.data.lstrParam29
            // this.lVMCommonQuery.lstrParam30=this.data.lstrParam30
            // this.lVMCommonQuery.lstrParam31=this.data.lstrParam31
            // this.lVMCommonQuery.lstrParam32=this.data.lstrParam32
            // this.lVMCommonQuery.lstrParam33=this.data.lstrParam33
            // this.lVMCommonQuery.lstrParam34=this.data.lstrParam34
            // this.lVMCommonQuery.lstrParam35=this.data.lstrParam35
            // this.lVMCommonQuery.lstrParam36=this.data.lstrParam36
            // this.lVMCommonQuery.lstrParam37=this.data.lstrParam37
            // this.lVMCommonQuery.lstrParam38=this.data.lstrParam38
            // this.lVMCommonQuery.lstrParam39=this.data.lstrParam39
            // this.lVMCommonQuery.lstrParam40=this.data.lstrParam40
            // this.lVMCommonQuery.lstrParam41=this.data.lstrParam41
            // this.lVMCommonQuery.lstrParam42=this.data.lstrParam42
            // this.lVMCommonQuery.lstrParam43=this.data.lstrParam43
            // this.lVMCommonQuery.lstrParam44=this.data.lstrParam44
            // this.lVMCommonQuery.lstrParam45=this.data.lstrParam45
            // this.lVMCommonQuery.lstrParam46=this.data.lstrParam46
            // this.lVMCommonQuery.lstrParam47=this.data.lstrParam47
            // this.lVMCommonQuery.lstrParam48=this.data.lstrParam48
            // this.lVMCommonQuery.lstrParam49=this.data.lstrParam49
            // this.lVMCommonQuery.lstrParam50=this.data.lstrParam50   
            // this.lVMCommonQuery.lstrParam51=this.data.lstrParam51
            // this.lVMCommonQuery.lstrParam52	=this.data.lstrParam52
            // this.lVMCommonQuery.lstrParam53	=this.data.lstrParam53
            // this.lVMCommonQuery.lstrParam54	=this.data.lstrParam54 
            // this.lVMCommonQuery.lstrParam55	=this.data.lstrParam55
            // this.lVMCommonQuery.lstrParam56	=this.data.lstrParam56
            // this.lVMCommonQuery.lstrParam57	=this.data.lstrParam57
            // this.lVMCommonQuery.lstrParam58	=this.data.lstrParam58
            // this.lVMCommonQuery.lstrParam59	=this.data.lstrParam59
            // this.lVMCommonQuery.lstrParam60	=this.data.lstrParam60
            // this.lVMCommonQuery.lstrParam61	=this.data.lstrParam61
            // this.lVMCommonQuery.lstrParam62	=this.data.lstrParam62
            // this.lVMCommonQuery.lstrParam63	=this.data.lstrParam63
            // this.lVMCommonQuery.lstrParam64	=this.data.lstrParam64
            // this.lVMCommonQuery.lstrParam65	=this.data.lstrParam65
            // this.lVMCommonQuery.lstrParam66	=this.data.lstrParam66
            // this.lVMCommonQuery.lstrParam67	=this.data.lstrParam67
            // this.lVMCommonQuery.lstrParam68	=this.data.lstrParam68
            // this.lVMCommonQuery.lstrParam69	=this.data.lstrParam69
            // this.lVMCommonQuery.lstrParam70	=this.data.lstrParam70
            // this.lVMCommonQuery.lstrParam71	=this.data.lstrParam71
            // this.lVMCommonQuery.lstrParam72	=this.data.lstrParam72
            // this.lVMCommonQuery.lstrParam73	=this.data.lstrParam73
            // this.lVMCommonQuery.lstrParam74	=this.data.lstrParam74
            // this.lVMCommonQuery.lstrParam75	=this.data.lstrParam75
            // this.lVMCommonQuery.lstrParam76	=this.data.lstrParam76
            // this.lVMCommonQuery.lstrParam77	=this.data.lstrParam77
            // this.lVMCommonQuery.lstrParam78	=this.data.lstrParam78
            // this.lVMCommonQuery.lstrParam79	=this.data.lstrParam79
            // this.lVMCommonQuery.lstrParam80	=this.data.lstrParam80
            // this.lVMCommonQuery.lstrParam81	=this.data.lstrParam81
            // this.lVMCommonQuery.lstrParam82	=this.data.lstrParam82
            // this.lVMCommonQuery.lstrParam83	=this.data.lstrParam83
            // this.lVMCommonQuery.lstrParam84	=this.data.lstrParam84
            // this.lVMCommonQuery.lstrParam85	=this.data.lstrParam85
            // this.lVMCommonQuery.lstrParam86	=this.data.lstrParam86
            // this.lVMCommonQuery.lstrParam87	=this.data.lstrParam87
            // this.lVMCommonQuery.lstrParam88	=this.data.lstrParam88
            // this.lVMCommonQuery.lstrParam89	=this.data.lstrParam89
            // this.lVMCommonQuery.lstrParam90	=this.data.lstrParam90
            // this.lVMCommonQuery.lstrParam91	=this.data.lstrParam91
            // this.lVMCommonQuery.lstrParam92	=this.data.lstrParam92
            // this.lVMCommonQuery.lstrParam93	=this.data.lstrParam93
            // this.lVMCommonQuery.lstrParam94	=this.data.lstrParam94
            // this.lVMCommonQuery.lstrParam95	=this.data.lstrParam95
            // this.lVMCommonQuery.lstrParam96	=this.data.lstrParam96
            // this.lVMCommonQuery.lstrParam97	=this.data.lstrParam97
            // this.lVMCommonQuery.lstrParam98	=this.data.lstrParam98
            // this.lVMCommonQuery.lstrParam99	=this.data.lstrParam99
            // this.lVMCommonQuery.lstrParam100	=this.data.lstrParam100
            // this.lVMCommonQuery.lstrParam101	=this.data.lstrParam101
            // this.lVMCommonQuery.lstrParam102	=this.data.lstrParam102
            // this.lVMCommonQuery.lstrParam103	=this.data.lstrParam103
            // this.lVMCommonQuery.lstrParam104	=this.data.lstrParam104
            // this.lVMCommonQuery.lstrParam105	=this.data.lstrParam105
            // this.lVMCommonQuery.lstrParam106	=this.data.lstrParam106
            // this.lVMCommonQuery.lstrParam107	=this.data.lstrParam107
            // this.lVMCommonQuery.lstrParam108	=this.data.lstrParam108
            // this.lVMCommonQuery.lstrParam109	=this.data.lstrParam109
            // this.lVMCommonQuery.lstrParam110	=this.data.lstrParam110
            // this.lVMCommonQuery.lstrParam111	=this.data.lstrParam111
            // this.lVMCommonQuery.lstrParam112	=this.data.lstrParam112
            // this.lVMCommonQuery.lstrParam113	=this.data.lstrParam113
            // this.lVMCommonQuery.lstrParam114	=this.data.lstrParam114
            // this.lVMCommonQuery.lstrParam115	=this.data.lstrParam115
            // this.lVMCommonQuery.lstrParam116	=this.data.lstrParam116
            // this.lVMCommonQuery.lstrParam117	=this.data.lstrParam117
            // this.lVMCommonQuery.lstrParam118	=this.data.lstrParam118
            // this.lVMCommonQuery.lstrParam119	=this.data.lstrParam119
            // this.lVMCommonQuery.lstrParam120	=this.data.lstrParam120
            // this.lVMCommonQuery.lstrParam121	=this.data.lstrParam121
            // this.lVMCommonQuery.lstrParam122	=this.data.lstrParam122
            // this.lVMCommonQuery.lstrParam123	=this.data.lstrParam123
            // this.lVMCommonQuery.lstrParam124	=this.data.lstrParam124
            // this.lVMCommonQuery.lstrParam125	=this.data.lstrParam125
            // this.lVMCommonQuery.lstrParam126	=this.data.lstrParam126
            // this.lVMCommonQuery.lstrParam127	=this.data.lstrParam127
            // this.lVMCommonQuery.lstrParam128	=this.data.lstrParam128
            // this.lVMCommonQuery.lstrParam129	=this.data.lstrParam129
            // this.lVMCommonQuery.lstrParam130	=this.data.lstrParam130
            // this.lVMCommonQuery.lstrParam131	=this.data.lstrParam131
            // this.lVMCommonQuery.lstrParam132	=this.data.lstrParam132
            // this.lVMCommonQuery.lstrParam133	=this.data.lstrParam133
            // this.lVMCommonQuery.lstrParam134	=this.data.lstrParam134
            // this.lVMCommonQuery.lstrParam135	=this.data.lstrParam135
            // this.lVMCommonQuery.lstrParam136	=this.data.lstrParam136
            // this.lVMCommonQuery.lstrParam137	=this.data.lstrParam137
            // this.lVMCommonQuery.lstrParam138	=this.data.lstrParam138
            // this.lVMCommonQuery.lstrParam139	=this.data.lstrParam139
            // this.lVMCommonQuery.lstrParam140	=this.data.lstrParam140
            // this.lVMCommonQuery.lstrParam141	=this.data.lstrParam141
            // this.lVMCommonQuery.lstrParam142	=this.data.lstrParam142
            // this.lVMCommonQuery.lstrParam143	=this.data.lstrParam143
            // this.lVMCommonQuery.lstrParam144	=this.data.lstrParam144
            // this.lVMCommonQuery.lstrParam145	=this.data.lstrParam145
            // this.lVMCommonQuery.lstrParam146	=this.data.lstrParam146
            // this.lVMCommonQuery.lstrParam147	=this.data.lstrParam147
            // this.lVMCommonQuery.lstrParam148	=this.data.lstrParam148
            // this.lVMCommonQuery.lstrParam149	=this.data.lstrParam149
            // this.lVMCommonQuery.lstrParam150	=this.data.lstrParam150
            // this.lVMCommonQuery.lstrParam151	=this.data.lstrParam151
            // this.lVMCommonQuery.lstrParam152	=this.data.lstrParam152
            // this.lVMCommonQuery.lstrParam153	=this.data.lstrParam153
            // this.lVMCommonQuery.lstrParam154	=this.data.lstrParam154
            // this.lVMCommonQuery.lstrParam155	=this.data.lstrParam155
            // this.lVMCommonQuery.lstrParam156	=this.data.lstrParam156
            // this.lVMCommonQuery.lstrParam157	=this.data.lstrParam157
            // this.lVMCommonQuery.lstrParam158	=this.data.lstrParam158
            // this.lVMCommonQuery.lstrParam159	=this.data.lstrParam159
            // this.lVMCommonQuery.lstrParam160	=this.data.lstrParam160
            // this.lVMCommonQuery.lstrParam161	=this.data.lstrParam161
            // this.lVMCommonQuery.lstrParam162	=this.data.lstrParam162
            // this.lVMCommonQuery.lstrParam163	=this.data.lstrParam163
            // this.lVMCommonQuery.lstrParam164	=this.data.lstrParam164
            // this.lVMCommonQuery.lstrParam165	=this.data.lstrParam165
            // this.lVMCommonQuery.lstrParam166	=this.data.lstrParam166
            // this.lVMCommonQuery.lstrParam167	=this.data.lstrParam167
            // this.lVMCommonQuery.lstrParam168	=this.data.lstrParam168
            // this.lVMCommonQuery.lstrParam169	=this.data.lstrParam169
            // this.lVMCommonQuery.lstrParam170	=this.data.lstrParam170
            // this.lVMCommonQuery.lstrParam171	=this.data.lstrParam171
            // this.lVMCommonQuery.lstrParam172	=this.data.lstrParam172
            // this.lVMCommonQuery.lstrParam173	=this.data.lstrParam173
            // this.lVMCommonQuery.lstrParam174	=this.data.lstrParam174
            // this.lVMCommonQuery.lstrParam175	=this.data.lstrParam175
            // this.lVMCommonQuery.lstrParam176	=this.data.lstrParam176
            // this.lVMCommonQuery.lstrParam177	=this.data.lstrParam177
            // this.lVMCommonQuery.lstrParam178	=this.data.lstrParam178
            // this.lVMCommonQuery.lstrParam179	=this.data.lstrParam179
            // this.lVMCommonQuery.lstrParam180	=this.data.lstrParam180
            // this.lVMCommonQuery.lstrParam181	=this.data.lstrParam181
            // this.lVMCommonQuery.lstrParam182	=this.data.lstrParam182
            // this.lVMCommonQuery.lstrParam183	=this.data.lstrParam183
            // this.lVMCommonQuery.lstrParam184	=this.data.lstrParam184
            // this.lVMCommonQuery.lstrParam185	=this.data.lstrParam185
            // this.lVMCommonQuery.lstrParam186	=this.data.lstrParam186
            // this.lVMCommonQuery.lstrParam187	=this.data.lstrParam187
            // this.lVMCommonQuery.lstrParam188	=this.data.lstrParam188
            // this.lVMCommonQuery.lstrParam189	=this.data.lstrParam189
            // this.lVMCommonQuery.lstrParam190	=this.data.lstrParam190
            // this.lVMCommonQuery.lstrParam191	=this.data.lstrParam191
            // this.lVMCommonQuery.lstrParam192	=this.data.lstrParam192
            // this.lVMCommonQuery.lstrParam193	=this.data.lstrParam193
            // this.lVMCommonQuery.lstrParam194	=this.data.lstrParam194
            // this.lVMCommonQuery.lstrParam195	=this.data.lstrParam195
            // this.lVMCommonQuery.lstrParam196	=this.data.lstrParam196
            // this.lVMCommonQuery.lstrParam197	=this.data.lstrParam197
            // this.lVMCommonQuery.lstrParam198	=this.data.lstrParam198
            // this.lVMCommonQuery.lstrParam199	=this.data.lstrParam199
            // this.lVMCommonQuery.lstrParam200	=this.data.lstrParam200

            // this.lVMCommonQuery.lstrSearch=lstrSearch
            // this.lVMCommonQuery.lstrSortCol=this.data.lstrSortCol
            // this.lVMCommonQuery.lbSortAsc=this.data.lbSortAsc 
    
            
            // this._user_service.getSearchData( this.lVMCommonQuery).subscribe((getdata) => { 
            //      this.lVMCommonQueryresult=getdata 
            //     this.cd.markForCheck()
            //     this.dataSource = new MatTableDataSource(getdata);
            //     this.dataSource.sort = this.sort;  
            //     this.dataSource.paginator = this.paginator;  
            //     setTimeout(() => this.dataSource.paginator = this.paginator);
                
            //     if(getdata.length==1){
            //         if(this.lVMSearch.lstrShowlist!="Y"){
            //             this.dialogRef.close(getdata[0]);
            //         }
                    
                     
            //     }
            // } );     

        }catch(error){
            this.handleError(error)
        }

    }
    
    handleError(error) { 
        if (error.status == 401) {
            alert("your session has expired please login again,")
            //localStorage.removeItem('access_token');   
            //this.router.navigate(['account/login']); 
        }
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
         window.alert(errorMessage);
        console.log(error)
        //return throwError(errorMessage);
    } 
    select( lv :VMCommonQuery){ 
       // console.log(lv);
        this.dialogRef.close(lv);
         
    }

    ue_cancel() {
        this.lVMCommonQuery.lstrData1="Cancel";
        this.dialogRef.close(this.lVMCommonQuery);
    }
     
    applyFilter(filterValue: string) {
        try{
            this.dataSource.filter = filterValue.trim().toLowerCase();
        }catch(error){
            this.handleError(error)
        }
        
      }

      keyenter(filterValue: string) { 
        this.getData(filterValue.trim().toLowerCase());
      }
      setInis_dynasearch(){
        
        let ls_VMCommonQuery:  VMCommonQuery= {
            TypeCode	:	''	,
            lstrData1	:	''	,
            lstrData2	:	''	,
            lstrData3	:	''	,
            lstrData4	:	''	,
            lstrData5	:	''	,
            lstrData6	:	''	,
            lstrData7	:	''	,
            lstrData8	:	''	,
            lstrData9	:	''	,
            lstrData10	:	''	,
            lstrData11	:	''	,
            lstrData12	:	''	,
            lstrData13	:	''	,
            lstrData14	:	''	,
            lstrData15	:	''	,
            lstrData16	:	''	,
            lstrData17	:	''	,
            lstrData18	:	''	,
            lstrData19	:	''	,
            lstrData20	:	''	,
            lstrData21	:	''	,
            lstrData22	:	''	,
            lstrData23	:	''	,
            lstrData24	:	''	,
            lstrData25	:	''	,
            lstrData26	:	''	,
            lstrData27	:	''	,
            lstrData28	:	''	,
            lstrData29	:	''	,
            lstrData30	:	''	,
            lstrData31	:	''	,
            lstrData32	:	''	,
            lstrData33	:	''	,
            lstrData34	:	''	,
            lstrData35	:	''	,
            lstrData36	:	''	,
            lstrData37	:	''	,
            lstrData38	:	''	,
            lstrData39	:	''	,
            lstrData40	:	''	,
            lstrData41	:	''	,
            lstrData42	:	''	,
            lstrData43	:	''	,
            lstrData44	:	''	,
            lstrData45	:	''	,
            lstrData46	:	''	,
            lstrData47	:	''	,
            lstrData48	:	''	,
            lstrData49	:	''	,
            lstrData50	:	''	,

             

            lstrSQL	:	''	,
            lstrError	:	''	,
            
            lstrUser :'',
            lstrParam1	:	''	,
            lstrParam2	:	''	,
            lstrParam3	:	''	,
            lstrParam4	:	''	,
            lstrParam5	:	''	,
            lstrParam6	:	''	,
            lstrParam7	:	''	,
            lstrParam8	:	''	,
            lstrParam9	:	''	,
            lstrParam10	:	''	,
            lstrParam11	:	''	,
            lstrParam12	:	''	,
            lstrParam13	:	''	,
            lstrParam14	:	''	,
            lstrParam15	:	''	,
            lstrParam16	:	''	,
            lstrParam17	:	''	,
            lstrParam18	:	''	,
            lstrParam19	:	''	,
            lstrParam20	:	''	,
            lstrParam21	:	''	,
            lstrParam22	:	''	,
            lstrParam23	:	''	,
            lstrParam24	:	''	,
            lstrParam25	:	''	,
            lstrParam26	:	''	,
            lstrParam27	:	''	,
            lstrParam28	:	''	,
            lstrParam29	:	''	,
            lstrParam30	:	''	,
            lstrParam31	:	''	,
            lstrParam32	:	''	,
            lstrParam33	:	''	,
            lstrParam34	:	''	,
            lstrParam35	:	''	,
            lstrParam36	:	''	,
            lstrParam37	:	''	,
            lstrParam38	:	''	,
            lstrParam39	:	''	,
            lstrParam40	:	''	,
            lstrParam41	:	''	,
            lstrParam42	:	''	,
            lstrParam43	:	''	,
            lstrParam44	:	''	,
            lstrParam45	:	''	,
            lstrParam46	:	''	,
            lstrParam47	:	''	,
            lstrParam48	:	''	,
            lstrParam49	:	''	,
            lstrParam50	:	''	, 

            lstrParam51	:	'', 
            lstrParam52	:	'', 
            lstrParam53	:	'', 
            lstrParam54	:	'', 
            lstrParam55	:	'', 
            lstrParam56	:	'', 
            lstrParam57	:	'', 
            lstrParam58	:	'', 
            lstrParam59	:	'', 
            lstrParam60	:	'', 
            lstrParam61	:	'', 
            lstrParam62	:	'', 
            lstrParam63	:	'', 
            lstrParam64	:	'', 
            lstrParam65	:	'', 
            lstrParam66	:	'', 
            lstrParam67	:	'', 
            lstrParam68	:	'', 
            lstrParam69	:	'', 
            lstrParam70	:	'', 
            lstrParam71	:	'', 
            lstrParam72	:	'', 
            lstrParam73	:	'', 
            lstrParam74	:	'', 
            lstrParam75	:	'', 
            lstrParam76	:	'', 
            lstrParam77	:	'', 
            lstrParam78	:	'', 
            lstrParam79	:	'', 
            lstrParam80	:	'', 
            lstrParam81	:	'', 
            lstrParam82	:	'', 
            lstrParam83	:	'', 
            lstrParam84	:	'', 
            lstrParam85	:	'', 
            lstrParam86	:	'', 
            lstrParam87	:	'', 
            lstrParam88	:	'', 
            lstrParam89	:	'', 
            lstrParam90	:	'', 
            lstrParam91	:	'', 
            lstrParam92	:	'', 
            lstrParam93	:	'', 
            lstrParam94	:	'', 
            lstrParam95	:	'', 
            lstrParam96	:	'', 
            lstrParam97	:	'', 
            lstrParam98	:	'', 
            lstrParam99	:	'', 
            lstrParam100	:	'', 
            lstrParam101	:	'', 
            lstrParam102	:	'', 
            lstrParam103	:	'', 
            lstrParam104	:	'', 
            lstrParam105	:	'', 
            lstrParam106	:	'', 
            lstrParam107	:	'', 
            lstrParam108	:	'', 
            lstrParam109	:	'', 
            lstrParam110	:	'', 
            lstrParam111	:	'', 
            lstrParam112	:	'', 
            lstrParam113	:	'', 
            lstrParam114	:	'', 
            lstrParam115	:	'', 
            lstrParam116	:	'', 
            lstrParam117	:	'', 
            lstrParam118	:	'', 
            lstrParam119	:	'', 
            lstrParam120	:	'', 
            lstrParam121	:	'', 
            lstrParam122	:	'', 
            lstrParam123	:	'', 
            lstrParam124	:	'', 
            lstrParam125	:	'', 
            lstrParam126	:	'', 
            lstrParam127	:	'', 
            lstrParam128	:	'', 
            lstrParam129	:	'', 
            lstrParam130	:	'', 
            lstrParam131	:	'', 
            lstrParam132	:	'', 
            lstrParam133	:	'', 
            lstrParam134	:	'', 
            lstrParam135	:	'', 
            lstrParam136	:	'', 
            lstrParam137	:	'', 
            lstrParam138	:	'', 
            lstrParam139	:	'', 
            lstrParam140	:	'', 
            lstrParam141	:	'', 
            lstrParam142	:	'', 
            lstrParam143	:	'', 
            lstrParam144	:	'', 
            lstrParam145	:	'', 
            lstrParam146	:	'', 
            lstrParam147	:	'', 
            lstrParam148	:	'', 
            lstrParam149	:	'', 
            lstrParam150	:	'', 
            lstrParam151	:	'', 
            lstrParam152	:	'', 
            lstrParam153	:	'', 
            lstrParam154	:	'', 
            lstrParam155	:	'', 
            lstrParam156	:	'', 
            lstrParam157	:	'', 
            lstrParam158	:	'', 
            lstrParam159	:	'', 
            lstrParam160	:	'', 
            lstrParam161	:	'', 
            lstrParam162	:	'', 
            lstrParam163	:	'', 
            lstrParam164	:	'', 
            lstrParam165	:	'', 
            lstrParam166	:	'', 
            lstrParam167	:	'', 
            lstrParam168	:	'', 
            lstrParam169	:	'', 
            lstrParam170	:	'', 
            lstrParam171	:	'', 
            lstrParam172	:	'', 
            lstrParam173	:	'', 
            lstrParam174	:	'', 
            lstrParam175	:	'', 
            lstrParam176	:	'', 
            lstrParam177	:	'', 
            lstrParam178	:	'', 
            lstrParam179	:	'', 
            lstrParam180	:	'', 
            lstrParam181	:	'', 
            lstrParam182	:	'', 
            lstrParam183	:	'', 
            lstrParam184	:	'', 
            lstrParam185	:	'', 
            lstrParam186	:	'', 
            lstrParam187	:	'', 
            lstrParam188	:	'', 
            lstrParam189	:	'', 
            lstrParam190	:	'', 
            lstrParam191	:	'', 
            lstrParam192	:	'', 
            lstrParam193	:	'', 
            lstrParam194	:	'', 
            lstrParam195	:	'', 
            lstrParam196	:	'', 
            lstrParam197	:	'', 
            lstrParam198	:	'', 
            lstrParam199	:	'', 
            lstrParam200	:	'', 
                lstrSearch :'',

            lstrSortCol :1,
            lbSortAsc:"Y",
    }
    return ls_VMCommonQuery;
}

   
}
