
<!-- <button [hidden]= "!libutton.lb_first"  (click)="ue_first()"   pButton type="button" label="First" style="margin-bottom:10px;width:auto" ></button> -->

<!-- <div class="buttons "> 
    <ng-container *ngIf="libutton.lb_first"  style="margin:5px;" >
        <button   (click)="ue_first()" mat-raised-button color="accent" > First </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_prior"  style="margin:5px;" >
        <button    (click)="ue_prior()" mat-raised-button color="accent" > Previous </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_prior"  style="margin:5px;" >
        <button [hidden]= "!libutton.lb_next"  (click)="ue_next()" mat-raised-button color="accent" > Next </button>
    </ng-container>

    <ng-container *ngIf="libutton.lb_last"  style="margin:5px;" >
        <button   (click)="ue_last()" mat-raised-button color="accent" > Last </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_new"  style="margin:5px;" >
        <button  (click)="ue_new()" mat-raised-button color="accent" > New </button>
    </ng-container>

    <ng-container *ngIf="libutton.lb_browse"  style="margin:5px;" >
    <button    (click)="ue_browse()" mat-raised-button color="accent" class=""> Browse </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_save"  style="margin:5px;" >
    <button    (click)="ue_save()" mat-raised-button color="accent" > Save </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_delete"  style="margin:5px;" >
    <button    (click)="ue_delete()" mat-raised-button color="accent" > Delete </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_listing"  style="margin:5px;" >
    <button    (click)="ue_listing()" mat-raised-button color="accent" > Listing </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_void"  style="margin:5px;" >
    <button    (click)="ue_void()" mat-raised-button color="accent" > Void </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_cheque"  style="margin:5px;" >
    <button    (click)="ue_cheque()" mat-raised-button color="accent" > Cheque </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_print"  style="margin:5px;" >
    <button    (click)="ue_print()" mat-raised-button color="accent" > Print </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_approve"  style="margin:5px;" >
    <button    (click)="ue_approve()" mat-raised-button color="accent" > Approve </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_status"  style="margin:5px;" >
    <button   (click)="ue_status()" mat-raised-button color="accent" > Status </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_close"  style="margin:5px;" >
    <button   (click)="ue_close()" mat-raised-button color="accent" > Close </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_attach"  style="margin:5px;" >
    <button    (click)="ue_attach()" mat-raised-button color="accent" > Attach </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_custom_fields"  style="margin:5px;" >
    <button [hidden]= "!libutton.lb_custom_fields"  (click)="ue_custom_fields()" mat-raised-button color="accent" > Custom </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_import"  style="margin:5px;" >
    <button   (click)="ue_import()" mat-raised-button color="accent" > Import </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_undo"  style="margin:5px;" >
    <button   (click)="ue_undo()" mat-raised-button color="accent" > Undo </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_send"  style="margin:5px;" >
    <button   (click)="ue_send()" mat-raised-button color="accent" > Send </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_refresh"  style="margin:5px;" >
    <button [hidden]= "!libutton.lb_refresh"  (click)="ue_refresh()" mat-raised-button color="accent" > Refresh </button>
    </ng-container>

</div> -->
<div class="buttons pb-12 m-0 pt-4"> 
    <ng-container *ngIf="libutton.lb_first"  style="margin:5px;" >
        <button   (click)="ue_first()" mat-flat-button title="First" class="brownbtn"   > 
            <mat-icon color="accent" >first_page</mat-icon>
            <span class="txt-btn-accent hide-text">{{ls_first}}</span>        
        </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_prior"  style="margin:5px;" >
        <button    (click)="ue_prior()" mat-flat-button title="Previous" class="brownbtn"  > 
            <mat-icon color="accent" >navigate_before</mat-icon>
            <span class="txt-btn-accent hide-text">{{ls_Previous}}</span>    </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_prior"  style="margin:5px;" >
        <button [hidden]= "!libutton.lb_next"  (click)="ue_next()" mat-flat-button title="Next" class="brownbtn"   > 
            <mat-icon color="accent" >navigate_next</mat-icon>
            <span class="txt-btn-accent hide-text">{{ls_Next}}</span>     
        </button>
    </ng-container>

    <ng-container *ngIf="libutton.lb_last"  style="margin:5px;" >
        <button   (click)="ue_last()" mat-flat-button title="Last" class="brownbtn"   > 
            <mat-icon color="accent" >last_page</mat-icon>
            <span class="txt-btn-accent hide-text">{{ls_Last}}</span> 
        </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_new"  style="margin:5px;" >
        <button mat-flat-button title="New" class="brownbtn" (click)="ue_new()" >
            <mat-icon color="accent">add</mat-icon>
            <span class="txt-btn-accent hide-text">{{ls_New}}</span> 
          </button>
    </ng-container>

  
    <ng-container *ngIf="libutton.lb_save"  style="margin:5px;" >
        <button    (click)="ue_save()" mat-flat-button title="Save" class="brownbtn"   > 
            <mat-icon color="accent">save</mat-icon>   
            <span class="txt-btn-accent hide-text">{{ls_Save}}</span>  
        </button>
    </ng-container>
   

    <ng-container *ngIf="libutton.lb_browse"  style="margin:5px;" >
        <button mat-flat-button title="Browse" class="brownbtn"   (click)="ue_browse()" > 
            <mat-icon color="accent">folder_open</mat-icon> 
            <span class="txt-btn-accent hide-text">{{ls_Browse}}</span>
        </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_delete"  style="margin:5px;" >
        <button    (click)="ue_delete()" mat-flat-button title="Delete" class="redbtn"   > 
            <mat-icon color="accent">delete</mat-icon> 
            <span class="txt-btn-accent hide-text">{{ls_Delete}}</span> 
        </button>
    </ng-container>
    
    <ng-container *ngIf="libutton.lb_void"  style="margin:5px;" >
        <button    (click)="ue_void()" mat-flat-button title="Void" class="redbtn"  > 
            <mat-icon color="accent">cancel</mat-icon> 
            <span class="txt-btn-accent hide-text">{{ls_Cancel}}</span>
        </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_cheque"  style="margin:5px;" >
    <button    (click)="ue_cheque()" mat-flat-button title="Pay" class="brownbtn"   > 
        <mat-icon color="accent">payments</mat-icon> 
        <span class="txt-btn-accent hide-text">{{ls_Pay}}</span>
    </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_print"  style="margin:5px;" >
        <button    (click)="ue_print()" mat-flat-button title="Print" class="brownbtn"   >  
            <mat-icon color="accent">print</mat-icon>
            <span class="txt-btn-accent hide-text">{{ls_Print}}</span>
        </button>
    </ng-container> 

    <ng-container *ngIf="libutton.lb_reportwriter"  style="margin:5px;" >
        <button    (click)="ue_reporting()" mat-flat-button title="Dynamic Reporting" class="brownbtn"   >  
            <mat-icon color="accent">print</mat-icon>
            <span class="txt-btn-accent hide-text">{{ls_d_report}}</span>
        </button>
    </ng-container>

    <ng-container *ngIf="libutton.lb_listing"  style="margin:5px;" >
        <button    (click)="ue_listing()" mat-flat-button title="Listing" class="brownbtn"   > 
            <mat-icon color="accent">format_list_bulleted</mat-icon> 
            <span class="txt-btn-accent hide-text">{{ls_Listing}}</span>    
        </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_approve"  style="margin:5px;" >
        <button    (click)="ue_approve()" mat-flat-button title="Approve" class="brownbtn"   > 
            <mat-icon color="accent">check</mat-icon>
            <span class="txt-btn-accent hide-text">{{ls_Approve}}</span>
        </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_status"  style="margin:5px;" >
        <button   (click)="ue_status()" mat-flat-button title="Status" class="brownbtn"   > 
            <mat-icon color="accent">info</mat-icon> 
            <span class="txt-btn-accent hide-text">{{ls_Status}}</span>       
        </button>
    </ng-container>
    
   
    <ng-container *ngIf="libutton.lb_custom_fields"  style="margin:5px;" >
        <button [hidden]= "!libutton.lb_custom_fields"  (click)="ue_custom_fields()" mat-flat-button title="Custom" class="brownbtn"   >
            <mat-icon color="accent">edit</mat-icon>    
            <span class="txt-btn-accent hide-text">{{ls_Edit}}</span>  
            </button>
    </ng-container>
   
   
    <ng-container *ngIf="libutton.lb_send"  style="margin:5px;" >
    <button   (click)="ue_send()" mat-flat-button title="Submit" class="brownbtn"  > 
        <mat-icon color="accent">send</mat-icon>   
        <span class="txt-btn-accent hide-text">{{ls_Submit}}</span>      
    </button>
    </ng-container>

    <ng-container *ngIf="libutton.lb_attach"  style="margin:5px;" >
        <button    (click)="ue_attach()" mat-flat-button title="Attach" class="brownbtn"    *ngIf="libutton.lb_attachedCount  == 0" > 
            <mat-icon color="accent" >attach_file</mat-icon>  
            <span class="txt-btn-accent hide-text"> {{ls_Attach}} </span>           
        </button>
        <button  (click)="ue_attach()" mat-flat-button title="Attach" class="btn-badge brownbtn"   *ngIf="libutton.lb_attachedCount > 0" matBadge="{{libutton.lb_attachedCount}}" matBadgeColor="warn"> 
             <mat-icon color="accent" >attachment</mat-icon>
            <span class="txt-btn-accent hide-text"> {{ls_Attach}} </span>           
        </button>
    </ng-container>

    <ng-container *ngIf="libutton.lb_import"  style="margin:5px;" >
        <button   (click)="ue_import()" mat-flat-button title="Import" class="brownbtn"   > 
            <mat-icon color="accent">import_export</mat-icon>     
            <span class="txt-btn-accent hide-text">{{ls_Import}}</span>
        </button>
    </ng-container>

    <ng-container *ngIf="libutton.lb_undo"  style="margin:5px;" >
        <button   (click)="ue_undo()" mat-flat-button title="Cash" class="brownbtn"  > 
            <mat-icon color="accent">receipt</mat-icon> 
            <span class="txt-btn-accent hide-text">{{ls_Cash}}</span>        
        </button>
        </ng-container>

    <ng-container *ngIf="libutton.lb_refresh"  style="margin:5px;" >
    <button [hidden]= "!libutton.lb_refresh"  (click)="ue_refresh()" mat-flat-button title="Refresh" class="brownbtn"   > 
        <mat-icon color="accent">refresh</mat-icon>  
        <span class="txt-btn-accent hide-text">{{ls_Refresh}}</span> </button>
    </ng-container>

   <ng-container *ngIf="libutton.lb_related"  style="margin:5px;" >
    <button [hidden]= "!libutton.lb_related"  (click)="ue_related()" mat-flat-button title="Next Menu" class="brownbtn"   > 
        <mat-icon color="accent">open_in_new</mat-icon>  
        <span class="txt-btn-accent hide-text">{{ls_Next}}</span> </button>
    </ng-container>

    <!-- <ng-container *ngIf="libutton.lb_related"  style="margin:5px;" >
        <button [hidden]= "!libutton.lb_related"  (click)="ue_help()" mat-flat-button title="Help" class=""   > 
            <mat-icon color="accent">help_center</mat-icon>  
            <span class="txt-btn-accent hide-text">Help</span> 
        </button>
    </ng-container> -->
    
    <ng-container *ngIf="libutton.lb_tasks"  style="margin:5px;" >
        <button [hidden]= "!libutton.lb_tasks"  (click)="ue_task()" mat-flat-button title="Task" class="brownbtn"   > 
            <mat-icon color="accent">assignment</mat-icon>  
            <span class="txt-btn-accent hide-text">{{ls_Task}}</span> </button>
        </ng-container>

        
        <ng-container *ngIf="libutton.lb_close"  style="margin:5px;" >
            <button   (click)="ue_close()" mat-flat-button title="Close" class="redbtn"  > 
                <mat-icon color="accent">close</mat-icon>  
                <span class="txt-btn-accent hide-text">{{ls_Close}}</span>   
            </button>
        </ng-container>

</div>

<!--  
<div class="button-row">

    <ng-container *ngIf="libutton.lb_first"  style="margin:5px;" >
        <button   (click)="ue_first()" mat-raised-button color="accent" > First </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_prior"  style="margin:5px;" >
        <button    (click)="ue_prior()" mat-raised-button color="accent" > Prior </button>
    </ng-container>
    <ng-container *ngIf="libutton.lb_prior"  style="margin:5px;" >
        <button [hidden]= "!libutton.lb_next"  (click)="ue_next()" mat-raised-button color="accent" > Next </button> 
    </ng-container>

    <ng-container *ngIf="libutton.lb_last"  style="margin:5px;" >
        <button   (click)="ue_last()" mat-raised-button color="accent" > Last </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_new"  style="margin:5px;" >
        <button  (click)="ue_new()" mat-raised-button color="accent" > New </button> 
    </ng-container>

    <ng-container *ngIf="libutton.lb_browse"  style="margin:5px;" >
    <button    (click)="ue_browse()" mat-raised-button color="accent" > Browse </button>
    </ng-container> 
    <ng-container *ngIf="libutton.lb_save"  style="margin:5px;" >
    <button    (click)="ue_save()" mat-raised-button color="accent" > Save </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_delete"  style="margin:5px;" >
    <button    (click)="ue_delete()" mat-raised-button color="accent" > Delete </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_listing"  style="margin:5px;" >
    <button    (click)="ue_listing()" mat-raised-button color="accent" > Listing </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_void"  style="margin:5px;" >
    <button    (click)="ue_void()" mat-raised-button color="accent" > Void </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_cheque"  style="margin:5px;" >
    <button    (click)="ue_cheque()" mat-raised-button color="accent" > Cheque </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_print"  style="margin:5px;" >
    <button    (click)="ue_print()" mat-raised-button color="accent" > Print </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_approve"  style="margin:5px;" >
    <button    (click)="ue_approve()" mat-raised-button color="accent" > Approve </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_status"  style="margin:5px;" >
    <button   (click)="ue_status()" mat-raised-button color="accent" > Status </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_close"  style="margin:5px;" >
    <button   (click)="ue_close()" mat-raised-button color="accent" > Close </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_attach"  style="margin:5px;" >
    <button    (click)="ue_attach()" mat-raised-button color="accent" > Attach </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_custom_fields"  style="margin:5px;" >
    <button [hidden]= "!libutton.lb_custom_fields"  (click)="ue_custom_fields()" mat-raised-button color="accent" > Custom </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_import"  style="margin:5px;" >
    <button   (click)="ue_import()" mat-raised-button color="accent" > Import </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_undo"  style="margin:5px;" >
    <button   (click)="ue_undo()" mat-raised-button color="accent" > Undo </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_send"  style="margin:5px;" >
    <button   (click)="ue_send()" mat-raised-button color="accent" > Send </button> 
    </ng-container>
    <ng-container *ngIf="libutton.lb_refresh"  style="margin:5px;" >
    <button [hidden]= "!libutton.lb_refresh"  (click)="ue_refresh()" mat-raised-button color="accent" > Refresh </button> 
    </ng-container>
</div>
 <p> </p> -->



 