import {Component, Inject} from '@angular/core';


import { UserService } from 'app/base/userservice'; 
import { BaseDialogwindowcomponent } from 'app/base/window/BaseDialogwindow.component';
import { basefunctionsService } from 'app/base/basefunctionsService';
import { SearchComponent } from 'app/base/search/search.component'; 
import { catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common'; 
import { dw_attachment_header, dw_attachment, dw_emp_checklist_detail, dw_attachment_policy, dw_voucher_request_detail } from './Attachment.data';
import { VMs_transinfo } from '../window/Basewindow.data';
import * as moment from 'moment';
import { element } from 'protractor';
import { VMCommonQuery } from '../search/search.data';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';


@Component({
  selector: 'Attachment_component',
  templateUrl:  './Attachment.component.html',
  styleUrls  : ['./Attachment.component.scss'],
  providers:[DatePipe] 
})

export class Attachmentcomponent  extends BaseDialogwindowcomponent  {
     
    lb_save:boolean=false
    lb_edit:boolean=false
    lb_delete:boolean=false
    lb_browse:boolean=false
    lb_new:boolean=false

    vmData :dw_attachment_header  
    id_vrdate:Date=new Date(); 
    data_s_transinfo: any;
    collapseHeight = false;  
    displayedColumns = [  'activity','business_activity_name'];
             
    selelement:any
    activity : string 
    business_activity_name : string ;
    sub_code_name : string;
    ls_size : any;
    ls_checklist: boolean= false;
    ls_attached: boolean= true;
    ls_policy: boolean= false;
    ls_request: boolean= false;
    ls_tabchecklist: boolean= true;
    ls_tabhide: string= 'N';
    sl_no : number = 0;
    ln_setRow : number = 0;
    ln_exclude_days :number=0;
     dataElements : any;
     tabID:number=1; 
    ls_file :any;
    date_time: any;
    ds_dw_attachments : MatTableDataSource<dw_attachment>;
    ds_dw_emp_checklist_detail : MatTableDataSource<dw_emp_checklist_detail>;
    ds_dw_attachment_policy : MatTableDataSource<dw_attachment_policy>;
    ds_dw_voucher_request_details : MatTableDataSource<dw_voucher_request_detail>
    //'sub_code', 'sub_code_name','mandatory','exclude_ind','excl_can_attach','excl_remarks','internal_doc','vr_date','att_af_approval','excl_date','excl_user','close_ind','close_date','close_user'
    dc_dw_attachments = ['attachmentfile','attachment','ls_size','remarks','created_user', ]
    dc_dw_emp_checklist_detail = ['status_name', 'expected_date', 'actual_date', 'close_ind', 'remarks', 'doc_file_path', 'sr_no']
    dc_dw_attachment_policy = ['name', 'comment']
    fileBlob: any;

    ls_file_name:string

    isEditable=false;
    // dc_dw_voucher_request_details = ['sl_no', 'vr_date', 'ref_trc_code', 'trc_name', 'remarks', 'status_ind']
    ln_sectElementatta:dw_attachment
    ln_sectElement :dw_attachment
    constructor(  public _user_service:UserService,public dialog: MatDialog   ,public _bfs :basefunctionsService, public adapter: DateAdapter<any>,
        private dialogRef: MatDialogRef<Attachmentcomponent>,@Inject(MAT_DIALOG_DATA) public data: VMs_transinfo,
         public datePipe : DatePipe  ){
        super(   _user_service, dialog, _bfs,adapter,datePipe); 
     
        this.vmData=this.inidw_attachment_header()
        // this.vmData.fy_code= data.ls_fy_code
        // this.vmData.trc_code= data.trc_code
        this.data.text1=data.ls_fy_code
        this.data.text2 = data.trc_code
        //this.data.text3 = data.lvr_no.toString();
        // this.date_time= moment(this.id_vrdate).format("DD/MM/YYYY HH:mm:ss");
        if(data.lvr_no){
            this.data.text3=data.lvr_no.toString()
        }else{
            this.data.text3=data.text3 
        }
        this.f_open()
        this.setAccess()
        
    }

    f_open(){
        console.log( this.data.text1,  this.data.text2,  this.data.text3)
        this.ln_sectElement= this.inidw_attachment();
      
        this.vmData=this.inidw_attachment_header()
        this.ue_retrieve();
    }
    
    async ue_retrieve(){ 
        try{
            this.vmTrans.strProcess="sp_attachment_browse"
            debugger
            if(!this.data.sr_no  ){
                this.vmData.sr_no = 9999;
            }
            else{
                this.vmData.sr_no=this.data.sr_no
            }
                this.vmTrans.stringjson=  JSON.stringify(this.vmData); 
                this.vmTrans =await this._user_service.getDatau(this.vmTrans);  
                if(this.vmTrans.Result=="Success"){
                    this.vmData=JSON.parse(this.vmTrans.stringjson); 
                    this.lb_form_enabled=true;
                    this.setResource();
                }
                else{ 
                    this._bfs.f_showmessage("Info",this.vmTrans.Result)
                    this.lb_form_enabled=true
                }  
                
        }catch(error){
            this.handleError(error)
        }
    }
    ue_exit(event){this._bfs.f_showmessage("Info",'ue_exit me');}
     
    async ue_new( ){ 
        

    } 
    async ue_save(event){
        if(this.lb_form_enabled==false ){return} 
        try{

            this.lb_form_enabled=false

            debugger
            // let sfind:Number
            // let ls_sub_code:string
            // let lrow:number = this.vmData.dw_attachments.length
            // while (lrow--) { 
            //     sfind=0
            //     ls_sub_code=this.vmData.dw_attachments[lrow].sub_code
            //     sfind=  this.vmData.dw_attachments.findIndex(x=>x.sub_code == ls_sub_code) 
            //     if ( sfind  ){
            //         if (sfind>0 && sfind !=lrow){
            //             this._bfs.f_showmessage( "Warning","Duplication found " )	
                           
            //         }
            //     }   
            // }

            if(await this.wf_mandatory()==false){
                this.lb_form_enabled=true
                return;            
            }else{

                
                    this.vmTrans.strProcess="sp_attachment_update"
                     
                this.vmTrans.stringjson=  JSON.stringify(this.vmData) ; 
                this.vmTrans =await this._user_service.postDataAtt(this.vmTrans) 
                if(this.vmTrans.Result=="Success"){
                    this.lb_form_enabled=true
                    this._bfs.f_showmessage("Info","Saved")
                    this.vmData=JSON.parse(this.vmTrans.stringjson);  
  
                    this.ue_retrieve()
                }
                else{ 
                    this._bfs.f_showmessage("Info",this.vmTrans.Result)  
                    this.lb_form_enabled=true 
                }   
            }
    
        }catch(error){
            this.handleError(error)
        }

 
         
    }
    async ue_delete( ){
        

    } 

    async wf_mandatory():Promise<boolean>{
        try{
             var sfind;
             
            // for(let i in this.vmData.dw_attachments){
            //     if(this.vmData.dw_attachments[i].sub_code == "" || this.vmData.dw_attachments[i].sub_code== null || this.vmData.dw_attachments[i].sub_code==undefined){
            //         this._bfs.f_showmessage('Information!','Type is missing') 
            //         return false
            //     }   
            //     // if(this.vmData.dw_attachments[i].attachment == "" || this.vmData.dw_attachments[i].attachment== null || this.vmData.dw_attachments[i].attachment==undefined){
            //     //     this._bfs.f_showmessage('Information!','Please Select attachment') 
            //     //     return false
            //     // }     
            // }

            // for(let i in this.vmData.dw_emp_checklist_details){
            //     if(this.vmData.dw_emp_checklist_details[i].status == "" || this.vmData.dw_emp_checklist_details[i].status== null || this.vmData.dw_emp_checklist_details[i].status==undefined){
            //         this._bfs.f_showmessage('Information!','Please Select status') 
            //         return false
            //     }   
            //     if(this.vmData.dw_emp_checklist_details[i].doc_file_path == "" || this.vmData.dw_emp_checklist_details[i].doc_file_path== null || this.vmData.dw_emp_checklist_details[i].doc_file_path ==undefined){
            //         this._bfs.f_showmessage('Information!','Please Select attachment in checklist tab') 
            //         return false
            //     }     


            // }

            


            // for(let i in this.vmData.dw_voucher_request_details){
            //     if(this.vmData.dw_voucher_request_details[i].ref_trc_code == "" || this.vmData.dw_voucher_request_details[i].ref_trc_code== null || this.vmData.dw_voucher_request_details[i].ref_trc_code==undefined){
            //         this._bfs.f_showmessage('Information!','Please Select status') 
            //         return false
            //     }   

            // }

            let ll_find;
            ll_find= 0

            sfind=  this.vmData.dw_attachments.find(x=>x.sub_code ==''); 
            if(!(sfind == null || typeof sfind === 'undefined')){
                sfind = ""
            }
            if(sfind= 'R10100'  ){
                ll_find =ll_find+ 1  
            }
		
            if (ll_find>1 ){
                this._bfs.f_showmessage("Info","Main Photo Cant be Repeated")
                return

            }

                 
            return true
           
        }catch(error){
            this.handleError(error)
        }
    }
    

    inidw_attachment_header()
    {


        let ldatDet2: dw_attachment[] = [];
        let ldatDet3: dw_emp_checklist_detail[] = [];
        let ldatDet4: dw_attachment_policy[] = [];
        let ldatDet5: dw_voucher_request_detail[] = [];		
        let ldata: dw_attachment_header = { 
            fy_code : this.data.text1 ,
            trc_code : this.data.text2 ,
            vr_no : this.data.text3 ,
            sr_no : 9999 ,
            dsr_no : null , 
            Result:"", 
            dw_attachments:ldatDet2,
            dw_emp_checklist_details:ldatDet3,
            dw_attachment_policys:ldatDet4,
            dw_voucher_request_details:ldatDet5,
        }    
            return ldata  
    } 
    inidw_attachment()
    {
        let ln_dsrno :number=1;
        if(this.data.sr_no){
            ln_dsrno= this.data.sr_no
        }
        else{
            ln_dsrno=9999
        }
        
        // if(  this.vmData.dw_attachments.length>0){

        //     let list: number[] = [];
        //     for(let key in   this.vmData.dw_attachments) {
        //         list[key]=this.vmData.dw_attachments[key].sr_no;
        //     }
        //     ln_dsrno  = Math.max.apply(Math, list) 
        //     ln_dsrno= ln_dsrno + 1 
        // } 

        let ldata: dw_attachment = { 
            fy_code : this.data.text1 ,
            trc_code : this.data.text2 ,
            vr_no : this.data.text3 ,
            sr_no : this.data.sr_no ,
            dsr_no : ln_dsrno ,
            attachment : null ,
            remarks : null ,
            sub_code : null ,
            type_name : null ,
            flen : null ,
            path : null ,
            ftp : "N"  ,
            uploaded : "N"  ,
            vr_date : this.id_vrdate,
            created_user : null ,
            viewed : null ,
            to_maill : null ,
            cc_maill : null ,
            subject_m : null ,
            message_m : null ,
            created_dt : null ,
            mail_send : null ,
            internal_doc : "N"  ,
            imageobject:null,
            imagetype:null,
            imagebase64:"",
            sub_code_name:"",
            imageurl:"",
            size:null,


 
            key_field_string : null ,
            mandatory : "N"  ,
            exclude_ind : "N"  ,
            excl_can_attach : null ,
            excl_user : null ,
            excl_date : null ,
            excl_remarks : null ,
            close_ind : "N"  ,
            close_date : null ,
            close_user : null ,
            att_af_approval : "N"   
            ,rowstatus:"New"



        }    
            return ldata  
    } 
    inidw_emp_checklist_detail()
    {
        let ln_srno :number=1;
        if(  this.vmData.dw_emp_checklist_details.length>0){

            let list: number[] = [];
            for(let key in   this.vmData.dw_emp_checklist_details) {
                list[key]=this.vmData.dw_emp_checklist_details[key].sr_no;
            }
            ln_srno  = Math.max.apply(Math, list) 
            ln_srno= ln_srno + 1 
        } 

        let ldata: dw_emp_checklist_detail = { 
            fy_code : this.data.text1 ,
            trc_code : this.data.text2 ,
            emp_code : this.data.text3 ,
            sr_no :  ln_srno,//this.data.sr_no ,
            status : null ,
            status_name : null ,
            expected_date : null ,
            actual_date : null ,
            close_ind : "N"  ,
            remarks : null ,
            doc_file_path : null ,
            chk_type : null ,
        }    
            return ldata  
    } 
    inidw_attachment_policy()
    {
        let ldata: dw_attachment_policy = { 
            parent_code : null ,
            code : null ,
            sr_no : this.data.sr_no ,
            name : null ,
            comment : null ,
        }    
            return ldata  
    } 
    inidw_voucher_request_detail()
    {  
        let vm=this.vmData.dw_voucher_request_details  
    
    
        let ldata: dw_voucher_request_detail = { 
            fy_code : this.data.text1 ,
            trc_code : this.data.text2 ,
            vr_no : this.data.text3 ,
            sr_no : this.data.sr_no ,
            dsr_no : null ,
            remarks : null ,
            created_user : null ,
            vr_date : this.id_vrdate ,
            ref_trc_code : null ,
            status_ind : "P"  ,
            trc_name : null ,
            emp_code : null ,
        }    
            return ldata  
    } 
 
    
       
    sub_code_clicked(element:dw_attachment){
        try{
            if(this.lb_form_enabled==false ){return}
            if(this.data.text2 == null){return}
            this.lb_form_enabled=false 
            this.lVMSearch =this.setIni_lVMSearch()
            this.lVMSearch.lstrSQL= "select_attach_sub_code_2"        
            const dialogConfig = new MatDialogConfig();
            this.lVMSearch.lstrColName1="Code"  
            this.lVMSearch.lstrColName2="Name"
            this.lVMSearch.lngColwidth1=30 
            this.lVMSearch.lngColwidth2=40  
            dialogConfig.autoFocus = true;
            dialogConfig.height='77%';
            dialogConfig.width='77%';
            debugger
            this.lVMSearch.lstrParam1 = this.data.text2;
            this.lVMSearch.lstrParam2 = ""//this.data.text2;
            dialogConfig.data=  this.lVMSearch 
            dialogConfig.disableClose = true;
            const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(res => {
                if(res){ 
                    this.ls_VMCommonQuery  = res; 
                    
                    if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                        element.sub_code=this.ls_VMCommonQuery.lstrData1 ;
                        element.sub_code_name=this.ls_VMCommonQuery.lstrData2;
 
                        if (this.ls_VMCommonQuery.lstrData3=='Yes') 
                        {
                            this.ls_VMCommonQuery.lstrData3='Y' 
                        }
                        else {
                            this.ls_VMCommonQuery.lstrData3='N'
                        }
                        element.mandatory=this.ls_VMCommonQuery.lstrData3; 
                    }                
                }
            },(catchError(this.handleError))); 

            this.lb_form_enabled=true

        }catch(error){
            this.handleError(error)
        }
    }

    uploadFile(event,element:dw_attachment) { 

        if(this.lb_edit ==false   ){
            if(element.rowstatus =="New"){

            }
            else{
                element.attachment=""
                this._bfs.f_showmessage("info","No Rights")
                return
            }
            
        }
        
            if (event.target.value) {
                const file = event.target.files[0];
                const type = file.type;
                
    
                this.changeFile(file).then((base64: string): any => { 
                    element.imagebase64= base64.split(',')[1]
                    element.imagetype=type 
                    element.attachment=file.name; 
                    element.imageurl=""
                    element.flen=+((file.size/1024)/1024).toFixed(2)
                    element.exclude_ind='N'
                    element.excl_can_attach=null
                    element.excl_remarks=null
                });
            } else alert('Nothing')
         
    }

    async viewfile(element:dw_attachment){ 
 
        if(this.lb_browse==true){
            
            debugger
            let url= await this._user_service.downloadURLs3_pop(element.fy_code,element.trc_code,element.vr_no,element.dsr_no)
            //let encoded=encodeURIComponent(url)
            //let link = "http://docs.google.com/viewer?url=" +  encoded + "&embedded=true"
           

            if(element.imageurl){
                //window.open(element.imageurl, '_blank'); 
                window.open( url); 
                //this.response(url);
            }
            else{
                let file  = this.b64toBlob( [element.imagebase64] , element.imagetype);
                this.response( file)
            } 
        }
        else{
            this._bfs.f_showmessage("messagebox.information","messagebox.no_rights")
        }
        
        
    }
    changeFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    
    b64toBlob(b64Data, contentType) { 
        contentType = contentType || 'image/png';
        let sliceSize =  512;
        
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
        
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
        
            var byteArray = new Uint8Array(byteNumbers);
        
            byteArrays.push(byteArray);
        }
        
        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    response(files ) { 
        var fileURL = URL.createObjectURL(files);
        window.open(fileURL);
    }

  async  exclude_ind(element:dw_attachment){
        if(element.exclude_ind == 'Y'){
            let l_VMCommonQuery :VMCommonQuery
            l_VMCommonQuery =this.setInis_dynasearch(); 
            l_VMCommonQuery.lstrSQL= "get_attachexclude_days"
           // l_VMCommonQuery.lstrParam1=this.vmApplicationRecMaster.as_code
            l_VMCommonQuery=  await this._user_service.getInLineResultAsyc(l_VMCommonQuery)
    
            if ( !(l_VMCommonQuery== null || typeof l_VMCommonQuery=== 'undefined')){ 
              
                this.ln_exclude_days= +l_VMCommonQuery.lstrData1 
               
                element.excl_can_attach=this.ln_exclude_days
                element.excl_remarks="attach within " + element.excl_can_attach + " days";
            }
            element.excl_date=this.id_vrdate
            element.excl_user=this.lVMUserData.user_id 
            this.isEditable=true;
        }else{
            element.excl_can_attach=null
            element.excl_remarks=null

            this.isEditable=false;
        }
    }

    updateRemarks(element){
        if(element.excl_can_attach){
        element.excl_remarks="attach within " + element.excl_can_attach + " days";
        }
    }


    status_name_clicked(element:dw_emp_checklist_detail){
        try{
            if(this.lb_form_enabled==false ){return}
            if(this.data.text2== null){return}
            this.lb_form_enabled=false 
            this.lVMSearch =this.setIni_lVMSearch()
            this.lVMSearch.lstrSQL= "select_attach_status_name"        
            const dialogConfig = new MatDialogConfig();
            this.lVMSearch.lstrColName1="Code"  
            this.lVMSearch.lstrColName2="Name"
            this.lVMSearch.lngColwidth1=30 
            this.lVMSearch.lngColwidth2=40  
            dialogConfig.autoFocus = true;
            dialogConfig.height='77%';
            dialogConfig.width='77%';
            this.lVMSearch.lstrParam1 = this.data.text2;
            dialogConfig.data=  this.lVMSearch 
            dialogConfig.disableClose = true;
            const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(res => {
                if(res){ 
                    this.ls_VMCommonQuery  = res; 
                    
                    if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                        element.status_name=this.ls_VMCommonQuery.lstrData2 ;
                    }                
                }
            },(catchError(this.handleError))); 

            this.lb_form_enabled=true

        }catch(error){
            this.handleError(error)
        }
    } 
    setRowattch(row:dw_attachment){
        this.ln_sectElementatta=row;
        //this.ln_sectrow = row;
    }

    setRow(row){
        this.ln_sectElement=row;
        this.ln_sectrow = row;
    }


    addAttachment(){
        try{
            this.vmData.dw_attachments = this.vmData.dw_attachments || [];
            this.vmData.dw_attachments.push(this.inidw_attachment()); 

            let lrow= this.vmData.dw_attachments.length -1

            this.vmData.dw_attachments[lrow].vr_date =this.id_vrdate 
            this.vmData.dw_attachments[lrow].created_user =localStorage.getItem('vg_userid')

            if(lrow>0){
                this.vmData.dw_attachments[lrow].sub_code =this.vmData.dw_attachments[lrow -1].sub_code
                this.vmData.dw_attachments[lrow].sub_code_name =this.vmData.dw_attachments[lrow -1].sub_code_name
                this.vmData.dw_attachments[lrow].type_name =this.vmData.dw_attachments[lrow -1].type_name
            }
            this.ds_dw_attachments = new MatTableDataSource(this.vmData.dw_attachments)
        }catch(error){this.handleError(error)}
    }

    deleteAttachment(){
        try{
            if(!this.ln_sectElementatta){
                this._bfs.f_showmessage("warning", "Please select the record you want to delete")
            }else{ 
                // if(  this.ln_sectElementatta.mandatory != "Y"){

                //     this.vmData.dw_attachments.splice(this.ln_sectrow, 1);
                //     this.ds_dw_attachments = new MatTableDataSource(this.vmData.dw_attachments)
                // }
                debugger
                let i=this.vmData.dw_attachments.length
                while (i--) { 
                    if((this.vmData.dw_attachments[i].mandatory|| "" )!= "Y"){
                        if(this.vmData.dw_attachments[i].dsr_no==this.ln_sectElementatta.dsr_no){
                            this.vmData.dw_attachments.splice(i, 1);
                        }
                    }
                    this.ds_dw_attachments = new MatTableDataSource(this.vmData.dw_attachments)
                    // if left(vl_itemcode,1)='2' then			
                    //     if wf_asset_budget_validation(ld_qty)=false then return 1
                    // end if	
                    // object.x_purchase_advised[i]=ld_qty
                   
                }
            }
        }catch(error){
            this.handleError(error)
        }
    }
   

    close(){
        this.dialogRef.close();
    }

    addchecklist(){
        try{
            this.vmData.dw_emp_checklist_details = this.vmData.dw_emp_checklist_details || [];
            this.vmData.dw_emp_checklist_details.push(this.inidw_emp_checklist_detail());
            this.ds_dw_emp_checklist_detail = new MatTableDataSource(this.vmData.dw_emp_checklist_details)
        }catch(error){this.handleError(error)
        }
    }

    deletechecklist(){
        try{
            if(this.ln_sectrow== -1){
                this._bfs.f_showmessage("warning", "Please select the record you want to delete")
            }else{
                this.vmData.dw_emp_checklist_details.splice(this.ln_sectrow, 1);
                this.ds_dw_emp_checklist_detail = new MatTableDataSource(this.vmData.dw_emp_checklist_details)
            }
        }catch(error){
            this.handleError(error)
        }
    }

    doc_file_path_clicked(element:dw_emp_checklist_detail){
        try{
            if(this.lb_form_enabled==false ){return}
            // if(this.data.text2== null){return}
            // this.lb_form_enabled=false 
            // this.lVMSearch =this.setIni_lVMSearch()       
            // const dialogConfig = new MatDialogConfig();
            // this.lVMSearch.lngColwidth1=30 
            // this.lVMSearch.lngColwidth2=40  
            // dialogConfig.autoFocus = true;
            // dialogConfig.height='77%';
            // dialogConfig.width='90%';
            // dialogConfig.maxWidth='80%'
            // dialogConfig.data=  this.lVMSearch 
            // dialogConfig.disableClose = true;
            // this.ls_tabhide= 'Y';
            // const dialogRef = this.dialog.open(Attachmentcomponent, dialogConfig);
            // dialogRef.afterClosed().subscribe(res => {
            //     if(res){ 
            //         this.ls_VMCommonQuery  = res; 
                    
            //         if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  

            //             element.status_name=this.ls_VMCommonQuery.lstrData2 ;
            //         }                
            //     }
            // },(catchError(this.handleError))); 

            this.lb_form_enabled=true

        }catch(error){
            this.handleError(error)
        }
    }

    addpolicy(){
        try{
                this._bfs.f_showmessage('Information', 'Insertion restricted');
                return false;
            
            // this.vmData.dw_attachment_policys = this.vmData.dw_attachment_policys || [];
            // this.vmData.dw_attachment_policys.push(this.inidw_attachment_policy());
            // this.ds_dw_attachment_policy = new MatTableDataSource(this.vmData.dw_attachment_policys)
        }catch(error){this.handleError(error)
        }
    }

    deletepolicy(){
        try{
            
                this._bfs.f_showmessage('Information', 'Deletion restricted');
                return false;
            
            // if(this.ln_sectrow== -1){
            //     this._bfs.f_showmessage("warning", "Please select the record you want to delete")
            // }else{
            //     this.vmData.dw_attachment_policys.splice(this.ln_sectrow, 1);
            //     this.ds_dw_attachment_policy = new MatTableDataSource(this.vmData.dw_attachment_policys)
            // }
        }catch(error){
            this.handleError(error)
        }
    }

    addreq(){
   
        try{
            debugger
            this.vmData.dw_voucher_request_details = this.vmData.dw_voucher_request_details || [];
            this.vmData.dw_voucher_request_details.push(this.inidw_voucher_request_detail());
            this.dataElements = this.vmData.dw_voucher_request_details;
            console.log(this.dataElements)
            // this.ds_dw_voucher_request_details = new MatTableDataSource(this.vmData.dw_voucher_request_details)
        }catch(error){this.handleError(error)
        }
    }

    deletereq(){
        try{
            debugger
        //    let  ele = this.vmData.dw_voucher_request_details.length;
            if( this.ln_sectrow == -1){
                this._bfs.f_showmessage("warning", "Please select the record you want to delete")
                return false;
            }else{
                this.vmData.dw_voucher_request_details.splice(this.ln_sectrow, 1);
                this.dataElements = this.vmData.dw_voucher_request_details;
            }
        }catch(error){
            this.handleError(error)
        }


    }

        setRowCo(row){
            this.ln_sectrow = row
        }


    ref_trc_code_clicked(element:dw_voucher_request_detail){
        try{
            if(this.lb_form_enabled==false ){return}
            if(this.data.text2== null){return}
            this.lb_form_enabled=false 
            this.lVMSearch =this.setIni_lVMSearch()
            this.lVMSearch.lstrSQL= "select_attach_ref_trc_code"        
            const dialogConfig = new MatDialogConfig();
            this.lVMSearch.lstrColName1="Code"  
            this.lVMSearch.lstrColName2="Name"
            this.lVMSearch.lngColwidth1=30 
            this.lVMSearch.lngColwidth2=40  
            dialogConfig.autoFocus = true;
            dialogConfig.height='77%';
            dialogConfig.width='77%';
            this.lVMSearch.lstrParam1 = this.data.text2;
            dialogConfig.data=  this.lVMSearch 
            dialogConfig.disableClose = true;
            const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(res => {
                if(res){ 
                    this.ls_VMCommonQuery  = res; 
                    
                    if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                        element.ref_trc_code=this.ls_VMCommonQuery.lstrData1 ;
                        element.trc_name = this.ls_VMCommonQuery.lstrData2 ;
                        element.remarks = this.ls_VMCommonQuery.lstrData2 ;
                    }                
                }
            },(catchError(this.handleError))); 

            this.lb_form_enabled=true

        }catch(error){
            this.handleError(error)
        }

    }


    async getTabData(tabId: number){ 
        this.tabID=tabId+1;
       if(this.tabID == 1){
        this.ls_attached= true;
        this.ls_checklist= false;
        this.ls_policy = false;
        this.ls_request = false;
       }
       else if (this.tabID == 2){
        this.ls_attached= false;
        this.ls_checklist= true;
        this.ls_policy = false;
        this.ls_request = false;
       }
       else if(this.tabID == 3){
        this.ls_attached= false;
        this.ls_checklist= false;
        this.ls_policy = true;
        this.ls_request = false;
       }
       else if(this.tabID == 4){
        this.ls_attached= false;
        this.ls_checklist= false;
        this.ls_policy = false;
        this.ls_request = true;
       }
       else{
        return;
       }
    }

    setResource(){
        console.log(this.vmData , "setree")
        this.ds_dw_attachments = new MatTableDataSource(this.vmData.dw_attachments);
        this.ds_dw_emp_checklist_detail = new MatTableDataSource(this.vmData.dw_emp_checklist_details);
        this.ds_dw_attachment_policy = new MatTableDataSource(this.vmData.dw_attachment_policys);
        this.dataElements = this.vmData.dw_voucher_request_details;
    }

    setAccess(){         
        debugger
        
        this.lb_save=true
        this.lb_delete=true
        this.lb_browse=true 
        this.lb_new=true  
        this.lb_edit=true  
        if (this.data.attachment_rights.indexOf('C') == -1){ 
                this.lb_new=false 
        }
        if (this.data.attachment_rights.indexOf('M') == -1 && this.data.attachment_rights.indexOf('C') == -1){ 
                this.lb_save=false 
        }
        if (this.data.attachment_rights.indexOf('M') == -1){
            this.lb_edit=false  
        }
        if (this.data.attachment_rights.indexOf('D') == -1){
            this.lb_delete=false
        }
        if (this.data.attachment_rights.indexOf('E') == -1){
            this.lb_browse=false
        }
          

      
        
    }

    async getAttachType(element:dw_attachment)
    {
        if(this.lb_form_enabled==false ){return}
            this.lb_form_enabled=false 
        try{
            this.lVMSearch =this.setIni_lVMSearch()
            this.lVMSearch.lstrSQL= "select_attach_sub_code"        
            const dialogConfig = new MatDialogConfig();
            this.lVMSearch.lstrColName1="Code"  
            this.lVMSearch.lstrColName2="Name"
            dialogConfig.autoFocus = true;
            dialogConfig.height='77%';
            dialogConfig.width='77%';
            this.lVMSearch.lstrSortCol=1
            this.lVMSearch.lbSortAsc="Y"
            dialogConfig.data=  this.lVMSearch 
            dialogConfig.disableClose = true;
            this.ls_VMCommonQuery =await this._user_service.getSearchResults(this.lVMSearch)
 
                if(this.ls_VMCommonQuery.lstrData1=="Cancel"){ 
                }
                else{
                    element.sub_code= this.ls_VMCommonQuery.lstrData1;
                     }
        
            this.lb_form_enabled=true; 
        }catch(error){
            this.handleError(error)
        }
    }
}