<div>
    <!-- <mat-card> -->
        <mat-card-title class="searchtitle">{{ls_serach_by}} <span>{{vmSearch.Result|translate}}</span></mat-card-title>

 
   <div class="row mt-8 mb-8">

        <mat-form-field  appearance="outline" >
            <mat-label>{{vmSearch.Result|translate}}</mat-label>
            <input [(ngModel)]="ls_filter" matInput style="height:40px" autocomplete="off">
        </mat-form-field>

        <!-- <mat-checkbox [checked]="ls_anySearch== 'Y'" (change)="ls_anySearch =ls_anySearch == 'N' ? 'Y': 'N'">
            Any
        </mat-checkbox> -->

    </div>

  

    <div class="buttons text-center ng-lg">
        <button  (click)="applyFilter()" mat-raised-button color="accent"> {{lbl_apply_filter}} </button>
        <button  (click)="removeFilter()" mat-raised-button color="accent">{{lbl_remove_filter}} </button>
        <button  (click)="ue_close()" mat-raised-button  class="redbtn">{{lbl_cancel}}  </button>
    </div>
<!-- </mat-card> -->
</div>
