import {Component, OnInit, ViewChild} from '@angular/core';   
import { UserService } from '../userservice';
import {   VMCommonQuery, VMSearch } from '../search/search.data';


import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';

import { SearchComponent } from '../search/search.component';
 
import { MessageboxComponent } from 'app/base/window/messagebox.component';
import { basefunctionsService } from 'app/base/basefunctionsService';
import { VMUserData } from '../global.data';
import { throwError } from 'rxjs';
import { DatePipe } from '@angular/common';
import { vm_transactions } from './Basewindow.data';

@Component({templateUrl: './BaseDialogwindow.component.html' , selector: 'basewindowDialog',providers:[DatePipe]} )

export class BaseDialogwindowcomponent implements OnInit {
  
    
    @ViewChild(MatPaginator) paginator: MatPaginator; 
    @ViewChild(MatSort) sort: MatSort;
    vmTrans:vm_transactions;
    ln_sectrow: number=-1;
    ls_menuCode:string;
    ls_menuName:string="";
    ls_mode:string;
    Idt_open_time:Date 
    id_vrdate:Date=new Date();
 
    lb_form_enabled:boolean=true
    ls_VMCommonQuery :VMCommonQuery;
    lVMUserData:VMUserData
   
    lVMSearch:VMSearch;  
    is_doc_approval_cnt  
    id_qty_dec:number =2
    
    formStyle = {
        width: '100%',
        display: 'block',
    };

    ngStyleSmall:any={};
    ngStyleXXSmall:any={};
    ngStyleXsmall:any={};
    
    ngStylemedium:any={};
    ngStylelarge:any={};
    ngStylenumeric:any={};
    ngStyleDate:any={};
    ngStyleAmount:any={};
    ngCenterHead={
        'text-align': 'center'
    }
    
    constructor(  public _user_service:UserService,public dialog: MatDialog   ,public _bfs :basefunctionsService, public adapter: DateAdapter<any>,
        public datePipe : DatePipe) {
         
       
        // this.vg_default_company=localStorage.getItem('vg_default_company');
        // this.vg_userid=localStorage.getItem('vg_userid');
        // this.vg_cur_code=localStorage.getItem('vg_cur_code'); 
        // this.vg_amount_dec=+localStorage.getItem('vg_amount_dec'); 
        this.lVMUserData = JSON.parse(localStorage.getItem('gsdata'));
 
        this.adapter.setLocale('en-GB');
          this.vmTrans=this.ini_vm_transactions()
    } 
     
    ngOnInit() { 
         
        if(innerWidth > 450)
        {
            this.ngStyleSmall = {width: '150px !important','max-width': '150px'}
            this.ngStyleXXSmall = {width: '50px !important','max-width': '50px'}
            this.ngStyleXsmall = {width: '70px !important','max-width': '70px'}
            this.ngStylemedium = {width: '250px !important','max-width': '400px'}
            this.ngStylelarge = {width: '350px !important','max-width': '500px'}
            this.ngStylenumeric = {width: '350px !important','max-width': '500px','text-align': 'right' }
            this.ngStyleDate = {width: '130px !important','max-width': '130px'}
            this.ngStyleAmount = {width: '150px !important','max-width': '150px','text-align': 'right'}
        }
        else
        {
            this.ngStylenumeric = {'text-align': 'right' }
            this.ngStyleAmount = {'text-align': 'right' }
 
        };
    } 
    ngOnDestroy(){ 
    }
 
 

    AmtDecInput (element) {
        const dotPos = element.target.value.indexOf('.');

        if (dotPos === -1) {
            element.target.value = parseFloat(element.target.value).toFixed(  this.lVMUserData.amt_decimal);
        } 
           
        if (dotPos !== -1 && (element.target.value.split('.')[1].length < this.lVMUserData.amt_decimal || element.target.value.split('.')[1].length >  this.lVMUserData.amt_decimal)) {
            element.target.value = parseFloat(element.target.value).toFixed(this.lVMUserData.amt_decimal);
        }
    }

    QtyDecInput (element) {
        const dotPos = element.target.value.indexOf('.');

        if (dotPos === -1) {
            element.target.value = parseFloat(element.target.value).toFixed(this.id_qty_dec);
        } 
           
        if (dotPos !== -1 && (element.target.value.split('.')[1].length < this.id_qty_dec || element.target.value.split('.')[1].length > this.id_qty_dec)) {
            element.target.value = parseFloat(element.target.value).toFixed(this.id_qty_dec);
        }
    }
    getdatetostring(vr_date:Date):string{ 
        let ls_data:string  =   this.datePipe.transform( vr_date, "MM/dd/yyyy");
         return  ls_data ;//vr_date.getMonth() + 1 +"/" +vr_date.getDate() +"/" +vr_date.getFullYear()
     }


    handleError(error) {
        if (error.status == 401) {
            //window.location.href =  '/#/account/login';
            localStorage.removeItem('access_token');  
        }
        this.lb_form_enabled=true
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
       // window.alert(errorMessage);
        console.log(error)
        return throwError(errorMessage);
    } 
   
    ini_vm_transactions(){
        let ls_dynstructure:  vm_transactions= { 
            Result :"",
            stringjson :"",
            strProcess :"",
            authcode:"",
            report_type:"",
            report_name :"",
            report_code:"",
            report_sr_no:0,
            vmd:""
            
        }
        return ls_dynstructure
    }
    setIni_lVMSearch(){
        let ls_tmp:  VMSearch= {
            TypeCode	:	''	,
            lstrData1	:	''	,
            lstrData2	:	''	,
            lstrData3	:	''	,
            lstrData4	:	''	,
            lstrData5	:	''	,
            lstrData6	:	''	,
            lstrData7	:	''	,
            lstrData8	:	''	,
            lstrData9	:	''	,
            lstrData10	:	''	,
            lstrData11	:	''	,
            lstrData12	:	''	,
            lstrData13	:	''	,
            lstrData14	:	''	,
            lstrData15	:	''	,
            lstrData16	:	''	,
            lstrData17	:	''	,
            lstrData18	:	''	,
            lstrData19	:	''	,
            lstrData20	:	''	,
            lstrData21	:	''	,
            lstrData22	:	''	,
            lstrData23	:	''	,
            lstrData24	:	''	,
            lstrData25	:	''	,
            lstrData26	:	''	,
            lstrData27	:	''	,
            lstrData28	:	''	,
            lstrData29	:	''	,
            lstrData30	:	''	,
            lstrData31	:	''	,
            lstrData32	:	''	,
            lstrData33	:	''	,
            lstrData34	:	''	,
            lstrData35	:	''	,
            lstrData36	:	''	,
            lstrData37	:	''	,
            lstrData38	:	''	,
            lstrData39	:	''	,
            lstrData40	:	''	,
            lstrData41	:	''	,
            lstrData42	:	''	,
            lstrData43	:	''	,
            lstrData44	:	''	,
            lstrData45	:	''	,
            lstrData46	:	''	,
            lstrData47	:	''	,
            lstrData48	:	''	,
            lstrData49	:	''	,
            lstrData50	:	''	,


            lngColwidth1	:	0	,
            lngColwidth2	:	0	,
            lngColwidth3	:	0	,
            lngColwidth4	:	0	,
            lngColwidth5	:	0	,
            lngColwidth6	:	0	,
            lngColwidth7	:	0	,
            lngColwidth8	:	0	,
            lngColwidth9	:	0	,
            lngColwidth10	:	0	,
            lngColwidth11	:	0	,
            lngColwidth12	:	0	,
            lngColwidth13	:	0	,
            lngColwidth14	:	0	,
            lngColwidth15	:	0	,
            lngColwidth16	:	0	,
            lngColwidth17	:	0	,
            lngColwidth18	:	0	,
            lngColwidth19	:	0	,
            lngColwidth20	:	0	,
            lngColwidth21	:	0	,
            lngColwidth22	:	0	,
            lngColwidth23	:	0	,
            lngColwidth24	:	0	,
            lngColwidth25	:	0	,
            lngColwidth26	:	0	,
            lngColwidth27	:	0	,
            lngColwidth28	:	0	,
            lngColwidth29	:	0	,
            lngColwidth30	:	0	,
            lngColwidth31	:	0	,
            lngColwidth32	:	0	,
            lngColwidth33	:	0	,
            lngColwidth34	:	0	,
            lngColwidth35	:	0	,
            lngColwidth36	:	0	,
            lngColwidth37	:	0	,
            lngColwidth38	:	0	,
            lngColwidth39	:	0	,
            lngColwidth40	:	0	,
            lngColwidth41	:	0	,
            lngColwidth42	:	0	,
            lngColwidth43	:	0	,
            lngColwidth44	:	0	,
            lngColwidth45	:	0	,
            lngColwidth46	:	0	,
            lngColwidth47	:	0	,
            lngColwidth48	:	0	,
            lngColwidth49	:	0	,
            lngColwidth50	:	0	,

            lstrColName1	:	''	,
            lstrColName2	:	''	,
            lstrColName3	:	''	,
            lstrColName4	:	''	,
            lstrColName5	:	''	,
            lstrColName6	:	''	,
            lstrColName7	:	''	,
            lstrColName8	:	''	,
            lstrColName9	:	''	,
            lstrColName10	:	''	,
            lstrColName11	:	''	,
            lstrColName12	:	''	,
            lstrColName13	:	''	,
            lstrColName14	:	''	,
            lstrColName15	:	''	,
            lstrColName16	:	''	,
            lstrColName17	:	''	,
            lstrColName18	:	''	,
            lstrColName19	:	''	,
            lstrColName20	:	''	,
            lstrColName21	:	''	,
            lstrColName22	:	''	,
            lstrColName23	:	''	,
            lstrColName24	:	''	,
            lstrColName25	:	''	,
            lstrColName26	:	''	,
            lstrColName27	:	''	,
            lstrColName28	:	''	,
            lstrColName29	:	''	,
            lstrColName30	:	''	,
            lstrColName31	:	''	,
            lstrColName32	:	''	,
            lstrColName33	:	''	,
            lstrColName34	:	''	,
            lstrColName35	:	''	,
            lstrColName36	:	''	,
            lstrColName37	:	''	,
            lstrColName38	:	''	,
            lstrColName39	:	''	,
            lstrColName40	:	''	,
            lstrColName41	:	''	,
            lstrColName42	:	''	,
            lstrColName43	:	''	,
            lstrColName44	:	''	,
            lstrColName45	:	''	,
            lstrColName46	:	''	,
            lstrColName47	:	''	,
            lstrColName48	:	''	,
            lstrColName49	:	''	,
            lstrColName50	:	''	,

            lstrSQL	:	''	,
            lstrError	:	''	,

            lstrUser	:	''	,

            lstrParam1	:	''	,
            lstrParam2	:	''	,
            lstrParam3	:	''	,
            lstrParam4	:	''	,
            lstrParam5	:	''	,
            lstrParam6	:	''	,
            lstrParam7	:	''	,
            lstrParam8	:	''	,
            lstrParam9	:	''	,
            lstrParam10	:	''	,
            lstrParam11	:	''	,
            lstrParam12	:	''	,
            lstrParam13	:	''	,
            lstrParam14	:	''	,
            lstrParam15	:	''	,
            lstrParam16	:	''	,
            lstrParam17	:	''	,
            lstrParam18	:	''	,
            lstrParam19	:	''	,
            lstrParam20	:	''	,
            lstrParam21	:	''	,
            lstrParam22	:	''	,
            lstrParam23	:	''	,
            lstrParam24	:	''	,
            lstrParam25	:	''	,
            lstrParam26	:	''	,
            lstrParam27	:	''	,
            lstrParam28	:	''	,
            lstrParam29	:	''	,
            lstrParam30	:	''	,
            lstrParam31	:	''	,
            lstrParam32	:	''	,
            lstrParam33	:	''	,
            lstrParam34	:	''	,
            lstrParam35	:	''	,
            lstrParam36	:	''	,
            lstrParam37	:	''	,
            lstrParam38	:	''	,
            lstrParam39	:	''	,
            lstrParam40	:	''	,
            lstrParam41	:	''	,
            lstrParam42	:	''	,
            lstrParam43	:	''	,
            lstrParam44	:	''	,
            lstrParam45	:	''	,
            lstrParam46	:	''	,
            lstrParam47	:	''	,
            lstrParam48	:	''	,
            lstrParam49	:	''	,
            lstrParam50	:	''	,
             
            lstrParam51	:	'', 
            lstrParam52	:	'', 
            lstrParam53	:	'', 
            lstrParam54	:	'', 
            lstrParam55	:	'', 
            lstrParam56	:	'', 
            lstrParam57	:	'', 
            lstrParam58	:	'', 
            lstrParam59	:	'', 
            lstrParam60	:	'', 
            lstrParam61	:	'', 
            lstrParam62	:	'', 
            lstrParam63	:	'', 
            lstrParam64	:	'', 
            lstrParam65	:	'', 
            lstrParam66	:	'', 
            lstrParam67	:	'', 
            lstrParam68	:	'', 
            lstrParam69	:	'', 
            lstrParam70	:	'', 
            lstrParam71	:	'', 
            lstrParam72	:	'', 
            lstrParam73	:	'', 
            lstrParam74	:	'', 
            lstrParam75	:	'', 
            lstrParam76	:	'', 
            lstrParam77	:	'', 
            lstrParam78	:	'', 
            lstrParam79	:	'', 
            lstrParam80	:	'', 
            lstrParam81	:	'', 
            lstrParam82	:	'', 
            lstrParam83	:	'', 
            lstrParam84	:	'', 
            lstrParam85	:	'', 
            lstrParam86	:	'', 
            lstrParam87	:	'', 
            lstrParam88	:	'', 
            lstrParam89	:	'', 
            lstrParam90	:	'', 
            lstrParam91	:	'', 
            lstrParam92	:	'', 
            lstrParam93	:	'', 
            lstrParam94	:	'', 
            lstrParam95	:	'', 
            lstrParam96	:	'', 
            lstrParam97	:	'', 
            lstrParam98	:	'', 
            lstrParam99	:	'', 
            lstrParam100	:	'', 
            lstrParam101	:	'', 
            lstrParam102	:	'', 
            lstrParam103	:	'', 
            lstrParam104	:	'', 
            lstrParam105	:	'', 
            lstrParam106	:	'', 
            lstrParam107	:	'', 
            lstrParam108	:	'', 
            lstrParam109	:	'', 
            lstrParam110	:	'', 
            lstrParam111	:	'', 
            lstrParam112	:	'', 
            lstrParam113	:	'', 
            lstrParam114	:	'', 
            lstrParam115	:	'', 
            lstrParam116	:	'', 
            lstrParam117	:	'', 
            lstrParam118	:	'', 
            lstrParam119	:	'', 
            lstrParam120	:	'', 
            lstrParam121	:	'', 
            lstrParam122	:	'', 
            lstrParam123	:	'', 
            lstrParam124	:	'', 
            lstrParam125	:	'', 
            lstrParam126	:	'', 
            lstrParam127	:	'', 
            lstrParam128	:	'', 
            lstrParam129	:	'', 
            lstrParam130	:	'', 
            lstrParam131	:	'', 
            lstrParam132	:	'', 
            lstrParam133	:	'', 
            lstrParam134	:	'', 
            lstrParam135	:	'', 
            lstrParam136	:	'', 
            lstrParam137	:	'', 
            lstrParam138	:	'', 
            lstrParam139	:	'', 
            lstrParam140	:	'', 
            lstrParam141	:	'', 
            lstrParam142	:	'', 
            lstrParam143	:	'', 
            lstrParam144	:	'', 
            lstrParam145	:	'', 
            lstrParam146	:	'', 
            lstrParam147	:	'', 
            lstrParam148	:	'', 
            lstrParam149	:	'', 
            lstrParam150	:	'', 
            lstrParam151	:	'', 
            lstrParam152	:	'', 
            lstrParam153	:	'', 
            lstrParam154	:	'', 
            lstrParam155	:	'', 
            lstrParam156	:	'', 
            lstrParam157	:	'', 
            lstrParam158	:	'', 
            lstrParam159	:	'', 
            lstrParam160	:	'', 
            lstrParam161	:	'', 
            lstrParam162	:	'', 
            lstrParam163	:	'', 
            lstrParam164	:	'', 
            lstrParam165	:	'', 
            lstrParam166	:	'', 
            lstrParam167	:	'', 
            lstrParam168	:	'', 
            lstrParam169	:	'', 
            lstrParam170	:	'', 
            lstrParam171	:	'', 
            lstrParam172	:	'', 
            lstrParam173	:	'', 
            lstrParam174	:	'', 
            lstrParam175	:	'', 
            lstrParam176	:	'', 
            lstrParam177	:	'', 
            lstrParam178	:	'', 
            lstrParam179	:	'', 
            lstrParam180	:	'', 
            lstrParam181	:	'', 
            lstrParam182	:	'', 
            lstrParam183	:	'', 
            lstrParam184	:	'', 
            lstrParam185	:	'', 
            lstrParam186	:	'', 
            lstrParam187	:	'', 
            lstrParam188	:	'', 
            lstrParam189	:	'', 
            lstrParam190	:	'', 
            lstrParam191	:	'', 
            lstrParam192	:	'', 
            lstrParam193	:	'', 
            lstrParam194	:	'', 
            lstrParam195	:	'', 
            lstrParam196	:	'', 
            lstrParam197	:	'', 
            lstrParam198	:	'', 
            lstrParam199	:	'', 
            lstrParam200	:	'', 
                lstrSearch :'',
            lstrSortCol :1,
            lbSortAsc:"Y", 
            lstrShowlist:"N"
        }
        return ls_tmp;
    }

    
    setInis_dynasearch(){
         
        
            let ls_VMCommonQuery:  VMCommonQuery= {
                TypeCode	:	''	,
                lstrData1	:	''	,
                lstrData2	:	''	,
                lstrData3	:	''	,
                lstrData4	:	''	,
                lstrData5	:	''	,
                lstrData6	:	''	,
                lstrData7	:	''	,
                lstrData8	:	''	,
                lstrData9	:	''	,
                lstrData10	:	''	,
                lstrData11	:	''	,
                lstrData12	:	''	,
                lstrData13	:	''	,
                lstrData14	:	''	,
                lstrData15	:	''	,
                lstrData16	:	''	,
                lstrData17	:	''	,
                lstrData18	:	''	,
                lstrData19	:	''	,
                lstrData20	:	''	,
                lstrData21	:	''	,
                lstrData22	:	''	,
                lstrData23	:	''	,
                lstrData24	:	''	,
                lstrData25	:	''	,
                lstrData26	:	''	,
                lstrData27	:	''	,
                lstrData28	:	''	,
                lstrData29	:	''	,
                lstrData30	:	''	,
                lstrData31	:	''	,
                lstrData32	:	''	,
                lstrData33	:	''	,
                lstrData34	:	''	,
                lstrData35	:	''	,
                lstrData36	:	''	,
                lstrData37	:	''	,
                lstrData38	:	''	,
                lstrData39	:	''	,
                lstrData40	:	''	,
                lstrData41	:	''	,
                lstrData42	:	''	,
                lstrData43	:	''	,
                lstrData44	:	''	,
                lstrData45	:	''	,
                lstrData46	:	''	,
                lstrData47	:	''	,
                lstrData48	:	''	,
                lstrData49	:	''	,
                lstrData50	:	''	,

                 

                lstrSQL	:	''	,
                lstrError	:	''	,
                
                lstrUser :'',
                lstrParam1	:	''	,
                lstrParam2	:	''	,
                lstrParam3	:	''	,
                lstrParam4	:	''	,
                lstrParam5	:	''	,
                lstrParam6	:	''	,
                lstrParam7	:	''	,
                lstrParam8	:	''	,
                lstrParam9	:	''	,
                lstrParam10	:	''	,
                lstrParam11	:	''	,
                lstrParam12	:	''	,
                lstrParam13	:	''	,
                lstrParam14	:	''	,
                lstrParam15	:	''	,
                lstrParam16	:	''	,
                lstrParam17	:	''	,
                lstrParam18	:	''	,
                lstrParam19	:	''	,
                lstrParam20	:	''	,
                lstrParam21	:	''	,
                lstrParam22	:	''	,
                lstrParam23	:	''	,
                lstrParam24	:	''	,
                lstrParam25	:	''	,
                lstrParam26	:	''	,
                lstrParam27	:	''	,
                lstrParam28	:	''	,
                lstrParam29	:	''	,
                lstrParam30	:	''	,
                lstrParam31	:	''	,
                lstrParam32	:	''	,
                lstrParam33	:	''	,
                lstrParam34	:	''	,
                lstrParam35	:	''	,
                lstrParam36	:	''	,
                lstrParam37	:	''	,
                lstrParam38	:	''	,
                lstrParam39	:	''	,
                lstrParam40	:	''	,
                lstrParam41	:	''	,
                lstrParam42	:	''	,
                lstrParam43	:	''	,
                lstrParam44	:	''	,
                lstrParam45	:	''	,
                lstrParam46	:	''	,
                lstrParam47	:	''	,
                lstrParam48	:	''	,
                lstrParam49	:	''	,
                lstrParam50	:	''	, 

                lstrParam51	:	'', 
                lstrParam52	:	'', 
                lstrParam53	:	'', 
                lstrParam54	:	'', 
                lstrParam55	:	'', 
                lstrParam56	:	'', 
                lstrParam57	:	'', 
                lstrParam58	:	'', 
                lstrParam59	:	'', 
                lstrParam60	:	'', 
                lstrParam61	:	'', 
                lstrParam62	:	'', 
                lstrParam63	:	'', 
                lstrParam64	:	'', 
                lstrParam65	:	'', 
                lstrParam66	:	'', 
                lstrParam67	:	'', 
                lstrParam68	:	'', 
                lstrParam69	:	'', 
                lstrParam70	:	'', 
                lstrParam71	:	'', 
                lstrParam72	:	'', 
                lstrParam73	:	'', 
                lstrParam74	:	'', 
                lstrParam75	:	'', 
                lstrParam76	:	'', 
                lstrParam77	:	'', 
                lstrParam78	:	'', 
                lstrParam79	:	'', 
                lstrParam80	:	'', 
                lstrParam81	:	'', 
                lstrParam82	:	'', 
                lstrParam83	:	'', 
                lstrParam84	:	'', 
                lstrParam85	:	'', 
                lstrParam86	:	'', 
                lstrParam87	:	'', 
                lstrParam88	:	'', 
                lstrParam89	:	'', 
                lstrParam90	:	'', 
                lstrParam91	:	'', 
                lstrParam92	:	'', 
                lstrParam93	:	'', 
                lstrParam94	:	'', 
                lstrParam95	:	'', 
                lstrParam96	:	'', 
                lstrParam97	:	'', 
                lstrParam98	:	'', 
                lstrParam99	:	'', 
                lstrParam100	:	'', 
                lstrParam101	:	'', 
                lstrParam102	:	'', 
                lstrParam103	:	'', 
                lstrParam104	:	'', 
                lstrParam105	:	'', 
                lstrParam106	:	'', 
                lstrParam107	:	'', 
                lstrParam108	:	'', 
                lstrParam109	:	'', 
                lstrParam110	:	'', 
                lstrParam111	:	'', 
                lstrParam112	:	'', 
                lstrParam113	:	'', 
                lstrParam114	:	'', 
                lstrParam115	:	'', 
                lstrParam116	:	'', 
                lstrParam117	:	'', 
                lstrParam118	:	'', 
                lstrParam119	:	'', 
                lstrParam120	:	'', 
                lstrParam121	:	'', 
                lstrParam122	:	'', 
                lstrParam123	:	'', 
                lstrParam124	:	'', 
                lstrParam125	:	'', 
                lstrParam126	:	'', 
                lstrParam127	:	'', 
                lstrParam128	:	'', 
                lstrParam129	:	'', 
                lstrParam130	:	'', 
                lstrParam131	:	'', 
                lstrParam132	:	'', 
                lstrParam133	:	'', 
                lstrParam134	:	'', 
                lstrParam135	:	'', 
                lstrParam136	:	'', 
                lstrParam137	:	'', 
                lstrParam138	:	'', 
                lstrParam139	:	'', 
                lstrParam140	:	'', 
                lstrParam141	:	'', 
                lstrParam142	:	'', 
                lstrParam143	:	'', 
                lstrParam144	:	'', 
                lstrParam145	:	'', 
                lstrParam146	:	'', 
                lstrParam147	:	'', 
                lstrParam148	:	'', 
                lstrParam149	:	'', 
                lstrParam150	:	'', 
                lstrParam151	:	'', 
                lstrParam152	:	'', 
                lstrParam153	:	'', 
                lstrParam154	:	'', 
                lstrParam155	:	'', 
                lstrParam156	:	'', 
                lstrParam157	:	'', 
                lstrParam158	:	'', 
                lstrParam159	:	'', 
                lstrParam160	:	'', 
                lstrParam161	:	'', 
                lstrParam162	:	'', 
                lstrParam163	:	'', 
                lstrParam164	:	'', 
                lstrParam165	:	'', 
                lstrParam166	:	'', 
                lstrParam167	:	'', 
                lstrParam168	:	'', 
                lstrParam169	:	'', 
                lstrParam170	:	'', 
                lstrParam171	:	'', 
                lstrParam172	:	'', 
                lstrParam173	:	'', 
                lstrParam174	:	'', 
                lstrParam175	:	'', 
                lstrParam176	:	'', 
                lstrParam177	:	'', 
                lstrParam178	:	'', 
                lstrParam179	:	'', 
                lstrParam180	:	'', 
                lstrParam181	:	'', 
                lstrParam182	:	'', 
                lstrParam183	:	'', 
                lstrParam184	:	'', 
                lstrParam185	:	'', 
                lstrParam186	:	'', 
                lstrParam187	:	'', 
                lstrParam188	:	'', 
                lstrParam189	:	'', 
                lstrParam190	:	'', 
                lstrParam191	:	'', 
                lstrParam192	:	'', 
                lstrParam193	:	'', 
                lstrParam194	:	'', 
                lstrParam195	:	'', 
                lstrParam196	:	'', 
                lstrParam197	:	'', 
                lstrParam198	:	'', 
                lstrParam199	:	'', 
                lstrParam200	:	'', 
                    lstrSearch :'',

                lstrSortCol :1,
                lbSortAsc:"Y",
        }
        return ls_VMCommonQuery; 
   
    }
  
 
}
