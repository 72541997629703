import { NgModule } from '@angular/core';   
import { BaseWindow } from 'app/base/window/BaseWindow.component';
import { CommonModule } from '@angular/common';
import { BaseWindowbuttons } from 'app/base/buttons/BaseWindowbuttons.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';


import { FormsModule } from '@angular/forms';
import { ApprovalSendComponent } from './approval.send.component';
import { ApprovalDocComponent } from './approval.doc.component';
import { searchModule } from '../search/search.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@NgModule({
    imports     : [ 
        CommonModule, 
        MatButtonModule, 
        MatInputModule, 
        MatDialogModule, 
        MatTableModule,
        MatPaginatorModule, 
        MatIconModule,
        FormsModule ,
        MatDatepickerModule,
        searchModule,
        MatProgressSpinnerModule
    ],
    exports     : [ 
        ApprovalDocComponent, 
        ApprovalSendComponent,
    ], 
    declarations: [  
        ApprovalDocComponent, ApprovalSendComponent
    ],
    entryComponents: [ApprovalDocComponent  ,ApprovalSendComponent ]
}) 

export class approvalModule
{
}
