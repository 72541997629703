import {Component,ViewChild,Inject} from '@angular/core';


import { MatButtonModule } from '@angular/material/button';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';



import { UserService } from 'app/base/userservice'; 
import { BaseWindow } from 'app/base/window/BaseWindow.component';
import { ActivatedRoute, Router } from '@angular/router';
import { globalData } from 'app/base/globalData';
import { basefunctionsService } from 'app/base/basefunctionsService';  
import { DatePipe } from '@angular/common';
import { componentFactoryName } from '@angular/compiler';
import { VMCommonQuery , VMSearch} from 'app/base/search/search.data'; 
import { BaseDialogwindowcomponent } from 'app/base/window/BaseDialogwindow.component';  
import { dw_approval_criteria_sub_master_setting, dw_approval_criteria_sub_master_header } from './dynSearch.data'; 
import { vm_parm, vm_transactions } from '../window/Basewindow.data';

@Component({    selector   : 'dynSearchComponent',    templateUrl: './dynSearch.component.html',    styleUrls  : ['./dynSearch.component.scss'] ,
     providers:[DatePipe]  
})

export class dynSearchComponent 
{
    vmData:any
    vmDataTmp:any

    vmSearch: vm_parm
    vmSearchTmp:  vm_parm

    ls_anySearch:string= 'Y'
   
    language_code:string
    ls_serach_by:string
    

    dc_dw_accounts_message_detail= [];
    ds_dw_accounts_message_detail:MatTableDataSource<any>
    ls_filter:string
    ls_selectedRow : string
     
    lbl_apply_filter : string
    lbl_cancel:string 
    lbl_remove_filter : string
    constructor(public _user_service: UserService, public dialog: MatDialog, public _bfs: basefunctionsService, public adapter: DateAdapter<any>,
        private dialogRef: MatDialogRef<dynSearchComponent>, @Inject(MAT_DIALOG_DATA) public data: vm_parm, public lcredireq: UserService
        , public datePipe: DatePipe) {
           
            this.vmSearch=data;

            let column =this.vmSearch.strProcess
            this.vmSearch.object.filterPredicate = function(data, filter: string): boolean { 
                return ( String (data[column] )||"" ).toLowerCase().includes(filter)  ;
             };

             this.language_code =localStorage.getItem('language_code')
             if(this.language_code=="AR"){
                this.ls_serach_by="البحث عن طريق"
                this.lbl_apply_filter="تطبيق عامل التصفية" 
                this.lbl_cancel="يلغي" 
                this.lbl_remove_filter="إزالة عامل التصفية" 
            }

             else{
                this.ls_serach_by="Search By"
                this.lbl_apply_filter="Apply Filter" 
                this.lbl_cancel="Cancel" 
                this.lbl_remove_filter="Remove Filter" 
             }
    }

    applyFilter(){

        let ls_filter = this.ls_filter.trim(); // Remove whitespace
        ls_filter = (ls_filter ||"" ) .toLowerCase()
        this.vmSearch.object.filter = ls_filter;

        // debugger
        // let filterValue= this.ls_filter;
        // if(filterValue== undefined ){ return false;}
        // if(this.vmSearch.strProcess){
        //     this.vmData=JSON.parse(this.vmSearch.stringjson);
        //     console.log(this.ls_anySearch);
        //     if(this.ls_anySearch == 'Y'){
        //         this.vmData =  this.vmData.filter((val)=> val[this.vmSearch.strProcess] != undefined ? val[this.vmSearch.strProcess].toString().toLowerCase().includes(filterValue.toString().toLowerCase()) : val[this.vmSearch.strProcess]);
        //     } else {
        //         this.vmData =  this.vmData.filter((val)=> val[this.vmSearch.strProcess] != undefined ? val[this.vmSearch.strProcess].toString().toLowerCase().startsWith(filterValue.toString().toLowerCase()) : val[this.vmSearch.strProcess]);
        //     }
        //     console.log(this.vmData);
        // }
        // if(this.vmData.length == 0 ){
        //     this._bfs.f_showmessage("Info",'No records matched')
        // }
        // else
        // {
        //     this.dialogRef.close(this.vmData);
        // }
        
    }
    removeFilter(){
        this.vmSearch.object.filter = "";
        // let filterValue= '';
        // if(this.vmSearch.strProcess){
        //     this.vmData=JSON.parse(this.vmSearch.stringjson);
        //     this.vmData =  this.vmData.filter(
        //     (val)=> val[this.vmSearch.strProcess] != undefined ? val[this.vmSearch.strProcess].toString().toLowerCase().includes(filterValue.toString().toLowerCase()) : val[this.vmSearch.strProcess]);
        //     console.log(this.vmData);
        // }
         //this.dialogRef.close(this.vmData);
        
    }

    ue_close(){
        this.dialogRef.close(this.vmData)
    }

}

