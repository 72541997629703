import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { globalData } from 'app/base/globalData';
import { HttpClient } from '@angular/common/http';  
import { UserService } from 'app/base/userservice';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    parentCode: string = '0'; level: number = 1;
    
    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    private baseapi: string = '';
    constructor( 
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService ,
        private authenticationService: UserService,
    )
    {
        // Set the private defaults
       
         //this._unsubscribeAll = new Subject();
    }

    private load(item:any=[])
    {
        debugger
        if (this.authenticationService.loggedIn()) {
            this._fuseNavigationService.getMenu(this.parentCode,this.level).subscribe(data=> {
                this.navigation=data; 
                this._changeDetectorRef.markForCheck();
            },(error=>console.log(error)));
        }
         
       
    }

    
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.load()
        //this.navigation = JSON.parse(localStorage.getItem('menu'));

      // console.log(this.navigation)

        // // Load the navigation either from the input or from the service
        // this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // // Subscribe to the current navigation changes
        // this._fuseNavigationService.onNavigationChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(() => {
        //         // Load the navigation
        //         this.navigation = this._fuseNavigationService.getCurrentNavigation();

        //         // Mark for check
        //         this._changeDetectorRef.markForCheck();
        //     });

        // // Subscribe to navigation item
        // merge(
        //     this._fuseNavigationService.onNavigationItemAdded,
        //     this._fuseNavigationService.onNavigationItemUpdated,
        //     this._fuseNavigationService.onNavigationItemRemoved
        // ).pipe(takeUntil(this._unsubscribeAll))
        //  .subscribe(() => {

        //      // Mark for check
        //      this._changeDetectorRef.markForCheck();
        //  });
    }
}
