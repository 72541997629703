import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


import { reportFilterComponent } from './reportFilter.component';
import { searchModule } from '../search/search.module';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule, 
        MatFormFieldModule, 
        MatInputModule,
        MatDatepickerModule,
        MatDialogModule, 
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        MatCardModule,
        MatTreeModule,
        MatTabsModule ,
        MatSidenavModule,
        MatOptionModule,
        MatSelectModule,MatRadioModule,searchModule,
    ],
    declarations: [
        reportFilterComponent
    ],
    exports: [
        reportFilterComponent
    ],
    entryComponents: [reportFilterComponent ]
})
export class reportFilterModule {
}