import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Router } from '@angular/router';
import { vm_transactions } from 'app/base/window/Basewindow.data';
import { VMUserData } from 'app/base/global.data';
import { UserService } from 'app/base/userservice'; 
import { SearchComponent } from 'app/base/search/search.component';
import { VMSearch } from 'app/base/search/search.data';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { TranslateConfigService } from 'app/services/translate-config.service';
@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    [x: string]: any;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    ls_userid:string
    ls_userName: string;
    ls_equ_group: string;
    ls_sms_pending_count:number;

    vmTrans:vm_transactions;
    lVMUserData:VMUserData;
   
    ls_role_supplier: boolean= false;
    ls_userShow: boolean= false;
    lVMSearch:VMSearch;

    ls_welcome:string
    ls_logout:string
    ls_govt:string
    ls_auth:string
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     * @param {Renderer2} _renderer
     */
    constructor(
        
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        public authService: AuthenticationService,
        private authenticationService: UserService,
        private router: Router,public dialog: MatDialog,
        private _renderer: Renderer2,
        // private translateConfigService: TranslateConfigService,

        
    )
    {

       
        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon' : 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon' : 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon' : 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                id   : 'EN',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'AR',
                title: 'Arabic',
                flag : 'ar'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        if (this.authenticationService.loggedIn()) {
            
            this.globalData()
          
        }
 
        this.language_code =localStorage.getItem('language_code') || "EN"
        if(this.language_code=="AR"){
         this.ls_welcome="أهلا بك"
         this.ls_logout="تسجيل خروج"
         this.ls_govt="حكومة الشارقة"
         this.ls_auth="هيئة الشارقة للموانىء"
        }
        else{
            this.ls_welcome="Welcome"
            this.ls_logout="Logout"
            this.ls_govt="Government of Sharjah"
            this.ls_auth="Sharjah Ports Authority"
        }
       
        
       
    }

    goprofile(){ 
        this.router.navigate(['w_own_user/19005013']).catch(err => console.log(err));

    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the config changesconsole.log(this.lVMUserData)

        if (this.authService.loggedIn() ){
            debugger
            this.ls_userid=localStorage.getItem('vg_userid')
            this.ls_userName = this.lVMUserData.name;  
           
        
        }

        


        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        this.language_code =localStorage.getItem('language_code') || "EN"
        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': this.language_code});
       
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
     async setLanguage(lang): Promise<void>
    // setLanguage(lang): void
    {
        // this.translateConfigService.changeLanguage(lang.id);        
        // this.lVMUserData.language_code= lang.id
        // localStorage.setItem('language_code',lang.id ); 
        
        // this.vmTrans=this.ini_vm_transactions()
        // this.selectedLanguage = lang;
        // this.translateConfigService.changeLanguage(lang.id);

        // this.lVMUserData.language_code=  lang.id    
        // localStorage.setItem('language_code', this.lVMUserData.language_code); 

        debugger
        this.lVMUserData.language_code=  lang.id.toUpperCase ()  
        this.vmTrans.strProcess="sp_user_update_lang" 
        this.vmTrans.stringjson=  JSON.stringify(this.lVMUserData) ; 
        this.vmTrans =await this.authenticationService.postData(this.vmTrans) 
        if(this.vmTrans.Result=="Success"){ 

            localStorage.setItem('language_code', this.lVMUserData.language_code); 
         //   this.globalDatamain()

            if( lang.title== "English")
            { 
                this.selectedLanguage = lang; 
                //this._translateService.use(lang.id);
                window.location.reload()
                // this.setradio('ltr')
                
            } 
            else 
            { 
                this.selectedLanguage = lang;
                //this._translateService.use(lang.id);
                window.location.reload()
                // window.location.reload()
                //this.setradio('rtl')
                
            }
 
            
            
        }
        else{         
            this._bfs.f_showmessage("messagebox.information",this.vmTrans.Result)
        }  

        
      
        
    }


    homeclick(){
        debugger
        localStorage.setItem('home','home'); 
        this.router.navigate(['#/HomeApproval']).catch(err => console.log(err));
    }

    async setradio(e: string): Promise<void>   
    {  
        this._renderer.setAttribute (
        document.body, 'dir', e);  
        
    }

    logout() {
        this.authService.logout();

      let result =  localStorage.getItem('uaepass' )
        //this.router.navigate(['account/login']);  
        //UAE PASSuri
        if(result =="Y"){ 
            
            let uri= "https://id.uaepass.ae/idshub/logout?redirect_uri=https://eservices.sharjahports.gov.ae/#/account/login" 
            //let uri= "https://stg-id.uaepass.ae/idshub/logout?redirect_uri=https://eservices-test.sharjahports.gov.ae/testapplication/#/account/login" 
            //let uri= "https://stg-id.uaepass.ae/idshub/logout?redirect_uri=http://localhost:4300/#/account/login" 
            window.open(  uri ,"_self"); 
        }else{
            this.router.navigate(['account/login']);  
        }
    
    }

    chatMenuShow(){
        try{
            this.ls_chatShow= !this.ls_chatShow;
        }catch(error){
        
        }
    }

    setIni_lVMSearch(){
        let ls_tmp:  VMSearch= {
            TypeCode	:	''	,
            lstrData1	:	''	,
            lstrData2	:	''	,
            lstrData3	:	''	,
            lstrData4	:	''	,
            lstrData5	:	''	,
            lstrData6	:	''	,
            lstrData7	:	''	,
            lstrData8	:	''	,
            lstrData9	:	''	,
            lstrData10	:	''	,
            lstrData11	:	''	,
            lstrData12	:	''	,
            lstrData13	:	''	,
            lstrData14	:	''	,
            lstrData15	:	''	,
            lstrData16	:	''	,
            lstrData17	:	''	,
            lstrData18	:	''	,
            lstrData19	:	''	,
            lstrData20	:	''	,
            lstrData21	:	''	,
            lstrData22	:	''	,
            lstrData23	:	''	,
            lstrData24	:	''	,
            lstrData25	:	''	,
            lstrData26	:	''	,
            lstrData27	:	''	,
            lstrData28	:	''	,
            lstrData29	:	''	,
            lstrData30	:	''	,
            lstrData31	:	''	,
            lstrData32	:	''	,
            lstrData33	:	''	,
            lstrData34	:	''	,
            lstrData35	:	''	,
            lstrData36	:	''	,
            lstrData37	:	''	,
            lstrData38	:	''	,
            lstrData39	:	''	,
            lstrData40	:	''	,
            lstrData41	:	''	,
            lstrData42	:	''	,
            lstrData43	:	''	,
            lstrData44	:	''	,
            lstrData45	:	''	,
            lstrData46	:	''	,
            lstrData47	:	''	,
            lstrData48	:	''	,
            lstrData49	:	''	,
            lstrData50	:	''	,


            lngColwidth1	:	0	,
            lngColwidth2	:	0	,
            lngColwidth3	:	0	,
            lngColwidth4	:	0	,
            lngColwidth5	:	0	,
            lngColwidth6	:	0	,
            lngColwidth7	:	0	,
            lngColwidth8	:	0	,
            lngColwidth9	:	0	,
            lngColwidth10	:	0	,
            lngColwidth11	:	0	,
            lngColwidth12	:	0	,
            lngColwidth13	:	0	,
            lngColwidth14	:	0	,
            lngColwidth15	:	0	,
            lngColwidth16	:	0	,
            lngColwidth17	:	0	,
            lngColwidth18	:	0	,
            lngColwidth19	:	0	,
            lngColwidth20	:	0	,
            lngColwidth21	:	0	,
            lngColwidth22	:	0	,
            lngColwidth23	:	0	,
            lngColwidth24	:	0	,
            lngColwidth25	:	0	,
            lngColwidth26	:	0	,
            lngColwidth27	:	0	,
            lngColwidth28	:	0	,
            lngColwidth29	:	0	,
            lngColwidth30	:	0	,
            lngColwidth31	:	0	,
            lngColwidth32	:	0	,
            lngColwidth33	:	0	,
            lngColwidth34	:	0	,
            lngColwidth35	:	0	,
            lngColwidth36	:	0	,
            lngColwidth37	:	0	,
            lngColwidth38	:	0	,
            lngColwidth39	:	0	,
            lngColwidth40	:	0	,
            lngColwidth41	:	0	,
            lngColwidth42	:	0	,
            lngColwidth43	:	0	,
            lngColwidth44	:	0	,
            lngColwidth45	:	0	,
            lngColwidth46	:	0	,
            lngColwidth47	:	0	,
            lngColwidth48	:	0	,
            lngColwidth49	:	0	,
            lngColwidth50	:	0	,

            lstrColName1	:	''	,
            lstrColName2	:	''	,
            lstrColName3	:	''	,
            lstrColName4	:	''	,
            lstrColName5	:	''	,
            lstrColName6	:	''	,
            lstrColName7	:	''	,
            lstrColName8	:	''	,
            lstrColName9	:	''	,
            lstrColName10	:	''	,
            lstrColName11	:	''	,
            lstrColName12	:	''	,
            lstrColName13	:	''	,
            lstrColName14	:	''	,
            lstrColName15	:	''	,
            lstrColName16	:	''	,
            lstrColName17	:	''	,
            lstrColName18	:	''	,
            lstrColName19	:	''	,
            lstrColName20	:	''	,
            lstrColName21	:	''	,
            lstrColName22	:	''	,
            lstrColName23	:	''	,
            lstrColName24	:	''	,
            lstrColName25	:	''	,
            lstrColName26	:	''	,
            lstrColName27	:	''	,
            lstrColName28	:	''	,
            lstrColName29	:	''	,
            lstrColName30	:	''	,
            lstrColName31	:	''	,
            lstrColName32	:	''	,
            lstrColName33	:	''	,
            lstrColName34	:	''	,
            lstrColName35	:	''	,
            lstrColName36	:	''	,
            lstrColName37	:	''	,
            lstrColName38	:	''	,
            lstrColName39	:	''	,
            lstrColName40	:	''	,
            lstrColName41	:	''	,
            lstrColName42	:	''	,
            lstrColName43	:	''	,
            lstrColName44	:	''	,
            lstrColName45	:	''	,
            lstrColName46	:	''	,
            lstrColName47	:	''	,
            lstrColName48	:	''	,
            lstrColName49	:	''	,
            lstrColName50	:	''	,

            lstrSQL	:	''	,
            lstrError	:	''	,

            lstrUser	:	''	,

            lstrParam1	:	''	,
            lstrParam2	:	''	,
            lstrParam3	:	''	,
            lstrParam4	:	''	,
            lstrParam5	:	''	,
            lstrParam6	:	''	,
            lstrParam7	:	''	,
            lstrParam8	:	''	,
            lstrParam9	:	''	,
            lstrParam10	:	''	,
            lstrParam11	:	''	,
            lstrParam12	:	''	,
            lstrParam13	:	''	,
            lstrParam14	:	''	,
            lstrParam15	:	''	,
            lstrParam16	:	''	,
            lstrParam17	:	''	,
            lstrParam18	:	''	,
            lstrParam19	:	''	,
            lstrParam20	:	''	,
            lstrParam21	:	''	,
            lstrParam22	:	''	,
            lstrParam23	:	''	,
            lstrParam24	:	''	,
            lstrParam25	:	''	,
            lstrParam26	:	''	,
            lstrParam27	:	''	,
            lstrParam28	:	''	,
            lstrParam29	:	''	,
            lstrParam30	:	''	,
            lstrParam31	:	''	,
            lstrParam32	:	''	,
            lstrParam33	:	''	,
            lstrParam34	:	''	,
            lstrParam35	:	''	,
            lstrParam36	:	''	,
            lstrParam37	:	''	,
            lstrParam38	:	''	,
            lstrParam39	:	''	,
            lstrParam40	:	''	,
            lstrParam41	:	''	,
            lstrParam42	:	''	,
            lstrParam43	:	''	,
            lstrParam44	:	''	,
            lstrParam45	:	''	,
            lstrParam46	:	''	,
            lstrParam47	:	''	,
            lstrParam48	:	''	,
            lstrParam49	:	''	,
            lstrParam50	:	''	,
             
            lstrParam51	:	'', 
            lstrParam52	:	'', 
            lstrParam53	:	'', 
            lstrParam54	:	'', 
            lstrParam55	:	'', 
            lstrParam56	:	'', 
            lstrParam57	:	'', 
            lstrParam58	:	'', 
            lstrParam59	:	'', 
            lstrParam60	:	'', 
            lstrParam61	:	'', 
            lstrParam62	:	'', 
            lstrParam63	:	'', 
            lstrParam64	:	'', 
            lstrParam65	:	'', 
            lstrParam66	:	'', 
            lstrParam67	:	'', 
            lstrParam68	:	'', 
            lstrParam69	:	'', 
            lstrParam70	:	'', 
            lstrParam71	:	'', 
            lstrParam72	:	'', 
            lstrParam73	:	'', 
            lstrParam74	:	'', 
            lstrParam75	:	'', 
            lstrParam76	:	'', 
            lstrParam77	:	'', 
            lstrParam78	:	'', 
            lstrParam79	:	'', 
            lstrParam80	:	'', 
            lstrParam81	:	'', 
            lstrParam82	:	'', 
            lstrParam83	:	'', 
            lstrParam84	:	'', 
            lstrParam85	:	'', 
            lstrParam86	:	'', 
            lstrParam87	:	'', 
            lstrParam88	:	'', 
            lstrParam89	:	'', 
            lstrParam90	:	'', 
            lstrParam91	:	'', 
            lstrParam92	:	'', 
            lstrParam93	:	'', 
            lstrParam94	:	'', 
            lstrParam95	:	'', 
            lstrParam96	:	'', 
            lstrParam97	:	'', 
            lstrParam98	:	'', 
            lstrParam99	:	'', 
            lstrParam100	:	'', 
            lstrParam101	:	'', 
            lstrParam102	:	'', 
            lstrParam103	:	'', 
            lstrParam104	:	'', 
            lstrParam105	:	'', 
            lstrParam106	:	'', 
            lstrParam107	:	'', 
            lstrParam108	:	'', 
            lstrParam109	:	'', 
            lstrParam110	:	'', 
            lstrParam111	:	'', 
            lstrParam112	:	'', 
            lstrParam113	:	'', 
            lstrParam114	:	'', 
            lstrParam115	:	'', 
            lstrParam116	:	'', 
            lstrParam117	:	'', 
            lstrParam118	:	'', 
            lstrParam119	:	'', 
            lstrParam120	:	'', 
            lstrParam121	:	'', 
            lstrParam122	:	'', 
            lstrParam123	:	'', 
            lstrParam124	:	'', 
            lstrParam125	:	'', 
            lstrParam126	:	'', 
            lstrParam127	:	'', 
            lstrParam128	:	'', 
            lstrParam129	:	'', 
            lstrParam130	:	'', 
            lstrParam131	:	'', 
            lstrParam132	:	'', 
            lstrParam133	:	'', 
            lstrParam134	:	'', 
            lstrParam135	:	'', 
            lstrParam136	:	'', 
            lstrParam137	:	'', 
            lstrParam138	:	'', 
            lstrParam139	:	'', 
            lstrParam140	:	'', 
            lstrParam141	:	'', 
            lstrParam142	:	'', 
            lstrParam143	:	'', 
            lstrParam144	:	'', 
            lstrParam145	:	'', 
            lstrParam146	:	'', 
            lstrParam147	:	'', 
            lstrParam148	:	'', 
            lstrParam149	:	'', 
            lstrParam150	:	'', 
            lstrParam151	:	'', 
            lstrParam152	:	'', 
            lstrParam153	:	'', 
            lstrParam154	:	'', 
            lstrParam155	:	'', 
            lstrParam156	:	'', 
            lstrParam157	:	'', 
            lstrParam158	:	'', 
            lstrParam159	:	'', 
            lstrParam160	:	'', 
            lstrParam161	:	'', 
            lstrParam162	:	'', 
            lstrParam163	:	'', 
            lstrParam164	:	'', 
            lstrParam165	:	'', 
            lstrParam166	:	'', 
            lstrParam167	:	'', 
            lstrParam168	:	'', 
            lstrParam169	:	'', 
            lstrParam170	:	'', 
            lstrParam171	:	'', 
            lstrParam172	:	'', 
            lstrParam173	:	'', 
            lstrParam174	:	'', 
            lstrParam175	:	'', 
            lstrParam176	:	'', 
            lstrParam177	:	'', 
            lstrParam178	:	'', 
            lstrParam179	:	'', 
            lstrParam180	:	'', 
            lstrParam181	:	'', 
            lstrParam182	:	'', 
            lstrParam183	:	'', 
            lstrParam184	:	'', 
            lstrParam185	:	'', 
            lstrParam186	:	'', 
            lstrParam187	:	'', 
            lstrParam188	:	'', 
            lstrParam189	:	'', 
            lstrParam190	:	'', 
            lstrParam191	:	'', 
            lstrParam192	:	'', 
            lstrParam193	:	'', 
            lstrParam194	:	'', 
            lstrParam195	:	'', 
            lstrParam196	:	'', 
            lstrParam197	:	'', 
            lstrParam198	:	'', 
            lstrParam199	:	'', 
            lstrParam200	:	'', 
                lstrSearch :'',
            lstrSortCol :1,
            lbSortAsc:"Y", 
            lstrShowlist:"N" 
        }
        return ls_tmp;
    }

    ini_vm_transactions(){
        let ls_dynstructure:  vm_transactions= { 
            Result :"",
            stringjson :"",
            strProcess :"",
            authcode:"",
            report_code:"",
            report_name:"",
            report_sr_no:null,
            report_type:null,
            vmd:""
        }
        return ls_dynstructure
    }
    ini_vm_user(){
        let ls_dynstructure:  VMUserData= { 
            equ_group : "", 
            user_id : "", 
            name : "", 
            counter : null, 
            fy_code : "", 
            password : "", 
            ac_creat_right : "", 
            is_gpmember : "", 
            security_level : "", 
            save_on_exit : "", 
            doc_expiry_notification : "", 
            cost_int : "", 
            payroll_rights : "", 
            dept_code : "", 
            password_change_date : null,  
            active_ind : "", 
            theme : "", 
            change_password : "", 
            login_period_applicable : "", 
            login_period : "", 
            profile_code : "", 
            language_code : "", 
            user_ip_address : "", 
            user_ip_restriction : "", 
            acc_code : "", 
            email_address : "", 
            default_co : "", 
            default_co_name : "", 
            today : null,  
            multi_currency : "", 
            sys_cur_code : "", 
            job_analysis_int : "", 
            job_analysis_type : null, 
            internal_entry_indicator : "", 
            qty_decimal : null, 
            rate_decimal : null, 
            amt_decimal : null, 
            s_dt_fy : null,  
            e_dt_fy : null,  
            verify_must : "", 
            message_delay : null, 
            company_name : "", 
            ddw_acccode : "", 
            ddw_acccodewithfilter : "", 
            company_license : null, 
            blob_attachment : "", 
            arabic_enabled : "", 
            app_confirm : "", 
            save_ack : "", 
            save_confim : "", 
            delete_confim : "", 
            delete_ask : "", 
            ite_group_t : "", 
            ite_family_t : "", 
            ite_class_t : "", 
            ite_commodity_t : "", 
            ite_custom_1_t : "", 
            ite_custom_2_t : "", 
            ite_custom_3_t : "", 
            ite_custom_4_t : "", 
            ite_custom_5_t : "", 
            ite_custom_6_t : "", 
            eas_required : "", 
            wbs_master_name : "", 
            audit_log_link : "", 
            section_levels : null, 
            dashboard_required : "", 
            doc_notification_required : "", 
            todlist_required : "", 
            tasks_required : "", 
            bi_required : "", 
            timer : "", 
            eas_popup_interval : null, 
            dash_board : "", 
            role_id:"",
            Result :"", 
            external_user:"",
            ref_acc_code:"",
            gs_boq:"",
            locale_id:"",
            address:"",
            mobile_no : null ,
            trade_license_no : "" , 
            customer_type:"C", 
            emirates_id:"", 
            firstname:"" ,
            last_name:"",
            comapany_name_t:"",
            sms_pending_count: null ,
            firstnamear:null ,
            approval_edit_rights:null ,
        }
        return ls_dynstructure
    }

    async globalData(){

        this.vmTrans= this.ini_vm_transactions() 
        this.vmTrans.strProcess="sp_user_Userinfo"
        this.lVMUserData=this.ini_vm_user()
             
        this.vmTrans.stringjson=  JSON.stringify(this.lVMUserData) ;  
        this.vmTrans =await this.authenticationService.getData(this.vmTrans) 
        if(this.vmTrans.Result=="Success"){
            this.lVMUserData=JSON.parse(this.vmTrans.stringjson);
            localStorage.setItem('gsdata', JSON.stringify(this.lVMUserData));
            
            if(this.lVMUserData){
                if(this.lVMUserData.role_id!='099002'){
                    this.ls_role_supplier= false;
                    }else{
                        this.ls_role_supplier= true;
                    } 
                    this.ls_userName = this.lVMUserData.name;
                     
                    this.ls_equ_group = this.lVMUserData.equ_group;
                    this.ls_sms_pending_count = this.lVMUserData.sms_pending_count;
                     
            }
        }
        else{ 
            alert( this.vmTrans.Result)  
            
        }  

      

    }
    
    userMenuShow(){
        try{
            this.ls_userShow= !this.ls_userShow;
        }catch(error){
        
        }
    }

    company_name_clicked(){
        
            // if(this.lb_form_enabled==false ){return}
            this.lb_form_enabled=false 
            this.lVMSearch =this.setIni_lVMSearch()
            this.lVMSearch.lstrSQL= "select_toobar_co"        
            const dialogConfig = new MatDialogConfig();
            this.lVMSearch.lstrColName1="Code"  
            this.lVMSearch.lstrColName2="Name"
            this.lVMSearch.lngColwidth1=30 
            this.lVMSearch.lngColwidth2=40  
            dialogConfig.autoFocus = true;
            dialogConfig.maxWidth='90%';
            dialogConfig.data=  this.lVMSearch 
            dialogConfig.disableClose = true;
            const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(res => {
                if(res){ 
                    this.ls_VMCommonQuery  = res; 
                    if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                        this.lVMUserData.default_co=  this.ls_VMCommonQuery.lstrData1
                        this.lVMUserData.company_name=  this.ls_VMCommonQuery.lstrData2 
                        localStorage.removeItem('gsdata' );
                        localStorage.setItem('gsdata', JSON.stringify(this.lVMUserData));
                    }                
                }
            },(catchError(this.handleError))); 

            this.lb_form_enabled=true

        
    }

    email_address_clicked(){
        alert(1)
    }

}
