
/**
 * Shijilesh 27/02/2017 
 * ePROMIS  
 **/

import { Component, Inject, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Injectable, OnInit } from '@angular/core';


import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';

import {  MatOptionModule } from '@angular/material/core';

import { VMCommonQuery, VMSearch, VMCommonQueryLarge } from './search.data';
import { UserService } from '../userservice';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { basefunctionsService } from 'app/base/basefunctionsService';
import { s_drill } from 'app/main/Reports/ReportFrame/ReportFrame.data';


@Component({
    selector   : 'search',
    templateUrl: './search.component.html', 
    styleUrls  : ['./search.component.scss']
}) 
@Injectable()
export class SearchComponent implements OnInit 
{ 
    pageSize: number;
    ls_status:string="nothing"
    cols: any[]=[];
    map: {[key:string]:string} = {};  

    ngStyleXXSmall = {
        width: '30px !important',
        'max-width': '30px',
    };
    ngStyleXsmall = {
        width: '70px !important',
        'max-width': '70px',
    }; 
    ngStyleSmall = {
        width: '150px !important',
        'max-width': '150px',
    };
   
    ngStylemedium = {
        width: '250px !important',
        'max-width': '400px',
    };
    ngStylelarge = {
        width: '350px !important',
        'max-width': '500px',
    };

    ngStylenumeric = {
        width: '350px !important',
        'max-width': '500px',
        'text-align': 'right' 
    }; 

    lVMCommonQuery:VMCommonQuery; 
    //lVMCommonQueryarr:VMCommonQuery[]; 
    lVMCommonQueryresult:VMCommonQueryLarge[]; 
    filtertext:string
    lVMSearch:VMSearch;
    ls_getdata : boolean = false;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns = [ 'lstrData1'  ,'lstrData2','lstrData3','lstrData4','lstrData5'];
    
    changeDetection: ChangeDetectionStrategy.OnPush
    dataSource: MatTableDataSource<VMCommonQuery>;
    ls_search:boolean= false;

    language_code:string
    ls_master:string
    ls_Select:string
    ls_cancel:string
    @ViewChild(MatSort) sort: MatSort;

    lselrow :VMCommonQuery
    ls_drill:s_drill
    constructor( 
        private dialogRef: MatDialogRef<SearchComponent>,public _bfs :basefunctionsService,
        @Inject(MAT_DIALOG_DATA) public data: VMSearch, public _user_service:UserService, public cd: ChangeDetectorRef,public router: Router) {

       // this.ls_dynstructure = data.description;
      

    //    this.cols = [
    //     { field: 'lstrData1', header: 'lstrData1' },
    //     { field: 'lstrData2', header: 'lstrData2' },
    //     { field: 'lstrData3', header: 'lstrData3' },
    //     { field: 'lstrData4', header: 'lstrData4' }
    //     ];
    //      this.cols=this.cols2 
  
      
         this.lVMSearch=data;
         this.lVMCommonQuery=this.setInis_dynasearch();    

         this.language_code =localStorage.getItem('language_code')
        if(this.language_code=="AR"){
            this.ls_master="رئيس"
            this.ls_Select="يختار"
            this.ls_cancel="يلغي"
        }


        else{
            this.ls_master="Master"
            this.ls_Select="Select"
            this.ls_cancel="Cancel"
        }

              
    }

     
    getPageSize = () => {
        let screenWidth = window.innerWidth; 
        if (screenWidth < 250) return (this.pageSize = 2);
        else if (screenWidth >= 250 && screenWidth < 350)
        return (this.pageSize = 4);
        else if (screenWidth >= 350 && screenWidth < 400)
        return (this.pageSize = 8);
        else if (screenWidth >= 400 && screenWidth < 412)
        return (this.pageSize = 10);
        else if (screenWidth >= 412 && screenWidth < 767)
        return (this.pageSize = 10);
        else if (screenWidth >= 767 && screenWidth < 992)
          return (this.pageSize = 3);
          else if (screenWidth >= 992 && screenWidth < 1100)
          return (this.pageSize = 5);
        else if (screenWidth >= 1100 && screenWidth < 1200)
          return (this.pageSize = 6);
        else if (screenWidth >= 1200 && screenWidth < 1370) return (this.pageSize = 7);
        else if (screenWidth >= 1370 && screenWidth < 1500) return (this.pageSize = 9);
        else if (screenWidth >= 1500 && screenWidth < 1600) return (this.pageSize = 9); // 1501-1599.99
        else if (screenWidth >= 1600 && screenWidth < 1700) return (this.pageSize = 12);
        else if (screenWidth >= 1700 && screenWidth < 1800) return (this.pageSize = 11);
        else if (screenWidth >= 1800 && screenWidth < 1920) return (this.pageSize = 12);
        else if (screenWidth >= 1920 && screenWidth < 2000) return (this.pageSize = 14); 
        else if (screenWidth >= 2000 && screenWidth < 2200) return (this.pageSize = 15);
       //else if (screenWidth >= 2200) return (this.pageSize = 15); // 2200-100000000000000000000000000
       else return (this.pageSize = 15); // 1920-9999999999999999999999999
      };

    ngOnInit() {
        
        this.f_set_map()
        this.cols = [
                { field: 'lstrData1', header: 'lstrData1' }, 
                ];

      //  console.log(this.lVMSearch) 
        let i: number,i2:number,lrow:number
        lrow= 0
        for (i = 0; i < 20; i++) {  
            i2=i+ 1 
            if(this.map['lstrColName' + i2] =="" || this.map['lstrColName' + i2]==null || typeof this.map['lstrColName' + i2] === 'undefined'){ 
          
            }else{
                lrow =lrow + 1
            }       
        } 
      
        this.cols[lrow] = new Array();
 

        i =this.cols.length
        while (i--) {
            
            this.cols.splice(i, 1)  
        }

        // this.cols.pop() 
        // this.cols.pop()
        // this.cols.pop()
 
      //  alert(lrow)
        for (i = 0; i <  lrow; i++) {
            i2=i+ 1 
            this.cols.push({field:'lstrData'+i2 ,header: this.map['lstrColName' + i2]}) 
        }  
   //     console.log(this.cols)
         this.getData( ""); 
        // console.log(this.ls_getdata, "init")
    }

    async f_set_map(  ){
        this.map['TypeCode'] = this.lVMSearch.TypeCode
        this.map['lstrColName1'] = this.lVMSearch.lstrColName1
        this.map['lstrColName2'] = this.lVMSearch.lstrColName2
        this.map['lstrColName3'] = this.lVMSearch.lstrColName3
        this.map['lstrColName4'] = this.lVMSearch.lstrColName4
        this.map['lstrColName5'] = this.lVMSearch.lstrColName5
        this.map['lstrColName6'] = this.lVMSearch.lstrColName6
        this.map['lstrColName7'] = this.lVMSearch.lstrColName7
        this.map['lstrColName8'] = this.lVMSearch.lstrColName8
        this.map['lstrColName9'] = this.lVMSearch.lstrColName9
        this.map['lstrColName10'] = this.lVMSearch.lstrColName10
        this.map['lstrColName11'] = this.lVMSearch.lstrColName11
        this.map['lstrColName12'] = this.lVMSearch.lstrColName12
        this.map['lstrColName13'] = this.lVMSearch.lstrColName13
        this.map['lstrColName14'] = this.lVMSearch.lstrColName14
        this.map['lstrColName15'] = this.lVMSearch.lstrColName15
        this.map['lstrColName16'] = this.lVMSearch.lstrColName16
        this.map['lstrColName17'] = this.lVMSearch.lstrColName17
        this.map['lstrColName18'] = this.lVMSearch.lstrColName18
        this.map['lstrColName19'] = this.lVMSearch.lstrColName19
        this.map['lstrColName20'] = this.lVMSearch.lstrColName20

       
    }
    ue_refresh(){ 
        try{
            this.getData(this.filtertext.trim().toLowerCase());
        }catch(error){
            this.handleError(error)
        }
    }
    getData( lstrSearch:string  ){
        try{
            

            this.lVMCommonQuery.lstrSQL=this.data.lstrSQL 
            this.lVMCommonQuery.lstrUser=this.data.lstrUser
            this.lVMCommonQuery.lstrParam1=this.data.lstrParam1
            this.lVMCommonQuery.lstrParam2=this.data.lstrParam2
            this.lVMCommonQuery.lstrParam3=this.data.lstrParam3
            this.lVMCommonQuery.lstrParam4=this.data.lstrParam4
            this.lVMCommonQuery.lstrParam5=this.data.lstrParam5
            this.lVMCommonQuery.lstrParam6=this.data.lstrParam6
            this.lVMCommonQuery.lstrParam7=this.data.lstrParam7
            this.lVMCommonQuery.lstrParam8=this.data.lstrParam8
            this.lVMCommonQuery.lstrParam9=this.data.lstrParam9
            this.lVMCommonQuery.lstrParam10=this.data.lstrParam10
            this.lVMCommonQuery.lstrParam11=this.data.lstrParam11
            this.lVMCommonQuery.lstrParam12=this.data.lstrParam12
            this.lVMCommonQuery.lstrParam13=this.data.lstrParam13
            this.lVMCommonQuery.lstrParam14=this.data.lstrParam14
            this.lVMCommonQuery.lstrParam15=this.data.lstrParam15
            this.lVMCommonQuery.lstrParam16=this.data.lstrParam16
            this.lVMCommonQuery.lstrParam17=this.data.lstrParam17
            this.lVMCommonQuery.lstrParam18=this.data.lstrParam18
            this.lVMCommonQuery.lstrParam19=this.data.lstrParam19
            this.lVMCommonQuery.lstrParam20=this.data.lstrParam20
            this.lVMCommonQuery.lstrParam21=this.data.lstrParam21
            this.lVMCommonQuery.lstrParam22=this.data.lstrParam22
            this.lVMCommonQuery.lstrParam23=this.data.lstrParam23
            this.lVMCommonQuery.lstrParam24=this.data.lstrParam24
            this.lVMCommonQuery.lstrParam25=this.data.lstrParam25
            this.lVMCommonQuery.lstrParam26=this.data.lstrParam26
            this.lVMCommonQuery.lstrParam27=this.data.lstrParam27
            this.lVMCommonQuery.lstrParam28=this.data.lstrParam28
            this.lVMCommonQuery.lstrParam29=this.data.lstrParam29
            this.lVMCommonQuery.lstrParam30=this.data.lstrParam30
            this.lVMCommonQuery.lstrParam31=this.data.lstrParam31
            this.lVMCommonQuery.lstrParam32=this.data.lstrParam32
            this.lVMCommonQuery.lstrParam33=this.data.lstrParam33
            this.lVMCommonQuery.lstrParam34=this.data.lstrParam34
            this.lVMCommonQuery.lstrParam35=this.data.lstrParam35
            this.lVMCommonQuery.lstrParam36=this.data.lstrParam36
            this.lVMCommonQuery.lstrParam37=this.data.lstrParam37
            this.lVMCommonQuery.lstrParam38=this.data.lstrParam38
            this.lVMCommonQuery.lstrParam39=this.data.lstrParam39
            this.lVMCommonQuery.lstrParam40=this.data.lstrParam40
            this.lVMCommonQuery.lstrParam41=this.data.lstrParam41
            this.lVMCommonQuery.lstrParam42=this.data.lstrParam42
            this.lVMCommonQuery.lstrParam43=this.data.lstrParam43
            this.lVMCommonQuery.lstrParam44=this.data.lstrParam44
            this.lVMCommonQuery.lstrParam45=this.data.lstrParam45
            this.lVMCommonQuery.lstrParam46=this.data.lstrParam46
            this.lVMCommonQuery.lstrParam47=this.data.lstrParam47
            this.lVMCommonQuery.lstrParam48=this.data.lstrParam48
            this.lVMCommonQuery.lstrParam49=this.data.lstrParam49
            this.lVMCommonQuery.lstrParam50=this.data.lstrParam50   
            this.lVMCommonQuery.lstrParam51=this.data.lstrParam51
            this.lVMCommonQuery.lstrParam52	=this.data.lstrParam52
            this.lVMCommonQuery.lstrParam53	=this.data.lstrParam53
            this.lVMCommonQuery.lstrParam54	=this.data.lstrParam54 
            this.lVMCommonQuery.lstrParam55	=this.data.lstrParam55
            this.lVMCommonQuery.lstrParam56	=this.data.lstrParam56
            this.lVMCommonQuery.lstrParam57	=this.data.lstrParam57
            this.lVMCommonQuery.lstrParam58	=this.data.lstrParam58
            this.lVMCommonQuery.lstrParam59	=this.data.lstrParam59
            this.lVMCommonQuery.lstrParam60	=this.data.lstrParam60
            this.lVMCommonQuery.lstrParam61	=this.data.lstrParam61
            this.lVMCommonQuery.lstrParam62	=this.data.lstrParam62
            this.lVMCommonQuery.lstrParam63	=this.data.lstrParam63
            this.lVMCommonQuery.lstrParam64	=this.data.lstrParam64
            this.lVMCommonQuery.lstrParam65	=this.data.lstrParam65
            this.lVMCommonQuery.lstrParam66	=this.data.lstrParam66
            this.lVMCommonQuery.lstrParam67	=this.data.lstrParam67
            this.lVMCommonQuery.lstrParam68	=this.data.lstrParam68
            this.lVMCommonQuery.lstrParam69	=this.data.lstrParam69
            this.lVMCommonQuery.lstrParam70	=this.data.lstrParam70
            this.lVMCommonQuery.lstrParam71	=this.data.lstrParam71
            this.lVMCommonQuery.lstrParam72	=this.data.lstrParam72
            this.lVMCommonQuery.lstrParam73	=this.data.lstrParam73
            this.lVMCommonQuery.lstrParam74	=this.data.lstrParam74
            this.lVMCommonQuery.lstrParam75	=this.data.lstrParam75
            this.lVMCommonQuery.lstrParam76	=this.data.lstrParam76
            this.lVMCommonQuery.lstrParam77	=this.data.lstrParam77
            this.lVMCommonQuery.lstrParam78	=this.data.lstrParam78
            this.lVMCommonQuery.lstrParam79	=this.data.lstrParam79
            this.lVMCommonQuery.lstrParam80	=this.data.lstrParam80
            this.lVMCommonQuery.lstrParam81	=this.data.lstrParam81
            this.lVMCommonQuery.lstrParam82	=this.data.lstrParam82
            this.lVMCommonQuery.lstrParam83	=this.data.lstrParam83
            this.lVMCommonQuery.lstrParam84	=this.data.lstrParam84
            this.lVMCommonQuery.lstrParam85	=this.data.lstrParam85
            this.lVMCommonQuery.lstrParam86	=this.data.lstrParam86
            this.lVMCommonQuery.lstrParam87	=this.data.lstrParam87
            this.lVMCommonQuery.lstrParam88	=this.data.lstrParam88
            this.lVMCommonQuery.lstrParam89	=this.data.lstrParam89
            this.lVMCommonQuery.lstrParam90	=this.data.lstrParam90
            this.lVMCommonQuery.lstrParam91	=this.data.lstrParam91
            this.lVMCommonQuery.lstrParam92	=this.data.lstrParam92
            this.lVMCommonQuery.lstrParam93	=this.data.lstrParam93
            this.lVMCommonQuery.lstrParam94	=this.data.lstrParam94
            this.lVMCommonQuery.lstrParam95	=this.data.lstrParam95
            this.lVMCommonQuery.lstrParam96	=this.data.lstrParam96
            this.lVMCommonQuery.lstrParam97	=this.data.lstrParam97
            this.lVMCommonQuery.lstrParam98	=this.data.lstrParam98
            this.lVMCommonQuery.lstrParam99	=this.data.lstrParam99
            this.lVMCommonQuery.lstrParam100	=this.data.lstrParam100
            this.lVMCommonQuery.lstrParam101	=this.data.lstrParam101
            this.lVMCommonQuery.lstrParam102	=this.data.lstrParam102
            this.lVMCommonQuery.lstrParam103	=this.data.lstrParam103
            this.lVMCommonQuery.lstrParam104	=this.data.lstrParam104
            this.lVMCommonQuery.lstrParam105	=this.data.lstrParam105
            this.lVMCommonQuery.lstrParam106	=this.data.lstrParam106
            this.lVMCommonQuery.lstrParam107	=this.data.lstrParam107
            this.lVMCommonQuery.lstrParam108	=this.data.lstrParam108
            this.lVMCommonQuery.lstrParam109	=this.data.lstrParam109
            this.lVMCommonQuery.lstrParam110	=this.data.lstrParam110
            this.lVMCommonQuery.lstrParam111	=this.data.lstrParam111
            this.lVMCommonQuery.lstrParam112	=this.data.lstrParam112
            this.lVMCommonQuery.lstrParam113	=this.data.lstrParam113
            this.lVMCommonQuery.lstrParam114	=this.data.lstrParam114
            this.lVMCommonQuery.lstrParam115	=this.data.lstrParam115
            this.lVMCommonQuery.lstrParam116	=this.data.lstrParam116
            this.lVMCommonQuery.lstrParam117	=this.data.lstrParam117
            this.lVMCommonQuery.lstrParam118	=this.data.lstrParam118
            this.lVMCommonQuery.lstrParam119	=this.data.lstrParam119
            this.lVMCommonQuery.lstrParam120	=this.data.lstrParam120
            this.lVMCommonQuery.lstrParam121	=this.data.lstrParam121
            this.lVMCommonQuery.lstrParam122	=this.data.lstrParam122
            this.lVMCommonQuery.lstrParam123	=this.data.lstrParam123
            this.lVMCommonQuery.lstrParam124	=this.data.lstrParam124
            this.lVMCommonQuery.lstrParam125	=this.data.lstrParam125
            this.lVMCommonQuery.lstrParam126	=this.data.lstrParam126
            this.lVMCommonQuery.lstrParam127	=this.data.lstrParam127
            this.lVMCommonQuery.lstrParam128	=this.data.lstrParam128
            this.lVMCommonQuery.lstrParam129	=this.data.lstrParam129
            this.lVMCommonQuery.lstrParam130	=this.data.lstrParam130
            this.lVMCommonQuery.lstrParam131	=this.data.lstrParam131
            this.lVMCommonQuery.lstrParam132	=this.data.lstrParam132
            this.lVMCommonQuery.lstrParam133	=this.data.lstrParam133
            this.lVMCommonQuery.lstrParam134	=this.data.lstrParam134
            this.lVMCommonQuery.lstrParam135	=this.data.lstrParam135
            this.lVMCommonQuery.lstrParam136	=this.data.lstrParam136
            this.lVMCommonQuery.lstrParam137	=this.data.lstrParam137
            this.lVMCommonQuery.lstrParam138	=this.data.lstrParam138
            this.lVMCommonQuery.lstrParam139	=this.data.lstrParam139
            this.lVMCommonQuery.lstrParam140	=this.data.lstrParam140
            this.lVMCommonQuery.lstrParam141	=this.data.lstrParam141
            this.lVMCommonQuery.lstrParam142	=this.data.lstrParam142
            this.lVMCommonQuery.lstrParam143	=this.data.lstrParam143
            this.lVMCommonQuery.lstrParam144	=this.data.lstrParam144
            this.lVMCommonQuery.lstrParam145	=this.data.lstrParam145
            this.lVMCommonQuery.lstrParam146	=this.data.lstrParam146
            this.lVMCommonQuery.lstrParam147	=this.data.lstrParam147
            this.lVMCommonQuery.lstrParam148	=this.data.lstrParam148
            this.lVMCommonQuery.lstrParam149	=this.data.lstrParam149
            this.lVMCommonQuery.lstrParam150	=this.data.lstrParam150
            this.lVMCommonQuery.lstrParam151	=this.data.lstrParam151
            this.lVMCommonQuery.lstrParam152	=this.data.lstrParam152
            this.lVMCommonQuery.lstrParam153	=this.data.lstrParam153
            this.lVMCommonQuery.lstrParam154	=this.data.lstrParam154
            this.lVMCommonQuery.lstrParam155	=this.data.lstrParam155
            this.lVMCommonQuery.lstrParam156	=this.data.lstrParam156
            this.lVMCommonQuery.lstrParam157	=this.data.lstrParam157
            this.lVMCommonQuery.lstrParam158	=this.data.lstrParam158
            this.lVMCommonQuery.lstrParam159	=this.data.lstrParam159
            this.lVMCommonQuery.lstrParam160	=this.data.lstrParam160
            this.lVMCommonQuery.lstrParam161	=this.data.lstrParam161
            this.lVMCommonQuery.lstrParam162	=this.data.lstrParam162
            this.lVMCommonQuery.lstrParam163	=this.data.lstrParam163
            this.lVMCommonQuery.lstrParam164	=this.data.lstrParam164
            this.lVMCommonQuery.lstrParam165	=this.data.lstrParam165
            this.lVMCommonQuery.lstrParam166	=this.data.lstrParam166
            this.lVMCommonQuery.lstrParam167	=this.data.lstrParam167
            this.lVMCommonQuery.lstrParam168	=this.data.lstrParam168
            this.lVMCommonQuery.lstrParam169	=this.data.lstrParam169
            this.lVMCommonQuery.lstrParam170	=this.data.lstrParam170
            this.lVMCommonQuery.lstrParam171	=this.data.lstrParam171
            this.lVMCommonQuery.lstrParam172	=this.data.lstrParam172
            this.lVMCommonQuery.lstrParam173	=this.data.lstrParam173
            this.lVMCommonQuery.lstrParam174	=this.data.lstrParam174
            this.lVMCommonQuery.lstrParam175	=this.data.lstrParam175
            this.lVMCommonQuery.lstrParam176	=this.data.lstrParam176
            this.lVMCommonQuery.lstrParam177	=this.data.lstrParam177
            this.lVMCommonQuery.lstrParam178	=this.data.lstrParam178
            this.lVMCommonQuery.lstrParam179	=this.data.lstrParam179
            this.lVMCommonQuery.lstrParam180	=this.data.lstrParam180
            this.lVMCommonQuery.lstrParam181	=this.data.lstrParam181
            this.lVMCommonQuery.lstrParam182	=this.data.lstrParam182
            this.lVMCommonQuery.lstrParam183	=this.data.lstrParam183
            this.lVMCommonQuery.lstrParam184	=this.data.lstrParam184
            this.lVMCommonQuery.lstrParam185	=this.data.lstrParam185
            this.lVMCommonQuery.lstrParam186	=this.data.lstrParam186
            this.lVMCommonQuery.lstrParam187	=this.data.lstrParam187
            this.lVMCommonQuery.lstrParam188	=this.data.lstrParam188
            this.lVMCommonQuery.lstrParam189	=this.data.lstrParam189
            this.lVMCommonQuery.lstrParam190	=this.data.lstrParam190
            this.lVMCommonQuery.lstrParam191	=this.data.lstrParam191
            this.lVMCommonQuery.lstrParam192	=this.data.lstrParam192
            this.lVMCommonQuery.lstrParam193	=this.data.lstrParam193
            this.lVMCommonQuery.lstrParam194	=this.data.lstrParam194
            this.lVMCommonQuery.lstrParam195	=this.data.lstrParam195
            this.lVMCommonQuery.lstrParam196	=this.data.lstrParam196
            this.lVMCommonQuery.lstrParam197	=this.data.lstrParam197
            this.lVMCommonQuery.lstrParam198	=this.data.lstrParam198
            this.lVMCommonQuery.lstrParam199	=this.data.lstrParam199
            this.lVMCommonQuery.lstrParam200	=this.data.lstrParam200

            this.lVMCommonQuery.lstrSearch=lstrSearch
            this.lVMCommonQuery.lstrSortCol=this.data.lstrSortCol
            this.lVMCommonQuery.lbSortAsc=this.data.lbSortAsc 
            let vrdate:Date
            vrdate=new Date() 
            this.ls_status="Requested" + vrdate.toString()
            // console.log(this.ls_status  )
            // this._user_service.getSearchData( this.lVMCommonQuery).subscribe((getdata) => {  
            //     this.lVMCommonQueryresult= getdata                
            //     this.ls_getdata = true  
            //     if(getdata.length==1){
            //         if(this.lVMSearch.lstrShowlist!="Y"){
            //             this.dialogRef.close(getdata[0]);
            //         }
            //     }
            // } );    
            
            
            if(this.lVMSearch.TypeCode=="U"){
                this._user_service.getSearchDataDynu( this.lVMCommonQuery).subscribe((getdata) => {  
                    this.lVMCommonQueryresult=JSON.parse(getdata.toString())                  
                    this.ls_getdata = true  
                    if(getdata.length==1){
                        if(this.lVMSearch.lstrShowlist!="Y"){
                            this.dialogRef.close(getdata[0]);
                        }
                    }
                } ); 
            }
            else{

                this._user_service.getSearchDataDyn( this.lVMCommonQuery).subscribe((getdata) => {  
                    this.lVMCommonQueryresult=JSON.parse(getdata.toString())                  
                    this.ls_getdata = true  
                    if(getdata.length==1){
                        if(this.lVMSearch.lstrShowlist!="Y"){
                            this.dialogRef.close(getdata[0]);
                        }
                    }
                } ); 
            }

        }catch(error){
            this.handleError(error)
        }

    }
    
    handleError(error) { 
        if (error.status == 401) {
            alert("your session has expired please login again!!!")
            //localStorage.removeItem('access_token');   
            this.router.navigate(['account/login']); 
        }
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
         window.alert(errorMessage);
        console.log(error)
        return throwError(errorMessage);
    } 

    selectrow(lv :VMCommonQuery){
        this.lselrow=lv
    }
    select( lv :VMCommonQuery){ 
        // console.log(lv);

        if (this.lVMSearch.TypeCode=='m'){
            this.closeM()

        }else{this.dialogRef.close(lv);}

          
     }

    selectedAll:VMCommonQuery[]=[];
    selectM(checkValue,selectedRec:VMCommonQuery)
    {
        debugger
        if(checkValue){
            this.selectedAll.push(selectedRec);
        }else{
            let i=this.selectedAll.length
            while (i--) { 
                if(this.selectedAll[i]==selectedRec){
                    this.selectedAll.splice(i,1)
                }
            }
        }
    }

    async closeM(){

        if (this.lVMSearch.TypeCode=='m'){
             
            let i=this.selectedAll.length
            if(i==0 || i==null || i==undefined){
                
              let resutdt

                if(this.language_code=="AR"){

                      resutdt= await this._bfs.f_showmessage_yes_no("تحذير","لم تقم بتحديد أي سجلات. هل تريد الإغلاق" )
                }
                else{
                      resutdt= await this._bfs.f_showmessage_yes_no('WARNING','You have not selected any records.Do you want to Close' )
                }

                if (resutdt==false ){
                     return
                }else{
                    this.dialogRef.close(this.selectedAll); 
                } 
              }
            this.dialogRef.close(this.selectedAll);  
    
        }else{this.dialogRef.close(this.lselrow);}
    
               
    }

    ue_cancel() {
        this.lVMCommonQuery.lstrData1="Cancel";
        this.dialogRef.close(this.lVMCommonQuery);
    }
     
    applyFilter(filterValue: string) {
        try{
            this.dataSource.filter = filterValue.trim().toLowerCase();
        }catch(error){
            this.handleError(error)
        }
        
      }

      keyenter(filterValue: string) { 
        this.getData(filterValue.trim().toLowerCase());
      }
      setInis_dynasearch(){
        
        let ls_VMCommonQuery:  VMCommonQuery= {
            TypeCode	:	''	,
            lstrData1	:	''	,
            lstrData2	:	''	,
            lstrData3	:	''	,
            lstrData4	:	''	,
            lstrData5	:	''	,
            lstrData6	:	''	,
            lstrData7	:	''	,
            lstrData8	:	''	,
            lstrData9	:	''	,
            lstrData10	:	''	,
            lstrData11	:	''	,
            lstrData12	:	''	,
            lstrData13	:	''	,
            lstrData14	:	''	,
            lstrData15	:	''	,
            lstrData16	:	''	,
            lstrData17	:	''	,
            lstrData18	:	''	,
            lstrData19	:	''	,
            lstrData20	:	''	,
            lstrData21	:	''	,
            lstrData22	:	''	,
            lstrData23	:	''	,
            lstrData24	:	''	,
            lstrData25	:	''	,
            lstrData26	:	''	,
            lstrData27	:	''	,
            lstrData28	:	''	,
            lstrData29	:	''	,
            lstrData30	:	''	,
            lstrData31	:	''	,
            lstrData32	:	''	,
            lstrData33	:	''	,
            lstrData34	:	''	,
            lstrData35	:	''	,
            lstrData36	:	''	,
            lstrData37	:	''	,
            lstrData38	:	''	,
            lstrData39	:	''	,
            lstrData40	:	''	,
            lstrData41	:	''	,
            lstrData42	:	''	,
            lstrData43	:	''	,
            lstrData44	:	''	,
            lstrData45	:	''	,
            lstrData46	:	''	,
            lstrData47	:	''	,
            lstrData48	:	''	,
            lstrData49	:	''	,
            lstrData50	:	''	,

             

            lstrSQL	:	''	,
            lstrError	:	''	,
            
            lstrUser :'',
            lstrParam1	:	''	,
            lstrParam2	:	''	,
            lstrParam3	:	''	,
            lstrParam4	:	''	,
            lstrParam5	:	''	,
            lstrParam6	:	''	,
            lstrParam7	:	''	,
            lstrParam8	:	''	,
            lstrParam9	:	''	,
            lstrParam10	:	''	,
            lstrParam11	:	''	,
            lstrParam12	:	''	,
            lstrParam13	:	''	,
            lstrParam14	:	''	,
            lstrParam15	:	''	,
            lstrParam16	:	''	,
            lstrParam17	:	''	,
            lstrParam18	:	''	,
            lstrParam19	:	''	,
            lstrParam20	:	''	,
            lstrParam21	:	''	,
            lstrParam22	:	''	,
            lstrParam23	:	''	,
            lstrParam24	:	''	,
            lstrParam25	:	''	,
            lstrParam26	:	''	,
            lstrParam27	:	''	,
            lstrParam28	:	''	,
            lstrParam29	:	''	,
            lstrParam30	:	''	,
            lstrParam31	:	''	,
            lstrParam32	:	''	,
            lstrParam33	:	''	,
            lstrParam34	:	''	,
            lstrParam35	:	''	,
            lstrParam36	:	''	,
            lstrParam37	:	''	,
            lstrParam38	:	''	,
            lstrParam39	:	''	,
            lstrParam40	:	''	,
            lstrParam41	:	''	,
            lstrParam42	:	''	,
            lstrParam43	:	''	,
            lstrParam44	:	''	,
            lstrParam45	:	''	,
            lstrParam46	:	''	,
            lstrParam47	:	''	,
            lstrParam48	:	''	,
            lstrParam49	:	''	,
            lstrParam50	:	''	, 

            lstrParam51	:	'', 
            lstrParam52	:	'', 
            lstrParam53	:	'', 
            lstrParam54	:	'', 
            lstrParam55	:	'', 
            lstrParam56	:	'', 
            lstrParam57	:	'', 
            lstrParam58	:	'', 
            lstrParam59	:	'', 
            lstrParam60	:	'', 
            lstrParam61	:	'', 
            lstrParam62	:	'', 
            lstrParam63	:	'', 
            lstrParam64	:	'', 
            lstrParam65	:	'', 
            lstrParam66	:	'', 
            lstrParam67	:	'', 
            lstrParam68	:	'', 
            lstrParam69	:	'', 
            lstrParam70	:	'', 
            lstrParam71	:	'', 
            lstrParam72	:	'', 
            lstrParam73	:	'', 
            lstrParam74	:	'', 
            lstrParam75	:	'', 
            lstrParam76	:	'', 
            lstrParam77	:	'', 
            lstrParam78	:	'', 
            lstrParam79	:	'', 
            lstrParam80	:	'', 
            lstrParam81	:	'', 
            lstrParam82	:	'', 
            lstrParam83	:	'', 
            lstrParam84	:	'', 
            lstrParam85	:	'', 
            lstrParam86	:	'', 
            lstrParam87	:	'', 
            lstrParam88	:	'', 
            lstrParam89	:	'', 
            lstrParam90	:	'', 
            lstrParam91	:	'', 
            lstrParam92	:	'', 
            lstrParam93	:	'', 
            lstrParam94	:	'', 
            lstrParam95	:	'', 
            lstrParam96	:	'', 
            lstrParam97	:	'', 
            lstrParam98	:	'', 
            lstrParam99	:	'', 
            lstrParam100	:	'', 
            lstrParam101	:	'', 
            lstrParam102	:	'', 
            lstrParam103	:	'', 
            lstrParam104	:	'', 
            lstrParam105	:	'', 
            lstrParam106	:	'', 
            lstrParam107	:	'', 
            lstrParam108	:	'', 
            lstrParam109	:	'', 
            lstrParam110	:	'', 
            lstrParam111	:	'', 
            lstrParam112	:	'', 
            lstrParam113	:	'', 
            lstrParam114	:	'', 
            lstrParam115	:	'', 
            lstrParam116	:	'', 
            lstrParam117	:	'', 
            lstrParam118	:	'', 
            lstrParam119	:	'', 
            lstrParam120	:	'', 
            lstrParam121	:	'', 
            lstrParam122	:	'', 
            lstrParam123	:	'', 
            lstrParam124	:	'', 
            lstrParam125	:	'', 
            lstrParam126	:	'', 
            lstrParam127	:	'', 
            lstrParam128	:	'', 
            lstrParam129	:	'', 
            lstrParam130	:	'', 
            lstrParam131	:	'', 
            lstrParam132	:	'', 
            lstrParam133	:	'', 
            lstrParam134	:	'', 
            lstrParam135	:	'', 
            lstrParam136	:	'', 
            lstrParam137	:	'', 
            lstrParam138	:	'', 
            lstrParam139	:	'', 
            lstrParam140	:	'', 
            lstrParam141	:	'', 
            lstrParam142	:	'', 
            lstrParam143	:	'', 
            lstrParam144	:	'', 
            lstrParam145	:	'', 
            lstrParam146	:	'', 
            lstrParam147	:	'', 
            lstrParam148	:	'', 
            lstrParam149	:	'', 
            lstrParam150	:	'', 
            lstrParam151	:	'', 
            lstrParam152	:	'', 
            lstrParam153	:	'', 
            lstrParam154	:	'', 
            lstrParam155	:	'', 
            lstrParam156	:	'', 
            lstrParam157	:	'', 
            lstrParam158	:	'', 
            lstrParam159	:	'', 
            lstrParam160	:	'', 
            lstrParam161	:	'', 
            lstrParam162	:	'', 
            lstrParam163	:	'', 
            lstrParam164	:	'', 
            lstrParam165	:	'', 
            lstrParam166	:	'', 
            lstrParam167	:	'', 
            lstrParam168	:	'', 
            lstrParam169	:	'', 
            lstrParam170	:	'', 
            lstrParam171	:	'', 
            lstrParam172	:	'', 
            lstrParam173	:	'', 
            lstrParam174	:	'', 
            lstrParam175	:	'', 
            lstrParam176	:	'', 
            lstrParam177	:	'', 
            lstrParam178	:	'', 
            lstrParam179	:	'', 
            lstrParam180	:	'', 
            lstrParam181	:	'', 
            lstrParam182	:	'', 
            lstrParam183	:	'', 
            lstrParam184	:	'', 
            lstrParam185	:	'', 
            lstrParam186	:	'', 
            lstrParam187	:	'', 
            lstrParam188	:	'', 
            lstrParam189	:	'', 
            lstrParam190	:	'', 
            lstrParam191	:	'', 
            lstrParam192	:	'', 
            lstrParam193	:	'', 
            lstrParam194	:	'', 
            lstrParam195	:	'', 
            lstrParam196	:	'', 
            lstrParam197	:	'', 
            lstrParam198	:	'', 
            lstrParam199	:	'', 
            lstrParam200	:	'', 
                lstrSearch :'',

            lstrSortCol :1,
            lbSortAsc:"Y",
    }
    return ls_VMCommonQuery;
}

    async wf_drill_to_screen( ){

        

        let ls_data:string

        if(this.lVMSearch.lstrParam199=="1"){ 
            ls_data=this.lselrow.lstrData1
        }
        else if(this.lVMSearch.lstrParam199=="2"){
            ls_data=this.lselrow.lstrData2
        } 
        else if(this.lVMSearch.lstrParam199=="3"){
            ls_data=this.lselrow.lstrData3
        } 
        else if(this.lVMSearch.lstrParam199=="4"){
            ls_data=this.lselrow.lstrData1
        } 
        else if(this.lVMSearch.lstrParam199=="5"){
            ls_data=this.lselrow.lstrData5
        } 
        else if(this.lVMSearch.lstrParam199=="6"){
            ls_data=this.lselrow.lstrData6
        } 
        else if(this.lVMSearch.lstrParam199=="7"){
            ls_data=this.lselrow.lstrData7
        } 
        else if(this.lVMSearch.lstrParam199=="8"){
            ls_data=this.lselrow.lstrData8
        } 
        else if(this.lVMSearch.lstrParam199=="9"){
            ls_data=this.lselrow.lstrData9
        } 
        else if(this.lVMSearch.lstrParam199=="10"){
            ls_data=this.lselrow.lstrData10
        } 
        else if(this.lVMSearch.lstrParam199=="11"){
            ls_data=this.lselrow.lstrData11
        } 

        if(this.lselrow){

            this.ls_drill=this.set_drill()
            this.ls_drill.ref_type =  'Approval Screen'
            this.ls_drill.text1=ls_data

            let l_VMCommonQuery :VMCommonQuery
            l_VMCommonQuery =this.setInis_dynasearch(); 
            l_VMCommonQuery.lstrSQL= "master_drill" 
            l_VMCommonQuery.lstrParam1=this.lVMSearch.lstrParam200 
             
            l_VMCommonQuery.lstrParam2=ls_data
            l_VMCommonQuery=  await this._user_service.getInLineResultAsyc(l_VMCommonQuery)
            if(l_VMCommonQuery){

                if(!l_VMCommonQuery.lstrData4){
                    this._bfs.f_showmessage("Info","Access denied.You do not have rights to open the Document")
                    return
                } 
                localStorage.setItem('s_drill', JSON.stringify(this.ls_drill)); 
                const url1 = this.router.createUrlTree([  l_VMCommonQuery.lstrData1 +'/' + l_VMCommonQuery.lstrData2 ]).toString();
                window.open('#' + url1, '_blank');
            }
              
                // s_transinfo.top_a_code=dw_1.getitemstring(dw_1.getrow(), s_thisstru.master_col) 
                
                // select location_type into :is_sub_head From BRMAS where br_code=:s_transinfo.top_a_code ;
                
                // if is_sub_head ='C' then 
                //     ls_window='w_cost_center_master'
                // elseif is_sub_head ='J' then 
                //     ls_window='w_jobmaster_tree'
                // elseif is_sub_head ='L' then 
                //     ls_window='w_location_master_tree'
                // end if 
                
                
                
                // s_transinfo.access_rights=''
                // select menu_tree.code,menu_tree.name into :ls_menu_code,:S_TRANSINFO.window_title from menu_object,menu_tree  where 
                // object_code=menu_object.code 
                // and menu_tree.prfile_code=:VG_MENU_PROFILE  and object_type='S_TRANSINFO' AND object_name= :ls_window ;
                
                // S_TRANSINFO.window_tag=ls_menu_code
                
                // select rights_code into :s_transinfo.access_rights from menu_rights where user_id=:vg_userid and  code =left(:ls_menu_code ,len(code));
                
                
                // if ls_window='w_cost_center_master' then s_transinfo.access_rights ='CMDES@PLW'
                
                // if isnull(s_transinfo.access_rights) or s_transinfo.access_rights='' then 
                //     gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
                //     return
                // end if	
            
                // SELECT  count (*) into  :lcnt    FROM brmas,dicuser_job_rights   WHERE  brmas.br_code=:s_transinfo.top_a_code and    brmas.br_code=dicuser_job_rights.job_no and dicuser_job_rights.user_id=suser_sname() ;

                // if isnull(lcnt) then lcnt =0
                
                // if lcnt =0 then 
                //     gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
                //     return 
                // end if 	
                // opensheetwithparm(vl_win,s_transinfo,ls_window ,vg_mdi,1,layered!)
                
            

        }
        // choose case  ls_tag
        //     case 'Itemmaster'
        //         s_transinfo.top_a_code=dw_1.getitemstring(dw_1.getrow(), s_thisstru.master_col) 
        //         s_transinfo.ite_code=dw_1.getitemstring(dw_1.getrow(), s_thisstru.master_col) 
        //         select GROUP_CODE into :is_sub_head From dicihmas where ite_code=:s_transinfo.ite_code ;
                
        //         if is_sub_head ='P' then 
        //             ls_window='w_itemmaster_spare_parts'
        //         else 
        //             ls_window='w_item_master_project'
        //         end if 
                
        //         s_transinfo.access_rights=''
        //         select menu_tree.code,menu_tree.name into :ls_menu_code,:S_TRANSINFO.window_title from menu_object,menu_tree  where 
        //         object_code=menu_object.code 
        //         and menu_tree.prfile_code=:VG_MENU_PROFILE  and object_type='S_TRANSINFO' AND object_name= :ls_window ;
                
        //         S_TRANSINFO.window_tag=ls_menu_code
                
        //         select rights_code into :s_transinfo.access_rights from menu_rights where user_id=:vg_userid and  code =left(:ls_menu_code ,len(code));
                
                
        //         if isnull(s_transinfo.access_rights) or s_transinfo.access_rights='' then 
        //             gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //             return
        //         end if	
            
        //         opensheetwithparm(vl_win,s_transinfo,ls_window ,vg_mdi,1,layered!)
                
        //     case 'w_employee_master'
                
        //         s_transinfo.text1=dw_1.getitemstring(dw_1.getrow(), s_thisstru.master_col) 
                
        //         select company_code into :s_transinfo.fy_code From pay_employee_master where code =:s_transinfo.text1 ;
        //         ls_window='w_employee_master'
                
        //         s_transinfo.access_rights=''
        //         select menu_tree.code,menu_tree.name into :ls_menu_code,:S_TRANSINFO.window_title from menu_object,menu_tree  where 
        //         object_code=menu_object.code 
        //         and menu_tree.prfile_code=:VG_MENU_PROFILE  and object_type='S_TRANSINFO' AND object_name= :ls_window ;
                
        //         S_TRANSINFO.window_tag=ls_menu_code
                
        //         select rights_code into :s_transinfo.access_rights from menu_rights where user_id=:vg_userid and  code =left(:ls_menu_code ,len(code));
                
                
        //         if isnull(s_transinfo.access_rights) or s_transinfo.access_rights='' then 
        //             gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //             return
        //         end if	
                
                
                

                
                

        //         string is_emp_rights_filter_applicable,IS_company_filter,IS_dept_filter,Is_filtered_employee_code
                
        //         select emp_rights_filter_applicable,payroll_company_filter,payroll_dept_filter,isnull(emp_code,'')
        //         into :is_emp_rights_filter_applicable,:IS_company_filter, :IS_dept_filter,:Is_filtered_employee_code     
        //         from dicuser where user_id=suser_sname();
                
                
        //         if is_emp_rights_filter_applicable='N' then
        //             SELECT  count (*) into :lcnt
        //             FROM pay_employee_master a, pay_emp_category_master b 
        //             Where  a.code=:s_transinfo.text1 and  a.category = b.code and  	 category  in 
        //             (select category_code from dicuser_category_rights where user_id=suser_sname() )  
        //             and (:IS_company_filter  ='N' or company_code in 
        //             (select company_code   from company_rights where user_id=suser_sname()) ) 
        //             and 		(:IS_dept_filter ='N' or department in
        //             (select job_no   from dicuser_hr_dept where user_id=suser_sname() ) ) ;
                
        //         else
                
        //                 SELECT  count (*) into  :lcnt FROM pay_employee_master a, pay_emp_category_master b Where a.code=:s_transinfo.text1 and a.category = b.code and   
        //                 category  in (select category_code from dicuser_category_rights where user_id=suser_sname() )  and &
        //                 (:IS_company_filter  ='N' or company_code in (select company_code   from company_rights where user_id=suser_sname()) ) and  
        //                 (:IS_dept_filter ='N' or department in (select job_no   from dicuser_hr_dept where user_id=suser_sname() ) ) ;
                
        //         end if 
                
                
        //         if isnull(lcnt) then lcnt =0
        //         if lcnt =0 then 
        //             gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //             return 
        //         end if 	
                
        //         opensheetwithparm(vl_win,s_transinfo,ls_window ,vg_mdi,1,layered!)
                
        //     case 'Assetmaster'
                
        //         s_transinfo.vr_no=dw_1.GetItemNumber(dw_1.getrow(), s_thisstru.master_col) 
            
        //         s_transinfo.access_rights=''
        //         select menu_tree.code,menu_tree.name into :ls_menu_code,:S_TRANSINFO.window_title from menu_object,menu_tree  where 
        //         object_code=menu_object.code 
        //         and menu_tree.prfile_code=:VG_MENU_PROFILE  and object_type='S_TRANSINFO' AND object_name='w_asset_master_tree';
                
        //         S_TRANSINFO.window_tag=ls_menu_code
                
        //         select rights_code into :s_transinfo.access_rights from menu_rights where user_id=:vg_userid and  code =left(:ls_menu_code ,len(code));
                
                
        //         if isnull(s_transinfo.access_rights) or s_transinfo.access_rights='' then 
        //             gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //             return
        //         end if	
            
            
        //         opensheetwithparm(vl_win,s_transinfo,"w_asset_master_tree",vg_mdi,1,layered!)
            
            
        //     case 'Locationmaster'
                
                
        //         s_transinfo.top_a_code=dw_1.getitemstring(dw_1.getrow(), s_thisstru.master_col) 
                
        //         select location_type into :is_sub_head From BRMAS where br_code=:s_transinfo.top_a_code ;
                
        //         if is_sub_head ='C' then 
        //             ls_window='w_cost_center_master'
        //         elseif is_sub_head ='J' then 
        //             ls_window='w_jobmaster_tree'
        //         elseif is_sub_head ='L' then 
        //             ls_window='w_location_master_tree'
        //         end if 
                
                
                
        //         s_transinfo.access_rights=''
        //         select menu_tree.code,menu_tree.name into :ls_menu_code,:S_TRANSINFO.window_title from menu_object,menu_tree  where 
        //         object_code=menu_object.code 
        //         and menu_tree.prfile_code=:VG_MENU_PROFILE  and object_type='S_TRANSINFO' AND object_name= :ls_window ;
                
        //         S_TRANSINFO.window_tag=ls_menu_code
                
        //         select rights_code into :s_transinfo.access_rights from menu_rights where user_id=:vg_userid and  code =left(:ls_menu_code ,len(code));
                
                
        //         if ls_window='w_cost_center_master' then s_transinfo.access_rights ='CMDES@PLW'
                
        //         if isnull(s_transinfo.access_rights) or s_transinfo.access_rights='' then 
        //             gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //             return
        //         end if	
            
        //         SELECT  count (*) into  :lcnt    FROM brmas,dicuser_job_rights   WHERE  brmas.br_code=:s_transinfo.top_a_code and    brmas.br_code=dicuser_job_rights.job_no and dicuser_job_rights.user_id=suser_sname() ;

        //         if isnull(lcnt) then lcnt =0
                
        //         if lcnt =0 then 
        //             gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //             return 
        //         end if 	
        //         opensheetwithparm(vl_win,s_transinfo,ls_window ,vg_mdi,1,layered!)
                
            
        //     case 'Accounts'
                
            
            
        //         s_transinfo.top_a_code=dw_1.getitemstring(dw_1.getrow(), s_thisstru.master_col) 
            
        //         select sub_head into :is_sub_head from dicasmas where as_code=left(:s_transinfo.top_a_code,6);
            
        //         s_transinfo.access_rights=''
                
        //         if is_sub_head='D' then
                    
        //             select menu_tree.code,menu_tree.name into :ls_menu_code,:S_TRANSINFO.window_title from menu_object,menu_tree  where 
        //             object_code=menu_object.code 
        //             and menu_tree.prfile_code=:VG_MENU_PROFILE  and object_type='S_TRANSINFO' AND object_name='w_customer_master_tree';
                    
        //             S_TRANSINFO.window_tag=ls_menu_code
                    
        //             select rights_code into :s_transinfo.access_rights from menu_rights where user_id=:vg_userid and  code =left(:ls_menu_code ,len(code));
                    
                    
        //             if isnull(s_transinfo.access_rights) or s_transinfo.access_rights='' then 
        //                 gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //                 return
        //             end if	
                    
        //             SELECT  count(*)    into  :lcnt      FROM dicadmas        WHERE    acc_code=:s_transinfo.top_a_code and 
        //             ('All' in ( select DEBIT_COLLECTION_code from DEBIT_COLLECTION_RIGHTS where user_id =suser_sname() )      or debt_collection in ( select DEBIT_COLLECTION_code  from DEBIT_COLLECTION_RIGHTS where user_id =suser_sname()) )
        //             and AS_CODE in (  
        //             SELECT   as_code    
        //             FROM dicasmas,user_customer_master_filter   WHERE sub_head='D' 
        //             and dicasmas.as_code=user_customer_master_filter.group_code
        //             and user_customer_master_filter.user_id=suser_sname()  );

        //             if isnull(lcnt) then lcnt =0
                    
        //             if lcnt =0 then 
        //                 gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //                 return 
        //             end if 	
                
        //             opensheetwithparm(vl_win,s_transinfo,"w_customer_master_tree",vg_mdi,1,layered!)
                
        //         elseif 	is_sub_head='C' then
                    
        //             select menu_tree.code,menu_tree.name into :ls_menu_code,:S_TRANSINFO.window_title from menu_object,menu_tree  where 
        //             object_code=menu_object.code 
        //             and menu_tree.prfile_code=:VG_MENU_PROFILE  and object_type='S_TRANSINFO' AND object_name='w_supplier_master_tree';
                    
        //             S_TRANSINFO.window_tag=ls_menu_code
                    
        //             select rights_code into :s_transinfo.access_rights from menu_rights where user_id=:vg_userid and  code =left(:ls_menu_code ,len(code));
                    
                    
        //             if isnull(s_transinfo.access_rights) or s_transinfo.access_rights='' then 
        //                 gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //                 return
        //             end if	
                    
        //             SELECT  count(*)    into  :lcnt      FROM dicadmas        WHERE    acc_code=:s_transinfo.top_a_code and 
        //             AS_CODE in (  			SELECT   as_code    
        //             FROM dicasmas,user_supplier_master_filter   WHERE sub_head='C' 
        //             and dicasmas.as_code=user_supplier_master_filter.group_code
        //             and user_supplier_master_filter.user_id=suser_sname()  );

        //             if isnull(lcnt) then lcnt =0
                    
        //             if lcnt =0 then 
        //                 gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //                 return 
        //             end if 	
                        
                    
        //             opensheetwithparm(vl_win,s_transinfo,"w_supplier_master_tree",vg_mdi,1,layered!)
                
        //         elseif 	is_sub_head='B' then
                    
        //             select menu_tree.code,menu_tree.name into :ls_menu_code,:S_TRANSINFO.window_title from menu_object,menu_tree  where 
        //             object_code=menu_object.code 
        //             and menu_tree.prfile_code=:VG_MENU_PROFILE  and object_type='S_TRANSINFO' AND object_name='w_bank_master_tree';
                    
        //             S_TRANSINFO.window_tag=ls_menu_code
                    
        //             select rights_code into :s_transinfo.access_rights from menu_rights where user_id=:vg_userid and  code =left(:ls_menu_code ,len(code));
                    
                    
        //             if isnull(s_transinfo.access_rights) or s_transinfo.access_rights='' then 
        //                 gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //                 return
        //             end if	
                    
        //             SELECT  count(*)    into  :lcnt      FROM dicadmas        WHERE    acc_code=:s_transinfo.top_a_code and 
        //             AS_CODE in (  			SELECT   as_code    
        //             FROM dicasmas,user_bank_master_filter   WHERE sub_head='B' 
        //             and dicasmas.as_code=user_bank_master_filter.group_code
        //             and user_bank_master_filter.user_id=suser_sname()  );

        //             if isnull(lcnt) then lcnt =0
                    
        //             if lcnt =0 then 
        //                 gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //                 return 
        //             end if 	
                    
        //             opensheetwithparm(vl_win,s_transinfo,"w_bank_master_tree",vg_mdi,1,layered!)
                
        //         else 
            
        //             select menu_tree.code,menu_tree.name into :ls_menu_code,:S_TRANSINFO.window_title from menu_object,menu_tree  where 
        //             object_code=menu_object.code 
        //             and menu_tree.prfile_code=:VG_MENU_PROFILE  and object_type='S_TRANSINFO' AND object_name='w_accounts_master_tree';
                    
        //             S_TRANSINFO.window_tag=ls_menu_code
                    
        //             select rights_code into :s_transinfo.access_rights from menu_rights where user_id=:vg_userid and  code =left(:ls_menu_code ,len(code));
                    
                    
        //             if isnull(s_transinfo.access_rights) or s_transinfo.access_rights='' then 
        //                 gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //                 return
        //             end if	
                    
        //             SELECT  count(*)    into  :lcnt      FROM dicadmas        WHERE    acc_code=:s_transinfo.top_a_code and 
        //             AS_CODE in (  			SELECT   as_code    
        //             FROM dicasmas,user_gl_master_filter   WHERE sub_head='N' 
        //             and dicasmas.as_code=user_gl_master_filter.group_code
        //             and user_gl_master_filter.user_id=suser_sname()  );

        //             if isnull(lcnt) then lcnt =0
                    
        //             if lcnt =0 then 
        //                 gnv_msgbox.MessageBox('Security Rights','Access denied.You do not have rights to open the Document')
        //                 return 
        //             end if 	
                    
        //             opensheetwithparm(vl_win,s_transinfo,"w_accounts_master_tree",vg_mdi,1,layered!)
                
        //         end if	

        // end choose

    


    }

customSort(event: SortEvent) {
    let strStartsWithALetter,n,strDate1,strDate2 ;
    this.ls_search= ! this.ls_search;
    if(this.ls_search){
        event.data.sort((data1, data2) => {
            let value1 = data1[event.field];
            let value2 = data2[event.field];
            let result = null;
            if (value1 == null && value2 != null){
                result = -1;
            }
            else if (value1 != null && value2 == null){
                result = 1;
            }
            else if (value1 == null && value2 == null){
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string'){
                n = value1.charCodeAt(0);
                strStartsWithALetter = (n >= 65 && n < 91) || (n >= 97 && n < 123);
                result = value1.localeCompare(value2);
                
                n=value1.charAt(2);
                if(n=='/'){
                    strDate1 = value1.split('/').reverse().join();
                    strDate2 = value2.split('/').reverse().join();
                    result = (strDate1 < strDate2) ? -1 : (strDate1 > strDate2) ? 1 : 0;
                    return (event.order * result);
                }
                
            }            
            else{
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            if(strStartsWithALetter){
                return (event.order * result);
            }
            return value1 - value2;
        }); 
    }else{
         event.data.sort((data1, data2) => {
            let value1 = data1[event.field];
            let value2 = data2[event.field];
            let result = null;
            if (value2 == null && value1 != null){
                result = -1;
            }
            else if (value2 != null && value1 == null){
                result = 1;
            }
            else if (value2 == null && value1 == null){
                result = 0;
            }
            else if (typeof value2 === 'string' && typeof value1 === 'string'){
                n = value1.charCodeAt(0);
                strStartsWithALetter = (n >= 65 && n < 91) || (n >= 97 && n < 123);
                result = value2.localeCompare(value1);
                
                n=value1.charAt(2);
                if(n=='/'){
                    strDate1 = value1.split('/').reverse().join();
                    strDate2 = value2.split('/').reverse().join();
                    result = (strDate2 > strDate1) ? -1 : (strDate2 < strDate1) ? 1 : 0;
                    return (event.order * result);
                }
            }            
            else{
                
                result = (value2 < value1) ? -1 : (value2 > value1) ? 1 : 0;
            }
            if(strStartsWithALetter){
                result = (value2 > value1) ? -1 : (value2 < value1) ? 1 : 0;
                return (event.order * result);
            }
            return value2 - value1;
        }); 
    }
   
}

    set_drill(){
        let arr:string[]=[]
        let data :s_drill ={
            
            fy_code	:	"",
            trc_code	:	"",
            vr_no	:	 0,
            ref_fy_code	:	"",
            ref_trc_code	:	"",
            ref_vr_no	:	 0,
            ref_sr_no	:	 0,
            ref_dsr_no	:	 0,
            text1	:	"",
            text2	:	"",
            text3	:	"",
            text4	:	"",
            text5	:	"",
            text6   :	"",
            text7	:	"",
            ref_type	:	"",
            ref_vr_nos:arr
        }
        return data
    }


    // compare function
    
// loadCustomers(event: LazyLoadEvent) {  
//     debugger
//      this.loading = true;
 
//      //imitate db connection over a network
//      this.lVMCommonQueryresult.sort((a, b) => this.compareField(a, b, event.sortField) * event.sortOrder); 
//      // this.ls_lVMCommonQueryresult= this.lVMCommonQueryresult
//      console.log(this.datasource, this.ls_lVMCommonQueryresult, this.lVMCommonQueryresult, "load")
//      setTimeout(() => {
//          debugger
//              // this.ls_lVMCommonQueryresult = this.datasource.slice(event.first, (event.first + event.rows));
//             if(this.datasource){
//              // this.ls_lVMCommonQueryresult = this.datasource.slice(event.first, (event.first + event.rows));
            
//              // console.log(this.datasource, this.ls_lVMCommonQueryresult, this.lVMCommonQueryresult, "load")
//              this.ls_lVMCommonQueryresult= this.lVMCommonQueryresult;
//              this.loading = false;
//             }
             
//              }, 1000);
 
//          }
 
//      compareField(rowA, rowB, field: string): number {
//          if (rowA[field] == null) return 1;
//          if (typeof rowA[field] === 'string') {
//          return rowA[field].localeCompare(rowB[field]);
//          }
//          if (typeof rowA[field] === 'number') {
//          if (rowA[field] > rowB[field]) return 1;
//          else return -1;
//          }
//      }
 
}
