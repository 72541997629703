import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "onlyTime" })
export class OnlyTimePipe implements PipeTransform {
  transform(dateString: string): any {
    if (dateString) {
      if (dateString.length > 5) {
          return dateString.substring(11, 16);
      } else {
        return dateString;
      }
    }
  }
}
