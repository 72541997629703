<div class="">
        <div mat-dialog-title class="background">Select Criteria</div>
        <br>
        <div class="column p-0 pt-24" *ngIf="ls_rangeoption=='Y'"  >
                <div class="row mb-16">
                        <mat-radio-group [(ngModel)]="ls_selOption" (change)="option_changed()" >
                                <mat-radio-button value="3" class="ml-0">Current #</mat-radio-button>
                                <mat-radio-button value="1" class="ml-8">Document #</mat-radio-button>
                                <mat-radio-button value="2"  class="ml-8">Date</mat-radio-button>
                                
                        </mat-radio-group>
                </div> 
                <div class="row mb-8" *ngIf="ls_selOption=='1'">
                        <mat-form-field  appearance="outline" class=" ">
                        <mat-label>Voucher # - From</mat-label>
                        <input [(ngModel)]="vmReportFilters.fromno" matInput class="mt-8">
                        </mat-form-field>

                        <mat-form-field  appearance="outline" class=" ">
                        <mat-label>Voucher #  - To</mat-label>
                        <input [(ngModel)]="vmReportFilters.tono" matInput>
                        </mat-form-field>             
                </div> 
                <div class="row mb-8"  *ngIf="ls_selOption=='2'" >
                        <mat-form-field appearance="outline" class=" ">
                                <mat-label>From Date</mat-label>
                                <input  [(ngModel)]="vmReportFilters.fromdate" matInput [readonly]="true" [matDatepicker]="pick_fromdate" class="mt-8">
                                <mat-datepicker-toggle matSuffix [for]="pick_fromdate" ></mat-datepicker-toggle>
                                <mat-datepicker #pick_fromdate></mat-datepicker>
                        </mat-form-field>


                        <mat-form-field appearance="outline" class=" ">
                                <mat-label>To Date</mat-label>
                                <input  [(ngModel)]="vmReportFilters.todate" matInput [readonly]="true" [matDatepicker]="pick_todate" class="mt-8">
                                <mat-datepicker-toggle matSuffix [for]="pick_todate" ></mat-datepicker-toggle>
                                <mat-datepicker #pick_todate></mat-datepicker>
                        </mat-form-field>                
                </div>
        
                <div class="row mb-8"   *ngIf="ls_selOption=='3'" >
                        <mat-form-field  appearance="outline" class=" ">
                        <mat-label>Voucher # - From</mat-label>
                        <input [(ngModel)]="vmReportFilters.fromno" matInput [readonly]="true" class="mt-8">
                        </mat-form-field>
                </div>
        </div>
        <div class="column p-8">
                <div class="mb-8" >
                        <mat-form-field  appearance="outline" class=" ">
                        <mat-label>Report</mat-label>
                        <input [(ngModel)]="vmReportFilters.graph_module" matInput [readonly]="true" (click)="printSel()"  class="mt-8">
                        </mat-form-field>
                </div> 
        </div>
        <div class="buttons m-12" style="justify-content: center;">
                <button  (click)="viewRpt()" mat-raised-button  class="brownbtn">
                        <mat-icon>print  </mat-icon>
                        <span class="hide-text"> View</span>
                </button>
                <!-- <button  (click)="printRpt()" mat-raised-button color="accent">
                        <mat-icon>print  </mat-icon>
                        <span class="hide-text"> Print</span>
                </button> -->
                <button  (click)="close()" mat-raised-button class="redbtn">
                <mat-icon>clear</mat-icon>
                <span class="hide-text"> Close</span>
                </button>
        </div>

</div>