
/**
 * Shijilesh 18/09/2019 
 * ePROMIS  
 **/
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { VMCommonQuery, VMSearch } from 'app/base/search/search.data';
import { basefunctionsService } from 'app/base/basefunctionsService';
import { globalData } from 'app/base/globalData';
import { UserService } from 'app/base/userservice';
import { Basewindowmaster } from 'app/base/window/Basewindowmaster.component';
import { BaseWindow } from 'app/base/window/BaseWindow.component'; 


import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';




@Component({  selector: 'app-loader-epro',  templateUrl: './loader-epro.component.html',  styleUrls: ['./loader-epro.component.scss'], providers:[DatePipe]})

export class loaderEproComponent extends BaseWindow{ 

  // @Input() dw_dragdropmasters:dw_dragdropmaster;
  
  
  
  constructor(  public route: ActivatedRoute,public router: Router, public ls_gdata:globalData,public _user_service:UserService,public dialog: MatDialog, public adapter: DateAdapter<any>

    ,public _bfs :basefunctionsService
    ,public datePipe : DatePipe
    
    ) {
    super(   route, router, ls_gdata,_user_service,dialog,_bfs,adapter ,datePipe );

    console.log("loading ")
  }

  async ngOnInit() {

    
  }

  
}
