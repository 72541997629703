import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { BaseDialogwindowcomponent } from '../window/BaseDialogwindow.component';
import { UserService } from '../userservice';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';

import { DatePipe, DecimalPipe } from '@angular/common';
import { basefunctionsService } from '../basefunctionsService';
import { VMs_transinfo } from '../window/Basewindow.data';
import { dw_task_history_header, dw_task_history } from './UserTasks.data';
import { SearchComponent } from '../search/search.component';
import { catchError } from 'rxjs/operators';
import { VMCommonQuery } from '../search/search.data';
import { Attachmentcomponent } from '../Attachment/Attachment.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'app-tasks',
  templateUrl: './UserTasks.component.html',
  styleUrls: ['./UserTasks.component.scss'],
  providers:[DatePipe, DecimalPipe]
})
export class UserTasksComponent extends BaseDialogwindowcomponent  {
  S_TRANSINFO:VMs_transinfo;
  vmData: dw_task_history_header;
  Selrow:dw_task_history
  ls_supervisor:string
  
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  
  ds_dw_task_history: MatTableDataSource<dw_task_history>;
  dc_dw_task_history = ['sl_no', 'user_code','assigned_user', 'vr_date','priority_status', 'type_screen', 'remarks','comments','status','closed_user','closed_date','f_closed_user','f_closed_date','attach'];
  constructor(  public _user_service:UserService,public dialog: MatDialog   ,public _bfs :basefunctionsService, public adapter: DateAdapter<any>,
    private dialogRef: MatDialogRef<UserTasksComponent>,@Inject(MAT_DIALOG_DATA) public data: VMs_transinfo,
     public datePipe : DatePipe  ){
    super(   _user_service, dialog, _bfs,adapter,datePipe);  
  
      this.vmData= this.inidw_task_history_header();
      this.f_open()
      this.S_TRANSINFO=this.ini_stransinfo()
      this.data.user_name="Pending"
  }

  async f_open() { 

    
    let l_VMCommonQuery :VMCommonQuery
    l_VMCommonQuery =this.setInis_dynasearch(); 
    l_VMCommonQuery.lstrSQL= "getsupervisor" 
    l_VMCommonQuery=  await this._user_service.getInLineResultAsyc(l_VMCommonQuery)
    if(  l_VMCommonQuery.lstrData1!="Cancel" ){
        this.ls_supervisor=l_VMCommonQuery.lstrData1  || ""
      
    } 


    this.ue_retrieve();
  }

  ini_stransinfo(){
    let ls_dynstructure:  VMs_transinfo= { 
        trc_code : "", 
        trc_name : "", 
        trc_object1 : "", 
        trc_object2 : "", 
        trc_abr : "", 
        top_a_code : "", 
        bot_a_code : "", 
        bot_a_dbcr : 0, 
        auto_verify : "", 
        text1 : "", 
        text2 : "", 
        text3 : "", 
        text4 : "", 
        text5 : "", 
        text6 : "", 
        text7 : "", 
        text8 : "", 
        text9 : "", 
        text10 : "", 
        text11 : "", 
        text12 : "", 
        text13 : "", 
        text14 : "", 
        inv_int : "", 
        acc_ind : "", 
        eff_cost : "", 
        chq_no_ind : "", 
        chq_date_ind : "", 
        chq_bank_ind : "", 
        invoice_rate_ind : 0, 
        chq_details_ind : "", 
        doc_analysis_code : "", 
        doc_refer_pr_code : "", 
        doc_refer_sr_code : "", 
        doc_refer_pi_code : "", 
        doc_refer_si_code : "", 
        doc_refer_po_code : "", 
        doc_refer_so_code : "", 
        doc_refer_grn_code : "", 
        doc_refer_dn_code : "", 
        print_on_save : "", 
        salesman : "", 
        trc_close_date : null,  
        narration_dup : "", 
        narration : "", 
        lastdate_dup : "", 
        book_object : "", 
        list_object : "", 
        register_object : "", 
        internal_entry_code1 : "", 
        internal_entry_code2 : "", 
        close_ind : "", 
        auto_bill_save : "", 
        ref_trc_abr : "", 
        auth1_name : "", 
        auth2_name : "", 
        auth3_name : "", 
        zero_save : "", 
        change_detail_dbcr : "", 
        trc_type : "", 
        graph_syntax : "", 
        qty_dec : 0, 
        rate_dec : 0, 
        amt_dec : 0, 
        credit_card : "", 
        s_dt_fy : null,  
        e_dt_fy : null,  
        s_dt_entry : null,  
        e_dt_entry : null,  
        internal_entry_indicator : "", 
        grn_type : "", 
        ddw_acccode : "", 
        ddw_acccodewithfilter : "", 
        advance : "", 
        user_name : "", 
        br_code : "", 
        br_name:"",
        i_vr_no : "", 
        sto_nega : "", 
        acc_filter : "", 
        vg_zoom : "", 
        cor_c_int : "", 
        cor_days : 0, 
        ent_c_int : "", 
        ent_days : 0, 
        c_l_ctrl : "", 
        vr_dt_edit : "", 
        cost_int : "", 
        sm_cntrl : "", 
        fixed_pric : "", 
        item_dup : "", 
        can_reedit : "", 
        security_level : "", 
        inventory_right : "", 
        windowstate : "", 
        rights : "", 
        object_name : "", 
        arg_1 : "", 
        reference_trc_code : "", 
        menu_code : "", 
        user_id : "", 
        ls_approvalind : "", 
        ls_appstatus : "", 
        ln_doccanapplcnt : 0,  
        approvalcnt : 0,  
        ls_fy_code : "", 
        lvr_no : 0, 
        menu_tree_name : "", 
        top_a_name:"",
        printType:"",
        sr_no:0,
        Result:"",
        cust_feild_cnt:0,
        reportobjectcnt:0,
        posting_method:"",
        attachment_applicable:"N",
        listing_applicable:"N",
        reportobjectsrno:0,
        reportobjectreportname:"",
        listobjectsrno:0,
        listobjectreportname:"",
        lreportwrcnt:0,
        rangeoption:"",
        attachment_rights:"",
        lrelated:0

    }
    return ls_dynstructure
    
}
  inidw_task_history_header(){
  let ldata1 :dw_task_history[]=[];
    let ldata :dw_task_history_header =  {
        menu_name:"",
        menu_code : '',
        Result:'',
        dw_task_historys :ldata1,
      }
        return ldata;
  }  

  inidw_task_history(){
    let ln_srno : number=1;
    if (this.vmData !=undefined){

        if( this.vmData.dw_task_historys.length>0){

            let list: number[] = [];
            for(let key in   this.vmData.dw_task_historys) {
                list[key]=this.vmData.dw_task_historys[key].sl_no;
            }
            ln_srno  = Math.max.apply(Math, list) 
            ln_srno= ln_srno + 1 
        }
    }

    let ldata: dw_task_history={
          menu_code : this.data.menu_code,
          sl_no : ln_srno,
          user_code : localStorage.getItem('vg_userid') ,
          vr_date : this.id_vrdate,
          type_screen : '',
          remarks : '',
          status: "Pending",
          row_status: "New",
          assigned_user:"",
          closed_user: "",
          closed_date: null,
          f_closed_user: "",
          f_closed_date: null,
          priority_status:"Low",
          comments:"",
          ref_menu_code : "",
          ref_sl_no : 0
      }
      return ldata;
  }

  setRow(row:dw_task_history){
    this.Selrow= row;
  }

  setResources(){
    this.ds_dw_task_history= new MatTableDataSource(this.vmData.dw_task_historys);
  }

  async add(){
    try{
      debugger
      
      let l_VMCommonQuery :VMCommonQuery
      l_VMCommonQuery =this.setInis_dynasearch(); 
      l_VMCommonQuery.lstrSQL= "select_task_lastnu" 
      l_VMCommonQuery.lstrParam1=this.data.menu_code  
      l_VMCommonQuery=  await this._user_service.getInLineResultAsyc(l_VMCommonQuery)
      
      
      if( +l_VMCommonQuery.lstrData2 >0 ){
        let resutdt= await this._bfs.f_showmessage_yes_no("Info","Pending ticket(s) found, Are you sure to create new ticket?")
            if (resutdt==false ){
                return   
            }
             
      }
 
        this.vmData.dw_task_historys = this.vmData.dw_task_historys || [];
        this.vmData.dw_task_historys.push(this.inidw_task_history());
        this.setsr_no()

        let i = this.vmData.dw_task_historys.length - 1
        if(this.vmData.dw_task_historys[i].sl_no<+l_VMCommonQuery.lstrData1 ){
          this.vmData.dw_task_historys[i].sl_no =+l_VMCommonQuery.lstrData1
        }
        this.setResources();
     
        
        
       

    }catch(error){this.handleError(error);}
  }

  async setsr_no(){
    debugger
    let l_VMCommonQuery :VMCommonQuery
    l_VMCommonQuery =this.setInis_dynasearch(); 
    l_VMCommonQuery.lstrSQL= "getlastnumtask" 
    l_VMCommonQuery.lstrParam1=this.data.menu_code  
    l_VMCommonQuery=  await this._user_service.getInLineResultAsyc(l_VMCommonQuery)
    let ln= this.vmData.dw_task_historys.length -1 
    
    if( this.vmData.dw_task_historys[ln].sl_no <  +l_VMCommonQuery.lstrData1 ){
      this.vmData.dw_task_historys[ln].sl_no = +l_VMCommonQuery.lstrData1
    }

  }
  delete(){
    try{

      if(!this.Selrow ){
        this._bfs.f_showmessage("Info", "Please select the record ");
        return;
      }
      else{
        let i=this.vmData.dw_task_historys.length
        while (i--) { 
           if(this.vmData.dw_task_historys[i].row_status!="browse"){
              if(this.vmData.dw_task_historys[i].sl_no ==this.Selrow.sl_no){
                this.vmData.dw_task_historys.splice(this.ln_sectrow, 1);
              }
          }
           
        }  
        
        this.setResources();
      }
    }catch(error){this.handleError(error);}
  }

  close(){
    // this.lb_form_enabled=false;
    // console.log(this.lb_form_enabled)
    this.dialogRef.close();
  }


  showall(){
    if (this.data.user_name=="All"){
      this.data.user_name="Pending"
    }
    else{
      this.data.user_name="All"
    }
    this.ue_retrieve()
  }
  showallList(){
    if (this.data.user_name=="All"){
      this.data.user_name="Pending"
    }
    else{
      this.data.user_name="List"
    }
    this.ue_retrieve()
  }
  async ue_retrieve(){ 
    try{ 
        this.vmTrans.strProcess="sp_task_history_browse"
        this.vmTrans.stringjson=  JSON.stringify(this.data) ;  
        this.vmTrans =await this._user_service.getData(this.vmTrans)   
        if(this.vmTrans.Result=="Success"){
            this.vmData=JSON.parse(this.vmTrans.stringjson); 
            this.lb_form_enabled=true 
            this.ds_dw_task_history = new MatTableDataSource(this.vmData.dw_task_historys) 
            
        }
        else{ 
            this._bfs.f_showmessage("Info",this.vmTrans.Result)
            this.lb_form_enabled=true
        }  

    }catch(error){
        this.handleError(error)
    }
}
  ue_browse(event){  
        if(this.lb_form_enabled==false ){return}
        this.lb_form_enabled=false 
        this.lVMSearch =this.setIni_lVMSearch() 
        this.lVMSearch.lstrSQL=  "select_credit_req_brws"
        const dialogConfig = new MatDialogConfig();
        this.lVMSearch.lbSortAsc="N"
        dialogConfig.autoFocus = true;
        dialogConfig.height='77%';
        dialogConfig.width='77%';
        dialogConfig.data= this.lVMSearch 
        dialogConfig.disableClose = true; 
        this.lVMSearch.lstrColName1="Vr #"
        this.lVMSearch.lstrColName2="Vr Date"   
        const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
        
        dialogRef.afterClosed().subscribe(res => {
                if(res){ 
                    this.ls_VMCommonQuery  = res; 
                    if(  this.ls_VMCommonQuery.lstrData1!="Cancel" ){
                        // this.vmData.vr_no =  this.ls_VMCommonQuery.lstrData1;  
                        this.ue_retrieve() 
                    } 
                }
        },(error => this.handleError(error))); 
        
        this.lb_form_enabled=true

    } 

    async wf_mandatory():Promise<boolean>{
      try{
               
          return true
         
      }catch(error){
          this.handleError(error)
      }
  }

    async ue_save(event){
          if(this.lb_form_enabled==false ){return} 
          try{
  
              this.lb_form_enabled=false
  
              if(await this.wf_mandatory()==false){
                  this.lb_form_enabled=true
                  return;            
              }else{
   
                      this.vmTrans.strProcess="sp_task_history_update"
                  this.vmTrans.stringjson=  JSON.stringify(this.vmData) ; 
                  this.vmTrans =await this._user_service.postData(this.vmTrans) 
                  if(this.vmTrans.Result=="Success"){
                      this.lb_form_enabled=true
                      this._bfs.f_showmessage("Info","Saved")
                      this.vmData=JSON.parse(this.vmTrans.stringjson);  
                      this.ue_retrieve()
                  }
                  else{ 
                      this._bfs.f_showmessage("Info",this.vmTrans.Result) 
                      //this.S_TRANSINFO.lvr_no=this.vmData.vr_no 
                      this.lb_form_enabled=true 
                  }   
              }
      
          }catch(error){
              this.handleError(error)
          }
  
   
           
      }

      async status_chnage(element:dw_task_history){
        debugger
        
        if(element.status =="To Be Closed" ){
          element.assigned_user="MPM"
          element.closed_date=this.id_vrdate 
        }
        else if(element.status =="Completed" || element.status =="WIP"){
          element.closed_user=localStorage.getItem('vg_userid') 
          element.closed_date=this.id_vrdate

          if(element.status =="Completed"){
            let resutdt= await this._bfs.f_showmessage_yes_no("Info","Do You want to assign this ticket to supervisor?")
            if (resutdt==true ){ 
              element.status="Closed"
              element.f_closed_user=localStorage.getItem('vg_userid') 
              element.f_closed_date=this.id_vrdate

              this.vmData.dw_task_historys.push(this.inidw_task_history());
              this.setsr_no()
              let lngth= this.vmData.dw_task_historys.length - 1
              this.vmData.dw_task_historys[lngth].assigned_user =this.ls_supervisor
              this.vmData.dw_task_historys[lngth].remarks =element.remarks
              this.vmData.dw_task_historys[lngth].type_screen =element.type_screen
              this.vmData.dw_task_historys[lngth].priority_status =element.priority_status 
              this.vmData.dw_task_historys[lngth].status ="Pending" 
              this.vmData.dw_task_historys[lngth].ref_menu_code =  element.menu_code
              this.vmData.dw_task_historys[lngth].ref_sl_no =  element.sl_no
              this.setResources();
              return
            }
          }


        }
        if(element.status =="Closed"){

          if(localStorage.getItem('vg_userid') =="MPM")
          {
            element.f_closed_user=localStorage.getItem('vg_userid') 
            element.f_closed_date=this.id_vrdate
          }
          else{
            element.status="Pending"
            this._bfs.f_showmessage("Info","Only MPM Has Rights")
          }

        }

      }

      assigned_user_clicked(element:dw_task_history){
        
 
        try{
          if(this.lb_form_enabled==false ){return}
          this.lb_form_enabled=false

          this.lVMSearch =this.setIni_lVMSearch()
          this.lVMSearch.lstrSQL= "Select_task_user"     
          const dialogConfig = new MatDialogConfig();
          this.lVMSearch.lstrColName1="Code"  
          this.lVMSearch.lstrColName2="Name"
          this.lVMSearch.lngColwidth1=30 
          this.lVMSearch.lngColwidth1=40 
          dialogConfig.autoFocus = true;
          dialogConfig.height='77%';
          dialogConfig.width='77%';
          dialogConfig.data=  this.lVMSearch 
          dialogConfig.disableClose = true;
          const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(async res => {
              if(res){ 
                  this.ls_VMCommonQuery  = res; 
                  if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                      element.assigned_user=this.ls_VMCommonQuery.lstrData1;
                                      
                  }                
              }
          },(catchError(this.handleError)));
  
          this.lb_form_enabled=true
      }catch(error){
          this.handleError(error)
      }
    }
      user_code_clicked(element:dw_task_history){
        

        if(element.row_status=="browse"){
          return
        }
        try{
          if(this.lb_form_enabled==false ){return}
          this.lb_form_enabled=false

          this.lVMSearch =this.setIni_lVMSearch()
          this.lVMSearch.lstrSQL= "Select_task_user"     
          const dialogConfig = new MatDialogConfig();
          this.lVMSearch.lstrColName1="Code"  
          this.lVMSearch.lstrColName2="Name"
          this.lVMSearch.lngColwidth1=30 
          this.lVMSearch.lngColwidth1=40 
          dialogConfig.autoFocus = true;
          dialogConfig.height='77%';
          dialogConfig.width='77%';
          dialogConfig.data=  this.lVMSearch 
          dialogConfig.disableClose = true;
          const dialogRef = this.dialog.open(SearchComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(async res => {
              if(res){ 
                  this.ls_VMCommonQuery  = res; 
                  if(  this.ls_VMCommonQuery.lstrData1!="Cancel"){  
                      element.user_code=this.ls_VMCommonQuery.lstrData1;
                                      
                  }                
              }
          },(catchError(this.handleError)));
  
          this.lb_form_enabled=true
      }catch(error){
          this.handleError(error)
      }
        
    }
    ue_attach(element:dw_task_history){
      // alert('ue_attach');
      try{
        debugger
        this.S_TRANSINFO.ls_fy_code="10"
        this.S_TRANSINFO.trc_code="TSK"
          this.S_TRANSINFO.text3 =element.menu_code +'' +element.sl_no
          if ( this.S_TRANSINFO.text3 || this.S_TRANSINFO.lvr_no){
              const dialogConfig = new MatDialogConfig(); 
              dialogConfig.autoFocus = true;
              dialogConfig.height='77%';
              dialogConfig.width='95%';        
              dialogConfig.maxWidth='100%';
              dialogConfig.disableClose = true;  
              debugger
              this.lVMSearch =this.setIni_lVMSearch(); 
              // this.S_TRANSINFO.printType="Listing"
              this.S_TRANSINFO.menu_code=this.ls_menuCode
              this.S_TRANSINFO.attachment_rights="CMDE"
              dialogConfig.data=this.S_TRANSINFO;
              const dialogRef = this.dialog.open(Attachmentcomponent, dialogConfig);
              dialogRef.afterClosed().subscribe(async result => {
                  if(result)
                  {
                      console.log(result);
                  }
              },
              (error => this.handleError(error)));         
          }
          else 
          {
              this._bfs.f_showmessage("Info","Please Save the document and try")
              return
          } 
      }catch(error){
          this.handleError(error)
      }
  
  }
}
