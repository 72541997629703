<app-loader-epro  *ngIf="!lb_form_enabled" class="loader-container"></app-loader-epro>
<div class="page-layout blank p-0" [ngClass]="ls_tabhide!= 'N' ? 'zIndex2Attach' : ''">
    <div fxLayout="column" class="ng-height-100">
        <div class="buttons">
            <ng-template [ngIf]="ls_attached">
                <button (click)="addAttachment()" mat-raised-button color="accent"  *ngIf="lb_new==true" >
                    <mat-icon>add</mat-icon>
                    <span class="hide-text">Add</span>
                </button>

                <button (click)="deleteAttachment()" mat-raised-button color="accent"  *ngIf="lb_delete==true">
                    <mat-icon>delete</mat-icon>
                    <span class="hide-text"> Delete</span>
                </button>
            </ng-template>

            <ng-template [ngIf]="ls_checklist">
                <button (click)="addchecklist()" mat-raised-button color="accent">
                    <mat-icon>add</mat-icon>
                    <span class="hide-text">Add</span>
                </button>

                <button (click)="deletechecklist()" mat-raised-button color="accent">
                    <mat-icon>delete</mat-icon>
                    <span class="hide-text"> Delete</span>
                </button>

            </ng-template>
            <ng-template [ngIf]="ls_policy">
                <button (click)="addpolicy()" mat-raised-button color="accent">
                    <mat-icon>add</mat-icon>
                    <span class="hide-text">Add</span>
                </button>

                <button (click)="deletepolicy()" mat-raised-button color="accent">
                    <mat-icon>delete</mat-icon>
                    <span class="hide-text"> Delete</span>
                </button>

            </ng-template>
            <ng-template [ngIf]="ls_request">
                <button (click)="addreq()" mat-raised-button color="accent">
                    <mat-icon>add</mat-icon>
                    <span class="hide-text">Add</span>
                </button>

                <button (click)="deletereq()" mat-raised-button color="accent">
                    <mat-icon>delete</mat-icon>
                    <span class="hide-text"> Delete</span>
                </button>

            </ng-template>
            <button (click)="ue_save($event)" mat-raised-button color="accent" *ngIf="lb_save==true">
                <mat-icon>save</mat-icon>
                <span class="hide-text">Save</span>
            </button>

            <button (click)="close()" mat-raised-button color="accent">
                <mat-icon>close</mat-icon>
                <span class="hide-text"> Close</span>
            </button>
        </div>
        <div class="p-4 ng-height-100 mt-24">
            <mat-tab-group class="mat-vertical-group mat-attachment" (selectedTabChange)="getTabData($event.index)" >
                <mat-tab label="Attachment">
                    <ng-template mat-tab-label>
                        <!-- <mat-icon>attach_file</mat-icon> -->
                        <span>Attachment</span>
                    </ng-template>
                    <!-- attachment tab -->
                    
                    <div class="table-wrapper ">
                        <table mat-table [dataSource]="ds_dw_attachments" >

                            <ng-container matColumnDef="sub_code">
                                <th mat-header-cell *matHeaderCellDef class=" d-none" >Type</th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm text-center">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input matInput [(ngModel)]="element.sub_code" name="element.sub_code"
                                                [readonly]="true" (click)="sub_code_clicked(element)">
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="sub_code_name">
                                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" class="">Type</th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-lg">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input matInput [(ngModel)]="element.sub_code_name" name="element.sub_code_name"
                                                [readonly]="true">
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="attachmentfile">
                                <th mat-header-cell *matHeaderCellDef class="">Attachment </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-lg">
                                    <div class="space-table">
                                        <div class="box-2 overflow-hidden">
                                            <input [(ngModel)]="element.attachment" id="{{element.attachment}}"  type="file" (change)="uploadFile($event,element)" class="inputFile ng-lg" >  
                                            <label class="label_inputFile ng-lg" for="{{element.attachment}}" *ngIf="!element.attachment">Click to upload Attachment</label> 
                                             <label class="label_inputFile ng-lg" for="{{element.attachment}}" *ngIf="element.attachment">{{element.attachment}}</label> 
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="attachment">
                                <th mat-header-cell *matHeaderCellDef class="">File Name </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-lg">
                                    <div class="space-table">
                                        <div class="box-2 overflow-hidden">
                                            <input matInput [(ngModel)]="element.attachment" name="element.attachment"
                                                [readonly]="true" (click)="viewfile(element)"  [disabled]="!lb_browse"> 
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="mandatory">
                                <th mat-header-cell *matHeaderCellDef class="ng-x-sm">Mandatory </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-x-sm text-center">
                                    <div class="space-table">
                                        <mat-checkbox [checked]="element.mandatory== 'Y'"
                                            (change)="element.mandatory =element.mandatory == 'N' ? 'Y': 'N'"   [disabled]="true" >
                                        </mat-checkbox>

                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="exclude_ind" >
                                <th mat-header-cell *matHeaderCellDef class="ng-x-sm">Exclude </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-x-sm text-center" >
                                    <div class="space-table" *ngIf="!element.attachment">
                                        <mat-checkbox [checked]="element.exclude_ind=='Y'" 
                                            (change)="element.exclude_ind=element.exclude_ind == 'N' || !element.exclude_ind ? 'Y': 'N';exclude_ind(element)">
                                        </mat-checkbox>
                                    </div>
                                    <div class="space-table" *ngIf="element.attachment">
                                        <mat-checkbox [checked]="element.exclude_ind=='Y'" 
                                            (change)="element.exclude_ind=element.exclude_ind == 'N' || !element.exclude_ind ? 'Y': 'N'" [disabled]="true">
                                        </mat-checkbox>
    
                                    </div>
                                </td>
                               
                            </ng-container>

                            <!-- <ng-container matColumnDef="exclude_ind">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm">Exclude </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                    <div class="space-table">
                                        <mat-checkbox [checked]="element.exclude_ind=='Y'" 
                                            (change)="element.exclude_ind=element.exclude_ind == 'N' || !element.exclude_ind ? 'Y': 'N';exclude_ind(element)">
                                        </mat-checkbox>

                                    </div>
                                </td>
                            </ng-container> -->
                            
                            <ng-container matColumnDef="excl_can_attach">
                                <th mat-header-cell *matHeaderCellDef class="ng-x-sm">Days </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-x-sm">
                                    <div class="box-2">
                                        <input matInput [(ngModel)]="element.excl_can_attach" name="element.excl_can_attach"
                                          (blur)="updateRemarks(element)" class="text-center" [readonly]="!this.isEditable">
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="excl_remarks">
                                <th mat-header-cell *matHeaderCellDef class="ng-lg">Exclude Remarks </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-lg">
                                    <div class="box-2">
                                        <input matInput [(ngModel)]="element.excl_remarks" name="element.excl_remarks"
                                        [readonly]="!this.isEditable">
                                    </div>
                                </td>
                            </ng-container>

                            <!-- <ng-container matColumnDef="excl_remarks">
                                <th mat-header-cell *matHeaderCellDef class="ng-lg">Exclude Remarks </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-lg">
                                    <div class="box-2">
                                        <input matInput [(ngModel)]="element.excl_remarks" name="element.excl_remarks"
                                          > 
                                    </div>
                                </td>
                            </ng-container> -->

                            <ng-container matColumnDef="ls_size">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm">Size (MB)</th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input matInput [(ngModel)]="element.flen" name="element.flen"
                                                [readonly]="true">
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="remarks">
                                <th mat-header-cell *matHeaderCellDef class="ng-lg">Remarks</th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-lg">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input matInput [(ngModel)]="element.remarks" name="element.remarks"
                                                [readonly]="false">
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="internal_doc">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm "></th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm text-center">
                                    <div class="space-table">
                                        <mat-checkbox [checked]="element.internal_doc== 'Y'"
                                            (change)="element.internal_doc =element.internal_doc == 'N' ? 'Y': 'N'">
                                            Internal Doc</mat-checkbox>

                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="created_user">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm"> User </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input matInput [(ngModel)]="element.created_user"
                                                name="element.created_user" [readonly]="true" disabled>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="vr_date">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm">Attached Date </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline" class="ng-form-sm" >
                                            <input [(ngModel)]="element.vr_date" name="element.vr_date" matInput
                                                [matDatepicker]="vr_date" [readonly]="true" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="vr_date"></mat-datepicker-toggle>
                                            <mat-datepicker #vr_date></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="att_af_approval">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm text-center">Attached After Approval </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm text-center">
                                    <div class="space-table">
                                        <mat-checkbox [checked]="element.att_af_approval== 'Y'"
                                            (change)="element.att_af_approval =element.att_af_approval == 'N' ? 'Y': 'N'"   [disabled]="true">
                                        </mat-checkbox>

                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="excl_date">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm">Excluded On </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline" class="ng-sm" disabled>
                                            <input [(ngModel)]="element.excl_date" name="element.excl_date" matInput
                                                [matDatepicker]="excl_date" [readonly]="true" >
                                            <mat-datepicker-toggle matSuffix [for]="excl_date"></mat-datepicker-toggle>
                                            <mat-datepicker #excl_date></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="excl_user">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm"> Excluded User </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input matInput [(ngModel)]="element.excl_user"
                                                name="element.excl_user" [readonly]="true" disabled>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>
 

                            <ng-container matColumnDef="close_ind">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm text-center">Closed </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm text-center">
                                    <div class="space-table">
                                        <mat-checkbox [checked]="element.close_ind== 'Y'"
                                            (change)="element.close_ind=element.close_ind == 'N' ? 'Y': 'N';exclude_ind(element)"   [disabled]="true">
                                        </mat-checkbox>

                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="close_date">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm">Closed Date</th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline" class="ng-sm" disabled>
                                            <input [(ngModel)]="element.close_date" name="element.close_date" matInput
                                                [matDatepicker]="close_date" [readonly]="true" >
                                            <mat-datepicker-toggle matSuffix [for]="close_date"></mat-datepicker-toggle>
                                            <mat-datepicker #close_date></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="close_user">
                                <th mat-header-cell *matHeaderCellDef class="ng-sm">Closed User </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input matInput [(ngModel)]="element.close_user"
                                                name="element.close_user" [readonly]="true" disabled>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="dc_dw_attachments" class="theadcol"></tr>
                            <tr mat-row *matRowDef="let element; let i = index; columns: dc_dw_attachments;"
                                (click)="setRowattch(element)"> </tr>

                        </table>
                    </div>
                    <!-- attachment tab ends-->
                </mat-tab>
                 <!-- checklist tab -->
                <mat-tab label="Checklist" *ngIf="this.ls_tabhide!= 'Y'">
                    <ng-template mat-tab-label>
                        <!-- <mat-icon>check</mat-icon> -->
                        <span>Checklist</span>
                    </ng-template>
                    
                    <div class="table-wrapper ">
                        <table mat-table [dataSource]="ds_dw_emp_checklist_detail" >

                            <ng-container matColumnDef="status_name">
                                <th mat-header-cell *matHeaderCellDef class="text-center">Status</th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-md ">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input matInput [(ngModel)]="element.status_name" name="element.status_name"
                                                [readonly]="true" (click)="status_name_clicked(element)">
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="expected_date">
                                <th mat-header-cell *matHeaderCellDef class="ng-lg">Expected Date </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input [(ngModel)]="element.expected_date" name="element.expected_date"
                                                matInput [matDatepicker]="expected_date" [readonly]="false">
                                            <mat-datepicker-toggle matSuffix [for]="expected_date">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #expected_date></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="actual_date">
                                <th mat-header-cell *matHeaderCellDef class="ng-lg">Actual Date </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-sm">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input [(ngModel)]="element.actual_date" name="element.actual_date" matInput
                                                [matDatepicker]="actual_date" [readonly]="true">
                                            <mat-datepicker-toggle matSuffix [for]="actual_date">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #actual_date></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="close_ind">
                                <th mat-header-cell *matHeaderCellDef class="ng-x-sm ">Closed</th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-x-sm text-center">
                                    <div class="space-table">
                                        <mat-checkbox [checked]="element.close_ind== 'Y'"
                                            (change)="element.close_ind =element.close_ind == 'N' ? 'Y': 'N'">
                                        </mat-checkbox>

                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="remarks">
                                <th mat-header-cell *matHeaderCellDef class="ng-lg">Remarks</th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-lg">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input matInput [(ngModel)]="element.remarks" name="element.remarks"
                                                [readonly]="false">
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="doc_file_path">
                                <th mat-header-cell *matHeaderCellDef class="ng-md">Attach </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-md">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input matInput [(ngModel)]="element.doc_file_path"
                                                name="element.doc_file_path" [readonly]="true"
                                                (click)="doc_file_path_clicked(element)">
                                        </mat-form-field>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="sr_no">
                                <th mat-header-cell *matHeaderCellDef class="ng-xx-sm"></th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-xx-sm">
                                    <div class="space-table">
                                        {{ i + 1 }}
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="dc_dw_emp_checklist_detail" class="theadcol"></tr>
                            <tr mat-row *matRowDef="let element; let i = index; columns: dc_dw_emp_checklist_detail;"
                                (click)="setRow(element)"> </tr>

                        </table>
                    </div>
                </mat-tab>
                 <!-- checklist tab ends-->

                  <!-- policy tab -->
                <mat-tab label="Policy" *ngIf="ls_tabhide!= 'Y'">
                    <ng-template mat-tab-label>
                        <!-- <mat-icon>assignment </mat-icon> -->
                        <span>Policy</span>
                    </ng-template>
                    
                    <div class="table-wrapper ">
                        <table mat-table [dataSource]="ds_dw_attachment_policy" >

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>Status</th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="ng-md text-center">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input matInput [(ngModel)]="element.name" name="element.name">
                                        </mat-form-field>
                                    </div>
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <ng-container matColumnDef="comment">
                                <th mat-header-cell *matHeaderCellDef class="">Comment </th>
                                <td mat-cell *matCellDef="let element ; let i = index" class="text-center">
                                    <div class="space-table">
                                        <mat-form-field appearance="outline">
                                            <input [(ngModel)]="element.comment" name="element.comment" matInput [readonly]="true">
                                        </mat-form-field>
                                    </div>
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="dc_dw_attachment_policy" class="theadcol"></tr>
                            <tr mat-row *matRowDef="let element; let i = index; columns: dc_dw_attachment_policy;"
                                (click)="setRow(element)"> </tr>
                            <tr mat-footer-row *matFooterRowDef="dc_dw_attachment_policy"></tr>
                        </table>
                    </div>

                </mat-tab>
                 <!-- policy tab ends-->

                  <!-- request tab -->
                <mat-tab label="Requests">
                    <ng-template mat-tab-label>
                        <!-- <mat-icon>accessible</mat-icon> -->
                        <span>Requests</span>
                    </ng-template>
                    
                    <div class="table-wrapperr mat-elevation-z0 pb-8">
                        <div class=" box-1 m-8" *ngFor="let i = index;let element of dataElements" (click)="setRowCo(element)">
                            <div class="row" >
                                <mat-form-field appearance="outline" class="ng-form-sm">
                                    <mat-label>SL #</mat-label>
                                    <input matInput [(ngModel)]="1 +  '0000' + element.sr_no" name="element.sr_no" [readonly]="true">
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="ng-form-sm">
                                    <mat-label>Vr Date</mat-label>
                                    <input [(ngModel)]="element.vr_date" name="element.vr_date" matInput
                                        [matDatepicker]="vr_date1" [readonly]="true">
                                    <mat-datepicker-toggle matSuffix [for]="vr_date1"></mat-datepicker-toggle>
                                    <mat-datepicker #vr_date1></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="ng-form-sm">
                                    <mat-label>Ref Trc #</mat-label>
                                    <input [(ngModel)]="element.ref_trc_code" name="element.ref_trc_code" matInput
                                        [readonly]="true" (click)="ref_trc_code_clicked(element)">
                                </mat-form-field>
    
                                <mat-form-field appearance="outline">
                                     <mat-label>Ref Trc Name</mat-label>
                                    <input [(ngModel)]="element.trc_name" name="element.trc_name" matInput
                                        [readonly]="true">
                                </mat-form-field>
                            </div>
                            <div class="row">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Remarks</mat-label>
                                        <input matInput [(ngModel)]="element.remarks" name="element.remarks"
                                            [readonly]="false" >
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <mat-form-field appearance="outline" class="ng-form-sm">
                                        <mat-label>Status</mat-label>
                                        <mat-select [(ngModel)]="element.status_ind" name="element.status_ind" class="select">
                                            <mat-option value="Y">Closed</mat-option>
                                            <mat-option value="R">Hold </mat-option>
                                            <mat-option value="P">Pending</mat-option>
                                        </mat-select>
                                    </mat-form-field>


                                </div>
                        </div>
                    </div>
                </mat-tab>
                 <!-- request tab ends-->
            </mat-tab-group>
        </div>
    </div>

</div>