 import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {NgModule} from '@angular/core'; 
import { AuthGuard } from './shared/utils/auth.guard'; 
import { SimpleLayoutComponent } from './layouts/simple-layout.component';
import { FullLayoutComponent } from './layouts/full-layout.component';
import { MenuPageComponent } from './main/menu-page/menu-page.component';


export const routes: Routes = [
    
    {
        path: '',
        redirectTo: '/HomeApproval',
        pathMatch: 'full',canActivate: [AuthGuard]
    },
    { 
        path: '', 
        data: { title: 'Home' },
        children: [
            {
                path: 'account/login',
                loadChildren: () => import( './account/account.module').then(m => m.AccountModule),
            },
            {
                path: 'account/register',
                loadChildren: () => import( './account/register/register.module').then(m => m.registerModule),
            }, 
            {
                path: 'account/forgot',
                loadChildren: () => import( './account/forgotpwd/forgotpwd.module').then(m => m.forgotpwdModule),
            }, 

            {
                //admin\registration :id1/:id2 :code/:state
                path: 'uaepass', 
                loadChildren: () => import( './main/admin/uaepass/uaepass.module').then(m => m.uaepassModule), 
            }, 
             {
                //admin\registration :id1/:id2 :code/:state
                path: 'uaepasslogin/:id', 
                loadChildren: () => import( './main/admin/uaepasslogin/uaepasslogin.module').then(m => m.uaepassloginModule), 
            }, 
        ]
    },

    { 
        path: '',
        //component: FullLayoutComponent,
        canActivate: [AuthGuard],
        data: { title: 'Home' },
        children: [
            //full wd 
            {
                path: 'w_user_group/:id', 
                loadChildren: () => import( './main/Utilities/MenuGroup/MenuGroup.module').then(m => m.MenuGroupModule),
                canActivate: [AuthGuard]
            } ,
            {
                path        : 'usermaster/:id', 
                loadChildren: () => import( './main/Utilities/UserMaster/UserMaster.module').then(m => m.UserMasterModule),
                canActivate: [AuthGuard] 
            },
            {
                path        : 'w_own_user/:id', 
                loadChildren: () => import( './main/Utilities/ownuser/ownuser.module').then(m => m.ownuserModule),
                canActivate: [AuthGuard] 
            },
            {
                path:'approvalStatus',
                loadChildren: () => import('./base/approvalstatus/approvalstatus.module').then(m => m.approvalstatusModule),
            },
            {
                path        : 'w_eas_right_voucher/:id', 
                loadChildren: () => import( './main/Utilities/EAS/ApprovalAssignment/ApprovalAssignment.module').then(m => m.ApprovalAssignmentModule),
                canActivate: [AuthGuard] 
            },  
            {
                path        : 'w_eas_transaction/:id', 
                loadChildren: () => import( './main/Utilities/EAS/EASTransaction/EASTransaction.module').then(m => m.EASTransactionModule),
                canActivate: [AuthGuard] 
            },
            {
                path        : 'erpemail/:id', 
                loadChildren: () => import( './main/Utilities/erpemail/erpemail.module').then(m => m.erpemailModule),
                canActivate: [AuthGuard] 
            },
            {
                path: 'w_approval_batch/:id', 
                loadChildren: () => import( './main/Utilities/EAS/ApprovalBatch/ApprovalBatch.module').then(m => m.ApprovalBatchModule),
                canActivate: [AuthGuard]
            },
            {
                    path: '',
                    redirectTo: '/HomeApproval',
                    pathMatch: 'full',canActivate: [AuthGuard]
            },
            
            { 
                path        : 'Reporting/:id',  
                loadChildren: () => import( './main/Reports/ReportFrame/ReportFrame.module').then(m => m.ReportFrameModule),
                canActivate: [AuthGuard]  
            }, 

            { 
                path        : 'nocrequest/:id',  
                loadChildren: () => import( './main/customer/noc_request/noc_request.module').then(m => m.noc_requestModule),
                canActivate: [AuthGuard]  
            }, 
            { 
                path        : 'companyregistration/:id',  
                loadChildren: () => import( './main/customer/company_registration/company_registration.module').then(m => m.company_registrationModule),
                canActivate: [AuthGuard]  
            }, 
            { 
                path        : 'nocmodification/:id',  
                loadChildren: () => import( './main/customer/noc_modification/noc_modification.module').then(m=>m.noc_modificationModule),
                canActivate: [AuthGuard]  
            }, 
            
            { 
                path        : 'nocsubcategory/:id',  
                loadChildren: () => import( './main/customer/nocsubcategory/nocsubcategory.module').then(m => m.nocsubcategoryModule),
                canActivate: [AuthGuard]  
            }, 

            { 
                path        : 'DReporting/:id',  
                loadChildren: () => import( './main/Reports/ReportDFrame/ReportDFrame.module').then(m => m.ReportDFrameModule),
                canActivate: [AuthGuard]  
            },
            { 
                path        : 'MReporting/:id',  
                loadChildren: () => import( './main/Reports/ReportMFrame/ReportMFrame.module').then(m => m.ReportMFrameModule),
                canActivate: [AuthGuard]  
            }, 
            { 
                path        : 'PReporting/:id',  
                loadChildren: () => import( './main/Reports/ReportPFrame/ReportPFrame.module').then(m => m.ReportPFrameModule),
                canActivate: [AuthGuard]  
            },
            { 
                path        : 'PrintOuts/:id',  
                loadChildren: () => import( './main/Reports/ReportCFrame/ReportCFrame.module').then(m => m.ReportCFrameModule),
                canActivate: [AuthGuard]  
            },
            
            //full wd  ends
 
            { 
                path        : 'report/:id',  
                loadChildren: () => import( './base/DynaReports/DynaReports.module').then(m => m.DynaReportsModule),
                canActivate: [AuthGuard]  
            },
            { 
                path        : 'Payrollreports/:id',  
                loadChildren: () => import( './base/Payrollreports/Payrollreports.module').then(m => m.PayrollreportsModule),
                canActivate: [AuthGuard]  
            },
            { 
                path: 'HomeApproval', 
                loadChildren: () => import('./main/Dashboards/approvals/approvalpending.module').then(m => m.ApprovalPendingModule),
                canActivate: [AuthGuard]
            },
                    
            {
                path: 'Menu', 
                component: MenuPageComponent, canActivate: [AuthGuard]
            },   
            
            {
                path: 'Reports/:id', 
                loadChildren: () => import('./base/reports/report.module').then(m => m.reportModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'dynamasterprint/:id',
             //   dynamasterprint.module.ts app\main\Dyamaster\Print
                loadChildren: () => import( './main/Dyamaster/Print/dynamasterprint.module').then(m => m.dynamasterprintModule),
                canActivate: [AuthGuard]
            },         
             
            
            {
                path        : 'dashboards/analytics',
                loadChildren: () => import( './dashboards/analytics/analytics.module').then(m => m.AnalyticsDashboardModule),
                canActivate: [AuthGuard]
            },
            {
                path        : 'dashboards',
                loadChildren: () => import( './dashboards/project/project.module').then(m => m.ProjectDashboardModule)
                ,canActivate: [AuthGuard],
            },
            {
                path: 'master/:id', 
                loadChildren: () => import( './main/Dyamaster/dynamaster.module').then(m => m.dynamasterModule),
                canActivate: [AuthGuard]
            }, 
                  
            {
                //admin\registration
                path: 'register/:id', 
                loadChildren: () => import( './main/admin/registration/registration.module').then(m => m.registrationModule),
                canActivate: [AuthGuard]
            }, 
           
            { 
                path: 'nocapproval/:id', 
                loadChildren: () => import( './main/admin/nocapproval/nocapproval.module').then(m => m.nocapprovalModule),
                canActivate: [AuthGuard]
            },    
            { 
                path: 'nocrequestview/:id', 
                loadChildren: () => import( './main/admin/noc_requestview/noc_requestview.module').then(m => m.noc_requestviewModule),
                canActivate: [AuthGuard]
            },  
            { 
                path        : 'nocsearch/:id',  
                loadChildren: () => import( './main/customer/noc_search/noc_search.module').then(m => m.noc_searchModule),
                canActivate: [AuthGuard]  
            }, 
            { 
                path: 'uidreport/:id', 
                loadChildren: () => import( './main/customer/uidreport/uidreport.module').then(m => m.uidreportModule),
                canActivate: [AuthGuard]
            },  
            {
                path        : 'w_reset_approval_request/:id', 
                loadChildren: () => import( './main/Utilities/EAS/ResetApproval/ResetApproval.module').then(m => m.ResetApprovalModule),
                canActivate: [AuthGuard]
            },
            { 
                path        : 'company_profile/:id',  
                loadChildren: () => import( './main/customer/company_profile/company_profile.module').then(m => m.company_profileModule),
                canActivate: [AuthGuard]  
            }, 
   
             
        ]
    }   
];
 
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }  