

<!-- <div class="page-layout blank p-24" >  -->
    <div mat-dialog-title class="background">Approval</div>

        <div class="row mt-50" >
             <form  > 
                <div [ngStyle]="formStyle">
                    <div class="">
                        <mat-form-field appearance="outline">  
                            <input  [(ngModel)]= "ls_approval.actiontext" name="ls_approval.actiontext"  type="text"  matInput placeholder="Action"  [readonly]="true"  (click)="selectaction()">    
                        </mat-form-field>
            
                        <!-- <mat-form-field [ngStyle]="ngStyleSmall" >  
                            <input  [(ngModel)]= "ls_approval.priority_text" name="ls_approval.priority_text"  type="text"  matInput placeholder="Priority"  [readonly]="true" (click)="selectpriority()" >    
                        </mat-form-field> -->
                    </div>
                    <div class="">
                        <mat-form-field  appearance="outline">  
                            <input  [(ngModel)]= "ls_approval.role" name="ls_approval.role"  type="text"  matInput placeholder="Role"  [readonly]="true"   >    
                        </mat-form-field>
                  
                    </div> 
                    <div class="">

                            <!-- this.ls_approval.actiontext =='Forward for Response' || this.ls_approval.actiontext=='Approve and Forward'  ||  this.ls_approval.actiontext=='Assigned'  -->
                        <mat-form-field  appearance="outline"  *ngIf="ls_approval.actiontext=='Forward for Response' || ls_approval.actiontext=='Approve and Forward' || ls_approval.actiontext=='Assigned'  ">  
                            <input  [(ngModel)]= "ls_approval.fwd_to" name="ls_approval.fwd_to"  type="text"  matInput placeholder="To User"  [readonly]="true"  (click)="selecttouser()" >    
                        </mat-form-field>
                  
                    </div>

                    <div class="">
                        <mat-form-field  appearance="outline">  
                            <textarea  [(ngModel)]= "ls_approval.remark" name="ls_approval.remark"  type="text"  matInput placeholder="Remarks"      >  </textarea>  
                        </mat-form-field>
                  
                    </div>
                </div>
            </form>
        </div>  

    <mat-dialog-actions>
            <button  (click)="ue_approve()" mat-raised-button class="brownbtn">
                <mat-icon>done</mat-icon>
                <span class="hide-text"> Apply Action</span>
            </button>
            <button   (click)="ue_cancel()" mat-button class="redbtn">
                <mat-icon>clear</mat-icon>
                <span class="hide-text"> Cancel</span>
            </button> 
    </mat-dialog-actions>
    <!-- </div> -->
    
    
     