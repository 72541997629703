import { Pipe,PipeTransform } from '@angular/core';
import {DecimalPipe} from '@angular/common';
@Pipe(
  {name: 'eProCurrency'}
)
export class eProCurrencyPipe  implements PipeTransform{
  constructor(private decimalPipe: DecimalPipe)
  {}
  transform(currencyString: string,decimalPoints:number): any {
    //console.log(currencyString);
    decimalPoints= decimalPoints ? decimalPoints : 2;
    if(currencyString){
        return this.decimalPipe.transform(currencyString, '1.' + decimalPoints + '-' + decimalPoints);
    }else if(currencyString=='0'){
        return this.decimalPipe.transform(0, '1.' + decimalPoints + '-' + decimalPoints);
    }
}}