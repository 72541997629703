import { NgModule } from '@angular/core';   
import { BaseWindow } from 'app/base/window/BaseWindow.component';
import { CommonModule } from '@angular/common';
import { BaseWindowbuttons } from 'app/base/buttons/BaseWindowbuttons.component';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field'; 
import { MatInputModule } from '@angular/material/input'; 
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs' 
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { TableModule } from 'primeng/table'
 
import { FormsModule } from '@angular/forms'; 
import { searchautocloseComponent } from './searchautoclose.component'; 
import { MatIconModule } from '@angular/material/icon';
@NgModule({
    imports     : [ 
        CommonModule, 
        MatButtonModule, 
        MatInputModule, 
        MatDialogModule, 
        MatTableModule,
        MatPaginatorModule, 
        MatIconModule,
        FormsModule ,
        TableModule
 
    ],
    exports     : [ 
        searchautocloseComponent, 
    ], 
    declarations: [  
        searchautocloseComponent, 
    ],
    entryComponents: [searchautocloseComponent   ]
}) 

export class searchautoclose 
{
}
